import { FaChevronDown } from "react-icons/fa6";
import { motion } from "framer-motion";
import React, { Dispatch, SetStateAction, useState } from "react";
import useQuery from '@/hooks/useQuery'

const ReactionButton = (props) => {
  const { reactionsData, userReactions } = props;
  const { putpostRequest } = useQuery()
  const [reactions, setReactions] = useState(reactionsData);
  const [selectedReaction, setSelectedReaction] = useState(userReactions.find(r => r.reactionable_id === props.reactionableId && r.reactionable_type === 'ProjectUpdate')?.reaction);
  const [open, setOpen] = useState(false);

  const toggleReaction = (reaction) => {
    const oldReaction = selectedReaction
    setSelectedReaction(reaction);
    setReactions((pv) => {
      const newReactions = { ...pv };
      newReactions[reaction] = newReactions[reaction] ? newReactions[reaction] + 1 : 1;
      if (oldReaction) { newReactions[oldReaction] = newReactions[oldReaction] - 1; }
      return newReactions;
    });
    setOpen(false);
    putpostRequest(`/api/v3/reactions/toggle_reaction`, 'POST', {
      reaction: reaction,
      reactionable_id: props.reactionableId,
      reactionable_type: props.reactionableType
    }, (err, jsonData) => {
      if (err) { return console.log(err) }
      // noop
    })
  };

  return (
    <div className="flex">
      <motion.div animate={open ? "open" : "closed"} className="relative">
        <button
          onClick={() => setOpen((pv) => !pv)}
          className="flex items-center border-1 border border-cccblue gap-2 px-3 py-2 rounded-md text-cccblue dark:text-white bg-white dark:bg-gray-900 hover:bg-cccblue hover:bg-opacity-20 transition-colors"
        >
          <div className="flex gap-x-2">
            { Object.keys(reactions).length === 0 && <span>👍</span> }
            { reactions.Like > 0 && <span>👍 <span className='text-sm'>{reactions.Like}</span></span> }
            { reactions.Seen > 0 && <span>👀 <span className='text-sm'>{reactions.Seen}</span></span> }
            { reactions.Nope > 0 && <span>👎 <span className='text-sm'>{reactions.Nope}</span></span> }
            { reactions.Rice > 0 && <span>🍚 <span className='text-sm'>{reactions.Rice}</span></span> }
          </div>
          <motion.span variants={iconVariants} className='text-xs'>
            <FaChevronDown />
          </motion.span>
        </button>

        <motion.ul
          initial={wrapperVariants.closed}
          variants={wrapperVariants}
          style={{ originY: "top", translateX: "-50%" }}
          className="flex gap-2 p-2 rounded-lg bg-white dark:bg-gray-900 shadow-xl absolute bottom-[120%] left-[50%] w-96 overflow-hidden"
        >
          <Option toggleReaction={toggleReaction} selectedReaction={selectedReaction} icon={'👍'} text="Like" />
          <Option toggleReaction={toggleReaction} selectedReaction={selectedReaction} icon={'👀'} text="Seen" />
          <Option toggleReaction={toggleReaction} selectedReaction={selectedReaction} icon={'👎'} text="Nope" />
          <Option toggleReaction={toggleReaction} selectedReaction={selectedReaction} icon={'🍚'} text="Rice" />
        </motion.ul>
      </motion.div>
    </div>
  );
};

const Option = ({ text, icon, toggleReaction, selectedReaction }) => {
  return (
    <motion.li
      variants={itemVariants}
      onClick={() => toggleReaction(text)}
      className={`flex items-center gap-2 w-full p-2 text-xs font-medium whitespace-nowrap rounded-md hover:bg-cccblue hover:bg-opacity-20 text-cccblue dark:text-white hover:text-cccblue transition-colors cursor-pointer ${selectedReaction === text ? "bg-cccblue bg-opacity-20" : ""}`}
    >
      <motion.span variants={actionIconVariants}>
        {icon}
      </motion.span>
      <span>{text}</span>
    </motion.li>
  );
};

export default ReactionButton;

const wrapperVariants = {
  open: {
    scaleY: 1,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.1,
    },
  },
  closed: {
    scaleY: 0,
    transition: {
      when: "afterChildren",
      staggerChildren: 0.1,
    },
  },
};

const iconVariants = {
  open: { rotate: 180 },
  closed: { rotate: 0 },
};

const itemVariants = {
  open: {
    opacity: 1,
    y: 0,
    transition: {
      when: "beforeChildren",
    },
  },
  closed: {
    opacity: 0,
    y: -15,
    transition: {
      when: "afterChildren",
    },
  },
};

const actionIconVariants = {
  open: { scale: 1, y: 0 },
  closed: { scale: 0, y: -7 },
};
