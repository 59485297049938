import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js'
import { useHistory } from 'react-router-dom'
import { RadioGroup } from '@headlessui/react'
import SVGIcon from '@/components/icon'
import { CreditCardIcon } from '@heroicons/react/solid'
import WhatsIncluded from '@/pages/SubscriptionForm/WhatsIncluded'
import LegacyNotice from '@/pages/SubscriptionForm/LegacyNotice'
import Downgrade from '@/pages/SubscriptionForm/Downgrade'
import ChangeBillingIntervalWarning from '@/pages/SubscriptionForm/ChangeBillingIntervalWarning'
import PreviewingChange from '@/pages/SubscriptionForm/PreviewingChange'

import DefaultButton from '@/shared/Buttons/Default'
import useQuery from '@/hooks/useQuery'
import useStripeMethods from '@/hooks/useStripeMethods'
import PrimaryButton from '@/shared/Buttons/Primary'
import classNames from '@/utils/classNamesLocal'
import { useGlobalState } from '@/state'

import Loading from '@/shared/Loading'

const options = {
  hidePostalCode: true,
  style: {
    base: {
      'fontSize': '16px',
      'color': '#424770',
      'letterSpacing': '0.025em',
      'fontFamily': 'Source Code Pro, monospace',
      '::placeholder': {
        color: '#aab7c4'
      }
    },
    invalid: {
      color: '#9e2146'
    }
  }
}

const findSelectedPlan = (existingSubscription, validPlans, yearlyPlans) => {
  if (!existingSubscription) { return yearlyPlans[0] }

  const thePlan = validPlans.find(plan => plan.id === existingSubscription.subscriptionPlanId)
  if (thePlan) { return thePlan }

  return yearlyPlans[0]
}

const currentVersionPlans = (subPlans, version) => {
  return subPlans.filter(plan => plan.version === version)
}

const currentMonthlyPlans = (validPlans) => {
  return validPlans.filter(plan => plan.billingInterval === 'monthly')
}

const currentYearlyPlans = (validPlans) => {
  return validPlans.filter(plan => plan.billingInterval === 'yearly')
}

const PlanSelector = (props) => {
  const { setUpdateExisting } = props
  const [currentUser, setCurrentUser] = useGlobalState('currentUser')
  const [subscriptionContainer] = useGlobalState('subscriptionContainer')
  const { subPlans, version, existingSubscription } = subscriptionContainer
  const [paymentMethods, setPaymentMethods] = useState(props.paymentMethods)
  const [newPaymentMethod, setNewPaymentMethod] = useState(true)
  const isBraintree = Boolean(existingSubscription?.braintreeSubscriptionId)
  const [billingInterval, setBillingInterval] = useState(existingSubscription?.billingInterval || 'yearly')
  const validPlans = currentVersionPlans(subPlans, version)
  const monthlyPlans = currentMonthlyPlans(validPlans)
  const yearlyPlans = currentYearlyPlans(validPlans)

  const history = useHistory()
  const [errorMessage, setErrorMessage] = useState(props.errorMessage)
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState(null)
  const [stripeReady, setStripeReady] = useState(false)
  const [loading, setLoading] = useState(false)
  const [quotedPrice, setQuotedPrice] = useState(null)
  const [previewing, setPreviewing] = useState(false)
  const { putpostRequest, getRequest } = useQuery()
  const [selectedPlan, setSelectedPlan] = useState(findSelectedPlan(existingSubscription, validPlans, yearlyPlans))

  let onOldVersion = false
  if (existingSubscription) {
   onOldVersion = existingSubscription.version !== version || currentUser.gold === 'gold'
  }

  const stripe = useStripe()
  const elements = useElements()

  const successfulPurchase = () => {
    setCurrentUser({ ...currentUser, gold: selectedPlan.planName })
    history.push('/dashboard?welcome=true')
  }

  const {
    stripeLoading, stripeErrorMessage, handlePaymentThatRequiresCustomerAction
  } = useStripeMethods(successfulPurchase, currentUser.id, selectedPlan.planName)

  const assignDefaultPaymentMethod = () => {
    const defaultPaymentMethod = paymentMethods.find(paymentMethod => paymentMethod.defaultPaymentMethod)
    if (defaultPaymentMethod) { setSelectedPaymentMethodId(defaultPaymentMethod.id) }
  }

  const changeBilling = (interval) => {
    setBillingInterval(interval)
    if (interval === 'yearly') {
      setSelectedPlan(yearlyPlans[0])
    } else {
      setSelectedPlan(monthlyPlans[0])
    }
  }

  useEffect(() => { setLoading(stripeLoading) }, [stripeLoading])
  useEffect(() => { setErrorMessage(stripeErrorMessage) }, [stripeErrorMessage])

  useEffect(() => {
    if (paymentMethods.length > 0) { setNewPaymentMethod(false) }
    assignDefaultPaymentMethod()
  }, [paymentMethods])

  useEffect(() => {
    if (existingSubscription?.plan === selectedPlan.planName && existingSubscription?.billingInterval === billingInterval) {
      setQuotedPrice('$0.00')
      return
    }

    setQuotedPrice(null)
    if (existingSubscription) {
      const data = {
        plan: selectedPlan.planName,
        billing_interval: billingInterval
      }
      setLoading(true)
      if (isBraintree) {
        getRequest(`/api/v3/braintree/subscriptions/${existingSubscription.braintreeSubscriptionId}/quote`, data, (err, jsonData) => {
          setLoading(false)
          if (err) { setErrorMessage(err) }
          setQuotedPrice(jsonData.quotedPrice)
        })
      } else {
        getRequest(`/api/v3/stripe/subscriptions/${existingSubscription.id}/quote`, data, (err, jsonData) => {
          setLoading(false)
          if (err) { setErrorMessage(err) }
          setQuotedPrice(jsonData.quotedPrice)
        })
      }
    }
  }, [billingInterval, selectedPlan])

  useEffect(() => {
    if (newPaymentMethod === true) {
      setSelectedPaymentMethodId(null)
    } else {
      assignDefaultPaymentMethod()
    }
  }, [newPaymentMethod])

  const onNewPaymentMethodChange = (e) => {
    if (e.error) {
      setErrorMessage('This appears to be an invalid card number.')
    } else {
      setErrorMessage(null)
      if (e.complete) {
        setStripeReady(true)
      } else {
        setStripeReady(false)
      }
    }
  }

  useEffect(() => {
    setStripeReady(Boolean(selectedPaymentMethodId))
  }, [selectedPaymentMethodId])

  const createStripePaymentMethod = async () => {
    setLoading(true)
    const stripePayload = await stripe.createPaymentMethod({ type: 'card', card: elements.getElement(CardElement) })
    setLoading(false)

    if (stripePayload.error || !stripePayload.paymentMethod || !stripePayload.paymentMethod.id) {
      // TODO: spam slack channel with stripePayload.error.message, current_user.id
      setErrorMessage(stripePayload.error.message)
      return
    }
    const newCard = {
      ...stripePayload.paymentMethod.card,
      id: stripePayload.paymentMethod.id,
      ccExpYear: stripePayload.paymentMethod.card.exp_year,
      ccExpMonth: stripePayload.paymentMethod.card.exp_month
    }
    setPaymentMethods([...paymentMethods, newCard])
    return stripePayload.paymentMethod.id
  }

  const purchase = async () => {
    setLoading(true)
    setErrorMessage(null)
    const paymentMethodId = selectedPaymentMethodId || await createStripePaymentMethod()
    setLoading(false)
    if (paymentMethodId) {
      setSelectedPaymentMethodId(paymentMethodId)
      if (existingSubscription) {
        setPreviewing(true)
      } else {
        createSubscription(paymentMethodId)
      }
    }
    /* The createStripePaymentMethod method will handle error messaging if it fails to create a payment method */
  }

  const createSubscription = async (paymentMethodId) => {
    setLoading(true)
    const data = {
      plan: selectedPlan.planName,
      billing_interval: billingInterval,
      payment_method_id: paymentMethodId
    }

    putpostRequest('/api/v3/stripe/subscriptions', 'POST', { subscriptions: data }, async (err, response) => {
      setLoading(false)
      if (err) { setErrorMessage(err) }
      if (response && !response.requiresAction) {
        // TODO: access and credits are not in ccc
        setCurrentUser({ ...currentUser, access: 'member', credits: selectedPlan.credits })

        successfulPurchase()
      }
      if (response && response.requiresAction) {
        await handlePaymentThatRequiresCustomerAction({
          stripe: stripe,
          paymentIntentClientSecret: response.paymentIntentClientSecret,
          paymentMethodId: selectedPaymentMethodId
        })
      }
    })
  }

  if (previewing) {
    return <PreviewingChange
      setPreviewing={setPreviewing}
      selectedPlan={selectedPlan}
      billingInterval={billingInterval}
      isBraintree={isBraintree}
      quotedPrice={quotedPrice}
      selectedPaymentMethodId={selectedPaymentMethodId}
      paymentMethods={paymentMethods}
      stripeReady={stripeReady}
    />
  }

  return <>
      <div className="mt-12 space-y-12 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-x-8">
        <div className='col-span-1'>
          <ul className="flex justify-end gap-x-5 mb-3">
            <li className="flex shadow-sm rounded-md">
              <button onClick={() => changeBilling('monthly') } className={ classNames(
                billingInterval === 'monthly' ? 'bg-cccpurple bg-opacity-20 border-cccpurple text-cccblue ' : 'border-gray-200',
                'w-full inline-flex items-center bg-white dark:bg-gray-700 px-4 py-2 border border-2 text-sm font-medium rounded-md dark:text-gray-200 hover:border-cccpurple focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccpurple')} >
                Monthly Plans </button>
            </li>
            <li className="flex shadow-sm rounded-md">
              <button onClick={() => changeBilling('yearly') } className={ classNames(
                billingInterval === 'yearly' ? 'bg-cccpurple bg-opacity-20 border-cccpurple text-cccblue ' : 'border-gray-200',
                'w-full inline-flex items-center bg-white dark:bg-gray-700 px-4 py-2 border border-2 text-sm font-medium rounded-md dark:text-gray-200 hover:border-cccpurple focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccpurple')} >
                <div className='flex flex-col'>
                  <span>Yearly Plans</span>
                  <span>(8 months free)</span>
                </div>
                </button>
            </li>
          </ul>

          { onOldVersion && <LegacyNotice legacyPlan={onOldVersion} /> }
          { existingSubscription?.downgradeTo && <Downgrade existingSubscription={existingSubscription} /> }
          <RadioGroup value={selectedPlan} onChange={setSelectedPlan}>
            <RadioGroup.Label className="sr-only" hidden>Pricing plans</RadioGroup.Label>
            { billingInterval === 'yearly' && <div className="relative bg-white rounded-md -space-y-px">
              {yearlyPlans.map((plan, planIdx) => (
                <RadioGroup.Option key={`subplan${plan.id}`} value={plan}
                  className={({ checked }) => classNames(
                      planIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                      planIdx === yearlyPlans.length - 1 ? 'rounded-bl-md rounded-br-md' : '',
                      checked ? 'bg-cccpurple bg-opacity-20 border-cccpurple z-10' : 'border-gray-200',
                      'relative border p-4 flex justify-between cursor-pointer md:pl-4 md:pr-6 md:grid md:grid-cols-2 focus:outline-none'
                  )}>
                  {({ active, checked }) => <>
                    <div className="flex items-center text-sm col-span-1">
                      <span className={classNames(
                          checked ? 'bg-cccblue border-transparent' : 'bg-white border-gray-300',
                          active ? 'ring-2 ring-offset-2 ring-indigo-500' : '',
                          'h-4 w-4 rounded-full border flex items-center justify-center'
                        )} aria-hidden="true" >
                        <span className="rounded-full bg-white w-1.5 h-1.5" />
                      </span>
                      <RadioGroup.Label as="span" className={classNames(checked ? 'text-cccblue' : 'text-gray-900', 'ml-3 font-medium capitalize')} >
                        {plan.planName}
                      </RadioGroup.Label>
                    </div>
                    <div className="flex items-center text-sm col-span-1 sm:justify-end">
                      <RadioGroup.Description as="span" className="ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-center">
                        <span className={classNames(checked ? 'text-cccblue' : 'text-gray-900', 'font-medium')}>
                          ${plan.priceMonthlyBilledYearly}/mo billed yearly
                          <div className={classNames(checked ? 'text-gray-700' : 'text-gray-400', 'text-xs')}>{plan.savings}</div>
                        </span>
                      </RadioGroup.Description>
                    </div>
                  </>}
                </RadioGroup.Option>
              ))}
            </div> }
            { billingInterval === 'monthly' && <div className="relative bg-white rounded-md -space-y-px">
              {monthlyPlans.map((plan, planIdx) => (
                <RadioGroup.Option key={`subplan${plan.id}`} value={plan}
                  className={({ checked }) => classNames(
                      planIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                      planIdx === monthlyPlans.length - 1 ? 'rounded-bl-md rounded-br-md' : '',
                      checked ? 'bg-cccpurple bg-opacity-20 border-cccpurple z-10' : 'border-gray-200',
                      'relative border p-4 flex justify-between cursor-pointer md:pl-4 md:pr-6 md:grid md:grid-cols-2 focus:outline-none'
                  )}>
                  {({ active, checked }) => <>
                    <div className="flex items-center text-sm col-span-1">
                      <span className={classNames(
                          checked ? 'bg-cccblue border-transparent' : 'bg-white border-gray-300',
                          active ? 'ring-2 ring-offset-2 ring-indigo-500' : '',
                          'h-4 w-4 rounded-full border flex items-center justify-center'
                        )} aria-hidden="true" >
                        <span className="rounded-full bg-white w-1.5 h-1.5" />
                      </span>
                      <RadioGroup.Label as="span" className={classNames(checked ? 'text-cccblue' : 'text-gray-900', 'ml-3 font-medium capitalize')} >
                        {plan.planName}
                      </RadioGroup.Label>
                    </div>
                    <div className="flex items-center text-sm col-span-1 sm:justify-end">
                      <RadioGroup.Description as="span" className="ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-center">
                        <span className={classNames(checked ? 'text-cccblue' : 'text-gray-900', 'font-medium')}>
                          ${plan.price} / month
                        </span>
                      </RadioGroup.Description>
                    </div>
                  </>}
                </RadioGroup.Option>
              ))}
            </div> }
          </RadioGroup>
          { Boolean(existingSubscription) && <ChangeBillingIntervalWarning existingSubscription={existingSubscription} billingInterval={billingInterval} /> }
          <div className="flex items-center p-6">
            <h4 className="flex-shrink-0 mr-3 bg-gray-100 dark:bg-gray-700 text-sm tracking-wider font-semibold uppercase text-cccblue">
              Total Today
            </h4>
            <div className="flex-1 border-t-2 border-gray-200"></div>
            <div className='ml-5 text-2xl dark:text-gray-100 font-semibold'>
              { !existingSubscription && <>${selectedPlan.price}</> }
              { Boolean(existingSubscription) && quotedPrice === null && <Loading noMessage={true} noLoadingMessage={true} /> }
              { Boolean(existingSubscription) && quotedPrice !== null && <strong className='text-2xl dark:text-gray-100 font-semibold'>{quotedPrice}</strong> }
            </div>
          </div>
          { !isBraintree && <>
            <label htmlFor="card-element" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">
              Credit or Debit Card
            </label>
            <div className='mt-1'>
              { !newPaymentMethod && <>
                { paymentMethods.length > 0 && <>
                  <ul role="list" className="flex flex-col">
                    { paymentMethods.map((pm, idx) => (
                      <li key={pm.id} onClick={() => setSelectedPaymentMethodId(pm.id)} className="relative cursor-pointer">
                        <dl className="mt-3 p-3 bg-gray-50 hover:bg-gray-100 dark:bg-gray-750 dark:hover:bg-gray-800 text-gray-600 dark:text-gray-300 border-2 rounded-md">
                          <dd className="flex flex-col sm:flex-row sm:items-center text-sm justify-start sm:justify-between font-medium sm:mr-6">
                            <div className='flex justify-start'>
                              <CreditCardIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                              <span className='whitespace-nowrap text-sm capitalize'>{pm.brand} ending in <strong>{pm.last4}</strong></span>
                              <span className="text-sm font-medium ml-3">{pm.ccExpMonth}/{pm.ccExpYear}</span>
                            </div>
                            { (selectedPaymentMethodId === pm.id) && <span className="inline-flex items-center px-3 rounded-full text-sm font-medium bg-green-100 text-green-800">Selected</span> }
                          </dd>
                        </dl>
                      </li>
                    ))}
                  </ul>
                  <div className='cursor-pointer text-cccpurple hover:text-cccpurple-light dark:hover:text-cccpurple-light text-sm py-3' onClick={() => setNewPaymentMethod(true)}>Add New Payment Method</div>
                </> }
              </> }
              { newPaymentMethod && <>
                <CardElement id='card-element' options={options} onChange={onNewPaymentMethodChange} />
                { paymentMethods.length > 0 && <>
                  <div className='cursor-pointer text-cccpurple hover:text-cccpurple-light text-sm py-3' onClick={() => setNewPaymentMethod(false)}>Use A Saved Card</div>
                </> }
              </>}
              { errorMessage && <div className='mt-2 text-sm text-red-600'>{errorMessage}</div> }
            </div>
          </> }

          <div className="flex justify-end items-center space-x-2">
            { existingSubscription?.id && <DefaultButton onClick={() => setUpdateExisting(false)} text="Back" /> }
            { isBraintree && <PrimaryButton onClick={() => setPreviewing(true)} loading={loading} text={'Preview Plan Change' } /> }
            { !isBraintree && <PrimaryButton onClick={purchase} loading={loading} disabled={!stripeReady} text={ existingSubscription ? 'Preview Plan Change' : 'Purchase' } /> }
          </div>
          <span className='text-xs text-gray-400 dark:text-gray-400'>We use Stripe to securely process transactions or you can use PayPal.</span>

          { !existingSubscription && <>
            <a href={`/paypal?plan=${selectedPlan.planName}&billingInterval=${billingInterval}`} className='bg-paypalyellow py-2 px-4 flex justify-center items-center cursor-pointer'>
              <SVGIcon name='paypal' className='h-4' />
            </a>
          </> }

          <div className='text-xs text-gray-400 dark:text-gray-400 pt-5'>
            With your purchase today, your subscription will automatically renew every {billingInterval === 'yearly' ? '12 months' : '1 month'}. You will be charged ${selectedPlan.price} on each renewal date unless you cancel in your Subscription settings. If you cancel, previous charges will not be refunded, but you may continue to use the service until the end of the prepaid term. By proceeding, you are agreeing to our Terms of Service.
          </div>
        </div>
        <div className='col-span-2'>
          <WhatsIncluded planId={selectedPlan.planName} comparisonPlanId={existingSubscription?.plan || 'base'} />

        </div>
      </div>
  </>
}

export default PlanSelector

PlanSelector.propTypes = {
  existingSubscription: PropTypes.object,
  setUpdateExisting: PropTypes.func.isRequired,
  paymentMethods: PropTypes.array.isRequired,
  errorMessage: PropTypes.string,
  legacyNotice: PropTypes.string
}
