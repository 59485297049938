import React, { Fragment, useState, useEffect } from 'react'

import { useHistory, Link } from 'react-router-dom'
import { useGlobalState } from '@/state'
import { CalendarIcon, LocationMarkerIcon } from '@heroicons/react/solid'

import useQuery from '@/hooks/useQuery'
import Card from '@/shared/Card'
import PublicDemoRow from '@/pages/Users/PublicProfile/PublicDemoRow'
import PublicEducationRow from '@/pages/Users/PublicProfile/PublicEducationRow'
import PublicRecommendations from '@/pages/Users/PublicProfile/PublicRecommendations'
import PublicCredits from '@/pages/Users/PublicProfile/PublicCredits'
import PublicShowcase from '@/pages/Users/PublicProfile/PublicShowcase'

const PublicProfileGeneral = () => {
  const [profileContainer] = useGlobalState('profileContainer')
  const [educations, setEducations] = useState([])
  const { getRequest } = useQuery()
  const history = useHistory()
  const { user, demos } = profileContainer

  useEffect(() => { fetchData() }, [])

  const fetchData = () => {
    getRequest(`/api/v3/users/${user.username}/educations`, {}, (err, jsonData) => {
      if (err) { /* hooks */ return }

      setEducations(jsonData.educations)
    })
  }

  const handleContentClick = (e) => {
    const targetLink = e.target.closest('a')
    if (!targetLink) { return }

    const to = e.target.getAttribute('data-to')
    const isMention = e.target.getAttribute('data-mention')

    if (isMention === 'mention') {
      e.preventDefault()
      history.push('/' + to)
    }
  }
  return <>
    <div className="w-full mt-1 mx-auto rounded-lg bg-white shadow p-5 text-gray-800 dark:text-gray-300 dark:bg-gray-800">
      <div className="w-full flex mb-4">
        <div className="flex flex-col">
          <h6 className="font-bold text-md dark:text-gray-100">{user.displayName}</h6>
          <Link to={`/${user.username}`} className="text-xs">@{user.username}</Link>
        </div>
        <div className="w-12 text-right">
          <i className="mdi mdi-twitter text-blue-400 text-3xl"></i>
        </div>
      </div>
      { user.headline && <div className="w-full mb-4">
        <p onClick={handleContentClick} className="text-sm" dangerouslySetInnerHTML={{ __html: user.headline }}></p>
      </div> }
      <div className="w-full space-x-5 flex justify-items-start items-center">
        {user.location && <span className="text-gray-800 dark:text-gray-100 flex flex-shrink flex-col sm:flex-row">
          <LocationMarkerIcon className="flex-shrink-0 h-5 w-5 mr-1" aria-hidden="true" /> <span className='text-xs sm:text-sm text-gray-500'> {user.location}</span>
        </span> }
        <span className="text-gray-800 dark:text-gray-100 flex flex-shrink flex-col sm:flex-row">
          <CalendarIcon className="flex-shrink-0 h-5 w-5 mr-1" aria-hidden="true" /> <span className='text-xs sm:text-sm text-gray-500'>Joined {user.createdAt}</span>
        </span>
        <a href="" className="text-gray-500 text-xs sm:text-sm"><span className='text-lg text-gray-800 dark:text-gray-100'>{user.followingCount}</span> Following</a>
        <a href="" className="text-gray-500 text-xs sm:text-sm"><span className='text-lg text-gray-800 dark:text-gray-100'>{user.followersCount}</span> Followers</a>
      </div>
    </div>

    <div className='mt-4' />
    { Boolean(user.description) && <Card light title={`About ${user.displayName}`}>
      <div className="prose max-w-none">
        <div className="text-base text-gray-500 dark:text-gray-300" dangerouslySetInnerHTML={{ __html: user.description }}></div>
      </div>
    </Card> }
    <div className='mt-4' />
    <PublicShowcase />
    <div className='mt-4' />
    { educations.length > 0 && <Card light title='Education'>
      {educations.map((edu) => (
        <PublicEducationRow key={`pubed${edu.id}`} education={edu} />
      ))}
    </Card> }
    <div className='mt-4' />
    { demos.length > 0 && <Card light title='Demos & Samples'>
      { demos.map((demo) => (
        <PublicDemoRow key={`pubdemo${demo.id}`} demo={demo} />
      ))}
    </Card> }
    <div className='mt-4' />
    { Boolean(user.pricing) && <Card light title="Pricing">
      <div className="text-gray-500 dark:text-gray-300 text-sm mt-3" dangerouslySetInnerHTML={{ __html: user.pricing }} />
    </Card> }
    <div className='mt-4' />
    { Boolean(user.lookingFor) && <Card light title={`What ${user.displayName} Is Looking For`}>
      <div className="text-gray-500 dark:text-gray-300 text-sm mt-3" dangerouslySetInnerHTML={{ __html: user.lookingFor }} />
    </Card> }
    <div className='mt-4' />
    <PublicRecommendations />
    <div className='mt-4' />
    <PublicCredits />
  </>
}

export default PublicProfileGeneral
