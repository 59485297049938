import React, { useState } from 'react'
import { CheckIcon, WifiIcon, ChevronDoubleDownIcon } from '@heroicons/react/solid'
import SVGIcon from '@/components/icon'

import useQuery from '@/hooks/useQuery'
import { useGlobalState } from '@/state'
import useModal from '@/hooks/useModalV2'
import Modal from '@/shared/Modal'
import PrimaryButton from '@/shared/Buttons/Primary'
import DefaultButton from '@/shared/Buttons/Default'

const PublishButtonModal = () => {
  const { openModal, closeModal, isOpen } = useModal()
  const [, setToast] = useGlobalState('toast')
  const [manageProjectContainer] = useGlobalState('manageProjectContainer')
  const { project } = manageProjectContainer
  const [status, setStatus] = useState(project.status)
  const [deleteConfirm, setDeleteConfirm] = useState(false)

  const { putpostRequest } = useQuery()
  const [loading, setLoading] = useState(false)

  const cancel = () => {
    setStatus(project.status)
    closeModal()
  }

  const deleteProject = () => {
    const data = { status: 'deleted' }
    setToast(<div className="ml-3 w-0 flex-1 pt-0.5">
      <p className="text-sm font-medium text-green-500">Deleting....</p>
      <p className="mt-1 text-sm text-green-500">Gimme a sec... Takes a couple of ....oh wait, there we go. It's going now..</p>
    </div>)
    closeModal()
    putpostRequest(`/api/v3/manage/projects/${project.id}/change_status`, 'POST', data, (err, jsonData) => {
      setLoading(false)
      if (err) { /* hook */ return }

      if (jsonData.status === 'deleted') { window.location.href = '/manage/projects' }
    })
  }

  const changeStatus = () => {
    const data = { status: status }

    putpostRequest(`/api/v3/manage/projects/${project.id}/change_status`, 'POST', data, (err, jsonData) => {
      setLoading(false)
      if (err) { /* hook */ return }

      setToast(<div className="ml-3 w-0 flex-1 pt-0.5">
        <p className="text-sm font-medium text-green-500">Status changed.</p>
        <p className="mt-1 text-sm text-green-500">Wait a sec. I need to reload the page.</p>
      </div>)

      if (jsonData.status === 'open') { window.location.href = `/manage/projects/${project.id}?published=true` }
      if (jsonData.status === 'completed') { window.location.reload() }
      closeModal()
    })
  }

  return <>
    { project.status === 'draft' && <button onClick={openModal} className="px-4 py-2 text-sm font-medium rounded-md text-white hover:text-white bg-gradient-to-r from-cccpurple via-cccblue to-cccorange hover:from-cccpurple hover:to-cccorange w-full flex items-center justify-center space-x-2" >
      <WifiIcon className='h-4 w-4' />
      <span>Publish!</span>
      <ChevronDoubleDownIcon className='h-4 w-4' />
    </button> }
    { project.status === 'open' && <button onClick={openModal} className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white dark:bg-gray-900 dark:text-white dark:hover:bg-gray-800 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccpurple" >
      <span>Status: Published</span>
      <ChevronDoubleDownIcon className='h-4 w-4' />
    </button> }
    <Modal isOpen={isOpen} closeModal={closeModal} >
      <div className="flex flex-col text-center items-center">
        <h2 className="mb-8 text-xs font-semibold tracking-widest text-gray-800 dark:text-gray-200 uppercase title-font">Change project status</h2>
      </div>

      <div className='flex flex-col space-y-2'>
        { project.status === 'draft' && <div className="flex rounded-md bg-white dark:bg-gray-800 dark:hover:bg-gray-900 hover:bg-gray-100">
          <div onClick={() => setStatus('draft')} className='flex w-full cursor-pointer'>
            <div className='flex-shrink-0 flex items-center justify-center w-16 text-white text-sm font-medium rounded-l-md  border-t border-l border-b border-gray-200 dark:border-gray-700'>
              <SVGIcon name='icon-draft' className='h-10 w-10' />
            </div>
            <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 dark:border-gray-700 rounded-r-md">
              <div className="flex-1 px-4 py-2 text-sm">
                <div className="text-gray-900 font-medium dark:text-gray-100"> Draft </div>
                <p className="text-xs text-gray-500 dark:text-gray-400">Make as many edits as you need. No one can see this but you.</p>
              </div>
              <div className='w-5'>{ status === 'draft' && <CheckIcon className="h-5 w-5 text-cccblue" aria-hidden="true" /> }</div>
            </div>
          </div>
        </div> }
        <div className="flex rounded-md bg-white dark:bg-gray-800 dark:hover:bg-gray-900 hover:bg-gray-100">
          <div onClick={() => setStatus('open')} className='flex w-full cursor-pointer'>
            <div className='flex-shrink-0 flex items-center justify-center w-16 text-white text-sm font-medium rounded-l-md  border-t border-l border-b border-gray-200 dark:border-gray-700'>
              <SVGIcon name='icon-publish' className='h-10 w-10' />
            </div>
            <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 dark:border-gray-700 rounded-r-md">
              <div className="flex-1 px-4 py-2 text-sm">
                <div className="text-gray-900 font-medium dark:text-gray-100"> Published </div>
                <p className="text-xs text-gray-500 dark:text-gray-400">Make this project live. You can change who can view this project on the next page.</p>
              </div>
              <div className='w-5'>{ status === 'open' && <CheckIcon className="h-5 w-5 text-cccblue" aria-hidden="true" /> }</div>
            </div>
          </div>
        </div>
        { project.status === 'open' && <div className="flex rounded-md bg-white dark:bg-gray-800 dark:hover:bg-gray-900 hover:bg-gray-100">
          <div onClick={() => setStatus('completed')} className='flex w-full cursor-pointer'>
            <div className='flex-shrink-0 flex items-center justify-center w-16 text-white text-sm font-medium rounded-l-md  border-t border-l border-b border-gray-200 dark:border-gray-700'>
              <SVGIcon name='icon-manage-projects' className='h-10 w-10' />
            </div>
            <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 dark:border-gray-700 rounded-r-md">
              <div className="flex-1 px-4 py-2 text-sm">
                <div className="text-gray-900 font-medium dark:text-gray-100"> Completed </div>
                <p className="text-xs text-gray-500 dark:text-gray-400">Mark as completed. Once you mark this as completed, you cannot re-open the project.</p>
              </div>
              <div className='w-5'>{ status === 'completed' && <CheckIcon className="h-5 w-5 text-cccblue" aria-hidden="true" /> }</div>
            </div>
          </div>
        </div> }

        { deleteConfirm && <div className="rounded-md bg-red-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <svg className="h-5 w-5 text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fillRule="evenodd" d="M19 10.5a8.5 8.5 0 11-17 0 8.5 8.5 0 0117 0zM8.25 9.75A.75.75 0 019 9h.253a1.75 1.75 0 011.709 2.13l-.46 2.066a.25.25 0 00.245.304H11a.75.75 0 010 1.5h-.253a1.75 1.75 0 01-1.709-2.13l.46-2.066a.25.25 0 00-.245-.304H9a.75.75 0 01-.75-.75zM10 7a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
              </svg>
            </div>
            <div className="ml-3 flex-1 md:flex md:justify-between">
              <p className="text-sm text-red-700">This will delete all data regarding this project. This cannot be undone.</p>
            </div>
          </div>
        </div> }
      </div>

      <div className="flex justify-between space-x-2 mt-4">
        { !deleteConfirm && <button className='text-xs text-cccblue' onClick={() => setDeleteConfirm(true)}>Delete</button> }
        { deleteConfirm && <button className='text-xs text-red-600' onClick={deleteProject}>Really delete this whole project?</button> }
        <div className="flex justify-end space-x-2">
          <DefaultButton onClick={cancel} text='Cancel' />
          <PrimaryButton onClick={changeStatus} loading={loading} text='Confirm' />
        </div>
      </div>
    </Modal>
  </>
}

export default PublishButtonModal
