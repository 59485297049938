import React, { useState, useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'
import { ChatAltIcon, FolderOpenIcon, FilmIcon, WifiIcon } from '@heroicons/react/solid'
import useQuery from '@/hooks/useQuery'

import Loading from '@/shared/Loading'
import Card from '@/shared/Card'
import UserHoverCard from '@/shared/UserHoverCard'

import FeedSubmissionRow from '@/pages/Dashboard/FeedSubmissionRow'
import FeedCreditRow from '@/pages/Dashboard/FeedCreditRow'
import { FeedProjectRow, FeedRowProjectUpdateRow } from '@/pages/Dashboard/FeedProjectAndUpdatesRow'

const FeedCommentRow = ({ feed }) => {
  return <li className='bg-white dark:bg-gray-700 rounded-lg border border-gray-200 dark:border-gray-700'>
      <div className="p-1">
        <div className='flex justify-between'>
          <div className="flex items-start space-x-3 overflow-hidden">
            <div className="relative m-1">
              <img className="h-10 w-10 rounded-sm bg-gray-200 flex items-center justify-center ring-4 ring-cccorange" src={feed.publicImageUrl} alt="" />

              <span className="absolute -bottom-0.5 -right-1 bg-cccorange rounded-tl px-0.5 py-px">
                <ChatAltIcon className="h-4 w-4 text-white" aria-hidden="true" />
              </span>
            </div>
            <div className="min-w-0 flex-1">
              <div className='flex flex-col sm:flex-row'>
                <UserHoverCard username={feed.username} displayName={feed.displayName} />
                <p className="mt-0.5 text-sm text-gray-500 sm:ml-1">commented on</p>
                <a href={feed.commentableUrl} className="sm:ml-1 font-medium text-cccblue cursor-pointer">
                  {feed.commentableName}
                </a>
              </div>
              <div className="mt-1 text-sm text-gray-600 dark:text-gray-400 truncate">
                {feed.body}
              </div>
            </div>
          </div>
          <div className="mt-0.5 text-sm text-gray-500 ml-1">
            {feed.timeAgo}
          </div>
        </div>
        <div className="mt-2 flex space-x-8">
          <div className="flex space-x-6">
            <button type="button" className="flex space-x-1 text-gray-400 hover:text-gray-500 dark:text-gray-400 dark:hover:text-gray-300 items-center">
              <ChatAltIcon className="h-5 w-5" aria-hidden="true" />
              <span className="text-xs">Reply</span>
            </button>
          </div>
          <div className="flex space-x-6">
            <button type="button" className="flex space-x-1 text-gray-400 hover:text-gray-500 dark:text-gray-400 dark:hover:text-gray-300 items-center">
              <FolderOpenIcon className="h-5 w-5" aria-hidden="true" />
              <span className="text-xs">Full Context</span>
            </button>
          </div>
        </div>
      </div>
    </li>
}

const FeedProjectCountRow = ({ feed }) => {
  return <li className='bg-white dark:bg-gray-700 rounded-lg border border-gray-200 dark:border-gray-700'>
      <div className="p-1">
        <div className='flex justify-between'>
          <div className="flex items-start space-x-3 overflow-hidden items-center">
            <div className="relative m-1">
              <img className="h-10 w-10 rounded-sm bg-gray-200 flex items-center justify-center ring-4 ring-cccpurple" src={'https://ddppjbdexhxzj.cloudfront.net/avatars/CCC_Cover_UNSURE.svg'} alt="" />

              <span className="absolute -bottom-0.5 -right-1 bg-cccpurple rounded-tl px-0.5 py-px">
                <FilmIcon className="h-4 w-4 text-white" aria-hidden="true" />
              </span>
            </div>
            <div className="min-w-0 flex-1">
              <div className='flex flex-col sm:flex-row'>
                <p className="mt-0.5 text-sm text-gray-500 sm:ml-1">There are</p>
                <a href={'/search?order_by=listed_at'} className="sm:mx-1 font-medium text-cccblue cursor-pointer">
                  {feed.projectCount} new projects
                </a>
                <p className="mt-0.5 text-sm text-gray-500">for you to review</p>
              </div>
            </div>
          </div>
          <div className="mt-0.5 text-sm text-gray-500 ml-1">
            {feed.timeAgo}
          </div>
        </div>
      </div>
    </li>
}

const FeedRow = ({ feed, voteIds, favoriteIds }) => {
  return <>
    { feed.objectType === 'Audition' && <FeedSubmissionRow feed={feed} voteIds={voteIds} /> }
    { feed.objectType === 'ChosenSubmission' && <FeedSubmissionRow feed={feed} cast={true} voteIds={voteIds} /> }
    { feed.objectType === 'RoleUserAssign' && <FeedSubmissionRow feed={feed} cast={true} voteIds={voteIds} /> }
    { feed.objectType === 'ProjectUpdate' && <FeedRowProjectUpdateRow feed={feed} /> }
    { feed.objectType === 'Project' && <FeedProjectRow feed={feed} favoriteIds={favoriteIds} /> }
    { feed.objectType === 'Comment' && <FeedCommentRow feed={feed} /> }
    { feed.objectType === 'ProjectCount' && <FeedProjectCountRow feed={feed} /> }
    { feed.objectType === 'Credit' && <FeedCreditRow feed={feed} /> }
  </>
}

const Feed = () => {
  const { getRequest } = useQuery()
  const [end, setEnd] = useState(false)
  const [loading, setLoading] = useState(true)

  const [state, setState] = useReducer(
    (state, newState) => (
      { ...state, ...newState }), {
        feeds: [],
        voteIds: [],
        favoriteIds: [],
        startsAt: null
      }
    )
  const { feeds, voteIds, favoriteIds, startsAt } = state

  useEffect(() => {
    getRequest('/api/v3/dashboard/feed', {}, (err, jsonData) => {
      setLoading(false)
      if (err) { /* handled in hook */ return }

      setState({
        feeds: jsonData.feeds,
        voteIds: jsonData.voteIds,
        favoriteIds: jsonData.favoriteIds,
        startsAt: jsonData.startsAt
      })
    })
  }, [])

  useEffect(() => {
    if (!startsAt) return

    if (feeds.length < 50) { loadMore() }
  }, [startsAt])

  const loadMore = () => {
    setLoading(true)
    getRequest('/api/v3/dashboard/feed', { ends_at: startsAt }, (err, jsonData) => {
      setLoading(false)
      if (err) { /* handled in hook */ return }
      if (jsonData.feeds.length > 0) {
        setState({
          feeds: [...feeds, ...jsonData.feeds],
          voteIds: [...voteIds, ...jsonData.voteIds],
          favoriteIds: [...favoriteIds, ...jsonData.favoriteIds],
          startsAt: jsonData.startsAt
        })
      } else {
        setEnd(true)
      }
    })
  }

  return <>
    <Card title={
      <span className='flex items-center'>
        <WifiIcon className="h-6 w-6 mr-1" />
        Latest Activity
      </span> }
      footer={!end && <button onClick={loadMore} className='text-cccblue hover:text-cccblue-alt'>Load More</button> }
      light>

      { feeds.length === 0 && <div className="text-sm dark:text-white">CCC is currently experiencing heavy <b>loads</b>. It's painful in places we'd rather not talk about right now. We are trying to clear the ...er... database. In the meantime, this feature is temporarily disabled.</div> }
      <ul className="space-y-3">
        { feeds.map((feed, idx) => <FeedRow
          key={`feed${feed.objectType}${idx}`}
          feed={feed}
          voteIds={voteIds}
          favoriteIds={favoriteIds}
        />) }
      </ul>
      { loading && <Loading /> }
      { end && <div className='p-2 text-gray-700 dark:text-gray-300'>That's the end, mate. You've reach the end. We don't have historical data beyond this point.</div> }
    </Card>
  </>
}
export default Feed

FeedCommentRow.propTypes = {
  feed: PropTypes.object.isRequired
}
FeedProjectCountRow.propTypes = {
  feed: PropTypes.object.isRequired
}
FeedRow.propTypes = {
  feed: PropTypes.object.isRequired,
  voteIds: PropTypes.array.isRequired,
  favoriteIds: PropTypes.array.isRequired
}
