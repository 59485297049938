import React, { Fragment, useState, useEffect, useRef } from 'react'
import { Listbox, Transition, Switch } from '@headlessui/react'

import classNames from '@/utils/classNamesLocal'
import { EyeIcon, EyeOffIcon, FingerPrintIcon, CheckIcon, ChevronDownIcon } from '@heroicons/react/solid'
import { useGlobalState } from '@/state'
import useQuery from '@/hooks/useQuery'

import Card from '@/shared/Card'

const publishingOptions = [
  { title: 'public', description: 'Your profile is public to everyone.' },
  { title: 'followers', description: 'Only the people you follow can view your profile. Your profile will not be listed in search.' },
  { title: 'private', description: 'Your profile is private.' }
]

const Options = () => {
  const [user] = useGlobalState('userContainerUser')
  const [visibility, setVisibility] = useState(user.visibility)
  const { putpostRequest } = useQuery()
  const [isOnline, setIsOnline] = useState(user.isOnline)
  const [showFeaturedMembers, setShowFeaturedMembers] = useState(user.showFeaturedMembers)
  const [canShowActivity, setCanShowActivity] = useState(user.canShowActivity)
  const [canShowAchievements, setCanShowAchievements] = useState(user.canShowAchievements)
  const isComponentMounted = useRef(0)

  useEffect(() => {
    isComponentMounted.current += 1
    if (isComponentMounted.current <= 1) { return }

    const data = {
      is_online: isOnline,
      can_show_achievements: canShowAchievements,
      can_show_activity: canShowActivity,
      show_featured_members: showFeaturedMembers,
      visibility: visibility
    }
    console.log(data)
    putpostRequest(`/api/v3/profiles/${user.profileId}`, 'PATCH', { profile: data }, (err, jsonData) => {
      if (err) { /* handled in hook */ }
    })
  }, [isOnline, canShowActivity, canShowAchievements, visibility, showFeaturedMembers])

  return (
    <div>
      <div className="md:grid md:grid-cols-3 md:gap-6">
        <div className="md:col-span-1">
          <div className="px-4 sm:px-0">
            <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-100">Customization Options</h3>
          </div>
        </div>
        <Card>
          <div className="grid grid-cols-3 gap-6">
            <div className='md:col-span-2 col-span-3'>
              <div className="flex items-center justify-between">
                <span className="flex-grow flex flex-col">
                  <span className="text-sm font-medium text-gray-900 dark:text-gray-200">Profile visibility</span>
                  <span className="text-sm text-gray-500">Set who can search for and view your profile</span>
                </span>
                <Listbox value={visibility} onChange={setVisibility}>
                  {({ open }) => (
                    <>
                      <Listbox.Label className="sr-only" hidden>Change visibility</Listbox.Label>
                      <div className="relative">
                        <div className="inline-flex shadow-sm rounded-md divide-x divide-white">
                          <div className="relative z-0 inline-flex shadow-sm rounded-md divide-x divide-white">
                            <div className="relative inline-flex items-center bg-cccblue py-2 pl-3 pr-4 border border-transparent rounded-l-md shadow-sm text-white w-32">
                              { visibility === 'followers' && <FingerPrintIcon className="h-5 w-5" aria-hidden="true" /> }
                              { visibility === 'private' && <EyeOffIcon className="h-5 w-5" aria-hidden="true" /> }
                              { visibility === 'public' && <EyeIcon className="h-5 w-5" aria-hidden="true" /> }
                              <p className="ml-2.5 text-sm font-medium capitalize">{visibility}</p>
                            </div>
                            <Listbox.Button className="relative inline-flex items-center bg-cccblue p-2 rounded-l-none rounded-r-md text-sm font-medium text-white hover:bg-cccblue focus:outline-none focus:z-10 focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-cccblue">
                              <span className="sr-only">Change visibility</span>
                              <ChevronDownIcon className="h-5 w-5 text-white" aria-hidden="true" />
                            </Listbox.Button>
                          </div>
                        </div>

                        <Transition show={open} as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0" >
                          <Listbox.Options static className="origin-top-right absolute z-10 right-0 mt-2 w-72 rounded-md shadow-lg overflow-hidden bg-white dark:bg-gray-800 divide-y divide-gray-200 ring-1 ring-black ring-opacity-5 focus:outline-none" >
                            {publishingOptions.map((option) => (
                              <Listbox.Option key={option.title}
                                className={({ active }) =>
                                  classNames(
                                    active ? 'text-white bg-cccblue' : 'text-gray-900 dark:text-gray-100',
                                    'cursor-default select-none relative p-2 text-sm'
                                  )
                                }
                                value={option.title}
                              >
                                {({ visibility, active }) => (
                                  <div className="flex flex-col">
                                    <div className="flex justify-between">
                                      <p className={visibility ? 'font-semibold capitalize' : 'font-normal capitalize'}>{option.title}</p>
                                      {visibility ? (
                                        <span className={active ? 'text-white' : 'text-cccblue'}>
                                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                        </span>
                                      ) : null}
                                    </div>
                                    <p className={classNames(active ? 'text-white' : 'text-gray-500 dark:text-gray-200 dark:bg-gray-800', 'mt-2')}>
                                      {option.description}
                                    </p>
                                  </div>
                                )}
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </Transition>
                      </div>
                    </>
                  )}
                </Listbox>
              </div>
            </div>
            <div className='md:col-span-2 col-span-3'>
              <Switch.Group as="div" className="flex items-center justify-between">
                <Switch.Label as="span" className="flex-grow flex flex-col" passive>
                  <span className="text-sm font-medium text-gray-900 dark:text-gray-200">Online indicator</span>
                  <span className="text-sm text-gray-500">People can see if I'm currently online</span>
                </Switch.Label>
                <Switch
                  checked={isOnline}
                  onChange={setIsOnline}
                  className={classNames(
                    isOnline ? 'bg-cccpurple' : 'bg-gray-300 dark:bg-gray-500',
                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccpurple'
                  )}
                >
                  <span className="sr-only" hidden>People can see if I'm currently online</span>
                  <span
                    aria-hidden="true"
                    className={classNames(
                      isOnline ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                    )}
                  />
                </Switch>
              </Switch.Group>
            </div>
            <div className='md:col-span-2 col-span-3'>
              <Switch.Group as="div" className="flex items-center justify-between">
                <Switch.Label as="span" className="flex-grow flex flex-col" passive>
                  <span className="text-sm font-medium text-gray-900 dark:text-gray-200">Recent Activity</span>
                  <span className="text-sm text-gray-500">Show activity such as comments, submissions, and new jobs on my profile</span>
                </Switch.Label>
                <Switch
                  checked={canShowActivity}
                  onChange={setCanShowActivity}
                  className={classNames(
                    canShowActivity ? 'bg-cccpurple' : 'bg-gray-300 dark:bg-gray-500',
                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccpurple'
                  )}
                >
                  <span className="sr-only" hidden>Show activity such as comments, submissions, and new jobs on my profile</span>
                  <span
                    aria-hidden="true"
                    className={classNames(
                      canShowActivity ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                    )}
                  />
                </Switch>
              </Switch.Group>
            </div>
            <div className='md:col-span-2 col-span-3'>
              <Switch.Group as="div" className="flex items-center justify-between">
                <Switch.Label as="span" className="flex-grow flex flex-col" passive>
                  <span className="text-sm font-medium text-gray-900 dark:text-gray-200">Achievements</span>
                  <span className="text-sm text-gray-500">Show my site achievements on my profile</span>
                </Switch.Label>
                <Switch
                  checked={canShowAchievements}
                  onChange={setCanShowAchievements}
                  className={classNames(
                    canShowAchievements ? 'bg-cccpurple' : 'bg-gray-300 dark:bg-gray-500',
                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccpurple'
                  )}
                >
                  <span className="sr-only" hidden>Show my site achievements on my profile</span>
                  <span
                    aria-hidden="true"
                    className={classNames(
                      canShowAchievements ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                    )}
                  />
                </Switch>
              </Switch.Group>
            </div>
            <div className='md:col-span-2 col-span-3'>
              <hr />
            </div>
            <div className='md:col-span-2 col-span-3'>
              <p className='text-gray-600 dark:text-gray-400 text-lg'>Paid Options</p>
              <Switch.Group as="div" className="flex items-center justify-between">
                <Switch.Label as="span" className="flex-grow flex flex-col" passive>
                  <span className="text-sm font-medium text-gray-900 dark:text-gray-200">Show Featured Members</span>
                  <span className="text-sm text-gray-500">Featured members will show up in the sidebar of your profile. Toggling this off is a paid feature.</span>
                </Switch.Label>
                <Switch
                  checked={showFeaturedMembers}
                  onChange={user.canHideUserSidebarSuggestions ? setShowFeaturedMembers : () => {}}
                  className={classNames(
                    showFeaturedMembers ? 'bg-cccpurple' : 'bg-gray-300 dark:bg-gray-500',
                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccpurple'
                  )}
                >
                  <span className="sr-only" hidden>Featured members will show up in the sidebar of your profile.</span>
                  <span
                    aria-hidden="true"
                    className={classNames(
                      showFeaturedMembers ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                    )}
                  />
                </Switch>
              </Switch.Group>
            </div>
          </div>
        </Card>
      </div>
    </div>
  )
}

const ProfileOptions = () => {
  return (<>
    <Options />
  </>
  )
}
export default ProfileOptions
