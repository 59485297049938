import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useGlobalState } from '@/state'
import useQuery from '@/hooks/useQuery'
import DefaultButton from '@/shared/Buttons/Default'
import StatusPill from '@/shared/StatusPill'
import useModal from '@/hooks/useModalV2'
import { randomToastSuccess } from '@/shared/Toast'

const RoleUserRow = ({ roleUser }) => {
  const { openModal, closeModal, isOpen } = useModal()
  const { role } = roleUser
  const [, setToast] = useGlobalState('toast')
  const { putpostRequest, getRequest } = useQuery()
  const [hide, setHide] = useState(false)
  const [status, setStatus] = useState(roleUser.talentStatus)
  let pillColor = 'default'
  if (status === 'open') {
    pillColor = 'warning'
  } else if (status === 'closed') {
    pillColor = 'success'
  }

  const toggle = () => {
    const newStatus = status === 'incompleted' ? 'completed' : 'incompleted'
    setStatus(newStatus)
    setHide(true)
    putpostRequest(`/api/v3/role_users/${roleUser.id}`, 'PATCH', { role_user: { talent_status: newStatus } }, (err, jsonData) => {
      if (err) { /* hook */ return }

      setToast(randomToastSuccess())
    })
  }

  if (hide) return null

  return <li className='bg-white dark:bg-gray-800 p-2 rounded-md relative'>
    <div className='grid sm:grid-cols-2'>
      <div className='flex flex-col grid-cols-1 space-y-2'>
        <div className="flex sm:items-center  flex-wrap sm:flex-nowrap">
          <div className="flex-shrink-0 hidden sm:block">
            <img className='h-20 w-20 rounded-md' src={role.imageUrl} alt={role.name} />
          </div>
          <div className="min-w-0 flex-auto px-0 sm:px-4 md:gap-4 w-full">
            <div className='flex flex-col truncate space-y-2'>
              <span className='flex justify-start space-x-4'>
                <span className="inline-flex items-center rounded-md bg-cccblue-alt bg-opacity-20 px-2.5 py-0.5 text-sm font-medium text-cccblue">{role.kind}</span>
                <StatusPill status={pillColor} text={status} />
              </span>
              <div className="font-bold dark:text-gray-300">{role.name}</div>
              <a href={`/projects/${role.projectSlug}`} className="text-sm">{role.projectName}</a>
            </div>
          </div>
        </div>
        <div>
        </div>
      </div>
      <span className="grid-cols-1">
        <div className="bg-cccblue bg-opacity-20 p-2 rounded-md">
          <div className='flex flex-col text-gray-900 dark:text-gray-100'>
            <div>Actions:</div>
            <div className='flex justify-start space-x-3 items-center'>
              <DefaultButton onClick={toggle} text={`${status === 'completed' ? 'Mark as Not Done' : 'Mark as Done'}`} />
              <a href={`/role_users/${roleUser.id}/collaborate`} className='inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white dark:bg-gray-900 dark:text-white dark:hover:bg-gray-800 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccpurple'>Collaborate</a>
            </div>
          </div>
        </div>
      </span>
    </div>
  </li>
}

export default RoleUserRow

RoleUserRow.propTypes = {
  roleUser: PropTypes.object.isRequired
}
