import React, { useState, useRef, useReducer, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { useDebouncedEffect } from '@/utils/useDebouncedEffect'
import { SidebarAd, MobileAd, MobileEducationAd, SidebarEducationAd, MobileVideomancyAd, SidebarVideomancyAd } from '@/Layout/Ads'
import Card from '@/shared/Card'
import { useGlobalState } from '@/state'
import classNames from '@/utils/classNamesLocal'
import StandAloneAudioPlayer from '@/shared/StandAloneAudioPlayer'
import PlayPauseButton from '@/shared/Buttons/PlayPauseButton'
import Loading from '@/shared/Loading'
import StatusPill from '@/shared/StatusPill'
import useQuery from '@/hooks/useQuery'
import SVGIcon from '@/components/icon'
import Pagination from '@/shared/Pagination'
import TagAutocomplete from '@/shared/TagAutocomplete'
import { CheckIcon, XIcon } from '@heroicons/react/solid'

const talentKindHash = {
  'voice_actor': { image: 'voiceactor', text: 'Voice Actor', to_db: 'voice_actor' },
  'live_actor': { image: 'actor', text: 'Actor', to_db: 'live_actor' },
  'singer': { image: 'singer', text: 'Singer', to_db: 'singer' },
  'artist': { image: 'artist', text: 'Artist', to_db: 'artist' },
  'animator': { image: 'animator', text: 'Animator', to_db: 'animator' },
  'video_editor': { image: 'video-editor', text: 'Video Editor', to_db: 'video_editor' },
  'music_composer': { image: 'musiccomposer', text: 'Musician', to_db: 'music_composer' },
  'audio_engineer': { image: 'audio-engineer', text: 'Audio Engineer', to_db: 'audio_engineer' },
  'writer': { image: 'writer', text: 'Writer', to_db: 'writer' },
  'director': { image: 'director', text: 'Director', to_db: 'director' },
  'agent': { image: 'agent', text: 'Agent', to_db: 'agent' },
  'producer': { image: 'producer', text: 'Producer', to_db: 'producer' }
}

const TalentSearchRow = ({ result }) => {
  const [currentUser] = useGlobalState('currentUser')
  const { object } = result
  const demo = object.demoUrls.Audio || []
  const languages = object.searchLabels['user-languages'] || []
  const accents = object.searchLabels['user-accents'] || []
  const hardwares = object.searchLabels['user-hardwares'] || []
  const softwares = object.searchLabels['user-softwares'] || []
  const genderAges = object.searchLabels['user-gender-age'] || []
  const demoCategories = object.searchLabels['demo-categories'] || []
  const setups = [...hardwares, ...softwares]
  const voices = [...genderAges, ...demoCategories]
  const skills = object.searchLabels['user-skills'] || []
  const demoCount = (object.demoUrls['Audio']?.length || 0) + (object.demoUrls['YouTube Video']?.length || 0)

  return <>
    <li className="bg-gray-50 dark:bg-gray-800 hover:bg-cccblue hover:bg-opacity-5 dark:hover:bg-gray-900 p-2 shadow sm:p-2 sm:rounded-lg">
      <div>
        <div className="sm:flex sm:justify-between sm:items-center">
          <div className="flex-shrink-0">
            <img className='h-16 w-16 rounded-sm' src={object.imageUrl} alt={object.displayName} />
          </div>
          <div className="flex-1 px-0 md:px-4 md:py-0 pb-2 w-full truncate grid grid-cols-3">
            <div className='flex flex-col truncate col-span-2'>
              <span className="flex space-x-2">
                <span aria-expanded="false">
                  <div><a className="font-medium" href={`/${object.username}`}>{object.displayName}</a></div>
                </span>
                <span className='text-xs dark:text-gray-300 text-gray-500 mr-1 flex items-center' dateTime={''}>@{object.username}</span>
              </span>
            </div>
            <div className='col-span-1'>
              { currentUser?.id && <div className='flex justify-end'>
                { demo && demo.length > 0 && <PlayPauseButton objectId={demo[0].id} objectKind='Demo' mediaUrl={demo[0].url} objectName={result.displayName} /> }
              </div> }
              { !currentUser?.id && <>
                { demo && demo.length > 0 && <StandAloneAudioPlayer objectId={demo[0].id} objectKind='Demo' mediaUrl={demo[0].url} /> }
              </> }
              { demoCount > 1 && <div className='flex justify-end mt-1'><a className='text-sm' href={`/${object.username}`}>{demoCount - 1} more demo(s) available</a></div> }
            </div>
          </div>
        </div>
        {object.headline && <div className="mt-2">
          <div className="text-sm truncate sm:w-150 dark:text-gray-300 text-gray-800" dangerouslySetInnerHTML={{ __html: object.headline }} />
        </div> }
        { languages.length > 0 && <div className='pb-1 flex justify-start items-center space-x-1 text-xs whitespace-nowrap truncate sm:w-150'>
          <span className='text-xs dark:text-gray-400'>Speaks: </span>
          {languages.map((lang, idx) => (
            <StatusPill key={`${idx}langs${result.id}`} status={'default'} text={lang} />
          ))}
        </div> }
        { accents.length > 0 && <div className='pb-1 flex justify-start items-center space-x-1 whitespace-nowrap truncate sm:w-150'>
          <span className='text-xs dark:text-gray-400'>Accents: </span>
          {accents.map((acc, idx) => (
            <StatusPill key={`${idx}accs${result.id}`} status={'default'} text={acc} />
          ))}
        </div> }
        { setups.length > 0 && <div className='pb-1 flex justify-start items-center space-x-1 whitespace-nowrap truncate sm:w-150'>
          <span className='text-xs dark:text-gray-400'>Setup: </span>
          {setups.map((setup, idx) => (
            <StatusPill key={`${idx}setups${result.id}`} status={'default'} text={setup} />
          ))}
        </div> }
        { voices.length > 0 && <div className='pb-1 flex justify-start items-center space-x-1 whitespace-nowrap truncate sm:w-150'>
          <span className='text-xs dark:text-gray-400'>Voice Description: </span>
          {voices.map((voice, idx) => (
            <StatusPill key={`${idx}voiced${result.id}`} status={'default'} text={voice} />
          ))}
        </div> }
        { skills.length > 0 && <div className='pb-1 flex justify-start items-center space-x-1 whitespace-nowrap truncate sm:w-150'>
          <span className='text-xs dark:text-gray-400'>Skills: </span>
          {skills.map((skill, idx) => (
            <StatusPill key={`${idx}skills${result.id}`} status={'default'} text={skill} />
          ))}
        </div> }
        <div className='flex flex-col sm:flex-row justify-between items-center space-x-3 mt-2 text-gray-900 font-medium text-sm dark:text-gray-100 px-10'>
          <a href={`/${object.username}`} className='whitespace-nowrap flex justify-end items-center p-2 space-x-1 cursor-pointer hover:bg-gray-200 text-gray-700 dark:text-gray-400'>
            <SVGIcon name='icon-recommendation' className='h-6 w-6' />
            <span><span className='text-green-500'>{object.recommendationsCount}</span> Recommendations</span>
          </a>
          <a href={`/${object.username}`} className='whitespace-nowrap flex justify-end items-center p-2 space-x-1 cursor-pointer hover:bg-gray-200 text-gray-700 dark:text-gray-400'>
            <SVGIcon name='icon-submissions' className='h-6 w-6' />
            <span><span className='text-green-500'>{object.auditionsCount}</span> submissions</span>
          </a>
          <a href={`/${object.username}`} className='whitespace-nowrap flex justify-end items-center p-2 space-x-1 cursor-pointer hover:bg-gray-200 text-gray-700 dark:text-gray-400'>
            <SVGIcon name='icon-credits' className='h-6 w-6' />
            <span><span className='text-green-500'>{object.userCreditsCount}</span> credits</span>
          </a>
          <a href={`/${object.username}`} className='whitespace-nowrap flex justify-end items-center p-2 space-x-1 cursor-pointer hover:bg-gray-200 text-gray-700 dark:text-gray-400'>
            <SVGIcon name='icon-followers' className='h-6 w-6' />
            <span><span className='text-green-500'>{object.followeesCount}</span> followers</span>
          </a>
        </div>
      </div>
    </li>
  </>
}


const arrayifyParams = (params, cleanFilters) => {
  if (!params) { return cleanFilters }

  const filters = Object.assign({}, cleanFilters)
  for (const item of params.split('|')) {
    if (item.includes('is:all')) { continue }
    if (item.includes('is:')) { filters.talentKind = item.replace('is:', '') }
    if (item.includes('user-gender-age:')) {
      const filter = item.replace('user-gender-age:', '')
      if (filters.genderAges.indexOf(filter) === -1) { filters.genderAges.push(filter) }
    }
    if (item.includes('user-languages:')) {
      const filter = item.replace('user-languages:', '')
      if (filters.languages.indexOf(filter) === -1) { filters.languages.push(filter) }
    }
    if (item.includes('user-accents:')) {
      const filter = item.replace('user-accents:', '')
      if (filters.accents.indexOf(filter) === -1) { filters.accents.push(filter) }
    }
    if (item.includes('user-skills:')) {
      const filter = item.replace('user-skills:', '')
      if (filters.skills.indexOf(filter) === -1) { filters.skills.push(filter) }
    }
    if (item.includes('user-softwares:')) {
      const filter = item.replace('user-softwares:', '')
      if (filters.softwares.indexOf(filter) === -1) { filters.softwares.push(filter) }
    }
    if (item.includes('user-hardwares:')) {
      const filter = item.replace('user-hardwares:', '')
      if (filters.hardwares.indexOf(filter) === -1) { filters.hardwares.push(filter) }
    }
    if (item.includes('user-interests:')) {
      const filter = item.replace('user-interests:', '')
      if (filters.interests.indexOf(filter) === -1) { filters.interests.push(filter) }
    }
    if (item.includes('demo-categories')) {
      const filter = item.replace('demo-categories:', '')
      if (filters.demoCategories.indexOf(filter) === -1) { filters.demoCategories.push(filter) }
    }
  }

  return filters
}

const SearchIndex = (props) => {
  const { initTalentKind } = props
  const urlParams = new window.URLSearchParams(window.location.search)
  const [results, setResults] = useState([])
  const { getRequest, createQueryString } = useQuery()
  const history = useHistory()
  const scrollRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const [meta, setMeta] = useState({})
  const [viewPreference, setViewPreference] = useState(null)
  const [page, setPage] = useState(urlParams.get('page') || 1)
  const [contains, setContains] = useState(urlParams.get('contains'))

  const cleanFilters = {
    talentKind: initTalentKind || 'all',
    softwares: [],
    hardwares: [],
    interests: [],
    skills: [],
    languages: [],
    accents: [],
    genderAges: [],
    demoCategories: []
  }

  const [state, setState] = useReducer(
    (state, newState) => (
      { ...state, ...newState }), arrayifyParams(urlParams.get('search'), cleanFilters)
    )
  const { hardwares, softwares, interests, skills, accents, languages, genderAges, demoCategories, talentKind } = state

  const clearFilters = () => {
    setContains(null)
    setPage(1)
    setState({
      talentKind: 'all',
      softwares: [],
      hardwares: [],
      interests: [],
      skills: [],
      languages: [],
      accents: [],
      genderAges: [],
      demoCategories: []
    })
  }

  const addLabel = (label, labelKind) => {
    if (labelKind === 'user-softwares') { setState({ softwares: [...softwares, ...[label]] }) }
    if (labelKind === 'user-hardwares') { setState({ hardwares: [...hardwares, ...[label]] }) }
    if (labelKind === 'user-interests') { setState({ interests: [...interests, ...[label]] }) }
    if (labelKind === 'user-skills') { setState({ skills: [...skills, ...[label]] }) }
    if (labelKind === 'user-languages') { setState({ languages: [...languages, ...[label]] }) }
    if (labelKind === 'user-accents') { setState({ accents: [...accents, ...[label]] }) }
    if (labelKind === 'user-gender-age') { setState({ genderAges: [...genderAges, ...[label]] }) }
    if (labelKind === 'demo-categories') { setState({ demoCategories: [...demoCategories, ...[label]] }) }
  }
  const removeLabel = (label, kind) => {
    if (kind === 'user-hardwares') { setState({ hardwares: hardwares.filter(item => item !== label) }) }
    if (kind === 'user-softwares') { setState({ softwares: softwares.filter(item => item !== label) }) }
    if (kind === 'user-interests') { setState({ interests: interests.filter(item => item !== label) }) }
    if (kind === 'user-skills') { setState({ skills: skills.filter(item => item !== label) }) }
    if (kind === 'user-languages') { setState({ languages: languages.filter(item => item !== label) }) }
    if (kind === 'user-accents') { setState({ accents: accents.filter(item => item !== label) }) }
    if (kind === 'user-gender-age') { setState({ genderAges: genderAges.filter(item => item !== label) }) }
    if (kind === 'demo-categories') { setState({ demoCategories: demoCategories.filter(item => item !== label) }) }
  }

  useDebouncedEffect(() => { fetchData(true) }, 200, [state])
  useDebouncedEffect(() => { fetchData(true) }, 300, [contains])
  useDebouncedEffect(() => { fetchData() }, 200, [page])

  useEffect(() => {
    fetchData()
    window.ezstandalone = window.ezstandalone || {}
    window.ezstandalone.cmd = window.ezstandalone.cmd || []
    window.ezstandalone.cmd.push(function() {
        window.ezstandalone?.define(114, 115)
        window.ezstandalone?.enable()
        window.ezstandalone?.display()
        window.ezstandalone?.refresh()
    })
  }, [])

  const fetchData = (newPage, talentKindOverride) => {
    let search = `is:${talentKind}`
    for (const item of hardwares) { search = `${search}|user-hardwares:${item}` }
    for (const item of softwares) { search = `${search}|user-softwares:${item}` }
    for (const item of interests) { search = `${search}|user-interests:${item}` }
    for (const item of skills) { search = `${search}|user-skills:${item}` }
    for (const item of languages) { search = `${search}|user-languages:${item}` }
    for (const item of accents) { search = `${search}|user-accents:${item}` }
    for (const item of genderAges) { search = `${search}|user-gender-age:${item}` }
    for (const item of demoCategories) { search = `${search}|demo-categories:${item}` }

    setLoading(true)
    const data = {
      page: newPage ? 1 : page,
      contains: contains,
      search: search
    }
    history.push(`/talent?${createQueryString(data)}`)
    getRequest('/api/v3/omnisearch/talent', data, (err, jsonData) => {
      if (err) { /* handled in hook */ return }
      setResults(jsonData.results)
      setMeta(jsonData.meta)
      setLoading(false)
    })
  }

  return <>
    <div className='flex flex-col gap-y-1'>
      <MobileVideomancyAd />
      <MobileEducationAd />
      <MobileAd adID={114} />
    </div>
    <div className='lg:block lg:flex lg:gap-x-2'>
      <div id='talentsearch' className='max-w-6xl w-full'>
        <Card title={
          <div className='flex flex-col sm:flex-row items-center justify-between'>
            <span>
              Filters
            </span>
            <button className='text-cccblue' onClick={clearFilters}>Clear Filters</button>
          </div>}
        >
          <ul className="grid grid-cols-2 gap-1 sm:gap-2 sm:grid-cols-2 lg:grid-cols-6">
            { Object.keys(talentKindHash).map((key, idx) => (
              <li key={`role${key}`} className="col-span-1 flex shadow-sm rounded-md">
                { loading === key && <Loading noMessage noLoadingMessage /> }
                { loading !== key && <>
                  <button onClick={() => setState({ talentKind: key })}
                    className={classNames(talentKind === key ? 'bg-cccblue bg-opacity-20 hover:bg-cccblue hover:bg-opacity-30 text-gray-900 dark:text-white' : 'dark:text-gray-200 dark:bg-gray-700 bg-gray-100 hover:bg-gray-200 dark:hover:bg-gray-750 ', 'w-full flex space-x-1 items-center px-1 py-2 text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccpurple')}>
                    <SVGIcon name={talentKindHash[key].image} className='h-14 w-14' />
                    <span className='text-xs'> {talentKindHash[key].text}</span>
                    { talentKind === key && <CheckIcon className="h-3 w-3 text-cccblue" aria-hidden="true" /> }
                </button>
                </> }
              </li>
            ))}
          </ul>

          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">

            <div className='rounded-sm p-2 bg-cccblue bg-opacity-5'>
              <label htmlFor="voice_age_and_gender" className="text-sm font-medium text-gray-700 dark:text-gray-200"> Username or Display Name </label>
              <div className="mt-1 sm:mt-0 max-w-xs">
                <input type='text' className="" defaultValue={contains} placeholder="Buford or @buford" aria-label="Search components" role="combobox" aria-expanded="true" onChange={(e) => setContains(e.target.value)} />
              </div>
            </div>

            <div className='rounded-sm p-2 bg-cccblue bg-opacity-5'>
              <label htmlFor="voice_age_and_gender" className="text-sm font-medium text-gray-700 dark:text-gray-200"> Voice Age & Gender </label>
              <div className="mt-1 sm:mt-0 max-w-xs">
                <TagAutocomplete kind='user-gender-age' addLabel={addLabel} placeholder='Female Young Adult, Male Teen, Baby'/>
                { genderAges.length > 0 && <>
                  <div className="mt-3 flex sm:items-center flex-wrap">
                    {genderAges.map((labeling, idx) => (
                      <span key={`lang${labeling}`} onClick={(id) => removeLabel(labeling, 'user-gender-age')} className="flex-shrink-0 inline-block px-2 mr-1 mb-1 py-0.5 text-white bg-cccorange dark:bg-cccpurple text-xs font-medium rounded-full flex cursor-pointer capitalize">{labeling} <XIcon className='w-4 h-4' /></span>
                    ))}
                  </div>
                </> }
              </div>
            </div>

            <div className='rounded-sm p-2 bg-cccblue bg-opacity-5'>
              <label htmlFor="voice_description" className="text-sm font-medium text-gray-700 dark:text-gray-200"> Voice Description </label>
              <div className="mt-1 sm:mt-0 max-w-xs">
                <TagAutocomplete kind='demo-categories' addLabel={addLabel} placeholder='Character, Warm, Funny, Dark' />
                { demoCategories.length > 0 && <>
                  <div className="mt-3 flex sm:items-center flex-wrap">
                    {demoCategories.map((labeling, idx) => (
                      <span key={`democa${labeling}`} onClick={(id) => removeLabel(labeling, 'demo-categories')} className="flex-shrink-0 inline-block px-2 mr-1 mb-1 py-0.5 text-white bg-cccorange dark:bg-cccpurple text-xs font-medium rounded-full flex cursor-pointer capitalize">{labeling} <XIcon className='w-4 h-4' /></span>
                    ))}
                  </div>
                </> }
              </div>
            </div>

            <div className='rounded-sm p-2 bg-cccblue bg-opacity-5'>
              <label htmlFor="software" className="text-sm font-medium text-gray-700 dark:text-gray-200"> Software </label>
              <div className="mt-1 sm:mt-0 max-w-xs">
                <TagAutocomplete kind='user-softwares' addLabel={addLabel} placeholder='ISDN, Audacity, Source Connect, Scrivener'/>
                { softwares.length > 0 && <>
                  <div className="mt-3 flex sm:items-center flex-wrap">
                    {softwares.map((labeling, idx) => (
                      <span key={`soft${labeling}`} onClick={(id) => removeLabel(labeling, 'user-softwares')} className="flex-shrink-0 inline-block px-2 mr-1 mb-1 py-0.5 text-white bg-cccorange dark:bg-cccpurple text-xs font-medium rounded-full flex cursor-pointer capitalize">{labeling} <XIcon className='w-4 h-4' /></span>
                    ))}
                  </div>
                </> }
              </div>
            </div>

            <div className='rounded-sm p-2 bg-cccblue bg-opacity-5'>
              <label htmlFor="hardware" className="text-sm font-medium text-gray-700 dark:text-gray-200"> Hardware </label>
              <div className="mt-1 sm:mt-0 max-w-xs">
                <TagAutocomplete kind='user-hardwares' addLabel={addLabel} placeholder='Scarlett FocusRite 2i2, Shure SM7b, Blue Yeti'/>
                { hardwares.length > 0 && <>
                  <div className="mt-3 flex sm:items-center flex-wrap">
                    {hardwares.map((labeling, idx) => (
                      <span key={`hard${labeling}`} onClick={(id) => removeLabel(labeling, 'user-hardwares')} className="flex-shrink-0 inline-block px-2 mr-1 mb-1 py-0.5 text-white bg-cccorange dark:bg-cccpurple text-xs font-medium rounded-full flex cursor-pointer capitalize">{labeling} <XIcon className='w-4 h-4' /></span>
                    ))}
                  </div>
                </> }
              </div>
            </div>

            <div className='rounded-sm p-2 bg-cccblue bg-opacity-5'>
              <label htmlFor="languages" className="text-sm font-medium text-gray-700 dark:text-gray-200"> Language Spoken </label>
              <div className="mt-1 sm:mt-0 max-w-xs">
                <TagAutocomplete kind='user-languages' addLabel={addLabel} placeholder='English, Japanese'/>
                { languages.length > 0 && <>
                  <div className="mt-3 flex sm:items-center flex-wrap">
                    {languages.map((labeling, idx) => (
                      <span key={`lang${labeling}`} onClick={(id) => removeLabel(labeling, 'user-languages')} className="flex-shrink-0 inline-block px-2 mr-1 mb-1 py-0.5 text-white bg-cccorange dark:bg-cccpurple text-xs font-medium rounded-full flex cursor-pointer capitalize">{labeling} <XIcon className='w-4 h-4' /></span>
                    ))}
                  </div>
                </> }
              </div>
            </div>

            <div className='rounded-sm p-2 bg-cccblue bg-opacity-5'>
              <label htmlFor="accents" className="text-sm font-medium text-gray-700 dark:text-gray-200"> Accents </label>
              <div className="mt-1 sm:mt-0 max-w-xs">
                <TagAutocomplete kind='user-accents' addLabel={addLabel} placeholder='Texan, Irish, French'/>
                { accents.length > 0 && <>
                  <div className="mt-3 flex sm:items-center flex-wrap">
                    {accents.map((labeling, idx) => (
                      <span key={`acce${labeling}`} onClick={(id) => removeLabel(labeling, 'user-accents')} className="flex-shrink-0 inline-block px-2 mr-1 mb-1 py-0.5 text-white bg-cccorange dark:bg-cccpurple text-xs font-medium rounded-full flex cursor-pointer capitalize">{labeling} <XIcon className='w-4 h-4' /></span>
                    ))}
                  </div>
                </> }
              </div>
            </div>

            <div className='rounded-sm p-2 bg-cccblue bg-opacity-5'>
              <label htmlFor="skills" className="text-sm font-medium text-gray-700 dark:text-gray-200"> Skills </label>
              <div className="mt-1 sm:mt-0 max-w-xs">
                <TagAutocomplete kind='user-skills' addLabel={addLabel} placeholder='Kung Fu Fighting, Foreboding Glare, Mediocre DadJokes'/>
                { skills.length > 0 && <>
                  <div className="mt-3 flex sm:items-center flex-wrap">
                    {skills.map((labeling, idx) => (
                      <span key={`sskill${labeling}`} onClick={(id) => removeLabel(labeling, 'user-skills')} className="flex-shrink-0 inline-block px-2 mr-1 mb-1 py-0.5 text-white bg-cccorange dark:bg-cccpurple text-xs font-medium rounded-full flex cursor-pointer capitalize">{labeling} <XIcon className='w-4 h-4' /></span>
                    ))}
                  </div>
                </> }
              </div>
            </div>

            <div className='rounded-sm p-2 bg-cccblue bg-opacity-5'>
              <label htmlFor="interests" className="text-sm font-medium text-gray-700 dark:text-gray-200"> Interests </label>
              <div className="mt-1 sm:mt-0 max-w-xs">
                <TagAutocomplete kind='user-interests' addLabel={addLabel} placeholder='Manga, Music, Audiobooks'/>
                { interests.length > 0 && <>
                  <div className="mt-3 flex sm:items-center flex-wrap">
                    {interests.map((labeling, idx) => (
                      <span key={`inte${labeling}`} onClick={(id) => removeLabel(labeling, 'user-interests')} className="flex-shrink-0 inline-block px-2 mr-1 mb-1 py-0.5 text-white bg-cccorange dark:bg-cccpurple text-xs font-medium rounded-full flex cursor-pointer capitalize">{labeling} <XIcon className='w-4 h-4' /></span>
                    ))}
                  </div>
                </> }
              </div>
            </div>

          </div>
        </Card>

        <div ref={scrollRef} />
        { loading && <Loading /> }
        { results.length > 0 && <Pagination meta={meta} callback={setPage} scrollRef={scrollRef} /> }
        <ul className="space-y-3 mt-2">
          { results.map((result, idx) => (
            <TalentSearchRow key={`${idx}result${result.id}`} result={result} />
          ))}
        </ul>
        { results.length > 0 && <Pagination meta={meta} callback={setPage} scrollRef={scrollRef} /> }
      </div>
      <div className='flex flex-col gap-y-1'>
        <SidebarVideomancyAd />
        <SidebarEducationAd />
        <SidebarAd adID={115} />
      </div>
    </div>
  </>
}

export default SearchIndex

SearchIndex.propTypes = { }
