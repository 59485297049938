import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { ChevronDoubleDownIcon } from '@heroicons/react/solid'

import useQuery from '@/hooks/useQuery'
import { useGlobalState } from '@/state'
import useModal from '@/hooks/useModalV2'
import Modal from '@/shared/Modal'
import PrimaryButton from '@/shared/Buttons/Primary'
import DefaultButton from '@/shared/Buttons/Default'

const FeatureModal = () => {
  const { openModal, closeModal, isOpen } = useModal()
  const [, setToast] = useGlobalState('toast')
  const [currentUser] = useGlobalState('currentUser')
  const [manageProjectContainer] = useGlobalState('manageProjectContainer')
  const { project } = manageProjectContainer
  const [ featured, setFeatured ] = useState(project.featured)

  const { putpostRequest } = useQuery()
  const [loading, setLoading] = useState(false)

  const feature = () => {
    putpostRequest(`/api/v3/manage/projects/${project.id}/feature`, 'POST', {}, (err, jsonData) => {
      setLoading(false)
      if (err) { /* hook */ return }
      console.log(jsonData)
      setFeatured(true)

      setToast(<div className="ml-3 w-0 flex-1 pt-0.5">
        <p className="text-sm font-medium text-green-500">Featured!</p>
        <p className="mt-1 text-sm text-green-500">Give the servers about 60 minutes to get this pushed out everywhere.</p>
      </div>)
      closeModal()
    })
  }

  if (project.status === 'completed') {
    return <div className="rounded-md bg-cccblue bg-opacity-10 p-2 border-cccblue border-l-4 w-full">
      <div className="flex">
        <div className='flex flex-col justify-between h-full'>
          <div className='pb-3 text-sm text-cccblue'>
            This project has been marked as <b>completed</b>
          </div>
        </div>
      </div>
    </div>
  }

  if (featured) {
    return <div className="rounded-md bg-cccblue bg-opacity-10 p-2 border-cccblue border-l-4">
      <div className="flex">
        <div className='flex flex-col justify-between h-full'>
          <div className='pb-3 text-sm text-cccblue'>
            This project is <b>featured</b>. Please allow for up to 60 minutes for it to appear on the search results.
          </div>
        </div>
      </div>
    </div>
  }

  return <>
    <div className="w-full rounded-md bg-cccblue bg-opacity-10 p-2 border-cccorange border-l-4">
      <div className='flex flex-col justify-between h-full'>
        <div className='pb-3 text-sm text-cccblue'>
          <b>Feature</b> your project so that it gets more attention.
        </div>
        <button onClick={openModal} className="px-4 py-2 text-sm font-medium rounded-md text-white hover:text-white bg-gradient-to-r from-cccpurple via-cccblue to-cccorange hover:from-cccpurple hover:to-cccorange w-full flex items-center justify-center" >
          <span>Feature</span>
          <ChevronDoubleDownIcon className='h-4 w-4' />
        </button>
      </div>
    </div>
    <Modal isOpen={isOpen} closeModal={closeModal} >
      <div className="flex flex-col text-center items-center">
        <h2 className="mb-8 text-xs font-semibold tracking-widest text-gray-800 dark:text-gray-200 uppercase title-font">Feature this project</h2>
      </div>
      <img src='https://ddppjbdexhxzj.cloudfront.net/perks/project-featured.jpg' />
      <div className='px-2 mt-1'>
        <i className='text-xs'>The above chart shows typical <b>Feature</b> results having a large boost to visibility. The above shows the number of submissions for a project in Nov, 2022. Before they featured their project, they got about 25 auditions in 20 days. When they featured their project, they got 35 more auditions in 7 days.</i>
      </div>
      <div className="rounded-md bg-blue-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <svg className="h-5 w-5 text-blue-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fillRule="evenodd" d="M19 10.5a8.5 8.5 0 11-17 0 8.5 8.5 0 0117 0zM8.25 9.75A.75.75 0 019 9h.253a1.75 1.75 0 011.709 2.13l-.46 2.066a.25.25 0 00.245.304H11a.75.75 0 010 1.5h-.253a1.75 1.75 0 01-1.709-2.13l.46-2.066a.25.25 0 00-.245-.304H9a.75.75 0 01-.75-.75zM10 7a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
            </svg>
          </div>
          <div className="ml-3 flex-1 md:flex md:justify-between">
            <p className="text-sm text-blue-700">When you <b>feature</b> a project, it appears at the top of the search results. It's a great way to increase visibility since about 2M people visit the search results per month.</p>
          </div>
        </div>
      </div>
      { !currentUser.canFeatureProjects && <div className="rounded-md bg-yellow-50 p-4 mt-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <svg className="h-5 w-5 text-yellow-700" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fillRule="evenodd" d="M19 10.5a8.5 8.5 0 11-17 0 8.5 8.5 0 0117 0zM8.25 9.75A.75.75 0 019 9h.253a1.75 1.75 0 011.709 2.13l-.46 2.066a.25.25 0 00.245.304H11a.75.75 0 010 1.5h-.253a1.75 1.75 0 01-1.709-2.13l.46-2.066a.25.25 0 00-.245-.304H9a.75.75 0 01-.75-.75zM10 7a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
            </svg>
          </div>
          <div className="ml-3 flex-1 flex flex-col md:justify-between">
            <p className="text-sm text-yellow-700">Featuring a project is Business Plan feature only. Buying a membership really helps keep the lights on at CCC. Please consider upgrading.</p>
            <Link to='/subscriptions' className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-cccblue text-base hover:text-white font-medium text-white sm:w-auto sm:text-sm" >
              Can I see the plans?
            </Link>
          </div>
        </div>
      </div> }

      <div className="flex justify-end space-x-2 mt-4">
        <DefaultButton onClick={closeModal} text='Cancel' />
        { currentUser.canFeatureProjects && <PrimaryButton onClick={feature} loading={loading} text='Yes, feature it' /> }
      </div>
    </Modal>
  </>
}

export default FeatureModal
