import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useGlobalState } from '@/state'
import { achievement } from '@/shared/Toast'
import { TippyTooltip } from '@/shared/ToolTip'
import { MicrophoneIcon, ChatAltIcon, FolderOpenIcon, ArrowCircleUpIcon, LinkIcon } from '@heroicons/react/solid'
import useQuery from '@/hooks/useQuery'

import Loading from '@/shared/Loading'
import PlayPauseButton from '@/shared/Buttons/PlayPauseButton'
import UserHoverCard from '@/shared/UserHoverCard'
import SubmissionShare from '@/pages/Users/PublicProfile/PublicProfileSubmissionRowShare'
import GiftModal from '@/shared/GiftModal'
import CommentForm from '@/shared/CommentForm'
import CommentRow from '@/shared/CommentRow'

const FeedSubmissionRow = ({ feed, cast, voteIds }) => {
  const [currentUser] = useGlobalState('currentUser')
  const [, setToast] = useGlobalState('toast')
  const [comments, setComments] = useState([])
  const [loadingComments, setLoadingComments] = useState(false)
  const [showComments, setShowComments] = useState(false)
  const [upvoted, setUpvoted] = useState(voteIds.indexOf(feed.objectId) >= 0)
  const { putpostRequest, getRequest } = useQuery()

  const loadComments = () => {
    setShowComments(!showComments)
    setLoadingComments(true)
    getRequest(`/api/v3/auditions/${feed.objectId}/comments`, {}, (err, jsonData) => {
      setLoadingComments(false)
      if (err) { /* hook */ }
      setComments(jsonData.comments)
    })
  }

  const addChildtoParent = (childComment) => {
    setComments([childComment, ...comments])
  }

  const upvote = () => {
    if (upvoted) { return }

    if (currentUser.username !== feed.username) { setUpvoted(true) }

    putpostRequest(`/api/v3/auditions/${feed.objectId}/upvote`, 'POST', {}, (err, jsonData) => {
      if (err) { /* hook */ return }
      if (jsonData.achievement) {
        setToast(achievement(jsonData.achievement))
      }
      if (!jsonData.achievement && currentUser.username === feed.userId) {
        setToast(<div className="ml-3 w-0 flex-1 pt-0.5">
          <p className="text-sm font-medium dark:text-red-100 text-red-600">Hey now</p>
          <p className="mt-1 text-sm dark:text-red-300 text-red-400">Silly goose. You can't upvote yourself.</p>
        </div>)
      }
    })
  }
  const incPlayedCount = () => {
    putpostRequest(`/api/v3/auditions/${feed.objectId}/played`, 'POST', {}, (err, jsonData) => {
      if (err) { /* hook */ }
    })
  }
  const submission = { id: feed.objectId, projectName: feed.name }

  return <li className='bg-white dark:bg-gray-700 rounded-lg border border-gray-200 dark:border-gray-700'>
    <div className="p-1">
      <div className='flex justify-between'>
        <div className="flex items-start space-x-3 overflow-hidden">
          <div className="relative m-1">
            <img className="h-10 w-10 rounded-sm bg-gray-200 flex items-center justify-center ring-4 ring-cccpurple" src={feed.publicImageUrl} alt={feed.displayName} />

            <span className="absolute -bottom-0.5 -right-1 bg-cccpurple rounded-tl px-0.5 py-px">
              <MicrophoneIcon className="h-4 w-4 text-white" aria-hidden="true" />
            </span>
          </div>
          <div className="min-w-0 flex-1">
            <div className='flex flex-col sm:flex-row sm:space-x-2'>
              { feed.audioUrl && <PlayPauseButton mediaUrl={feed.audioUrl} objectId={feed.objectId} objectKind='Audition' /> }

              { feed.remoteLink && <div className='inline-flex justify-start w-full min-w-3 min-h-3'>
                <a onClick={incPlayedCount} href={feed.remoteLink} aria-label='Go to submission' target='_blank' rel="noreferrer" className="inline-flex items-center py-2 shadow-sm text-sm font-medium text-gray-700 text-cccblue hover:text-cccblue-alt">
                  <LinkIcon className="mr-1 h-5 w-5" aria-hidden="true" />
                  {feed.remoteLink}
                </a>
              </div> }
              <div className='flex-col'>
                <div className='flex flex-col sm:flex-row sm:space-x-1 items-center'>
                  <UserHoverCard username={feed.username} displayName={feed.displayName} />
                  {cast && <span className="text-sm text-gray-500">was <b>chosen</b> for</span> }
                  {!cast && <span className="text-sm text-gray-500">added a submission for</span> }
                </div>
                <a href={`/projects/${feed.slug}`} className="font-medium text-cccblue cursor-pointer truncate">
                  {feed.name}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-0.5 text-sm text-gray-500 ml-1">
          {feed.timeAgo}
        </div>
      </div>
      <div className="mt-2 flex space-x-8">
        <div className="flex space-x-6">
          <button onClick={loadComments} aria-label='Load Comments' type="button" className="flex space-x-1 text-gray-400 hover:text-gray-500 dark:text-gray-400 dark:hover:text-gray-300 items-center">
            <ChatAltIcon className="h-5 w-5" aria-hidden="true" />
            {cast && <span className="text-xs hidden sm:inline">
              Say Congratulations!
              {feed.commentCount > 0 && <>({feed.commentCount})</> }
            </span> }
            {!cast && <span className="text-xs hidden sm:inline">
              Wish Them Luck
              {feed.commentCount > 0 && <>({feed.commentCount})</> }
            </span> }
          </button>
        </div>
        <div className="flex space-x-6">
          <TippyTooltip content={<>Your upvote is only visible to you and the owner of the submission.</>}>
            <button type="button" onClick={upvote} aria-label='Upvote' className="flex space-x-1 text-gray-400 hover:text-gray-500 dark:text-gray-400 dark:hover:text-gray-300 items-center">
              <ArrowCircleUpIcon className={`h-5 w-5 ${upvoted ? 'text-green-500' : ''}`} aria-hidden="true" />
              { !upvoted && <>
                { currentUser.username !== feed.username && <span className="text-xs hidden sm:inline">upvote</span> }
              </> }
              { Boolean(upvoted) && <span className="text-xs text-green-500 hidden sm:inline">upvoted!</span> }
            </button>
          </TippyTooltip>
        </div>

        <SubmissionShare submission={submission} />
        <GiftModal giftableId={submission.id} giftableType='Audition' />
        <div className="flex space-x-6">
          <a href={`/auditions/${feed.objectId}`} type="button" className="flex space-x-1 text-gray-400 hover:text-gray-500 dark:text-gray-400 dark:hover:text-gray-300 items-center">
            <span className="sr-only" hidden>View Submission</span>
            <FolderOpenIcon className="h-5 w-5" aria-hidden="true" />
            <span className="text-xs hidden sm:block">View Submission</span>
          </a>
        </div>
      </div>
      { showComments && <div className='overflow-hidden'>
        <CommentForm commentableId={submission.id} commentableType='Audition' addChildtoParent={addChildtoParent} setHide={setShowComments} />
        {comments.length > 0 && <>
          <div className="space-y-3 mt-3">
            {comments.map((com, idx) => (
              <CommentRow key={`com${com.id}`} initialComment={com} />
            ))}
          </div>
        </> }
      </div> }
      {loadingComments && <Loading /> }
    </div>
  </li>
}

export default FeedSubmissionRow

FeedSubmissionRow.propTypes = {
  feed: PropTypes.array.isRequired,
  cast: PropTypes.func.isRequired,
  voteIds: PropTypes.array.isRequired
}
