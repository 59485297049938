import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { useStripe } from '@stripe/react-stripe-js'
import { ExclamationIcon, EmojiHappyIcon } from '@heroicons/react/solid'

import DefaultButton from '@/shared/Buttons/Default'
import useQuery from '@/hooks/useQuery'
import useStripeMethods from '@/hooks/useStripeMethods'
import PrimaryButton from '@/shared/Buttons/Primary'
import { useGlobalState } from '@/state'
import DowngradePreview from '@/pages/SubscriptionForm/DowngradePreview'

const ShowRandomLoadingMessage = () => {
  const idx = useRef(0)
  const [shownMsgs, setShownMsgs] = useState(['This usually takes about 30 seconds'])

  const messages = [
    'Negotiating with the powers that be',
    'They have stopped for a coffee break',
    '...well, this is awkward',
    'They are back! Hopefully resolved soon.',
    '*sigh* Now they are having lunch.',
    'I mean, come on.',
    'Right. They are ba--- What, second lunch!?',
    'This is getting ridiculous.',
    'I am going to file a complaint',
    'Finally! I see someone who can help',
    'CLOSED FOR A WEDDING?!',
    'How do you close a financial institution for a wedding?!',
    'The secretary got married. The wedding was lovely.',
    'They had those little mochis filled with strawberry cream',
    'It seems something serious has happened.',
    'No joke this time. If you made it this far that means this operation has timed out. Please try again or contact moderators@castingcall.club for help.'
  ]

  const addMsg = () => {
    const msgArr = Object.assign([], shownMsgs)
    msgArr.push(messages[idx.current])
    setShownMsgs(msgArr)
    console.log(msgArr)
  }

  useEffect(() => {
    console.log('effect')
    if (idx.current >= messages.length) { return }
    console.log('effect2')
    idx.current += 1
    setTimeout(addMsg, 2000)
  }, [shownMsgs])

  return <ul className='text-sm text-gray-400 dark:text-gray-500'>
      {shownMsgs.map((msg, shownIdx) => (
        <li className={shownIdx === idx.current ? 'font-semibold' : ''} key={msg}>{msg}</li>
      ))}
    </ul>
}

const PreviewingChange = (
  { setPreviewing, selectedPlan, billingInterval, isBraintree, quotedPrice, selectedPaymentMethodId, paymentMethods, stripeReady }
) => {
  const [errorMessage, setErrorMessage] = useState(null)
  const history = useHistory()
  const [currentUser, setCurrentUser] = useGlobalState('currentUser')
  const [subscriptionContainer] = useGlobalState('subscriptionContainer')
  const { existingSubscription } = subscriptionContainer
  const [, setToast] = useGlobalState('toast')
  const [loading, setLoading] = useState(false)
  const { putpostRequest } = useQuery()
  const stripe = useStripe()

  const selectedPaymentMethodDetails = () => {
    const paymentMethodDetails = paymentMethods.find(paymentMethod => paymentMethod.id === selectedPaymentMethodId)
    return paymentMethodDetails
  }

  const successfulPurchase = () => {
    setCurrentUser({ ...currentUser, gold: selectedPlan.plan })
    setToast(<>
      <div className="flex-shrink-0">
        <EmojiHappyIcon className="h-6 w-6 text-green-500" aria-hidden="true" />
      </div>
      <div className="ml-3 w-0 flex-1 pt-0.5">
        <p className="text-sm font-medium dark:text-white text-gray-800">Successfully upgraded!</p>
        <p className="mt-1 text-sm dark:text-gray-100 text-gray-500">You will receive an email confirmation.</p>
      </div>
    </>)
    history.push('/dashboard')
  }

  const {
    stripeLoading, stripeErrorMessage, handlePaymentThatRequiresCustomerAction
  } = useStripeMethods(successfulPurchase, currentUser.id, selectedPlan.plan, stripe)

  useEffect(() => { setLoading(stripeLoading) }, [stripeLoading])
  useEffect(() => { setErrorMessage(stripeErrorMessage) }, [stripeErrorMessage])

  const updateBraintreeSubscription = async (stripePaymentMethodId) => {
    const data = {
      sub_plan_id: selectedPlan.id,
      payment_method_id: selectedPaymentMethodId
    }

    setLoading(true)
    putpostRequest(`/api/v3/braintree/subscriptions/${existingSubscription.braintreeSubscriptionId}`, 'PATCH', data, async (err, response) => {
      setLoading(false)
      if (err) { setErrorMessage(err) }
      if (response && !response.requiresAction) {
        setCurrentUser({ ...currentUser, credits: selectedPlan.credits })
        successfulPurchase(true)
      }
    })
  }

  const updateSubscription = async (stripePaymentMethodId) => {
    if (!selectedPaymentMethodId) {
      setErrorMessage('Please enter your card details')
      setPreviewing(false)
      return
    }

    const data = {
      sub_plan_id: selectedPlan.id,
      payment_method_id: selectedPaymentMethodId
    }

    setLoading(true)
    putpostRequest(`/api/v3/stripe/subscriptions/${existingSubscription.id}`, 'PATCH', { subscriptions: data }, async (err, response) => {
      setLoading(false)
      if (err) { setErrorMessage(err) }
      if (response && !response.requiresAction) {
        setCurrentUser({ ...currentUser, credits: selectedPlan.credits })
        successfulPurchase(true)
      }
      if (response && response.requiresAction) {
        await handlePaymentThatRequiresCustomerAction({
          stripe: stripe,
          paymentIntentClientSecret: response.paymentIntentClientSecret,
          upgrade: true,
          paymentMethodId: selectedPaymentMethodId
        })
      }
    })
  }

  if (existingSubscription.subscriptionPlanId === selectedPlan.id) {
    return <>
      <div className="rounded-md bg-yellow-50 p-4 mt-10">
        <div className="flex">
          <div className="flex-shrink-0">
            <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-yellow-800">Cannot Change Plans</h3>
            <div className="mt-2 text-sm text-yellow-700">
              <p>You are already on the {selectedPlan.planName} plan.</p>
            </div>
          </div>
        </div>
      </div>
      <DefaultButton className='mt-5' onClick={() => setPreviewing(false)} text="Cancel" />
    </>
  }

  return <>
    <DowngradePreview quotedPrice={quotedPrice} existingSubscription={existingSubscription} />
    <div className='pt-5'>
      <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-200">Plan Changes</h3>
    </div>
    <div className="mt-5 border-t border-gray-200">
      <dl className="sm:divide-y sm:divide-gray-200">
        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
          <dt className="text-sm font-medium text-gray-500 dark:text-gray-300">Plan Name</dt>
          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 dark:text-gray-200 capitalize">{selectedPlan.planName}</dd>
        </div>
        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
          <dt className="text-sm font-medium text-gray-500 dark:text-gray-300">Billing Interval</dt>
          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 dark:text-gray-200">
            {billingInterval}
          </dd>
        </div>
        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
          <dt className="text-sm font-medium text-gray-500 dark:text-gray-300">Amount Charged On Next Subscription Cycle</dt>
          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 dark:text-gray-200">
            ${selectedPlan.price}
            { existingSubscription.billingInterval === billingInterval && <> on {existingSubscription.expiresAt}</> }
          </dd>
        </div>
        { !isBraintree && <>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
            <dt className="text-sm font-medium text-gray-500 dark:text-gray-300">Using Card Ending in </dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 dark:text-gray-200">xxxxx<b>{selectedPaymentMethodDetails().last4}</b></dd>
          </div>
        </> }
        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
          <dt className="text-sm font-medium text-gray-500 dark:text-gray-300">Total Charge Today</dt>
          <dd className="mt-1 text-2xl text-gray-900 dark:text-gray-100 sm:mt-0 sm:col-span-2">{quotedPrice}</dd>
        </div>
      </dl>
    </div>
    { errorMessage && <div className='mt-2 text-sm text-red-600'>{errorMessage}</div> }
    <div className='flex items-center justify-start'>
      <DefaultButton disabled={loading} className='mr-5' onClick={() => setPreviewing(false)} text="Cancel" />
      { !isBraintree && <PrimaryButton onClick={updateSubscription} loading={loading} disabled={!stripeReady} text="Update Subscription" /> }
      { isBraintree && <PrimaryButton onClick={updateBraintreeSubscription} loading={loading} text="Update Subscription" /> }
    </div>
    { loading && <ShowRandomLoadingMessage /> }
  </>
}

export default PreviewingChange

PreviewingChange.propTypes = {
  setPreviewing: PropTypes.func.isRequired,
  selectedPlan: PropTypes.object.isRequired,
  billingInterval: PropTypes.string.isRequired,
  isBraintree: PropTypes.bool.isRequired,
  quotedPrice: PropTypes.string.isRequired,
  selectedPaymentMethodId: PropTypes.string,
  paymentMethods: PropTypes.array.isRequired,
  stripeReady: PropTypes.bool
}
