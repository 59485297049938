import React, { Fragment, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { RadioGroup } from '@headlessui/react'

import classNames from '@/utils/classNamesLocal'
import { useGlobalState } from '@/state'
import useQuery from '@/hooks/useQuery'
import Card from '@/shared/Card'
import PrimaryButton from '@/shared/Buttons/Primary'
import useModal from '@/hooks/useModalV2'
import Modal from '@/shared/Modal'

const DowngradeGold = ({ closeModal }) => {
  const { putpostRequest } = useQuery()
  const [, setToast] = useGlobalState('toast')
  const [loading, setLoading] = useState(false)
  const [reason, setReason] = useState(null)
  const { formState, register, unregister, handleSubmit, setValue } = useForm({
   defaultValues: {
     reason: null,
     reason_typein: null
    }
  })

  const { errors } = formState

  useEffect(() => {
    register('reason', { required: true })
    return () => {
      unregister('reason')
    }
  }, [register])

  useEffect(() => {
    if (!reason) { return }

    setValue('reason', reason, { shouldDirty: true, shouldValidate: true })
  }, [reason])

  const onSubmit = (data) => {
    setLoading(true)

    const formData = {
      survey: {
        survey_type: 'downgrade-gold-v1',
        answers: {
          reason: reason,
          reason_typein: data.reason_typein
        }
      }
    }

    putpostRequest('/api/v3/surveys', 'POST', formData, (err, jsonData) => {
      setLoading(false)
      if (err) { /* 422 code */ return }

      setToast(<div className="ml-3 w-0 flex-1 pt-0.5">
        <p className="text-sm font-medium dark:text-white text-gray-800">Thanks!</p>
        <p className="mt-1 text-sm dark:text-gray-100 text-gray-500">This really helps me make things better. :)</p>
      </div>)
      closeModal()
    })
  }

  return <>
    <Card title="Quick question on why you canceled"
      footer={
        <div className='flex flex-rows justify-end items-end'>
          <div className='flex flex-col items-end w-min ml-2'>
            <PrimaryButton className='w-full' onClick={handleSubmit(onSubmit)} loading={loading} text='Submit' />
          </div>
        </div>
      } >
      <form>
        <RadioGroup value={reason} onChange={setReason}>
          <RadioGroup.Label className="sr-only" hidden>Reason</RadioGroup.Label>
          <div className="p-2 rounded-sm bg-white dark:bg-gray-800">
            <label htmlFor="reason" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
              Why did you decide to cancel your premium?
            </label>
            <div className="mt-1 flex flex-col rounded-md shadow-sm">
              <RadioGroup.Option value='bugs' className={({ checked }) => classNames(checked ? 'bg-cccpurple bg-opacity-20 border-cccpurple z-10' : 'border-gray-200', 'relative border p-4  w-full cursor-pointer focus:outline-none')}>
                {({ active, checked }) => <>
                  <div className="flex items-center text-sm col-span-1">
                    <span className={classNames(
                      checked ? 'bg-cccblue border-transparent' : 'bg-white border-gray-300',
                      active ? 'ring-2 ring-offset-2 ring-indigo-500' : '',
                      'h-4 w-4 rounded-full border flex items-center justify-center'
                    )} aria-hidden="true" >
                      <span className="rounded-full bg-white w-1.5 h-1.5" />
                    </span>
                    <RadioGroup.Label as="span" className={classNames(checked ? 'text-cccblue' : 'text-gray-900', 'ml-3 font-medium')} >
                      Too many bugs
                    </RadioGroup.Label>
                  </div>
                </>}
              </RadioGroup.Option>
              <RadioGroup.Option value='one-time-use' className={({ checked }) => classNames(checked ? 'bg-cccpurple bg-opacity-20 border-cccpurple z-10' : 'border-gray-200', 'relative border p-4  w-full cursor-pointer focus:outline-none')}>
                {({ active, checked }) => <>
                  <div className="flex items-center text-sm col-span-1">
                    <span className={classNames(
                      checked ? 'bg-cccblue border-transparent' : 'bg-white border-gray-300',
                      active ? 'ring-2 ring-offset-2 ring-indigo-500' : '',
                      'h-4 w-4 rounded-full border flex items-center justify-center'
                    )} aria-hidden="true" >
                      <span className="rounded-full bg-white w-1.5 h-1.5" />
                    </span>
                    <RadioGroup.Label as="span" className={classNames(checked ? 'text-cccblue' : 'text-gray-900', 'ml-3 font-medium')} >
                      I only needed it for a short time
                    </RadioGroup.Label>
                  </div>
                </>}
              </RadioGroup.Option>
              <RadioGroup.Option value='moved-on' className={({ checked }) => classNames(checked ? 'bg-cccpurple bg-opacity-20 border-cccpurple z-10' : 'border-gray-200', 'relative border p-4  w-full cursor-pointer focus:outline-none')}>
                {({ active, checked }) => <>
                  <div className="flex items-center text-sm col-span-1">
                    <span className={classNames(
                      checked ? 'bg-cccblue border-transparent' : 'bg-white border-gray-300',
                      active ? 'ring-2 ring-offset-2 ring-indigo-500' : '',
                      'h-4 w-4 rounded-full border flex items-center justify-center'
                    )} aria-hidden="true" >
                      <span className="rounded-full bg-white w-1.5 h-1.5" />
                    </span>
                    <RadioGroup.Label as="span" className={classNames(checked ? 'text-cccblue' : 'text-gray-900', 'ml-3 font-medium')} >
                      I've moved on to p2p platforms
                    </RadioGroup.Label>
                  </div>
                </>}
              </RadioGroup.Option>
              <RadioGroup.Option value='other' className={({ checked }) => classNames(checked ? 'bg-cccpurple bg-opacity-20 border-cccpurple z-10' : 'border-gray-200', 'relative border p-4  w-full cursor-pointer focus:outline-none')}>
                {({ active, checked }) => <>
                  <div className="flex items-center text-sm col-span-1">
                    <span className={classNames(
                      checked ? 'bg-cccblue border-transparent' : 'bg-white border-gray-300',
                      active ? 'ring-2 ring-offset-2 ring-indigo-500' : '',
                      'h-4 w-4 rounded-full border flex items-center justify-center'
                    )} aria-hidden="true" >
                      <span className="rounded-full bg-white w-1.5 h-1.5" />
                    </span>
                    <RadioGroup.Label as="span" className={classNames(checked ? 'text-cccblue' : 'text-gray-900', 'ml-3 font-medium')} >
                      Other
                    </RadioGroup.Label>
                  </div>
                </>}
              </RadioGroup.Option>
            </div>
          </div>
        </RadioGroup>
        { errors.reason && <div className='text-red-500'>Please answer! Please!!</div> }

        <div className={reason && reason === 'other' ? 'mt-2' : 'hidden'}>
          <label htmlFor="reason_typein" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
            Interesting! Why "other"?
          </label>
          <div className="mt-1 flex rounded-md shadow-sm">
            <input type="text" {...register('reason_typein')} placeholder="I just don't like the vibe" />
          </div>
        </div>
      </form>
    </Card>
  </>
}

const GoldSurvey = ({ closeModal }) => {
  const { putpostRequest } = useQuery()
  const [, setToast] = useGlobalState('toast')
  const [loading, setLoading] = useState(false)
  const [reason, setReason] = useState(null)
  const { formState, register, reset, unregister, handleSubmit, setValue, getValues, setError } = useForm({
   defaultValues: {
     reason: null,
     reason_typein: null
    }
  })

  const { errors } = formState

  useEffect(() => {
    register('reason', { required: true })
    return () => {
      unregister('reason')
    }
  }, [register])

  useEffect(() => {
    if (!reason) { return }

    setValue('reason', reason, { shouldDirty: true, shouldValidate: true })
  }, [reason])

  const onSubmit = (data) => {
    setLoading(true)

    const formData = {
      survey: {
        survey_type: 'buy-gold-v1',
        answers: {
          reason: reason,
          reason_typein: data.reason_typein
        }
      }
    }

    putpostRequest('/api/v3/surveys', 'POST', formData, (err, jsonData) => {
      setLoading(false)
      if (err) { /* 422 code */ return }

      setToast(<div className="ml-3 w-0 flex-1 pt-0.5">
        <p className="text-sm font-medium dark:text-white text-gray-800">Thanks!</p>
        <p className="mt-1 text-sm dark:text-gray-100 text-gray-500">I really appreciate your response. :)</p>
      </div>)
      closeModal()
    })
  }

  return <>
    <Card title="Hey, quick question for you"
      footer={
        <div className='flex flex-rows justify-end items-end'>
          <div className='flex flex-col items-end w-min ml-2'>
            <PrimaryButton className='w-full' onClick={handleSubmit(onSubmit)} loading={loading} text='Submit' />
          </div>
        </div>
      } >
      <form>
        <RadioGroup value={reason} onChange={setReason}>
          <RadioGroup.Label className="sr-only" hidden>Reason</RadioGroup.Label>
          <div className="p-2 rounded-sm bg-white dark:bg-gray-800">
            <label htmlFor="reason" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
              Why did you decide to buy a premium plan?
            </label>
            <div className="mt-1 flex flex-col rounded-md shadow-sm">
              <RadioGroup.Option value='support-ccc' className={({ checked }) => classNames(checked ? 'bg-cccpurple bg-opacity-20 border-cccpurple z-10' : 'border-gray-200', 'relative border p-4  w-full cursor-pointer focus:outline-none')}>
                {({ active, checked }) => <>
                  <div className="flex items-center text-sm col-span-1">
                    <span className={classNames(
                      checked ? 'bg-cccblue border-transparent' : 'bg-white border-gray-300',
                      active ? 'ring-2 ring-offset-2 ring-indigo-500' : '',
                      'h-4 w-4 rounded-full border flex items-center justify-center'
                    )} aria-hidden="true" >
                      <span className="rounded-full bg-white w-1.5 h-1.5" />
                    </span>
                    <RadioGroup.Label as="span" className={classNames(checked ? 'text-cccblue' : 'text-gray-900 dark:text-gray-200', 'ml-3 font-medium')} >
                      I just wanted to support an independent business like CCC
                    </RadioGroup.Label>
                  </div>
                </>}
              </RadioGroup.Option>
              <RadioGroup.Option value='specific-feature' className={({ checked }) => classNames(checked ? 'bg-cccpurple bg-opacity-20 border-cccpurple z-10' : 'border-gray-200', 'relative border p-4  w-full cursor-pointer focus:outline-none')}>
                {({ active, checked }) => <>
                  <div className="flex items-center text-sm col-span-1">
                    <span className={classNames(
                      checked ? 'bg-cccblue border-transparent' : 'bg-white border-gray-300',
                      active ? 'ring-2 ring-offset-2 ring-indigo-500' : '',
                      'h-4 w-4 rounded-full border flex items-center justify-center'
                    )} aria-hidden="true" >
                      <span className="rounded-full bg-white w-1.5 h-1.5" />
                    </span>
                    <RadioGroup.Label as="span" className={classNames(checked ? 'text-cccblue' : 'text-gray-900 dark:text-gray-200', 'ml-3 font-medium')} >
                      I wanted a specific feature
                    </RadioGroup.Label>
                  </div>
                </>}
              </RadioGroup.Option>
              <RadioGroup.Option value='other' className={({ checked }) => classNames(checked ? 'bg-cccpurple bg-opacity-20 border-cccpurple z-10' : 'border-gray-200', 'relative border p-4  w-full cursor-pointer focus:outline-none')}>
                {({ active, checked }) => <>
                  <div className="flex items-center text-sm col-span-1">
                    <span className={classNames(
                      checked ? 'bg-cccblue border-transparent' : 'bg-white border-gray-300',
                      active ? 'ring-2 ring-offset-2 ring-indigo-500' : '',
                      'h-4 w-4 rounded-full border flex items-center justify-center'
                    )} aria-hidden="true" >
                      <span className="rounded-full bg-white w-1.5 h-1.5" />
                    </span>
                    <RadioGroup.Label as="span" className={classNames(checked ? 'text-cccblue' : 'text-gray-900 dark:text-gray-200', 'ml-3 font-medium')} >
                      Other
                    </RadioGroup.Label>
                  </div>
                </>}
              </RadioGroup.Option>
            </div>
          </div>
        </RadioGroup>
        { errors.reason && <div className='text-red-500'>Please answer! Please!!</div> }

        <div className={reason && reason !== 'support-ccc' ? 'mt-2' : 'hidden'}>
          <label htmlFor="reason_typein" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
            { reason === 'specific-feature' && <>What Specific Feature was it?</> }
            { reason === 'other' && <>Interesting! Why "other"?</> }
          </label>
          <div className="mt-1 flex rounded-md shadow-sm">
            <input type="text" {...register('reason_typein')} placeholder={reason === 'specific-feature' ? 'I wanted to promo my profile' : 'I just like the vibe'} />
          </div>
        </div>
      </form>
    </Card>
  </>
}

export default function SurveyPrompt() {
  const { getRequest } = useQuery()
  const [surveyType, setSurveyType] = useState(null)
  const { openModal, closeModal, isOpen } = useModal()

  useEffect(() => { if (surveyType) { openModal() } }, [surveyType])
  useEffect(() => {
    getRequest('/api/v3/surveys', {}, (err, jsonData) => {
      if (err) { /* shit */ return }
      if (jsonData.surveyType) {
        setSurveyType(jsonData.surveyType)
      }
    })
  }, [])

  if (surveyType === null) { return null }

  return (
    <Modal isOpen={isOpen} closeModal={closeModal} >
      {surveyType === 'buy-gold-v1' && <GoldSurvey closeModal={closeModal} /> }
      {surveyType === 'downgrade-gold-v1' && <DowngradeGold closeModal={closeModal} /> }
    </Modal>
  )
}
