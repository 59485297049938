import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Loading from '@/shared/Loading'
import Card from '@/shared/Card'

import useModal from '@/hooks/useModalV2'
import Modal from '@/shared/Modal'
import ImageUploadForm from '@/shared/ImageUploadForm'
import useQuery from '@/hooks/useQuery'

const ProjectImage = ({ project }) => {
  const [url, setUrl] = useState(project.imageUrl)
  const [uploaded, setUploaded] = useState(false)
  const { openModal, closeModal, isOpen } = useModal()
  const { getRequest } = useQuery()

  const reloadPublicImageURL = () => {
    getRequest(`/api/v3/manage/projects/${project.id}/image`, {}, (err, jsonData) => {
      if (err) { return }
      setUrl(jsonData.url)
    })
  }

  return <>
    <div className="md:grid md:grid-cols-4 md:gap-6">
      <div className='md:grid-cols-1'>
        <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-100">Project Image</h3>
        <p className="mt-1 text-sm text-gray-600 dark:text-gray-300">This is completely optional, but it helps your project stand out.</p>
      </div>
      <Card title="Image">
        <div className="mt-1 flex items-center justify-center p-3 hover:opacity-75 cursor-pointer border-2 border-gray-300 border-dashed rounded-sm relative w-96">
          { !uploaded && <div className='overflow-hidden aspect-w-16 aspect-h-9 w-full'>
              <img className='object-cover w-full' src={url} onClick={openModal} alt={project.name} />
            </div>
            }
          { uploaded && <div className='overflow-hidden aspect-w-16 aspect-h-9 w-full'>
            <img className='object-cover w-full' src={url} onClick={openModal} alt={project.name} />
            <div className='absolute h-20 w-20 top-8'>
              <Loading noMessage noLoadingMessage />
            </div>
          </div> }
        </div>
        <Modal isOpen={isOpen} closeModal={closeModal} >
          <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white"> Edit Image</h3>
          <div className='flex justify-center'>
            <div className="mt-2">
              <ImageUploadForm
                kind='Project'
                aspect='video'
                projectId={project.id}
                url={url}
                setUrl={setUrl}
                success={reloadPublicImageURL}
                didUpload={setUploaded}
              />
            </div>
          </div>
          <div className='text-sm p-4'>For best results, your image should be a ratio of 16:9 (like a youtube video). Example: 960x540</div>
        </Modal>
      </Card>
    </div>
  </>
}

export default ProjectImage

ProjectImage.propTypes = {
  project: PropTypes.object.isRequired
}
