import React, { useState, useEffect, useRef } from 'react'
import Pagination from '@/shared/Pagination'
import Loading from '@/shared/Loading'
import { useGlobalState } from '@/state'
import { EyeIcon } from '@heroicons/react/solid'
import useQuery from '@/hooks/useQuery'
import ManageRolesRow from '@/pages/ManageProjects/ManageRoles/ManageRolesRow'
import Card from '@/shared/Card'

const NoResults = () => {
  return (
    <span>
      <div className="relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccblue" >
        <EyeIcon className="mx-auto h-12 w-12 text-gray-400" />
        <span className="mt-2 block text-sm font-medium text-gray-900 dark:text-gray-300"> No roles created yet.</span>
      </div>
    </span>
  )
}

const RolesIndex = () => {
  const [manageProjectContainer] = useGlobalState('manageProjectContainer')
  const { project } = manageProjectContainer
  const [currentUser] = useGlobalState('currentUser')
  const user = currentUser
  const [meta, setMeta] = useState({})
  const [roles, setRoles] = useState([])
  const { getRequest } = useQuery()
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(1)
  const scrollRef = useRef(null)

  useEffect(() => { fetchData() }, [page])

  const fetchData = (newPage) => {
    setLoading(true)
    const data = {
      page: newPage ? 1 : page
    }
    getRequest(`/api/v3/manage/projects/${project.id}/role_users`, data, (err, jsonData) => {
      setLoading(false)
      if (err) { /* hooks */ return }

      setRoles(jsonData.roles)
      setMeta(jsonData.meta)
    })
  }

  return <>
    <div className='mt-1' />
    <Card title='Manage Project Roles'>
      <>
        <div ref={scrollRef}>
          { roles.length >= 0 && <div className='mt-2'><Pagination meta={meta} callback={setPage} scrollRef={scrollRef} /></div> }
        </div>
        { user.auditionsCount === 0 && <NoResults /> }
        { loading && <Loading /> }
        <ul className="space-y-3">
          {roles.map((role) => (
            <ManageRolesRow key={`rolepr${role.id}`} role={role} />
          ))}
        </ul>
        { roles.length >= 0 && <div className='mt-2'><Pagination meta={meta} callback={setPage} scrollRef={scrollRef} /></div> }
      </>
    </Card>
  </>
}

export default RolesIndex
