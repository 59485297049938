import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import useQuery from '@/hooks/useQuery'
import { useGlobalState } from '@/state'

import Loading from '@/shared/Loading'
import Card from '@/shared/Card'

const PostUserRow = ({ postUser, projectUserId }) => {
  const [currentUser] = useGlobalState('currentUser')
  const [kicked, setKicked] = useState(false)
  const [confirmKick, setConfirmKick] = useState(false)
  const [showMenu, setShowMenu] = useState(false)
  const { getRequest, putpostRequest } = useQuery()

  const kick = () => {
    setKicked(true)
    putpostRequest(`/api/v3/posts/${postUser.id}/kick`, 'POST', {}, (err, jsonData) => {
      if (err) { /* hook */ }
    })
  }

  const menuOn = () => {
    if (currentUser.id !== projectUserId || showMenu) { return }
    setShowMenu(true)
  }
  const menuOff = () => { setShowMenu(false) }

  if (kicked) return null

  return <li className="relative p-2" onMouseEnter={menuOn} onMouseLeave={menuOff}>
    <div className="relative flex items-start space-x-3">
      <img className="h-16 w-16 rounded-sm bg-gray-200 flex items-center justify-center ring-4 ring-cccorange" src={postUser.publicImageUrl} alt="" />
      <div className="min-w-0 flex-1">
        <div className="text-sm flex justify-between">
          <Link to={`/${postUser.userUsername}`} className="font-medium">{postUser.userDisplayName}</Link>
        </div>
        { !showMenu && <div className='text-xs dark:text-gray-300' dangerouslySetInnerHTML={{ __html: postUser.headline }} /> }
        { showMenu && currentUser.id !== postUser.userId && <>
          { !confirmKick && <div className='text-xs text-cccblue cursor-pointer' onClick={() => setConfirmKick(true)}>Remove from Thread</div> }
          { confirmKick && <div className='text-xs text-red-500 cursor-pointer' onClick={kick}>Really remove them?</div> }
        </> }
      </div>
    </div>
  </li>
}

const ProjectMessagePeopleInThisThread = ({postId, projectUserId}) => {
  const [postUsers, setPostUsers] = useState([])
  const [currentUser] = useGlobalState('currentUser')
  const { getRequest, putpostRequest } = useQuery()
  const [loading, setLoading] = useState(true)
  const [confirmLeave, setConfirmLeave] = useState(false)

  const leaveThread = () => {
    putpostRequest(`/api/v3/posts/${postId}/leave`, 'POST', {}, (err, jsonData) => {
      if (err) { /* hook */ }
      window.location.href = '/private_messages'
    })
  }

  useEffect(() => {
    if (!postId) { return }

    setLoading(true)
    getRequest(`/api/v3/posts/${postId}/post_users`, {}, (err, jsonData) => {
      setLoading(false)
      if (err) { /* handled in hook */ return }

      setPostUsers(jsonData.postUsers)
    })
  }, [postId])

  return <>
    <Card light title='People In This Thread'>
      <div className='h-75vh overflow-y-auto flex flex-col'>
        <ul role="list" className='flex-grow overflow-y-auto'>
          {postUsers.map((pu, idx) => (
            <PostUserRow key={`privpostus${pu.id}`} postUser={pu} projectUserId={projectUserId} />
          ))}
          { loading && <Loading /> }
        </ul>
        <div className='flex justify-end items-center'>
          { !confirmLeave && currentUser.id !== projectUserId && <div className='text-xs text-cccblue cursor-pointer' onClick={() => setConfirmLeave(true)}>Leave Thread</div> }
          { confirmLeave && <div className='text-xs text-red-500 cursor-pointer' onClick={leaveThread}>Really leave?</div> }
        </div>
      </div>
    </Card>
  </>
}

export default ProjectMessagePeopleInThisThread

PostUserRow.propTypes = {
  postUser: PropTypes.object.isRequired
}
