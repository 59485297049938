import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useDebouncedEffect } from '@/utils/useDebouncedEffect'
import Pagination from '@/shared/Pagination'
import Loading from '@/shared/Loading'
import { useGlobalState } from '@/state'
import useQuery from '@/hooks/useQuery'
import Card from '@/shared/Card'
import StatusPill from '@/shared/StatusPill'
import UserHoverCard from '@/shared/UserHoverCard'
import { FilmIcon } from '@heroicons/react/solid'
import NoResults from '@/pages/Credits/NoResults'

const PublicCreditRow = ({ userCredit }) => {
  return <li className='bg-gray-100 dark:bg-gray-700 px-2 py-2 sm:px-3 rounded-lg shadow-sm'>
    <div className="flex items-start space-x-1 sm:space-x-3">
      <div className="relative m-1">
        <img className="h-8 w-8 sm:h-10 sm:w-10 rounded-sm bg-gray-200 flex items-center justify-center ring-4 ring-cccblue" src={userCredit.imageUrl} alt={userCredit.name} />

        <span className="absolute -bottom-0.5 -right-1 bg-cccblue rounded-tl px-0.5 py-px">
          <FilmIcon className="h-4 w-4 text-white" aria-hidden="true" />
        </span>
      </div>
      <div className="flex-1 py-0 w-full truncate">
        <div className='flex flex-col truncate'>
          <div className='flex items-start justify-between space-x-1'>
            <div className='flex flex-col truncate'>
              <div className='flex items-center justify-start space-x-3'>
                <span className="uppercase text-xs dark:text-gray-300 text-gray-500"> {userCredit.year} </span>
                { userCredit.stage && <StatusPill status='default' text={userCredit.stage} /> }
              </div>
              { userCredit.parentName && <span className='text-sm dark:text-gray-300 text-gray-500'>Part of series: <Link to={`/credits/${userCredit.parentSlug}`} className="text-cccblue">{userCredit.parentName}</Link></span> }
              <span><UserHoverCard username={userCredit.username} displayName={userCredit.userDisplayName} /></span>
              <Link to={`/credits/${userCredit.slug}`} className="font-medium text-cccblue truncate">{userCredit.name}</Link>
              <span className='dark:text-gray-300 text-gray-500'> {userCredit.creditType} {userCredit.role && <span> - {userCredit.role }</span> } </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </li>
}

const UserCredits = ({ userCredits }) => {
  return <>
    <ul className="space-y-3">
      {userCredits.map((uc) => (
        <PublicCreditRow key={`pubcrerowrec${uc.id}`} userCredit={uc} />
      ))}
    </ul>
  </>
}

const PublicCredits = () => {
  const [profileContainer] = useGlobalState('profileContainer')
  const { user } = profileContainer
  const [userCredits, setUserCredits] = useState([])
  const [meta, setMeta] = useState({})
  const { getRequest } = useQuery()
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(1)
  const [contains] = useState('')
  const scrollRef = useRef(null)

  useDebouncedEffect(() => { fetchData() }, 200, [contains])
  useEffect(() => { fetchData() }, [page])

  const fetchData = () => {
    setLoading(true)
    const data = {
      contains: contains,
      page: contains.length > 0 ? 1 : page
    }
    getRequest(`/api/v3/users/${user.id}/user_credits`, data, (err, jsonData) => {
      setLoading(false)
      if (err) { /* hooks */ return }

      setUserCredits(jsonData.userCredits)
      setMeta(jsonData.meta)
    })
  }

  if (userCredits.length === 0 && contains === '') { return null }

  return <>
    <Card light title='Credits'>
      <>
        <div ref={scrollRef}>
          { userCredits.length >= 0 && <Pagination meta={meta} callback={setPage} scrollRef={scrollRef} /> }
        </div>
        <div className="mt-2 sm:mt-6 min-w-0 flex-1">
          <UserCredits userCredits={userCredits} />
        </div>
        { loading && <Loading /> }
        { userCredits.length === 0 && !loading && <NoResults /> }
      </>
    </Card>
  </>
}

UserCredits.propTypes = {
  userCredits: PropTypes.array.isRequired
}

PublicCreditRow.propTypes = {
  userCredit: PropTypes.object.isRequired
}

export default PublicCredits
