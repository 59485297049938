import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useDebouncedEffect } from '@/utils/useDebouncedEffect'
import UserHoverCard from '@/shared/UserHoverCard'
import useQuery from '@/hooks/useQuery'
import PublicSubmissionRow from '@/pages/Users/PublicProfile/PublicSubmissionRow'

import Pagination from '@/shared/Pagination'
import Tabs from '@/shared/Tabs'
import Loading from '@/shared/Loading'
import { AnnotationIcon, GiftIcon } from '@heroicons/react/solid'
import Card from '@/shared/Card'

const NoResults = () => {
  return (
    <span>
      <div className="relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccblue" >
        <AnnotationIcon className="mx-auto h-12 w-12 text-gray-400" />
        <span className="mt-2 block text-sm font-medium text-gray-900 dark:text-gray-300"> No results. Unless the database is broken, which would be a bummer.</span>
      </div>
    </span>
  )
}

const CoinsAwardRow = ({ award, received }) => {
  let user
  let anon = false
  if (received) {
    user = award.giverUser
    anon = award.anonymous
  } else {
    user = award.receiverUser
  }

  return <li className='bg-white dark:bg-gray-700 sm:p-1 rounded-lg border border-gray-200 dark:border-gray-700'>
    <div className="flex items-start space-x-0 sm:space-x-3">
      <div className="relative m-1 hidden sm:block">
        <img className="h-16 w-16 rounded-sm bg-gray-200 flex items-center justify-center ring-4 ring-cccblue" src={user.imageUrl} alt="" />

        <span className="absolute -bottom-0.5 -right-1 bg-cccblue rounded-tl px-0.5 py-px">
          <AnnotationIcon className="h-4 w-4 text-white" aria-hidden="true" />
        </span>
      </div>
      <div className="flex flex-col justify-start flex-1">
        { anon && <div>{user.displayName}</div>}
        { !anon && <div><UserHoverCard username={user.username} displayName={user.displayName} /></div> }
        <div className="mt-1 text-sm text-gray-600 dark:text-gray-400 flex flex-row items-center space-x-1">
          <span className="uppercase text-xs dark:text-gray-300 text-gray-500">
            <time dateTime={award.createdAt}>{award.createdAt}</time>
          </span>
          <span>for</span>
          { award.giftableType === 'Comment' && <>a comment</> }
          { award.giftableType === 'Audition' && <a href={`/auditions/${award.giftableId}`}>this audition</a> }
          { award.giftableType === 'Project' && <a href={`/projects/${award.projectSlug}`}>this project</a> }
        </div>
        { award.message && <div className="mb-4">
          <p className="text-sm dark:text-gray-300 text-gray-800" dangerouslySetInnerHTML={{ __html: award.message }}></p>
        </div> }
      </div>
    </div>
  </li>
}

const CoinsIndex = () => {
  const [meta, setMeta] = useState({})
  const [awards, setAwards] = useState([])
  const [ledger, setLedger] = useState([])
  const [sub, setSub] = useState({})
  const { getRequest } = useQuery()
  const [loading, setLoading] = useState(true)
  const [loadingLedger, setLoadingLedger] = useState(true)
  const [page, setPage] = useState(1)
  const [status, setStatus] = useState('received')
  const scrollRef = useRef(null)
  const statuses = ['received', 'given']

  useEffect(() => { fetchLedger() }, [])
  useDebouncedEffect(() => { fetchData(true) }, 200, [status])
  useEffect(() => { fetchData() }, [page])

  const fetchLedger = () => {
    getRequest('/api/v3/coins/ledger', {}, (err, jsonData) => {
      setLoadingLedger(false)
      if (err) { /* hooks */ return }

      setLedger(jsonData.ledger)
      setSub(jsonData.sub)
    })
  }

  const fetchData = (newPage) => {
    setLoading(true)
    const data = {
      status: status,
      page: newPage ? 1 : page
    }
    getRequest('/api/v3/coins', data, (err, jsonData) => {
      setLoading(false)
      if (err) { /* hooks */ return }

      setAwards(jsonData.awards)
      setMeta(jsonData.meta)
    })
  }

  const changeTab = (tab) => { setStatus(tab) }
  const tablist = [
    { name: 'Received', count: null, changeValue: 'received' },
    { name: 'Given', count: null, changeValue: 'given' }
  ]
  const received = status === 'received'

  return <>
    <div className='mt-3 h-screen'>
      <div className="md:grid md:grid-cols-3 md:gap-6">
        <div className="md:col-span-1">
          <div className="p-4 sm:px-0">
            <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-100 flex items-center">
              <img className='h-6 w-6' src='https://ddppjbdexhxzj.cloudfront.net/icons/CCC_COIN.svg' />
              cccCoins
            </h3>
            <p className="mt-1 text-xs sm:text-sm text-gray-600 dark:text-gray-300"> Use cccCoins to save humanity: </p>
            <ul className='mt-3 text-gray-600 dark:text-gray-300 text-sm'>
              <li>- Gift Premium memberships to your friends or someone who did an amazing job.</li>
              <li>- Award someone who deserves it with medals and stickers.</li>
              <li>- Feel awesome because you help CCC pay for servers.</li>
            </ul>
            <div className="mt-5 text-xs sm:text-sm text-gray-600 dark:text-gray-300 flex items-center space-x-1">
              <span>Click on the</span>
              <span className="flex space-x-1 text-gray-400 hover:text-gray-500 dark:text-gray-400 dark:hover:text-gray-300 items-center">
                <GiftIcon className="h-5 w-5" aria-hidden="true" />
                <span className="text-xs hidden sm:block">Award</span>
              </span>
              <span>below to try it out</span>
            </div>
            { loadingLedger && <Loading /> }
            <ul className="space-y-3 mt-1 dark:bg-gray-900 p-1">
              { !loadingLedger && sub?.id && <PublicSubmissionRow submission={sub} voteIds={[]} /> }
            </ul>
          </div>
        </div>
        <div className="md:col-span-2">
          <Card light title='Coin History'>
            <div className='h-96 overflow-y-auto'>
              { loadingLedger && <Loading /> }
              <ul role="list" className='space-y-2'>
                {ledger.map((led) => (
                  <li key={`led${led.id}`} className='flex justify-between items-center text-gray-800 dark:text-gray-200 text-sm'>
                    <div className='flex flex-col sm:flex-row sm:space-x-3'>
                      <span className="uppercase text-xs dark:text-gray-300 text-gray-500">
                        <time dateTime={led.createdAt}>{led.createdAt}</time>
                      </span>
                      <span>{led.reason}</span>
                    </div>
                    <b>{led.sign}{led.amount} cccCoins</b>
                  </li>
                ))}
              </ul>
              { ledger.length === 0 && !loading && <NoResults /> }
              <span className='text-xs'>Only shows latest 50 entries</span>
            </div>
          </Card>

          <div className='mt-4' />

          <Card light title='Awards'>
            <div className='h-75vh overflow-y-auto'>
              <div className='pb-1' ref={scrollRef}>
                <div className='flex justify-between items-center'>
                  <Tabs statuses={statuses} tablist={tablist} currentTab={status} changeTab={changeTab} />
                </div>
                { awards.length >= 0 && <Pagination meta={meta} callback={setPage} scrollRef={scrollRef} /> }
              </div>
              { loading && <Loading /> }
              <ul role="list" className='space-y-2'>
                {awards.map((award) => (
                  <CoinsAwardRow key={`awardrow${award.id}`} award={award} received={received} />
                ))}
              </ul>
              { awards.length >= 0 && <Pagination meta={meta} callback={setPage} scrollRef={scrollRef} /> }
              { awards.length === 0 && !loading && <NoResults /> }
            </div>
          </Card>
        </div>
      </div>
    </div>
  </>
}

export default CoinsIndex

CoinsAwardRow.propTypes = {
  award: PropTypes.object.isRequired,
  received: PropTypes.bool
}
