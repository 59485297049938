import React, { useState } from 'react'
import { ExclamationCircleIcon } from '@heroicons/react/solid'
import { debounce } from 'throttle-debounce'
import { useForm } from 'react-hook-form'
import SVGIcon from '@/components/icon'

import useQuery from '@/hooks/useQuery'
import { useGlobalState } from '@/state'
import useModal from '@/hooks/useModalV2'
import Modal from '@/shared/Modal'
import Card from '@/shared/Card'
import PrimaryButton from '@/shared/Buttons/Primary'

const SavedSearchProjectModal = ({ }) => {
  const { openModal, closeModal, isOpen } = useModal()
  const [, setToast] = useGlobalState('toast')
  const [customError, setCustomError] = useState(null)
  const { putpostRequest } = useQuery()
  const { formState, register, reset, unregister, handleSubmit, setValue, getValues, setError } = useForm({
   defaultValues: {
     status: 'weekly',
     title: null
    }
  })

  const { errors } = formState
  const [loading, setLoading] = useState(false)

  const onSubmit = debounce(300, (data) => {
    setLoading(true)

    const formData = {
      saved_search_project: {
        title: data.title,
        status: data.status,
        search: window.location.search
      }
    }

    putpostRequest('/api/v3/saved_search_projects', 'POST', formData, (err, jsonData) => {
      setLoading(false)
      if (err) { // 422 code
        if (typeof err === 'string') { // 422 custom error
          setCustomError(err)
        }
        if (typeof err !== 'string') { // 500 code on API
          Object.entries(err).forEach(([key, value]) => {
            setError(key, value)
          })
        }
        return
      }
      setToast(<div className="ml-3 w-0 flex-1 pt-0.5">
        <p className="text-sm font-medium dark:text-white text-gray-800">Saved!</p>
        <p className="mt-1 text-sm dark:text-gray-100 text-gray-500">You can view your saved searches by click no the 'saved searches' link on the sidebar. Incredibly intuitive!</p>
      </div>)
      closeModal()
    })
  })

  const keyPress = (e) => {
    if (e.target.name === 'name' && e.keyCode === 13) { e.preventDefault() }
    if ((e.metaKey || e.ctrlKey) && e.keyCode === 13) {
      if (typeof (e.target.name) === 'undefined') {
        e.target.blur() // froala hack to make it update model so it can save properly
      }
      handleSubmit(onSubmit)()
    }
  }

  return <>

    <button onClick={openModal} className='hover:underline dark:hover:underline text-cccblue dark:text-cccblue text-sm flex gap-x-1'>
      <SVGIcon name={'icon-saved-searches'} className='h-4 w-4' />
      Save This Search
    </button>
    <Modal isOpen={isOpen} closeModal={closeModal} >
      <Card title="Save This Search"
        footer={
          <div className='flex flex-rows justify-end items-end'>
            <div className='flex flex-col items-end w-min ml-2'>
              <div className="hidden sm:block w-min text-xs border border-gray-200 dark:border-gray-800 rounded px-2 font-sans text-gray-500 dark:text-gray-400">⌘/ctrl+ENTER</div>
              <PrimaryButton className='w-full' onClick={handleSubmit(onSubmit)} loading={loading} text='Save' />
            </div>
          </div>
        } >
        <form>
          <div className="grid sm:grid-cols-4 gap-6">
            <div className="col-span-3 sm:col-span-3">
              <label htmlFor="country" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                How often do you want to be notified about this search
              </label>
              <div className="mt-1">
                <select {...register('status', { required: true })}
                  className="shadow-sm dark:bg-gray-900 dark:text-white focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                >
                  <option value="weekly">Once a week</option>
                  <option value="daily">Once a day</option>
                  <option value="disabled">Never - I'll manually click this when I visit CCC</option>
                </select>
              </div>
            </div>

            <div className="col-span-4 sm:col-span-4">
              <div className='mt-4'>
                <label htmlFor="pricing" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                  Give this saved search a title so you can remember what this search is about
                </label>
                <div className="mt-1 shadow-sm w-full min-w-full">
                <div className='relative'>
                  <input onKeyDown={keyPress} type="text" className={errors.title ? 'errors' : ''} {...register('title', { required: true })} placeholder="Paid projects. No minecraft." />
                  { errors.title && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                  </div> }
                </div>
                </div>
                { errors.title && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
              </div>
            </div>
          </div>
        </form>
        { customError && <div className='mt-2 text-sm text-red-600'>{customError}</div> }
      </Card>
    </Modal>
  </>
}

export default SavedSearchProjectModal

SavedSearchProjectModal.propTypes = { }
