import React, { useReducer, useEffect } from 'react'

import Card from '@/shared/Card'

import TagAutocomplete from '@/shared/TagAutocomplete'

import { XIcon } from '@heroicons/react/solid'
import useQuery from '@/hooks/useQuery'
import { useGlobalState } from '@/state'

const ProfileTags = () => {
  const { getRequest, putpostRequest } = useQuery()
  const [currentUser] = useGlobalState('currentUser')
  const [state, setState] = useReducer(
    (state, newState) => (
      { ...state, ...newState }), {
        languages: [],
        interests: [],
        skills: [],
        accents: [],
        genderAges: []
      }
    )
  const { languages, genderAges, accents, interests, skills } = state

  useEffect(() => { fetchData() }, [])

  const fetchData = () => {
    getRequest('/api/v3/labelings/user_labels', {}, (err, jsonData) => {
      if (err) { /* hooks */ return }

      setState({
        languages: jsonData.languages,
        skills: jsonData.skills,
        interests: jsonData.interests,
        genderAges: jsonData.genderAges,
        accents: jsonData.accents
      })
    })
  }

  const addLabel = (label, labelKind) => {
    const data = {
      labeled_kind: 'User',
      labeled_id: currentUser.id
    }
    const labelData = {
      name: label,
      label_kind: labelKind
    }
    putpostRequest('/api/v3/labelings', 'POST', { labeling: data, label: labelData }, (err, jsonData) => {
      if (err) { /* hook */ return }

      if (labelKind === 'user-languages') {
        setState({ languages: [...languages, ...[jsonData.labeling]] })
      }
      if (labelKind === 'user-skills') {
        setState({ skills: [...skills, ...[jsonData.labeling]] })
      }
      if (labelKind === 'user-interests') {
        setState({ interests: [...interests, ...[jsonData.labeling]] })
      }
      if (labelKind === 'user-accents') {
        setState({ accents: [...accents, ...[jsonData.labeling]] })
      }
      if (labelKind === 'user-gender-age') {
        setState({ genderAges: [...genderAges, ...[jsonData.labeling]] })
      }
    })
  }

  const removeLabel = (labelingId, kind) => {
    if (kind === 'user-languages') {
      const labelings = languages.filter(labeling => labeling.id !== labelingId)
      setState({ languages: labelings })
    }
    if (kind === 'user-interests') {
      const labelings = interests.filter(labeling => labeling.id !== labelingId)
      setState({ interests: labelings })
    }
    if (kind === 'user-skills') {
      const labelings = skills.filter(labeling => labeling.id !== labelingId)
      setState({ skills: labelings })
    }
    if (kind === 'user-accents') {
      const labelings = accents.filter(labeling => labeling.id !== labelingId)
      setState({ accents: labelings })
    }
    if (kind === 'user-gender-age') {
      const labelings = genderAges.filter(labeling => labeling.id !== labelingId)
      setState({ genderAges: labelings })
    }

    putpostRequest(`/api/v3/labelings/${labelingId}`, 'DELETE', {}, (err, jsonData) => {
      if (err) { /* hook */ }
    })
  }

  return <>
        <div>
      <div className="md:grid md:grid-cols-4 md:gap-6 space-x-2">
        <div className="md:col-span-1">
          <div className="px-4 sm:px-0">
            <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-100">Tags</h3>
            <p className="mt-1 text-sm text-gray-600 dark:text-gray-300">
              List a bunch of stuff to help other people find you.
            </p>
          </div>
        </div>
        <Card>
          <>
         <div className="space-y-6 sm:space-y-5">
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="first_name" className="block text-sm font-medium text-gray-700 dark:text-gray-200 sm:mt-px sm:pt-2">
                Voice Age & Gender
                <p className='text-xs text-gray-500'>Searchable for Voice Actors, Singers, & Actors</p>
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2 max-w-xs">
                <TagAutocomplete kind='user-gender-age' addLabel={addLabel} placeholder='Female Young Adult, Male Teen, Baby'/>
                { genderAges.length > 0 && <>
                  <div className="mt-3 flex sm:items-center flex-wrap">
                    {genderAges.map((labeling, idx) => (
                      <span key={`lang${labeling.id}`} onClick={(id) => removeLabel(labeling.id, 'user-gender-age')} className="flex-shrink-0 inline-block px-2 mr-1 mb-1 py-0.5 text-white bg-cccorange dark:bg-cccpurple text-xs font-medium rounded-full flex cursor-pointer capitalize">{labeling.name} <XIcon className='w-4 h-4' /></span>
                    ))}
                  </div>
                </> }
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="first_name" className="block text-sm font-medium text-gray-700 dark:text-gray-200 sm:mt-px sm:pt-2">
                Languages You Speak Proficiently
                <p className='text-xs text-gray-500'>Searchable for Voice Actors, Singers, & Actors</p>
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2 max-w-xs">
                <TagAutocomplete kind='user-languages' addLabel={addLabel} placeholder='English, Japanese'/>
                { languages.length > 0 && <>
                  <div className="mt-3 flex sm:items-center flex-wrap">
                    {languages.map((labeling, idx) => (
                      <span key={`lang${labeling.id}`} onClick={(id) => removeLabel(labeling.id, 'user-languages')} className="flex-shrink-0 inline-block px-2 mr-1 mb-1 py-0.5 text-white bg-cccorange dark:bg-cccpurple text-xs font-medium rounded-full flex cursor-pointer capitalize">{labeling.name} <XIcon className='w-4 h-4' /></span>
                    ))}
                  </div>
                </> }
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="first_name" className="block text-sm font-medium text-gray-700 dark:text-gray-200 sm:mt-px sm:pt-2">
                Accents
                <p className='text-xs text-gray-500'>Searchable for Voice Actors, Singers, & Actors</p>
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2 max-w-xs">
                <TagAutocomplete kind='user-accents' addLabel={addLabel} placeholder='Texan, Irish, French'/>
                { accents.length > 0 && <>
                  <div className="mt-3 flex sm:items-center flex-wrap">
                    {accents.map((labeling, idx) => (
                      <span key={`lang${labeling.id}`} onClick={(id) => removeLabel(labeling.id, 'user-accents')} className="flex-shrink-0 inline-block px-2 mr-1 mb-1 py-0.5 text-white bg-cccorange dark:bg-cccpurple text-xs font-medium rounded-full flex cursor-pointer capitalize">{labeling.name} <XIcon className='w-4 h-4' /></span>
                    ))}
                  </div>
                </> }
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="first_name" className="block text-sm font-medium text-gray-700 dark:text-gray-200 sm:mt-px sm:pt-2">
                Interests
                <p className='text-xs text-gray-500'>Searchable for Everyone</p>
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2 max-w-xs">
                <TagAutocomplete kind='user-interests' addLabel={addLabel} placeholder='Manga, Music, Audiobooks'/>
                { interests.length > 0 && <>
                  <div className="mt-3 flex sm:items-center flex-wrap">
                    {interests.map((labeling, idx) => (
                      <span key={`lang${labeling.id}`} onClick={(id) => removeLabel(labeling.id, 'user-interests')} className="flex-shrink-0 inline-block px-2 mr-1 mb-1 py-0.5 text-white bg-cccorange dark:bg-cccpurple text-xs font-medium rounded-full flex cursor-pointer capitalize">{labeling.name} <XIcon className='w-4 h-4' /></span>
                    ))}
                  </div>
                </> }
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="first_name" className="block text-sm font-medium text-gray-700 dark:text-gray-200 sm:mt-px sm:pt-2">
                Skills
                <p className='text-xs text-gray-500'>Searchable for Everyone</p>
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2 max-w-xs">
                <TagAutocomplete kind='user-skills' addLabel={addLabel} placeholder='Figma, Reaper, Audacity, ADR'/>
                { skills.length > 0 && <>
                  <div className="mt-3 flex sm:items-center flex-wrap">
                    {skills.map((labeling, idx) => (
                      <span key={`lang${labeling.id}`} onClick={(id) => removeLabel(labeling.id, 'user-skills')} className="flex-shrink-0 inline-block px-2 mr-1 mb-1 py-0.5 text-white bg-cccorange dark:bg-cccpurple text-xs font-medium rounded-full flex cursor-pointer capitalize">{labeling.name} <XIcon className='w-4 h-4' /></span>
                    ))}
                  </div>
                </> }
              </div>
            </div>
          </div>
          </>

        </Card>
      </div>
    </div>

  </>
}

export default ProfileTags
