import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import PrimaryButton from '@/shared/Buttons/Primary'

import { useGlobalState } from '@/state'
import useQuery from '@/hooks/useQuery'
import UserHoverCard from '@/shared/UserHoverCard'

const PublicUserRow = ({ user, followingUserIds }) => {
  const { putpostRequest } = useQuery()
  const [, setToast] = useGlobalState('toast')
  const [following, setFollowing] = useState(followingUserIds.indexOf(user.id) >= 0)
  const history = useHistory()

  const follow = () => {
    putpostRequest(`/api/v3/follows/${user.id}/follow_user`, 'POST', {}, (err, jsonData) => {
      if (err) {
        if (typeof err === 'string') { // 422 custom error
          setToast(<div className="ml-3 w-0 flex-1 pt-0.5">
            <p className="text-sm font-medium text-red-500 dark:text-red-500">Hol up</p>
            <p className="mt-1 text-sm dark:text-gray-100 text-gray-500">{err}</p>
          </div>)
        }
        return
      }
      setFollowing(!following)
      setToast(<div className="ml-3 w-0 flex-1 pt-0.5">
        <p className="text-sm font-medium dark:text-white text-gray-800">{!following === true ? 'Following!' : 'Stopped following' }</p>
        <p className="mt-1 text-sm dark:text-gray-100 text-gray-500">{!following === true ? 'You will see activity from this person in your dashboard.' : 'You will no longer see activity about this person'}</p>
      </div>)
    })
  }

  const handleContentClick = (e) => {
    const targetLink = e.target.closest('a')
    if (!targetLink) { return }

    const to = e.target.getAttribute('data-to')
    const isMention = e.target.getAttribute('data-mention')

    if (isMention === 'mention') {
      e.preventDefault()
      history.push('/' + to)
    }
  }

  return <li>
    <div className="flex items-center space-x-4">
      <div className="flex-shrink-0">
        <img className="h-12 w-12 rounded-sm" src={user.publicImageUrl} alt="" />
      </div>
      <div className="flex-1 flex-col items-center">
        <span className='max-w-0'><UserHoverCard username={user.username} displayName={user.displayName} /></span>
        <p className="text-sm text-gray-500 truncate">{'@' + user.username}</p>
      </div>
      <div>
        <PrimaryButton onClick={follow} text={following ? 'Unfollow' : 'Follow' } />
      </div>
    </div>
    { user.headline && <div className="mb-4 ml-16">
      <p onClick={handleContentClick} className="text-sm line-clamp-2 dark:text-gray-300 text-gray-800" dangerouslySetInnerHTML={{ __html: user.headline }}></p>
    </div> }
  </li>
}

export default PublicUserRow

PublicUserRow.propTypes = {
  user: PropTypes.object.isRequired,
  followingUserIds: PropTypes.array
}
