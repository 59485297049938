import React, { useState } from 'react'
import { ChevronDoubleDownIcon, CheckIcon } from '@heroicons/react/solid'
import SVGIcon from '@/components/icon'

import useQuery from '@/hooks/useQuery'
import { useGlobalState } from '@/state'
import useModal from '@/hooks/useModalV2'
import Modal from '@/shared/Modal'
import PrimaryButton from '@/shared/Buttons/Primary'
import DefaultButton from '@/shared/Buttons/Default'

const ListingModal = () => {
  const { openModal, closeModal, isOpen } = useModal()
  const [, setToast] = useGlobalState('toast')
  const [manageProjectContainer] = useGlobalState('manageProjectContainer')
  const { project } = manageProjectContainer
  const [permissions, setPermissions] = useState(project.permissions)

  const { putpostRequest } = useQuery()
  const [loading, setLoading] = useState(false)

  const cancel = () => {
    setPermissions(project.permissions)
    closeModal()
  }

  const changePermissions = () => {
    const data = {
      project: {
        permissions: permissions
      }
    }
    putpostRequest(`/api/v3/manage/projects/${project.id}`, 'PATCH', data, (err, jsonData) => {
      setLoading(false)
      if (err) { /* hook */ return }

      setToast(<div className="ml-3 w-0 flex-1 pt-0.5">
        <p className="text-sm font-medium text-green-500">Permissions changed.</p>
        <p className="mt-1 text-sm text-green-500">Give the servers about 30 minutes to get this pushed out everywhere.</p>
      </div>)
      closeModal()
    })
  }

  if (project.status === 'completed') {
    return <div className="w-full rounded-md bg-cccorange bg-opacity-10 p-2 border-cccorange border-l-4">
      <div className='flex flex-col justify-between h-full'>
        <div className='pb-3 text-sm text-cccorange'>
          This project has been marked as <b>completed</b>
        </div>
      </div>
    </div>
  }

  return <>
    <div className="w-full rounded-md bg-cccorange bg-opacity-10 p-2 border-cccorange border-l-4">
      <div className='flex flex-col justify-between h-full'>
        <div className='pb-3 text-sm text-cccorange'>
          <b>Control</b> where your project is listed
        </div>
        <button onClick={openModal} className="px-4 py-2 text-sm font-medium rounded-md text-white hover:text-white bg-cccorange hover:bg-cccorange-alt w-full flex items-center justify-center" >
          <span className='capitalize'>{permissions}</span>
          <ChevronDoubleDownIcon className='h-4 w-4' />
        </button>
      </div>
    </div>
    <Modal isOpen={isOpen} closeModal={closeModal} >
      <div className="flex flex-col text-center items-center">
        <h2 className="mb-8 text-xs font-semibold tracking-widest text-gray-800 dark:text-gray-200 uppercase title-font">Where to list this project</h2>
      </div>

      <div className='flex flex-col space-y-2'>
        <div className="flex rounded-md bg-white dark:bg-gray-800 dark:hover:bg-gray-900 hover:bg-gray-100">
          <div onClick={() => setPermissions('public')} className='flex w-full cursor-pointer'>
            <div className='flex-shrink-0 flex items-center justify-center w-16 text-white text-sm font-medium rounded-l-md  border-t border-l border-b border-gray-200 dark:border-gray-700'>
              <SVGIcon name='icon-public' className='h-10 w-10' />
            </div>
            <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 dark:border-gray-700 rounded-r-md">
              <div className="flex-1 px-4 py-2 text-sm">
                <div className="text-gray-900 font-medium dark:text-gray-100"> Public </div>
                <p className="text-xs text-gray-500 dark:text-gray-400">Anyone can send in a submission. There are about 1,100,000 total CCC members and 2M visitors per month.</p>
              </div>
              <div className='w-5'>{ permissions === 'public' && <CheckIcon className="h-5 w-5 text-cccblue" aria-hidden="true" /> }</div>
            </div>
          </div>
        </div>
        { false && <>
          <div className="flex rounded-md bg-white dark:bg-gray-800 dark:hover:bg-gray-900 hover:bg-gray-100">
            <div onClick={() => setPermissions('premium only')} className='flex w-full cursor-pointer'>
              <div className='flex-shrink-0 flex items-center justify-center w-16 text-white text-sm font-medium rounded-l-md  border-t border-l border-b border-gray-200 dark:border-gray-700'>
                <SVGIcon name='icon-premium-only' className='h-10 w-10' />
              </div>
              <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 dark:border-gray-700 rounded-r-md">
                <div className="flex-1 px-4 py-2 text-sm">
                  <div className="text-gray-900 font-medium dark:text-gray-100"> Premium Members Only </div>
                  <p className="text-xs text-gray-500 dark:text-gray-400">Anyone who is a premium member can send in a submission. This is a great way to find people who are 'serious'. You will get fewer, but higher quality submissions.</p>
                </div>
                <div className='w-5'>{ permissions === 'premium only' && <CheckIcon className="h-5 w-5 text-cccblue" aria-hidden="true" /> }</div>
              </div>
            </div>
          </div>
        </> }
        <div className="flex rounded-md bg-white dark:bg-gray-800 dark:hover:bg-gray-900 hover:bg-gray-100">
          <div onClick={() => setPermissions('unlisted')} className='flex w-full cursor-pointer'>
            <div className='flex-shrink-0 flex items-center justify-center w-16 text-white text-sm font-medium rounded-l-md  border-t border-l border-b border-gray-200 dark:border-gray-700'>
              <SVGIcon name='icon-unlisted' className='h-10 w-10' />
            </div>
            <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 dark:border-gray-700 rounded-r-md">
              <div className="flex-1 px-4 py-2 text-sm">
                <div className="text-gray-900 font-medium dark:text-gray-100"> Unlisted </div>
                <p className="text-xs text-gray-500 dark:text-gray-400">Not listed in search. You can send this to your private network (discord, twitter, etc).</p>
              </div>
              <div className='w-5'>{ permissions === 'unlisted' && <CheckIcon className="h-5 w-5 text-cccblue" aria-hidden="true" /> }</div>
            </div>
          </div>
        </div>
      </div>

      { permissions === 'premium only' && <div className="rounded-md bg-blue-50 p-4 mt-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <svg className="h-5 w-5 text-blue-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fillRule="evenodd" d="M19 10.5a8.5 8.5 0 11-17 0 8.5 8.5 0 0117 0zM8.25 9.75A.75.75 0 019 9h.253a1.75 1.75 0 011.709 2.13l-.46 2.066a.25.25 0 00.245.304H11a.75.75 0 010 1.5h-.253a1.75 1.75 0 01-1.709-2.13l.46-2.066a.25.25 0 00-.245-.304H9a.75.75 0 01-.75-.75zM10 7a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
            </svg>
          </div>
          <div className="ml-3 flex-1 md:flex md:justify-between">
            <p className="text-sm text-blue-700">If you list to <b>Premium Members Only</b>, please be aware that moderators will change the project to <b>Public</b> if your project is unpaid or deferred payment.</p>
          </div>
        </div>
      </div> }

      <div className="flex justify-end space-x-2 mt-4">
        <DefaultButton onClick={cancel} text='Cancel' />
        <PrimaryButton onClick={changePermissions} loading={loading} text='Confirm' />
      </div>
    </Modal>
  </>
}

export default ListingModal
