import React, { useEffect, useRef } from 'react'
import { Switch, Route, Link, useParams, useLocation } from 'react-router-dom'
import useQuery from '@/hooks/useQuery'
import { useGlobalState } from '@/state'

import Breadcrumbs from '@/Layout/Breadcrumbs'
import PageTitle from '@/Layout/PageTitle'
import { AdjustmentsIcon } from '@heroicons/react/solid'

import PublicProfile from '@/pages/Users/PublicProfile'
import Loading from '@/shared/Loading'

const CannotShowProfile = () => {
  return <main className="container mx-auto max-w-xl px-4 sm:px-6 lg:px-8">
    <div className="rounded-md bg-yellow-50 p-4 mt-10  text-yellow-900">
      <div className="flex">
        <div className="flex-shrink-0">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
          </svg>
        </div>
        <div className="ml-3">
          <div className='pb-3 text-sm'>
            This person's profile is too new to publicly show. As this person gains reputation, it will be viewable to the public.
          </div>
          <h3 className="text-sm font-medium text-yellow-800">
            Search <Link to='/talent'>other talent</Link>
          </h3>
        </div>
      </div>
    </div>
  </main>
}

function breadCrumbs() {
  const [profileContainer] = useGlobalState('profileContainer')
  const { user } = profileContainer
  const crumbs = [
    { to: '/members', name: 'Members' },
    { to: '/profile', name: 'Profile' },
    { to: `/profile/${user.username}`, name: user.displayName }
  ]
  return crumbs
}

function pageTitle() {
  const [profileContainer] = useGlobalState('profileContainer')
  const { user } = profileContainer
  const [currentUser] = useGlobalState('currentUser')

  const buttons = []
  if (currentUser?.id === user.id) {
    buttons.push(
      <Link key='editprofile' to='/profile' className="ml-3 inline-flex justify-center px-4 py-2 shadow-sm text-sm font-medium rounded-md text-white dark:text-gray-200 dark:hover:bg-cccblue-alt bg-cccblue hover:bg-cccblue-alt hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 mb-2">
      <AdjustmentsIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
      Edit Profile
    </Link>
    )
  }

  const titleInfo = {
    title: user.displayName,
    buttons: buttons
  }
  return titleInfo
}

function usePrevious(value) {
  const ref = useRef()
  useEffect(() => { ref.current = value })
  return ref.current
}

function trackProfileViews(username) {
  const location = useLocation()

  useEffect(() => {
    if (typeof window.ga === 'function') {
      window.ga('send', {
        hitType: 'event',
        eventCategory: 'Profile',
        eventAction: 'view',
        eventLabel: username
      })
    }
  }, [location])
}

export default function ProfileContainer(props) {
  const crumbs = breadCrumbs()
  const titleInfo = pageTitle()
  const isComponentMounted = useRef(false)
  const { getRequest } = useQuery()
  const { username } = useParams()
  const userRef = useRef(null)
  const [profileContainer, setProfileContainer] = useGlobalState('profileContainer')
  const currentUserId = usePrevious(profileContainer.user?.id)

  trackProfileViews(username)

  useEffect(() => {
    getRequest(`/api/v3/users/${username}`, {}, (err, jsonData) => {
      if (err) { /* handled in hook */ return }
      setProfileContainer({
        ...profileContainer,
        user: jsonData.user,
        blocked: jsonData.blocked,
        blocking: jsonData.blocking,
        demos: jsonData.demos,
        genderAges: jsonData.genderAges,
        languages: jsonData.languages,
        skills: jsonData.skills,
        interests: jsonData.interests,
        accents: jsonData.accents,
        softwares: jsonData.softwares,
        hardwares: jsonData.hardwares,
        isFollowing: jsonData.isFollowing,
        userMeta: jsonData.userMeta
      })
    })
    isComponentMounted.current = true
  }, [username])

  useEffect(() => {
    if (userRef.current && currentUserId !== profileContainer.user?.id) {
      userRef.current.scrollIntoView()
    }
  }, [profileContainer])

  if (!profileContainer.user?.id) {
    return <Loading />
  }

  if (!profileContainer.user.canShowPublicProfile) {
    return <CannotShowProfile />
  }

  return <div ref={userRef}>
    <Breadcrumbs>
      { crumbs.map((crumb, index) => (
        <Link key={`${index}${crumb.name}`} to={crumb.to} className="text-xs sm:text-sm text-gray-500 hover:text-gray-700 dark:text-gray-100">
          {crumb.name}
        </Link>
      ))}
    </Breadcrumbs>
    <PageTitle pagetitle={titleInfo.title}>
      {titleInfo.buttons}
    </PageTitle>
    <Switch>
      <Route {...props} exact path={[`/${username}`]}>
        <PublicProfile />
      </Route>
    </Switch>
  </div>
}
