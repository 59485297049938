import React from 'react'
import PropTypes from 'prop-types'
import { ExclamationIcon } from '@heroicons/react/solid'

const ChangeBillingIntervalWarning = ({ existingSubscription, billingInterval }) => {
  if (existingSubscription.billingInterval === 'monthly') { return null }
  if (existingSubscription.billingInterval === billingInterval) { return null }

  return <div className="rounded-md bg-yellow-50 p-4 mt-10">
    <div className="flex">
      <div className="flex-shrink-0">
        <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
      </div>
      <div className="ml-3">
        <h3 className="text-sm font-medium text-yellow-800">Changing Billing Cycle Important Information!</h3>
        <div className="mt-2 text-sm text-yellow-700">
          If you're changing your billing cycle (monthly/yearly), these effects will happen after your current subscription renewal cycle ends. For example, your subscription renewal is {existingSubscription.expiresAt}. If you changed your billing cycle today, the change will go into effect {existingSubscription.expiresAt}. You can preview the full changes on the next page.
        </div>
      </div>
    </div>
  </div>
}

export default ChangeBillingIntervalWarning

ChangeBillingIntervalWarning.propTypes = {
  existingSubscription: PropTypes.object.isRequired,
  billingInterval: PropTypes.string.isRequired
}
