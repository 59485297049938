import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { useGlobalState } from '@/state'
import useQuery from '@/hooks/useQuery'
import ImageUploadForm from '@/shared/ImageUploadForm'
import useModal from '@/hooks/useModalV2'
import Loading from '@/shared/Loading'
import StatusPill from '@/shared/StatusPill'
import Modal from '@/shared/Modal'

import RoleEdit from '@/pages/ManageProjects/ProjectEdit/RoleEdit'

const RoleImage = ({ role }) => {
  const [url, setUrl] = useState(role.imageUrl)
  const [uploaded, setUploaded] = useState(false)
  const { openModal, closeModal, isOpen } = useModal()
  const { getRequest } = useQuery()

  const reloadPublicImageURL = () => {
    getRequest(`/api/v3/roles/${role.id}/image`, {}, (err, jsonData) => {
      if (err) { return }
      setUrl(jsonData.url)
    })
  }

  return <>
    <div className="flex justify-center p-3 hover:opacity-75 cursor-pointer border-2 border-gray-300 border-dashed rounded-md relative">
      { !uploaded && <span>
          <img className='h-20 w-20 rounded-md' src={url} onClick={openModal} alt={role.name} />
        </span>
        }
      { uploaded && <div className='relative h-20 w-20 rounded-full flex items-center justify-center dark:bg-gray-700'>
        <img className='h-20 w-20 rounded-full' src={url} onClick={openModal} alt={role.name} />
        <div className='absolute h-20 w-20 top-8'>
          <Loading noMessage noLoadingMessage />
        </div>
      </div> }
    </div>
    <Modal isOpen={isOpen} closeModal={closeModal} >
      <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white"> Edit Image</h3>
      <div className='flex justify-center'>
        <div className="mt-2">
          <ImageUploadForm
            kind='Role'
            roleId={role.id}
            url={url}
            setUrl={setUrl}
            success={reloadPublicImageURL}
            didUpload={setUploaded}
          />
        </div>
      </div>
    </Modal>
  </>
}

const RoleRow = ({ initialRole, setPositionChanging, positionChanging, idx, reorder }) => {
  const { openModal, closeModal, isOpen, willClose } = useModal()
  const [, setToast] = useGlobalState('toast')
  const { putpostRequest } = useQuery()
  const [role, setRole] = useState(initialRole)
  const [deleteConfirm, setDeleteConfirm] = useState(false)
  const [destroyed, setDestroyed] = useState(false)

  let pillColor = 'default'
  if (role.status === 'open') {
    pillColor = 'warning'
  } else if (role.status === 'closed') {
    pillColor = 'success'
  }

  const destroy = () => {
    putpostRequest(`/api/v3/roles/${role.id}`, 'DELETE', { }, (err, jsonData) => {
      if (err) { /* hook */ return }

      if (jsonData.errors) {
        setToast(<div className="ml-3 w-0 flex-1 pt-0.5">
          <p className="text-sm font-medium text-red-500">Cannot delete role</p>
          <p className="mt-1 text-sm text-red-500">{jsonData.errors}</p>
        </div>)
      } else {
        setDestroyed(true)
        setToast(<div className="ml-3 w-0 flex-1 pt-0.5">
          <p className="text-sm font-medium dark:text-white text-gray-800">Role Deleted</p>
          <p className="mt-1 text-sm dark:text-gray-100 text-gray-500">Please. A moment of silence. ...okay, I'm good now.</p>
        </div>)
      }
    })
  }
 
  if (destroyed) { return null }

  return <li className='bg-white dark:bg-gray-800 p-2 rounded-md relative' draggable>
    <Modal full={true} isOpen={isOpen} closeModal={closeModal} >
      <RoleEdit initialRole={role} updateRole={setRole} closeModal={closeModal} />
    </Modal>

    { positionChanging && <div onClick={reorder} className="absolute inset-0 flex justify-center items-center z-10 cursor-pointer">
      <div className="flex flex-col justify-center items-center dark:text-gray-200">
        <div className="text-5xl font-bold">{idx}</div>
        <div className="text-xl font-bold">Click to re-order</div>
      </div>
    </div> }
    <div className={`flex flex-col sm:flex-row sm:items-centerflex-wrap ${positionChanging ? 'opacity-20' : ''}`}>
      <div className="min-w-0 flex-1 flex sm:items-center  flex-wrap sm:flex-nowrap">
        <div className="flex-shrink-0 hidden sm:block">
          <RoleImage role={role} />
        </div>
        <div className="min-w-0 flex-auto px-0 sm:px-4 md:gap-4 w-full">
          <div className='flex flex-col truncate space-y-2'>
            <span className='flex justify-start space-x-4'>
              <span className="inline-flex items-center rounded-md bg-cccblue-alt bg-opacity-20 px-2.5 py-0.5 text-sm font-medium text-cccblue">{role.kind}</span>
              <StatusPill status={pillColor} text={role.status} />
            </span>
            <div className="font-bold dark:text-gray-300">{role.name}</div>
            <span className='flex justify-start space-x-6 text-sm'>
              <button onClick={openModal} type="button" className="text-cccblue hover:text-cccblue-alt"> Edit </button>
              <Link to={`/manage/projects/${role.projectId}/roles`} className="text-cccblue hover:text-cccblue-alt"> Manage </Link>
              { !deleteConfirm && <button onClick={() => setDeleteConfirm(true)} type="button" className="text-cccblue hover:text-cccblue-alt"> Delete </button> }
              { deleteConfirm && <button onClick={destroy} type="button" className="text-red-500"> Really Delete? </button> }
              <button onClick={setPositionChanging} type="button" className="text-cccblue hover:text-cccblue-alt"> Re-order </button>
            </span>
          </div>
        </div>
      </div>
      <span className="ml-4 flex-shrink-0 flex items-start space-x-4">
      </span>
    </div>
  </li>
}

export default RoleRow

RoleRow.propTypes = {
  initialRole: PropTypes.object.isRequired,
  setPositionChanging: PropTypes.func.isRequired,
  idx: PropTypes.number.isRequired,
  reorder: PropTypes.func.isRequired
}
