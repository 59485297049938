import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Switch } from '@headlessui/react'
import { debounce } from 'throttle-debounce'
import PropTypes from 'prop-types'
import classNames from '@/utils/classNamesLocal'

import { useForm } from 'react-hook-form'
import useQuery from '@/hooks/useQuery'
import { useGlobalState } from '@/state'
import PrimaryButton from '@/shared/Buttons/Primary'

import Card from '@/shared/Card'

const EmailPreferences = () => {
  const { getRequest } = useQuery()
  const [prefs, setPrefs] = useState(null)
  useEffect(() => { fetchData() }, [])

  const fetchData = debounce(300, () => {
    getRequest('/api/v3/preferences', {}, (err, jsonData) => {
      if (err) { /* hooks */ return }
      setPrefs(jsonData.preferences)
    })
  })

  if (!prefs) { return null }

  return <>
    <EmailPreferencesForm preferences={prefs} />
    <div className="col-span-4 mt-3" />
    <GeneralPreferencesForm preferences={prefs} />
  </>
}
const GeneralPreferencesForm = ({ preferences }) => {
  const { putpostRequest } = useQuery()
  const [currentUser, setCurrentUser] = useGlobalState('currentUser')
  const [, setToast] = useGlobalState('toast')
  const { formState, reset, register, unregister, watch, handleSubmit, setValue, getValues } = useForm({
   defaultValues: {
      allow_nsfw: preferences.allow_nsfw,
      direct_messages: preferences.direct_messages,
      show_rejected: preferences.show_rejected
    }
  })

  const { isDirty } = formState
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    register('allow_nsfw', { required: false })
    register('show_rejected', { required: false })
    return () => {
      unregister('allow_nsfw')
      unregister('show_rejected')
    }
  }, [register])

  const onSubmit = debounce(300, (data) => {
    setLoading(true)
    putpostRequest(`/api/v3/preferences/${preferences.id}`, 'PATCH', { preference: data }, (err, _) => {
      setLoading(false)
      if (err) { /* 422 code */ return }

      setToast(<div className="ml-3 w-0 flex-1 pt-0.5">
        <p className="text-sm font-medium dark:text-green-300 text-green-600">General Preferences Saved</p>
        <p className="mt-1 text-sm dark:text-green-300 text-green-400">Wait, no hold on. Are they? ....  Yeah just checked, they're saved.</p>
      </div>)

      setCurrentUser({ ...currentUser, showRejectedSubmissions: getValues().show_rejected })
      reset({ ...getValues() })
    })
  })

  const handleUpdateSwitch = (key) => {
    setValue(key, !getValues()[key], { shouldDirty: true, shouldValidate: true })
  }

  const allowNsfw = watch('allow_nsfw')
  const showRejected = watch('show_rejected')
  return <>
    <div className="md:col-span-1 mb-3">
      <div className="px-0 sm:px-4">
        <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-100">General Preferences</h3>
        <p className="mt-1 text-sm text-gray-600 dark:text-gray-300">
          Everyone has strong opinions on how this site should operate.
        </p>
      </div>
    </div>
    <Card title='Preferences' footer={
      <div className='flex flex-rows justify-end items-end'>
        <div className='flex flex-col items-end w-min ml-2'>
          <div className='flex flex-col items-end w-min ml-2'>
            <PrimaryButton className='w-full' onClick={handleSubmit(onSubmit)} disabled={!isDirty} loading={loading} text={<div className='flex flex-col'> Save </div>} />
          </div>
        </div>
      </div>
      } >
      <form className='col-span-3'>
        <div className="grid grid-cols-4 gap-6">
          <div className='col-span-4 sm:col-span-3 rounded-sm p-2 bg-white dark:bg-gray-700'>
            <h3 className='uppercase text-gray-900 dark:text-gray-100 text-center'> Content</h3>
            <div className='ml-2'>
              <Switch.Group as="div" className="flex items-center justify-between mb-2">
                <Switch.Label as="span" className="flex-grow flex flex-col" >
                  <span className="text-sm text-gray-700 dark:text-gray-300">View NSFW content (I am 18+)</span>
                </Switch.Label>
                <Switch checked={allowNsfw} onChange={() => handleUpdateSwitch('allow_nsfw')}
                  className={classNames(allowNsfw ? 'bg-cccpurple' : 'bg-gray-300 dark:bg-gray-500',
                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccpurple')} >
                  <span className="sr-only" hidden>View NSFW content (I am 18+)</span>
                  <span aria-hidden="true" className={classNames(allowNsfw ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200')}
                  />
                </Switch>
              </Switch.Group>
              <Switch.Group as="div" className="flex items-center justify-between mb-2">
                <Switch.Label as="span" className="flex-grow flex flex-col" >
                  <span className="text-sm text-gray-700 dark:text-gray-300">Show "Dismissed" label on submissions that were not chosen</span>
                </Switch.Label>
                <Switch checked={showRejected} onChange={() => handleUpdateSwitch('show_rejected')}
                  className={classNames(showRejected ? 'bg-cccpurple' : 'bg-gray-300 dark:bg-gray-500',
                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccpurple')} >
                  <span className="sr-only" hidden>Show "Dismissed" label on submissions that were not chosen</span>
                  <span aria-hidden="true" className={classNames(showRejected ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200')}
                  />
                </Switch>
              </Switch.Group>
              <div className='flex justify-between items-center mb-2'>
                <span className="text-sm text-gray-700 dark:text-gray-300">Who can send me direct messages?</span>
                <select
                  {...register('direct_messages')}
                  className="mt-1 block py-2 px-3 border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm"
                >
                  <option value='allow_all'>Everyone</option>
                  <option value='private'>Only People I Follow</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Card>
  </>
}

const EmailPreferencesForm = ({ preferences }) => {
  const { putpostRequest } = useQuery()
  const [currentUser] = useGlobalState('currentUser')
  const [, setToast] = useGlobalState('toast')
  const [upgrade, setUpgrade] = useState(false)
  const { formState, reset, register, unregister, watch, handleSubmit, setValue, getValues } = useForm({
   defaultValues: {
      new_comment: preferences.new_comment,
      activity: preferences.activity,
      paid_projects: preferences.paid_projects,
      new_mention: preferences.new_message,
      new_audition_comment: preferences.new_audition_comment,
      new_audition_reply: preferences.new_audition_reply,
      new_message: preferences.new_message,
      newsletter: preferences.newsletter,
      education: preferences.education,
      new_role_comment: preferences.new_role_comment,
      new_audition: preferences.new_audition,
      new_like: preferences.new_like,
      audition_frequency: preferences.audition_frequency,
      project_updates: preferences.project_updates,
      project_end: preferences.project_end,
      role_almost_expired: preferences.role_almost_expired,
      unread_notifications: preferences.unread_notifications,
      unsubscribe_all: preferences.unsubscribe_all,
      successful_audition: preferences.successful_audition,
      follower_cast: preferences.follower_cast,
      follower_new_project: preferences.follower_new_project,
      upvote: preferences.upvote,
      new_reply: preferences.new_reply
    }
  })

  const { isDirty, errors } = formState
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    register('new_comment', { required: false })
    register('education', { required: false })
    register('newsletter', { required: false })
    register('new_reply', { required: false })
    register('paid_projects', { required: false })
    register('new_mention', { required: false })
    register('new_audition_comment', { required: false })
    register('new_audition_reply', { required: false })
    register('new_message', { required: false })
    register('new_role_comment', { required: false })
    register('new_like', { required: false })
    register('new_audition', { required: false })
    register('audition_frequency', { required: false })
    register('project_end', { required: false })
    register('project_updates', { required: false })
    register('role_almost_expired', { required: false })
    register('unread_notifications', { required: false })
    register('unsubscribe_all', { required: false })
    register('successful_audition', { required: false })
    register('follower_new_project', { required: false })
    register('follower_cast', { required: false })
    register('upvote', { required: false })
    register('activity', { required: false })
    return () => {
      unregister('new_comment')
      unregister('activity')
      unregister('new_reply')
      unregister('paid_projects')
      unregister('new_mention')
      unregister('new_audition_comment')
      unregister('education')
      unregister('newsletter')
      unregister('new_audition_reply')
      unregister('new_message')
      unregister('new_role_file')
      unregister('new_like')
      unregister('new_audition')
      unregister('audition_frequency')
      unregister('unread_notifications')
      unregister('unsubscribe_all')
      unregister('project_end')
      unregister('project_updates')
      unregister('role_almost_expired')
      unregister('successful_audition')
      unregister('follower_new_project')
      unregister('follower_cast')
      unregister('upvote')
    }
  }, [register])

  const onSubmit = debounce(300, (data) => {
    console.log('hi')
    setLoading(true)
    putpostRequest(`/api/v3/preferences/${preferences.id}`, 'PATCH', { preference: data }, (err, _) => {
      setLoading(false)
      if (err) { /* 422 code */ return }

      setToast(<div className="ml-3 w-0 flex-1 pt-0.5">
        <p className="text-sm font-medium dark:text-green-300 text-green-600">Preferences Saved</p>
        <p className="mt-1 text-sm dark:text-green-300 text-green-400">Nice clicking, partner.</p>
      </div>)
      reset({ ...getValues() })
    })
  })
  console.log(errors)

  const handleUpdateSwitch = (key) => {
    setValue(key, !getValues()[key], { shouldDirty: true, shouldValidate: true })
  }

  const newComment = watch('new_comment')
  const education = watch('education')
  const newsletter = watch('newsletter')
  const newReply = watch('new_reply')
  const paidProjects = watch('paid_projects')
  const newMessage = watch('new_message')
  const newMention = watch('new_mention')
  const newAuditionComment = watch('new_audition_comment')
  const newAuditionReply = watch('new_audition_reply')
  const newRoleComment = watch('new_role_comment')
  const newAudition = watch('new_audition')
  const newLike = watch('new_like')
  const activity = watch('activity')
  const unreadNotifications = watch('unread_notifications')
  const projectEnd = watch('project_end')
  const projectUpdates = watch('project_updates')
  const roleAlmostExpired = watch('role_almost_expired')
  const successfulAudition = watch('successful_audition')
  const upvote = watch('upvote')
  const followerNewProject = watch('follower_new_project')
  const followerCast = watch('follower_cast')
  const unsubscribeAll = watch('unsubscribe_all')

  return <>
    <div className="md:col-span-1 mb-3">
      <div className="px-0 sm:px-4">
        <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-100">Email & Notification Preferences</h3>
        <p className="mt-1 text-sm text-gray-600 dark:text-gray-300">
          Control how often you are emailed when something happens.
        </p>
      </div>
    </div>
    <Card title='Preferences' footer={
      <div className='flex flex-rows justify-end items-end'>
        <div className='flex flex-col items-end w-min ml-2'>
          <div className='flex flex-col items-end w-min ml-2'>
            <PrimaryButton className='w-full' onClick={handleSubmit(onSubmit)} disabled={!isDirty} loading={loading} text={<div className='flex flex-col'> Save </div>} />
          </div>
        </div>
      </div>
      } >
      <form className='col-span-3'>
        <div className="grid grid-cols-4 gap-6">
          <div className='col-span-4 sm:col-span-2 rounded-sm p-2 bg-white dark:bg-gray-700'>
            <h3 className='uppercase text-gray-900 dark:text-gray-100 text-center text-lg'> Activity Emails</h3>
            <Switch.Group as="div" className="flex items-center justify-between mb-2">
              <Switch.Label as="span" className="flex-grow flex flex-col">
                <span className="text-sm text-gray-700 dark:text-gray-300">Send me these activity emails 👇</span>
              </Switch.Label>
              <Switch checked={activity} onChange={() => handleUpdateSwitch('activity')}
                className={classNames(activity ? 'bg-cccpurple' : 'bg-gray-300 dark:bg-gray-500',
                  'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccpurple')} >
                <span className="sr-only" hidden>Activity Emails</span>
                <span aria-hidden="true" className={classNames(activity ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200')}
                />
              </Switch>
            </Switch.Group>

            <div className={classNames(activity ? '' : 'opacity-20')}>
              <h3 className='uppercase text-cccorange text-center text-sm'> Premium Member Perks</h3>
              <div className='ml-2'>
                <Switch.Group as="div" className="flex items-center justify-between mb-2">
                  <Switch.Label as="span" className="flex-grow flex flex-col" >
                    <span className="text-sm text-gray-700 dark:text-gray-300">Email me immediately when a paid project is posted</span>
                  </Switch.Label>
                  { currentUser.canTogglePaidProjectEmails && <>
                    <Switch checked={paidProjects} onChange={() => handleUpdateSwitch('paid_projects')}
                      className={classNames(paidProjects ? 'bg-cccpurple' : 'bg-gray-300 dark:bg-gray-500',
                        'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccpurple')} >
                      <span className="sr-only" hidden>Email me immediately when a paid project is posted</span>
                      <span aria-hidden="true" className={classNames(paidProjects ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200')}
                      />
                    </Switch>
                  </> }
                  { !currentUser.canTogglePaidProjectEmails && <>
                    <Switch checked={false} onChange={() => setUpgrade(true)} className='bg-gray-300 dark:bg-gray-500 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccpurple'>
                      <span className="sr-only" hidden>Email me immediately when a paid project is posted</span>
                      <span aria-hidden="true" className={'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'}
                      />
                    </Switch>
                  </> }
                </Switch.Group>
                { upgrade && <Link to='/membership'>Upgrade to enable paid project emails </Link> }
              </div>
              <h3 className='uppercase text-cccorange text-sm text-center'> Comments</h3>
              <span className="text-sm font-medium text-gray-900 dark:text-gray-200">Email me when someone</span>
              <div className='ml-2'>
                <Switch.Group as="div" className="flex items-center justify-between mb-2">
                  <Switch.Label as="span" className="flex-grow flex flex-col" >
                    <span className="text-sm text-gray-700 dark:text-gray-300">...comments on my project</span>
                  </Switch.Label>
                  <Switch checked={newComment} onChange={() => handleUpdateSwitch('new_comment')}
                    className={classNames(newComment ? 'bg-cccpurple' : 'bg-gray-300 dark:bg-gray-500',
                      'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccpurple')} >
                    <span className="sr-only" hidden>comments on my project</span>
                    <span aria-hidden="true" className={classNames(newComment ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200')}
                    />
                  </Switch>
                </Switch.Group>
                <Switch.Group as="div" className="flex items-center justify-between mb-2">
                  <Switch.Label as="span" className="flex-grow flex flex-col" >
                    <span className="text-sm text-gray-700 dark:text-gray-300">...replies on my comment</span>
                  </Switch.Label>
                  <Switch checked={newReply} onChange={() => handleUpdateSwitch('new_reply')}
                    className={classNames(newReply ? 'bg-cccpurple' : 'bg-gray-300 dark:bg-gray-500',
                      'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccpurple')} >
                    <span className="sr-only" hidden>replies on my comment</span>
                    <span aria-hidden="true" className={classNames(newReply ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200')}
                    />
                  </Switch>
                </Switch.Group>
                <Switch.Group as="div" className="flex items-center justify-between mb-2">
                  <Switch.Label as="span" className="flex-grow flex flex-col" >
                    <span className="text-sm text-gray-700 dark:text-gray-300">...@ mentions me</span>
                  </Switch.Label>
                  <Switch checked={newMention} onChange={() => handleUpdateSwitch('new_mention')}
                    className={classNames(newMention ? 'bg-cccpurple' : 'bg-gray-300 dark:bg-gray-500',
                      'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccpurple')} >
                    <span className="sr-only" hidden>@ mentions me</span>
                    <span aria-hidden="true" className={classNames(newMention ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200')}
                    />
                  </Switch>
                </Switch.Group>
                <Switch.Group as="div" className="flex items-center justify-between mb-2">
                  <Switch.Label as="span" className="flex-grow flex flex-col" >
                    <span className="text-sm text-gray-700 dark:text-gray-300">...comments on my submission</span>
                  </Switch.Label>
                  <Switch checked={newAuditionComment} onChange={() => handleUpdateSwitch('new_audition_comment')}
                    className={classNames(newAuditionComment ? 'bg-cccpurple' : 'bg-gray-300 dark:bg-gray-500',
                      'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccpurple')} >
                    <span className="sr-only" hidden>comments on my submission</span>
                    <span aria-hidden="true" className={classNames(newAuditionComment ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200')}
                    />
                  </Switch>
                </Switch.Group>
                <Switch.Group as="div" className="flex items-center justify-between mb-2">
                  <Switch.Label as="span" className="flex-grow flex flex-col" >
                    <span className="text-sm text-gray-700 dark:text-gray-300">...replies on my comment</span>
                  </Switch.Label>
                  <Switch checked={newAuditionReply} onChange={() => handleUpdateSwitch('new_audition_reply')}
                    className={classNames(newAuditionReply ? 'bg-cccpurple' : 'bg-gray-300 dark:bg-gray-500',
                      'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccpurple')} >
                    <span className="sr-only" hidden>replies on my comment</span>
                    <span aria-hidden="true" className={classNames(newAuditionReply ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200')}
                    />
                  </Switch>
                </Switch.Group>
                <Switch.Group as="div" className="flex items-center justify-between mb-2">
                  <Switch.Label as="span" className="flex-grow flex flex-col" >
                    <span className="text-sm text-gray-700 dark:text-gray-300">...sends me a private message</span>
                  </Switch.Label>
                  <Switch checked={newMessage} onChange={() => handleUpdateSwitch('new_message')}
                    className={classNames(newMessage ? 'bg-cccpurple' : 'bg-gray-300 dark:bg-gray-500',
                      'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccpurple')} >
                    <span className="sr-only" hidden>sends me a private message</span>
                    <span aria-hidden="true" className={classNames(newMessage ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200')}
                    />
                  </Switch>
                </Switch.Group>
                <Switch.Group as="div" className="flex items-center justify-between mb-2">
                  <Switch.Label as="span" className="flex-grow flex flex-col" >
                    <span className="text-sm text-gray-700 dark:text-gray-300">...sends me a message about my role</span>
                  </Switch.Label>
                  <Switch checked={newRoleComment} onChange={() => handleUpdateSwitch('new_role_comment')}
                    className={classNames(newRoleComment ? 'bg-cccpurple' : 'bg-gray-300 dark:bg-gray-500',
                      'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccpurple')} >
                    <span className="sr-only" hidden>sends me a message about my role</span>
                    <span aria-hidden="true" className={classNames(newRoleComment ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200')}
                    />
                  </Switch>
                </Switch.Group>

                <h3 className='uppercase text-cccorange text-center text-sm'> Reminders</h3>
                <span className="text-sm font-medium text-gray-900 dark:text-gray-200">Email me</span>
                <div className='ml-2'>
                  <Switch.Group as="div" className="flex items-center justify-between mb-2">
                    <Switch.Label as="span" className="flex-grow flex flex-col" >
                      <span className="text-sm text-gray-700 dark:text-gray-300">...once a week if I have unread notifications</span>
                    </Switch.Label>
                    <Switch checked={unreadNotifications} onChange={() => handleUpdateSwitch('unread_notifications')}
                      className={classNames(unreadNotifications ? 'bg-cccpurple' : 'bg-gray-300 dark:bg-gray-500',
                        'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccpurple')} >
                      <span className="sr-only" hidden>once a week if I have unread notifications</span>
                      <span aria-hidden="true" className={classNames(unreadNotifications ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200')}
                      />
                    </Switch>
                  </Switch.Group>
                  <Switch.Group as="div" className="flex items-center justify-between mb-2">
                    <Switch.Label as="span" className="flex-grow flex flex-col" >
                      <span className="text-sm text-gray-700 dark:text-gray-300">...when a role I've favorited is about to expire</span>
                    </Switch.Label>
                    <Switch checked={roleAlmostExpired} onChange={() => handleUpdateSwitch('role_almost_expired')}
                      className={classNames(roleAlmostExpired ? 'bg-cccpurple' : 'bg-gray-300 dark:bg-gray-500',
                        'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccpurple')} >
                      <span className="sr-only" hidden>when a role I've favorited is about to expire</span>
                      <span aria-hidden="true" className={classNames(roleAlmostExpired ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200')}
                      />
                    </Switch>
                  </Switch.Group>
                  <Switch.Group as="div" className="flex items-center justify-between mb-2">
                    <Switch.Label as="span" className="flex-grow flex flex-col" >
                      <span className="text-sm text-gray-700 dark:text-gray-300">...when a project I follow has an update</span>
                    </Switch.Label>
                    <Switch checked={projectUpdates} onChange={() => handleUpdateSwitch('project_updates')}
                      className={classNames(projectUpdates ? 'bg-cccpurple' : 'bg-gray-300 dark:bg-gray-500',
                        'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccpurple')} >
                      <span className="sr-only" hidden>when a project I follow has an update</span>
                      <span aria-hidden="true" className={classNames(projectUpdates ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200')}
                      />
                    </Switch>
                  </Switch.Group>
                  <Switch.Group as="div" className="flex items-center justify-between mb-2">
                    <Switch.Label as="span" className="flex-grow flex flex-col" >
                      <span className="text-sm text-gray-700 dark:text-gray-300">...when a project I follow ends</span>
                    </Switch.Label>
                    <Switch checked={projectEnd} onChange={() => handleUpdateSwitch('project_end')}
                      className={classNames(projectEnd ? 'bg-cccpurple' : 'bg-gray-300 dark:bg-gray-500',
                        'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccpurple')} >
                      <span className="sr-only" hidden>when a project I follow ends</span>
                      <span aria-hidden="true" className={classNames(projectEnd ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200')}
                      />
                    </Switch>
                  </Switch.Group>
                </div>
                <h3 className='uppercase text-cccorange text-center text-sm'> Submissions</h3>
                <span className="text-sm font-medium text-gray-900 dark:text-gray-200">Email me when someone</span>
                <div className='ml-2'>
                  <Switch.Group as="div" className="flex items-center justify-between mb-2">
                    <Switch.Label as="span" className="flex-grow flex flex-col" >
                      <span className="text-sm text-gray-700 dark:text-gray-300">...posts a submission to my project</span>
                    </Switch.Label>
                    <Switch checked={newAudition} onChange={() => handleUpdateSwitch('new_audition')}
                      className={classNames(newAudition ? 'bg-cccpurple' : 'bg-gray-300 dark:bg-gray-500',
                        'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccpurple')} >
                      <span className="sr-only" hidden>posts a submission to my project</span>
                      <span aria-hidden="true" className={classNames(newAudition ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200')}
                      />
                    </Switch>
                  </Switch.Group>
                  { newAudition && <div className='flex justify-between items-center mb-2'>
                    <span className="text-sm text-gray-700 dark:text-gray-300">How often?</span>
                    <select
                      {...register('audition_frequency')}
                      className="mt-1 block py-2 px-3 border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm"
                    >
                      <option>Once per day</option>
                      <option>Once per hour</option>
                      <option value='When Casting Call Is About to End'>Near Project Deadline</option>
                    </select>
                  </div> }

                  <Switch.Group as="div" className="flex items-center justify-between mb-2">
                    <Switch.Label as="span" className="flex-grow flex flex-col" >
                      <span className="text-sm text-gray-700 dark:text-gray-300">...upvotes my submission</span>
                    </Switch.Label>
                    <Switch checked={newLike} onChange={() => handleUpdateSwitch('new_like')}
                      className={classNames(newLike ? 'bg-cccpurple' : 'bg-gray-300 dark:bg-gray-500',
                        'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccpurple')} >
                      <span className="sr-only" hidden>upvotes</span>
                      <span aria-hidden="true" className={classNames(newLike ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200')}
                      />
                    </Switch>
                  </Switch.Group>
                </div>
              </div>
            </div>
          </div>
          <div className='col-span-4 sm:col-span-2 rounded-sm p-2 bg-white dark:bg-gray-700'>
            <div className='p-2 bg-white dark:bg-gray-700 rounded-sm mb-4'>
              <h3 className='uppercase text-gray-900 dark:text-gray-100 text-center'> Other Emails</h3>
              <Switch.Group as="div" className="flex items-center justify-between mb-2">
                <Switch.Label as="span" className="flex-grow flex flex-col">
                  <span className="text-sm text-gray-700 dark:text-gray-300">Send me newsletters once a month</span>
                </Switch.Label>
                <Switch checked={newsletter} onChange={() => handleUpdateSwitch('newsletter')}
                  className={classNames(newsletter ? 'bg-cccpurple' : 'bg-gray-300 dark:bg-gray-500',
                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccpurple')} >
                  <span className="sr-only" hidden>Newsletter Emails</span>
                  <span aria-hidden="true" className={classNames(newsletter ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200')}
                  />
                </Switch>
              </Switch.Group>
              <Switch.Group as="div" className="flex items-center justify-between mb-2">
                <Switch.Label as="span" className="flex-grow flex flex-col">
                  <span className="text-sm text-gray-700 dark:text-gray-300">Send me education emails</span>
                </Switch.Label>
                <Switch checked={education} onChange={() => handleUpdateSwitch('education')}
                  className={classNames(education ? 'bg-cccpurple' : 'bg-gray-300 dark:bg-gray-500',
                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccpurple')} >
                  <span className="sr-only" hidden>Education Emails</span>
                  <span aria-hidden="true" className={classNames(education ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200')}
                  />
                </Switch>
              </Switch.Group>
            </div>
            <hr />
            <div className='p-2 bg-white dark:bg-gray-700 rounded-sm'>
              <h3 className='uppercase text-gray-900 dark:text-gray-100 text-center'> New Project Emails</h3>
              <span className="text-sm font-medium text-gray-900 dark:text-gray-200">For daily and weekly new project emails, please see <a href='/saved_searches'>Saved Searches</a>.</span>
            </div>
            <hr />
            <div className='p-2 bg-white dark:bg-gray-700 rounded-sm'>
              <h3 className='uppercase text-gray-900 dark:text-gray-100 text-center'> Unsubscribe From All Emails</h3>
              <Switch.Group as="div" className="flex items-center justify-between mb-2">
                <Switch.Label as="span" className="flex-grow flex flex-col" >
                  <span className="text-sm text-gray-700 dark:text-gray-300">Never email me again about any of this stuff</span>
                </Switch.Label>
                <Switch checked={unsubscribeAll} onChange={() => handleUpdateSwitch('unsubscribe_all')}
                  className={classNames(unsubscribeAll ? 'bg-cccpurple' : 'bg-gray-300 dark:bg-gray-500',
                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccpurple')} >
                  <span className="sr-only" hidden>Never email me again about any of this stuff</span>
                  <span aria-hidden="true" className={classNames(unsubscribeAll ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200')}
                  />
                </Switch>
              </Switch.Group>
            </div>
          </div>
          <div className='col-span-4 sm:col-span-2 rounded-sm p-2 bg-white dark:bg-gray-700'>
            <div className='p-2 bg-white dark:bg-gray-700 rounded-sm mb-4'>
              <h3 className='uppercase text-gray-900 dark:text-gray-100 text-center'> Notifications</h3>
              <span className="text-sm font-medium text-gray-900 dark:text-gray-200">Notify me within Casting Call Club when</span>
              <div className='ml-2'>
                <Switch.Group as="div" className="flex items-center justify-between mb-2">
                  <Switch.Label as="span" className="flex-grow flex flex-col" >
                    <span className="text-sm text-gray-700 dark:text-gray-300">...my submission successfully posts</span>
                  </Switch.Label>
                  <Switch checked={successfulAudition} onChange={() => handleUpdateSwitch('successful_audition')}
                    className={classNames(successfulAudition ? 'bg-cccpurple' : 'bg-gray-300 dark:bg-gray-500',
                      'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccpurple')} >
                    <span className="sr-only" hidden>my submission successfully posts</span>
                    <span aria-hidden="true" className={classNames(successfulAudition ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200')}
                    />
                  </Switch>
                </Switch.Group>
                <Switch.Group as="div" className="flex items-center justify-between mb-2">
                  <Switch.Label as="span" className="flex-grow flex flex-col" >
                    <span className="text-sm text-gray-700 dark:text-gray-300">...someone I follow is chosen for a role</span>
                  </Switch.Label>
                  <Switch checked={followerCast} onChange={() => handleUpdateSwitch('follower_cast')}
                    className={classNames(followerCast ? 'bg-cccpurple' : 'bg-gray-300 dark:bg-gray-500',
                      'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccpurple')} >
                    <span className="sr-only" hidden>someone I follow is chosen for a role</span>
                    <span aria-hidden="true" className={classNames(followerCast ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200')}
                    />
                  </Switch>
                </Switch.Group>
                <Switch.Group as="div" className="flex items-center justify-between mb-2">
                  <Switch.Label as="span" className="flex-grow flex flex-col" >
                    <span className="text-sm text-gray-700 dark:text-gray-300">...someone I follow creates a new project</span>
                  </Switch.Label>
                  <Switch checked={followerNewProject} onChange={() => handleUpdateSwitch('follower_new_project')}
                    className={classNames(followerNewProject ? 'bg-cccpurple' : 'bg-gray-300 dark:bg-gray-500',
                      'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccpurple')} >
                    <span className="sr-only" hidden>someone I follow creates a new project</span>
                    <span aria-hidden="true" className={classNames(followerNewProject ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200')}
                    />
                  </Switch>
                </Switch.Group>
                <Switch.Group as="div" className="flex items-center justify-between mb-2">
                  <Switch.Label as="span" className="flex-grow flex flex-col" >
                    <span className="text-sm text-gray-700 dark:text-gray-300">...someone upvotes my submission</span>
                  </Switch.Label>
                  <Switch checked={upvote} onChange={() => handleUpdateSwitch('upvote')}
                    className={classNames(upvote ? 'bg-cccpurple' : 'bg-gray-300 dark:bg-gray-500',
                      'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccpurple')} >
                    <span className="sr-only" hidden>someone upvotes my submission</span>
                    <span aria-hidden="true" className={classNames(upvote ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200')}
                    />
                  </Switch>
                </Switch.Group>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Card>
  </>
}

export default EmailPreferences

EmailPreferencesForm.propTypes = {
  preferences: PropTypes.object.isRequired
}
GeneralPreferencesForm.propTypes = {
  preferences: PropTypes.object.isRequired
}
