import React, { useState } from 'react'

import { useGlobalState } from '@/state'
import Modal from '@/shared/Modal'
import useModal from '@/hooks/useModalV2'
import useQuery from '@/hooks/useQuery'
import PrimaryButton from '@/shared/Buttons/Primary'
import DefaultButton from '@/shared/Buttons/Default'

const defaultImages = [
  { name: 'waves', url: 'https://ddppjbdexhxzj.cloudfront.net/covers/cover-cheese.svg' },
  { name: 'confetti-blue', url: 'https://ddppjbdexhxzj.cloudfront.net/covers/cover-confetti-blue.svg' },
  { name: 'confetti-orange', url: 'https://ddppjbdexhxzj.cloudfront.net/covers/cover-confetti-orange.svg' },
  { name: 'diamond-blue', url: 'https://ddppjbdexhxzj.cloudfront.net/covers/cover-diamond-blue-orange.svg' },
  { name: 'diamond-purple', url: 'https://ddppjbdexhxzj.cloudfront.net/covers/cover-diamond-blue-purple.svg' },
  { name: 'dalmation-purple', url: 'https://ddppjbdexhxzj.cloudfront.net/covers/cover-dalmation-purple.svg' },
  { name: 'dalmation', url: 'https://ddppjbdexhxzj.cloudfront.net/covers/cover-dalmation.svg' },
  { name: 'mountains', url: 'https://ddppjbdexhxzj.cloudfront.net/covers/cover-mountains.svg' },
  { name: 'slanted', url: 'https://ddppjbdexhxzj.cloudfront.net/covers/cover-slanted.svg' },
  { name: 'bullseye', url: 'https://ddppjbdexhxzj.cloudfront.net/covers/cover-bullseye.svg' }
]

const ProfileGeneralCover = () => {
  const [user, setUser] = useGlobalState('userContainerUser')
  const [url, setUrl] = useState(user.coverPublicImageUrl)
  const [loading, setLoading] = useState(false)
  const { openModal, closeModal, isOpen } = useModal()
  const { putpostRequest } = useQuery()

  const save = () => {
    setLoading(true)
    const data = {
      profile: {
        public_cover_image_url: url
      }
    }
    putpostRequest(`/api/v3/profiles/${user.profileId}`, 'PATCH', data, (err, jsonData) => {
      setLoading(false)
      if (err) { console.log('toast errors'); return }
      closeModal()

      setUser({ ...user, coverPublicImageUrl: url })
    })
  }

  return <>
    <div>
      <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Cover photo</label>
      <div onClick={openModal} className="mt-1 hover:opacity-75 cursor-pointer flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
        <div className='overflow-hidden aspect-w-3 aspect-h-1 w-full'>
          <img src={user.coverPublicImageUrl} className='object-cover' />
        </div>
      </div>
      <Modal full isOpen={isOpen} closeModal={closeModal} >
        <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white"> Edit Cover Photo</h3>
        <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
          <div className='overflow-hidden aspect-w-3 aspect-h-1 w-full'>
            <img src={url} className='object-cover' />
          </div>
        </div>
        <ul role="list" className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8 mt-4">
          { defaultImages.map((img, idx) => (
            <li key={img.name} onClick={() => setUrl(img.url)} className="relative">
              <div className="group block w-full aspect-w-3 aspect-h-1 rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden">
                <img src={img.url} alt="" className="object-cover pointer-events-none group-hover:opacity-75" />
                <button type="button" className="absolute inset-0 focus:outline-none">
                  <span className="sr-only" hidden>{img.name}</span>
                </button>
              </div>
            </li>
          ))}
        </ul>

        <div className='flex justify-end mt-5'>
          <PrimaryButton loading={loading} onClick={save} text="Save" />
          <DefaultButton className='ml-5' onClick={closeModal} text="Cancel" />
        </div>
      </Modal>
    </div>
  </>
}

export default ProfileGeneralCover
