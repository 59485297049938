import React from 'react'
import PropTypes from 'prop-types'
import { ExclamationIcon } from '@heroicons/react/solid'

const DowngradePreview = ({ quotedPrice, existingSubscription }) => {
  if (!(quotedPrice === '$0' || quotedPrice === '$0.00')) { return null }

  return <div className="rounded-md bg-yellow-50 p-4 mt-10">
    <div className="flex">
      <div className="flex-shrink-0">
        <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
      </div>
      <div className="ml-3">
        <h3 className="text-sm font-medium text-yellow-800">Notice</h3>
        <div className="mt-2 text-sm text-yellow-700">
          You are downgrading your plan. Since you've already paid for your current plan through the end of the terms, the new plan would go into effect on {existingSubscription.expiresAt}.
        </div>
      </div>
    </div>
  </div>
}

export default DowngradePreview

DowngradePreview.propTypes = {
  quotedPrice: PropTypes.string.isRequired,
  existingSubscription: PropTypes.object.isRequired
}
