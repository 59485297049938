import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { Switch } from '@headlessui/react'

import classNames from '@/utils/classNamesLocal'
import useQuery from '@/hooks/useQuery'
import { debounce } from 'throttle-debounce'
import { useGlobalState } from '@/state'
import { randomToastSuccess } from '@/shared/Toast'
import FroalaWrapper from '@/shared/FroalaWrapper'
import PrimaryButton from '@/shared/Buttons/Primary'
import Card from '@/shared/Card'

const ProfileWorkingInformation = () => {
  const [user, setUser] = useGlobalState('userContainerUser')
  const [, setToast] = useGlobalState('toast')
  const [timezones, setTimezones] = useState([])
  const { putpostRequest, getRequest } = useQuery()
  const { formState, reset, register, unregister, watch, handleSubmit, setValue, getValues, setError } = useForm({
   defaultValues: {
      pricing: user.pricing || '', // you have to hold froala's goddamn hand with a non-null value
      isAvailableNonUnion: user.isAvailableNonUnion,
      isAvailableWork: user.isAvailableWork,
      timezone: user.timezone,
      lookingFor: user.lookingFor || ''
    }
  })

  const { isDirty } = formState
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getRequest('/api/v3/static/timezones', {}, (err, jsonData) => {
      if (err) { /* handled in hook */ return }

      if (jsonData.timezones) {
        setTimezones(jsonData.timezones)
        setValue('timezone', user.timezone, { shouldDirty: false, shouldValidate: false })
      }
    })
  }, [])

  useEffect(() => {
    register('pricing', { required: false })
    register('lookingFor', { required: false })
    register('isAvailableNonUnion', { required: false })
    register('isAvailableWork', { required: false })
    return () => {
      unregister('lookingFor')
      unregister('pricing')
      unregister('isAvailableNonUnion')
      unregister('isAvailableWork')
    }
  }, [register])

  const handleUpdateFroala = (key, val) => {
    setValue(key, val, { shouldDirty: true, shouldValidate: true })
  }
  const handleUpdateSwitch = (key) => {
    setValue(key, !getValues()[key], { shouldDirty: true, shouldValidate: true })
  }

  const onSubmit = debounce(300, data => {
    setLoading(true)
    const formData = {
      pricing: data.pricing,
      timezone: data.timezone,
      looking_for: data.lookingFor,
      is_available_non_union: data.isAvailableNonUnion,
      is_available_work: data.isAvailableWork
    }

    putpostRequest(`/api/v3/profiles/${user.profileId}`, 'PATCH', { profile: formData }, (err, jsonData) => {
      setLoading(false)
      if (err) { // 422 code
        Object.entries(err).forEach(([key, value]) => {
          setError(key, value)
        })
        return
      }

      setUser({ ...user, ...getValues() })

      reset({
        ...getValues(), ...{ lookingFor: getValues().lookingFor + ' ', pricing: getValues().pricing + ' ' }
      }) // resetting this without this hack kills froala somehow

      setToast(randomToastSuccess())
    })
  })

  const lookingFor = getValues().lookingFor
  const pricing = getValues().pricing
  const isAvailableNonUnion = watch('isAvailableNonUnion')
  const isAvailableWork = watch('isAvailableWork')

  return (
    <div>
      <div className="md:grid md:grid-cols-4 md:gap-6 space-x-2">
        <div className="md:col-span-1">
          <div className="px-4 sm:px-0">
            <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-100">Working Information</h3>
            <p className="mt-1 text-sm text-gray-600 dark:text-gray-300">
              General work information. What you previously worked on can be found in the <Link to='/credits'>credits</Link> page.
            </p>
          </div>
        </div>
        <Card footer={<PrimaryButton onClick={handleSubmit(onSubmit)} loading={loading} disabled={!isDirty} text="Save" />}>
          <form>
            <div className="grid grid-cols-3 gap-6">
              <div className='col-span-3 sm:col-span-2'>
                <Switch.Group as="div" className="flex items-center justify-between">
                  <Switch.Label as="span" className="flex-grow flex flex-col" passive>
                    <span className="text-sm font-medium text-gray-900 dark:text-gray-200">Available to do non-Union work</span>
                    <span className="text-sm text-gray-500">If you haven't signed an exclusivity contract with a union, you can probably ignore this.</span>
                  </Switch.Label>
                  <Switch
                    checked={isAvailableNonUnion}
                    onChange={() => handleUpdateSwitch('isAvailableNonUnion')}
                    className={classNames(
                      isAvailableNonUnion ? 'bg-cccpurple' : 'bg-gray-300 dark:bg-gray-500',
                      'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccpurple'
                    )}
                  >
                    <span className="sr-only" hidden>Available to do non-union work</span>
                    <span
                      aria-hidden="true"
                      className={classNames(
                        isAvailableNonUnion ? 'translate-x-5' : 'translate-x-0',
                        'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                      )}
                    />
                  </Switch>
                </Switch.Group>
              </div>
              <div className='col-span-3 sm:col-span-2'>
                <Switch.Group as="div" className="flex items-center justify-between">
                  <Switch.Label as="span" className="flex-grow flex flex-col" passive>
                    <span className="text-sm font-medium text-gray-900 dark:text-gray-200">Available to hire</span>
                    <span className="text-sm text-gray-500">Are you actively taking on new work right now?</span>
                  </Switch.Label>
                  <Switch
                    checked={isAvailableWork}
                    onChange={() => handleUpdateSwitch('isAvailableWork')}
                    className={classNames(
                      isAvailableWork ? 'bg-cccpurple' : 'bg-gray-300 dark:bg-gray-500',
                      'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccpurple'
                    )}
                  >
                    <span className="sr-only" hidden>Available to hire</span>
                    <span
                      aria-hidden="true"
                      className={classNames(
                        isAvailableWork ? 'translate-x-5' : 'translate-x-0',
                        'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                      )}
                    />
                  </Switch>
                </Switch.Group>
              </div>

              <div className='col-span-2'>
                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="country" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                    Preferred Timezone
                  </label>
                  <select
                    {...register('timezone')}
                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm"
                  >
                    { timezones.map((tz) => (
                      <option key={tz.computer} value={tz.computer}>{tz.human.replace(/_/g, ' ')}</option>
                    ))}
                  </select>
                </div>
              </div>

              <div className='col-span-3'>

                <div className='mt-4'>
                  <label htmlFor="pricing" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                    Pricing
                  </label>
                  <p className="text-xs text-gray-500"> What rates do you charge?  </p>
                  <div className="mt-1 shadow-sm w-full min-w-full">
                    <FroalaWrapper
                      model={pricing}
                      updateModel={(val) => handleUpdateFroala('pricing', val)}
                    />
                  </div>
                  <i className="mt-2 flex justify-end text-xs text-gray-500 dark:text-gray-400">
                    (Tip: You can add URLs and @mention your followers and your credits.)
                  </i>
                </div>

                <div className='mt-4'>
                  <label htmlFor="lookingFor" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                    What I'm Looking For?
                  </label>
                  <p className="text-xs text-gray-500">
                    What sort of projects or work do you work on? What do you want to eventually get out of it? Why did you join Casting Call Club?
                  </p>
                  <div className="mt-1 shadow-sm w-full min-w-full">
                    <FroalaWrapper
                      model={lookingFor}
                      placeholder="I like to work on roles that showcase my nasally voice. When I'm not voice acting, I like to collaborate on writing mysteries."
                      updateModel={(val) => handleUpdateFroala('lookingFor', val)}
                    />
                  </div>
                  <i className="mt-2 flex justify-end text-xs text-gray-500 dark:text-gray-400">
                    (Tip: You can add URLs and @mention your followers and your credits.)
                  </i>
                </div>
              </div>
            </div>
          </form>
        </Card>
      </div>
    </div>
  )
}

export default ProfileWorkingInformation
