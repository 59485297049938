import React from 'react'
import PropTypes from 'prop-types'

const PublicProjectRow = ({ project }) => {
  let pillColor = 'bg-indigo-500'
  if (project.status === 'open') {
    pillColor = 'bg-green-500'
  }

  return <li className='bg-gray-100 dark:bg-gray-700 px-4 py-4 sm:px-6  rounded-lg shadow-sm'>
    <div className="sm:flex sm:justify-between sm:items-center">
      <div className="flex-shrink-0 text-gray-500 dark:text-gray-100 hidden sm:block">
        <img className='h-16 w-16 rounded-full' src={project.imageUrl} alt={project.name} />
      </div>
      <div className="flex-1 px-0 md:px-4 md:py-0 pb-2 w-full truncate">
        <div className='flex flex-col truncate'>
          <span className="uppercase text-xs dark:text-gray-300 text-gray-500 mr-5">
            {project.createdAt} - {project.deadline}
          </span>
          <a href={`/projects/${project.slug}`} className="font-medium text-cccblue">{project.name}</a>
          <span className='flex justify-start'>
            <span className={`flex-shrink-0 inline-block px-2 py-0.5 text-white ${pillColor} text-xs font-medium rounded-full flex cursor-pointer capitalize`}>
              {project.status}
            </span>
          </span>
        </div>
      </div>
    </div>
  </li>
}

PublicProjectRow.propTypes = {
  project: PropTypes.object.isRequired
}

export default PublicProjectRow
