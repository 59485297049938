import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import Card from '@/shared/Card'

import { useGlobalState } from '@/state'
import useModal from '@/hooks/useModalV2'
import Loading from '@/shared/Loading'
import Modal from '@/shared/Modal'
import { XCircleIcon } from '@heroicons/react/solid'
import useQuery from '@/hooks/useQuery'

import { TippyTooltip } from '@/shared/ToolTip'

import DemoForm from '@/pages/Users/ProfileDemos/DemoForm'
import DemoRow from '@/pages/Users/ProfileDemos/DemoRow'

const ShowUpgradePanel = () => {
  return <div className="bg-white shadow sm:rounded-lg">
    <div className="px-4 py-5 sm:p-6">
      <h3 className="text-lg leading-6 font-medium text-gray-900">Cannot Add Demo</h3>
      <div className="mt-2">
        <div className="text-sm text-gray-500">
          <p className='mb-2'>With the free plan, you're only able to have one demo.</p>
          <p>I know it's a pain, and I'm super sorry to ask, but if you want to have more demos, please upgrade to a paid plan. You get

          <TippyTooltip content={<>Unlimited Power!</>}>
            <i className='mx-1 underline'>unlimited</i>
          </TippyTooltip>
          demos with any of them.</p>
          <Link to='/membership' className="mt-2 inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-cccblue hover:bg-cccblue-alt sm:text-sm focus:outline-none" >
            View Plans <span aria-hidden="true">&rarr;</span>
          </Link>
        </div>
      </div>
    </div>
  </div>
}

const ProfileDemosIndex = () => {
  const { getRequest } = useQuery()
  const { openModal, closeModal, isOpen, willClose } = useModal()

  const [usersContainer, setUsersContainer] = useGlobalState('usersContainer')
  const [currentUser] = useGlobalState('currentUser')
  const [loading, setLoading] = useState(true)
  const [mounted, setMounted] = useState(false)
  const { demos } = usersContainer

  useEffect(() => { setMounted(true) }, [])
  useEffect(() => { fetchData() }, [mounted]) // not sure why, but: Can't perform a React state update on an unmounted component.

  useEffect(() => {
    if (!willClose) { return }

    fetchData()
  }, [willClose])

  const fetchData = () => {
    if (!mounted) { return }

    setLoading(true)
    getRequest('/api/v3/demos', {}, (err, jsonData) => {
      setLoading(false)
      if (err) { /* hooks */ return }

      setUsersContainer({
        ...usersContainer,
        demos: jsonData.demos
      })
    })
  }

  if (loading) {
    return (
      <section aria-labelledby="loading" className='p-5'>
        <button type="button" className="relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" >
          <svg xmlns="http://www.w3.org/2000/svg" className="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19.428 15.428a2 2 0 00-1.022-.547l-2.387-.477a6 6 0 00-3.86.517l-.318.158a6 6 0 01-3.86.517L6.05 15.21a2 2 0 00-1.806.547M8 4h8l-1 1v5.172a2 2 0 00.586 1.414l5 5c1.26 1.26.367 3.414-1.415 3.414H4.828c-1.782 0-2.674-2.154-1.414-3.414l5-5A2 2 0 009 10.172V5L8 4z" />
          </svg>
          <span className="mt-2 block text-sm font-medium text-gray-900 dark:text-gray-300">
            Demos
            <Loading />
          </span>
        </button>
      </section>
    )
  }

  const canAddMore = demos.length < 1 || currentUser.multipleDemos

  return <>
    <Modal full={canAddMore} isOpen={isOpen} closeModal={closeModal} >
      <div className='relative mb-1'>
        <span className="sr-only" hidden>Close Modal</span>
        <XCircleIcon onClick={closeModal} className='cursor-pointer w-7 h-7 absolute -top-5 -right-5 text-gray-500 dark:text-gray-400' />
      </div>
      { !canAddMore && <ShowUpgradePanel /> }
      { canAddMore && <DemoForm demo={{}} setDemo={() => {}} /> }
    </Modal>
    <div>
      <div className="md:grid md:grid-cols-4 md:gap-6 space-x-2">
        <div className="md:col-span-1">
          <div className="px-4 sm:px-0">
            <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-100">Demos & Samples</h3>
            <p className="mt-1 text-sm text-gray-600 dark:text-gray-300">
              Demo reels, video reels, clips, samplers, scripts. You name it. Show off your highlights on what you can do! You can use your demos you enter here as submissions/auditions for when you're looking for projects.
            </p>
          </div>
        </div>
        <div className="mt-5 md:mt-0 md:col-span-3">
          { demos.length === 0 && <>
            <section aria-labelledby="my-course-content" onClick={openModal}>
              <button type="button" className="relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" >
                <svg xmlns="http://www.w3.org/2000/svg" className="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19.428 15.428a2 2 0 00-1.022-.547l-2.387-.477a6 6 0 00-3.86.517l-.318.158a6 6 0 01-3.86.517L6.05 15.21a2 2 0 00-1.806.547M8 4h8l-1 1v5.172a2 2 0 00.586 1.414l5 5c1.26 1.26.367 3.414-1.415 3.414H4.828c-1.782 0-2.674-2.154-1.414-3.414l5-5A2 2 0 009 10.172V5L8 4z" />
                </svg>
                <span className="mt-2 block text-sm font-medium text-gray-900 dark:text-gray-300">If you've got a demo reel, youtube video, audio/visual clips, script samples, Dribbble profile, Tumblr, etc, this is the place to add it. Help people find you by your work. </span>
              </button>
            </section>
          </> }
          { demos.length > 0 && <>
            <Card header={<div className='text-cccblue hover:text-cccblue-alt cursor-pointer' onClick={openModal}>Add Demo / Sample</div>}>
              <div className="grid grid-cols-3 gap-6">
                <div className='col-span-3'>
                  <div className="sm:rounded-md">
                    <ul className="space-y-3">
                      { demos.map((demo, idx) => (
                        <DemoRow key={`demo${demo.id}`} demo={demo} fetchData={fetchData} />
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </Card>
          </> }
        </div>
      </div>
    </div>
  </>
}

export default ProfileDemosIndex
