import React, { useState } from 'react'
import { useGlobalState } from '@/state'
import { XIcon } from '@heroicons/react/solid'
import useQuery from '@/hooks/useQuery'
import UserFollowerAutocomplete from '@/shared/UserFollowerAutocomplete'
import DefaultButton from '@/shared/Buttons/Default'
import PrimaryButton from '@/shared/Buttons/Primary'
import Modal from '@/shared/Modal'
import useModal from '@/hooks/useModalV2'

const ManauallyAssignSomeoneModal = ({ role, addRoleUser }) => {
  const { openModal, closeModal, isOpen } = useModal()
  const [, setToast] = useGlobalState('toast')
  const { putpostRequest, getRequest } = useQuery()
  const [loading, setLoading] = useState(false)
  const [username, setUsername] = useState(null)
  const [userId, setUserId] = useState(null)

  const addUser = (autosuggestUser) => {
    setUsername(autosuggestUser.username)
    setUserId(autosuggestUser.id)
  }
  const nullifyUserId = () => {
    setUsername(null)
    setUserId(null)
  }

  const assign = () => {
    const data = {
      user_id: userId,
      role_id: role.id
    }
    putpostRequest('/api/v3/role_users', 'POST', { role_user: data }, (err, jsonData) => {
      setLoading(false)
      if (err) { /* hook */ return }

      setToast(<div className="ml-3 w-0 flex-1 pt-0.5">
        <p className="text-sm font-medium text-green-500">Role assigned!</p>
        <p className="mt-1 text-sm text-green-500">You can manage your assigned roles in the Manage Role tab</p>
      </div>)
      addRoleUser(jsonData.roleUser)
      closeModal()
    })
  }

  return <>
    <div className='flex flex-col text-gray-900 dark:text-gray-100'>
      <div>Want to add someone who hasn't applied?</div>
    </div>
    <DefaultButton onClick={openModal} text='Manually assign someone' />
    <Modal isOpen={isOpen} closeModal={closeModal} >
      <div className="flex flex-col text-center items-center">
        <h2 className="mb-8 text-xs font-semibold tracking-widest text-gray-800 dark:text-gray-200 uppercase title-font">Assign a role!</h2>
        <p className='flex justify-center items-center text-gray-900 dark:text-gray-100'>You can add anyone who follows you, anyone who sent in a submission for this role, or anyone on your roster.</p>
      </div>

      <label htmlFor="username" className="mt-2 block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">What is their username?</label>
      <div className="mt-1 flex flex-col rounded-md shadow-sm">
        <div className='relative'>

          { username && <pre onClick={nullifyUserId} className='placeholder-gray-500 placeholder-opacity-25 dark:placeholder-opacity-50 block w-full focus:ring-cccpurple dark:bg-gray-900 dark:text-white focus:border-cccpurple sm:text-sm border-gray-300 rounded-md flex p-2 cursor-pointer items-center'>{username} <XIcon className='w-4 h-4' /></pre> }
          { !username && <UserFollowerAutocomplete addUser={addUser} placeholder='Buford'/> }
        </div>
      </div>
      <div className="rounded-md bg-blue-50 mt-4 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <svg className="h-5 w-5 text-blue-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fillRule="evenodd" d="M19 10.5a8.5 8.5 0 11-17 0 8.5 8.5 0 0117 0zM8.25 9.75A.75.75 0 019 9h.253a1.75 1.75 0 011.709 2.13l-.46 2.066a.25.25 0 00.245.304H11a.75.75 0 010 1.5h-.253a1.75 1.75 0 01-1.709-2.13l.46-2.066a.25.25 0 00-.245-.304H9a.75.75 0 01-.75-.75zM10 7a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
            </svg>
          </div>
          <div className="ml-3 flex-1 md:flex md:justify-between">
            <p className="text-sm text-blue-700">This person will get an email and they will be invited into the Project DMs so you can speak directly with them.</p>
          </div>
        </div>
      </div>
      <div className="flex justify-end space-x-2 mt-4">
        <DefaultButton onClick={closeModal} text='Cancel' />
        <PrimaryButton onClick={assign} loading={false} text='Assign' />
      </div>
    </Modal>
  </>
}

export default ManauallyAssignSomeoneModal
