import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDebouncedEffect } from '@/utils/useDebouncedEffect'
import Autosuggest from 'react-autosuggest'

import useQuery from '@/hooks/useQuery'

const UserAutocomplete = ({ addUser, placeholder }) => {
  const { getRequest } = useQuery()
  const [users, setUsers] = useState([])
  const [searchText, setSearchText] = useState('')

  const onKeyDown = (event) => {
    const { keyCode } = event
    if (keyCode === 13 || keyCode === 188) {
      // we want to do nothing here. do not allow them to type in any user
    }
  }

  useDebouncedEffect(() => { fetchData(searchText) }, 200, [searchText])

  const fetchData = (newValue) => {
    console.log(newValue)
    // no need to hit the server repeatedly when the query is blank
    if (newValue === '') {
      setUsers([])
      return
    }
    getRequest('/api/v3/omnisearch', { search: newValue }, (err, jsonData) => {
      if (err) { /* hook */ return }
      setUsers(jsonData.users)
      if (newValue === '') {
        setUsers(jsonData.users)
      }
    })
  }

  const handleAutocomplete = (event, { newValue, method }) => {
    if (method === 'up' || method === 'down') return

    setSearchText(newValue)

    if (method === 'enter' || method === 'click') {
      addUser(newValue)
      setSearchText('')
    }
  }

  // When suggestion is clicked, Autosuggest needs to populate the input
  // based on the clicked suggestion. Teach Autosuggest how to calculate the
  // input value for every given suggestion.
  const getSuggestionValue = suggestion => suggestion ? suggestion.autosuggest : ''

  const renderSuggestion = (suggestion, { query, isHighlighted }) => {
    return (
      <div className={`${isHighlighted ? 'bg-cccpurple bg-opacity-20 text-gray-900' : 'bg-gray-50 dark:bg-gray-800 text-gray-700 dark:text-gray-200'} relative flex items-start p-2 space-x-3`}>
        <img className="h-10 w-10 rounded-sm bg-gray-200 flex items-center justify-center ring-4 ring-cccorange" src={suggestion.imageUrl} alt="" />
        <div className="min-w-0 flex-1">
          <div className="text-sm flex flex-col">
            <div className="font-medium">{suggestion.displayName}</div>
            <div className="">@{suggestion.username}</div>
          </div>
        </div>
      </div>
    )
  }

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  const onSuggestionsFetchRequested = ({ value }) => { /* setFranchises(value) */ }
  const onSuggestionsClearRequested = () => { setUsers([]) }
  const shouldRenderSuggestions = (value, reason) => {
    return value.trim().length >= 0
  }

  const onFocus = (event) => {
    if (users.length === 0) { setSearchText('') }
  }

  const inputProps = {
    placeholder: placeholder,
    value: searchText, // must be a string
    type: 'search',
    onKeyDown: onKeyDown,
    autoFocus: true,
    onFocus: onFocus,
    onChange: handleAutocomplete
  }

  return <Autosuggest
    className=''
    suggestions={users}
    highlightFirstSuggestion={true}
    onSuggestionsFetchRequested={onSuggestionsFetchRequested}
    onSuggestionsClearRequested={onSuggestionsClearRequested}
    shouldRenderSuggestions={shouldRenderSuggestions}
    getSuggestionValue={getSuggestionValue}
    renderSuggestion={renderSuggestion}
    inputProps={inputProps}
  />
}

export default UserAutocomplete

UserAutocomplete.propTypes = {
  placeholder: PropTypes.string,
  addUser: PropTypes.func.isRequired
}
