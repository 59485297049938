import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { useForm } from 'react-hook-form'
import { debounce } from 'throttle-debounce'

import { randomToastSuccess } from '@/shared/Toast'

import TagAutocomplete from '@/shared/TagAutocomplete'
import UserFollowerAutocomplete from '@/shared/UserFollowerAutocomplete'
import PrimaryButton from '@/shared/Buttons/Primary'
import DefaultButton from '@/shared/Buttons/Default'
import { ExclamationCircleIcon, XIcon } from '@heroicons/react/solid'
import useQuery from '@/hooks/useQuery'
import { useGlobalState } from '@/state'

const TheForm = ({ initialUserCredit, creditId, setCreatingNewUserCredit, setCredit, myselfOnly }) => {
  const [, setToast] = useGlobalState('toast')
  const [currentUser] = useGlobalState('currentUser')
  const [userCredit, setUserCredit] = useState(initialUserCredit)
  const [username, setUsername] = useState(initialUserCredit.username)
  const { putpostRequest } = useQuery()
  let defaultWhoCrediting = !initialUserCredit?.userId ? 'Myself' : 'Someone Else'
  defaultWhoCrediting = (defaultWhoCrediting === 'Myself' || initialUserCredit.userId === currentUser.id) ? 'Myself' : 'Someone Else'
  defaultWhoCrediting = myselfOnly ? 'Myself' : defaultWhoCrediting
  const { formState, register, reset, watch, handleSubmit, setValue, getValues, setError, setFocus } = useForm({
   defaultValues: {
      role: initialUserCredit.role,
      who_crediting: defaultWhoCrediting,
      username_or_email: initialUserCredit.userEmail ? 'Email' : 'Username',
      credit_type: initialUserCredit.creditType,
      user_id: initialUserCredit.userId,
      user_email: initialUserCredit.userEmail
    }
  })

  const { errors } = formState
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setFocus('credit_type')
  }, [setFocus])

  const keyPress = (e) => {
    if (e.target.name === 'name' && e.keyCode === 13) { e.preventDefault() }
    if ((e.metaKey || e.ctrlKey) && e.keyCode === 13) {
      if (typeof (e.target.name) === 'undefined') {
        e.target.blur() // froala hack to make it update model so it can save properly
      }
      handleSubmit(onSubmit)()
    }
  }

  const onSubmit = debounce(300, (data) => {
    setLoading(true)

    let url = '/api/v3/user_credits/'
    let action = 'POST'
    const formData = {
      ...data,
      credit_id: creditId
    }

    if (userCredit.id) {
      url = `/api/v3/user_credits/${userCredit.id}`
      action = 'PATCH'
    }

    putpostRequest(url, action, { user_credit: formData }, (err, jsonData) => {
      setLoading(false)
      if (err) { // 422 code
        if (typeof err !== 'string') { // 500 code on API
          Object.entries(err).forEach(([key, value]) => {
            setError(key, value)
          })
        }
        return
      }

      setUserCredit({ ...jsonData.userCredit })
      if (myselfOnly) {
        setToast(<div className="ml-3 w-0 flex-1 pt-0.5">
          <p className="text-sm font-medium dark:text-white text-gray-800">Request sent</p>
          <p className="mt-1 text-sm dark:text-gray-100 text-gray-500">You will be notified if the request is approved or denied</p>
        </div>)
      } else {
        setToast(randomToastSuccess())
      }
      setCredit(jsonData.userCredit)
      if (action === 'POST') {
        reset({ ...getValues() })
        setCreatingNewUserCredit(false)
      }
    })
  })

  const cancel = () => { setCreatingNewUserCredit(false) }

  const addLabel = (label, labelKind) => {
    if (labelKind === 'credit-credit-type') {
      setValue('credit_type', label, { shouldDirty: true, shouldValidate: true })
    }
  }

  const addUser = (autosuggestUser) => {
    setValue('user_id', autosuggestUser.id, { shouldDirty: true, shouldValidate: true })
    setUsername(autosuggestUser.username)
  }

  const nullifyCreditType = () => {
    setValue('credit_type', null, { shouldDirty: true, shouldValidate: false })
  }

  const nullifyUserId = () => {
    setValue('user_id', null, { shouldDirty: true, shouldValidate: false })
    setUsername(null)
  }

  const creditType = watch('credit_type')
  const whoCrediting = watch('who_crediting')
  const usernameOrEmail = watch('username_or_email')

  return <>
    <form className='bg-white dark:bg-gray-700 p-2'>
      <div className="grid sm:grid-cols-4 gap-6">
        <div className="col-span-4 sm:col-span-1">
          <label htmlFor="school_name" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">Who are you crediting?</label>
          <select
            {...register('who_crediting')}
            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm"
          >
            <option>Myself</option>
            { !myselfOnly && <option>Someone Else</option> }
          </select>
        </div>
        { whoCrediting === 'Someone Else' && <>
          <div className="col-span-4 sm:col-span-1">
            <label htmlFor="school_name" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">How will you invite them to be credited?</label>
            <select
              {...register('username_or_email')}
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm"
            >
              <option>Username</option>
              <option>Email</option>
            </select>
          </div>
        </> }

        { whoCrediting === 'Someone Else' && usernameOrEmail === 'Username' && <>
          <div className="col-span-4 sm:col-span-2">
            <label htmlFor="school_name" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">What is their username?</label>
            <div className="mt-1 flex flex-col rounded-md shadow-sm">
              <div className='relative'>

                { username && <pre onClick={nullifyUserId} className='placeholder-gray-500 placeholder-opacity-25 dark:placeholder-opacity-50 block w-full focus:ring-cccpurple dark:bg-gray-900 dark:text-white focus:border-cccpurple sm:text-sm border-gray-300 rounded-md flex p-2 cursor-pointer items-center'>{username} <XIcon className='w-4 h-4' /></pre> }
                { !username && <UserFollowerAutocomplete addUser={addUser} placeholder='Buford'/> }
                <input type="hidden" {...register('user_id', { required: true }) } />
                { errors.user_id && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                </div> }
              </div>
            </div>
            <p className="text-xs text-gray-500 dark:text-gray-400 mt-1">You can only add people who follow you.</p>
            { errors.user_id && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
          </div>
        </> }
        { whoCrediting === 'Someone Else' && usernameOrEmail === 'Email' && <>
          <div className="col-span-4 sm:col-span-2">
            <label htmlFor="school_name" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">What is their email?</label>
            <div className="mt-1 flex flex-col rounded-md shadow-sm">
              <div className='relative'>

                <input onKeyDown={keyPress} type="text" className={errors.role ? 'errors' : ''} {...register('user_email', { required: true, pattern: /^\S+@\S+$/i }) } placeholder="sally@sample.com" />
                { errors.user_email && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                </div> }
              </div>
            </div>
            <p className="text-xs text-gray-500 dark:text-gray-400 mt-1">If they are not a member of CCC, you can add them by email and they will get an invite.</p>
            { errors.user_email?.type === 'pattern' && <div className='mt-2 text-sm text-red-600'>That a valid email?</div> }
            { errors.user_email?.type === 'required' && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
          </div>
        </> }

        <div className="col-span-4" />
        <div className="col-span-4 sm:col-span-2">
          <label htmlFor="school_name" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">Credited For</label>
          <div className="mt-1 flex flex-col rounded-md shadow-sm">
            <div className='relative'>

              { creditType && <pre onClick={nullifyCreditType} className='placeholder-gray-500 placeholder-opacity-25 dark:placeholder-opacity-50 block w-full focus:ring-cccpurple dark:bg-gray-900 dark:text-white focus:border-cccpurple sm:text-sm border-gray-300 rounded-md flex p-2 cursor-pointer items-center'>{creditType} <XIcon className='w-4 h-4' /></pre> }
              { !creditType && <TagAutocomplete kind='credit-credit-type' addLabel={addLabel} placeholder='Actor/Actress, Writer, Casting Director, Special Thanks'/> }
              <input type="hidden" {...register('credit_type', { required: true }) } />
              { errors.credit_type && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
              </div> }
            </div>
          </div>
          <p className="text-xs text-gray-500 dark:text-gray-400 mt-1">What they are credited for</p>
          { errors.credit_type && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
        </div>

        <div className="col-span-4 sm:col-span-2">
          <label htmlFor="school_name" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">Role Name 2</label>
          <div className="mt-1 flex flex-col rounded-md shadow-sm">
            <div className='relative'>
              <input onKeyDown={keyPress} type="text" className={errors.role ? 'errors' : ''} {...register('role', { required: false }) } placeholder="Self, Wolverine, Exotic Butters" />
              { errors.role && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
              </div> }
            </div>
          </div>
          <p className="text-xs text-gray-500 dark:text-gray-400 mt-1">If they are credited for 'Actor/Actress' or 'Voice Actor/Actress', please specifiy which role they played.</p>
        </div>
        <div className="col-span-4 sm:col-start-4 sm:col-span-1 flex justify-end">
          { !initialUserCredit.id && <DefaultButton onClick={cancel} text='Cancel' /> }
          <div className='flex flex-col items-end w-min ml-2'>
            <PrimaryButton className='w-full' onClick={handleSubmit(onSubmit)} loading={loading} text={<div className='flex flex-col'>
              Save
              <div className="hidden sm:block w-min text-xs px-2 font-sans text-gray-50">⌘/ctrl+ENTER</div>
            </div>} />
          </div>
        </div>
      </div>
    </form>

    { errors.base && <div className='mt-2 text-sm text-red-600'>{errors.base[0]}</div> }
  </>
}

const UserCreditForm = ({ initialUserCredit, creditId, setCreatingNewUserCredit, myselfOnly }) => {
  const [editing, setEditing] = useState(false)
  const [credit, setCredit] = useState(initialUserCredit)

  const { username, userEmail, role, creditType, status } = credit

  useEffect(() => {
    setEditing(false)
  }, [credit])

  if (editing || typeof setCreatingNewUserCredit === 'function') {
    return <TheForm
      initialUserCredit={credit}
      creditId={creditId}
      setCreatingNewUserCredit={setCreatingNewUserCredit}
      setCredit={setCredit}
      myselfOnly={myselfOnly}
    />
  }

  return <>
    <div className='bg-white dark:bg-gray-700 p-2 rounded-sm flex justify-between items-center'>
      <div className='flex flex-col truncate'>
        <span className="dark:text-gray-300 text-gray-500 mr-5 truncate">{username || userEmail }</span>
        <span className="text-sm font-medium text-gray-900 dark:text-gray-200 truncate"> {creditType} </span>
        <span className="text-sm font-medium text-gray-900 dark:text-gray-200 truncate"> {role} afewa fw ef awse fa wef a wef aw  </span>
      </div>
      <div className='flex'>
        { status === 'invited' && <div className="mr-10 font-medium text-yellow-500 capitalize">{status}</div> }
        { status === 'confirmed' && <div className="mr-10 font-medium text-green-500 capitalize">{status}</div> }
        { status === 'rejected' && <div className="mr-10 font-medium text-red-500 capitalize">{status}</div> }
        <span className="sm:ml-4 flex-shrink-0 flex items-start space-x-4">
          <button onClick={() => setEditing(true)} type="button" className="text-cccblue hover:text-cccblue-alt">
            Edit
          </button>
        </span>
      </div>
    </div>
  </>
}

export default UserCreditForm

TheForm.propTypes = {
  initialUserCredit: PropTypes.object.isRequired,
  creditId: PropTypes.number.isRequired,
  setCreatingNewUserCredit: PropTypes.func,
  setCredit: PropTypes.func,
  myselfOnly: PropTypes.bool
}

UserCreditForm.propTypes = {
  initialUserCredit: PropTypes.object.isRequired,
  creditId: PropTypes.number.isRequired,
  setCreatingNewUserCredit: PropTypes.func,
  myselfOnly: PropTypes.bool
}
