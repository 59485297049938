import React, { useState, useEffect, useRef } from 'react'
import { useGlobalState } from '@/state'
import CommentRow from '@/shared/CommentRow'
import { ChatAltIcon } from '@heroicons/react/solid'
import Loading from '@/shared/Loading'
import useQuery from '@/hooks/useQuery'
import Card from '@/shared/Card'

const ProjectCommentsIndex = () => {
  const [manageProjectContainer] = useGlobalState('manageProjectContainer')
  const { project } = manageProjectContainer

  const { getRequest, putpostRequest } = useQuery()
  const [loading, setLoading] = useState(false)
  const scrollRef = useRef(null)
  const [comments, setComments] = useState([])
  const [, setToast] = useGlobalState('toast')

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = () => {
    setLoading(true)
    getRequest(`/api/v3/manage/projects/${project.id}/comments`, {}, (err, jsonData) => {
      if (err) { /* handled in hook */ return }
      setLoading(false)
      setComments(jsonData.comments)
    })
  }

  return <div className='mt-3'>
    <Card title={
      <div className='flex justify-between items-center'>
        <div className='font-medium leading-6 text-cccorange dark:text-cccpurple'>Project Comments</div>
      </div>
      }>
      <div ref={scrollRef}>
        { loading && <Loading /> }
        { comments.length === 0 && !loading && <NoResults /> }
        <ul className="space-y-3">
          {comments.map((comment, idx) => (
            <CommentRow key={`manprocomm${comment.id}`} initialComment={comment} />
          ))}
        </ul>
      </div>
    </Card>
  </div>
}

const NoResults = () => {
  return (
    <span>
      <div className="relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccblue" >
        <ChatAltIcon className="mx-auto h-12 w-12 text-gray-400" />
        <span className="mt-2 block text-sm font-medium text-gray-900 dark:text-gray-300"> If someone makes a comment on your project, it will appear here. You can reply to or delete their comment. Up to you.</span>
      </div>
    </span>
  )
}

export default ProjectCommentsIndex
