import { useState, useRef } from 'react'
import useQuery from '@/hooks/useQuery'

// You can use this hook to conditionally render components based on media queries
export default function useStripeMethods(successfulPurchase, currentUserId, newPlan) {
  const [stripeLoading, setStripeLoading] = useState(false)
  const [stripeErrorMessage, setStripeErrorMessage] = useState(false)
  const { getRequest } = useQuery()
  const pollRef = useRef(0)

  const pollForPlanChange = () => {
    setStripeLoading(true)
    console.log(currentUserId)
    pollRef.current += 1
    getRequest(`/api/v3/users/${currentUserId}/gold`, {}, (err, jsonData) => {
      if (err) { /* hook */ return }
      if (pollRef.current > 20) {
        setStripeErrorMessage('Code 523: Could not verify card in time')
        return
      }
      if (jsonData.user.gold === newPlan) {
        setStripeLoading(false)
        successfulPurchase()
      } else {
        setTimeout(pollForPlanChange, 2000)
      }
    })
  }

  const handlePaymentThatRequiresCustomerAction = async ({ stripe, paymentMethodId, paymentIntentClientSecret, upgrade }) => {
    if (paymentIntentClientSecret) {
      setStripeLoading(true)
      const confirmCardResult = await stripe.confirmCardPayment(paymentIntentClientSecret, { payment_method: paymentMethodId })
      setStripeLoading(false)
      if (confirmCardResult.error) {
        // The card was declined (i.e. insufficient funds, card has expired, etc). Try another payment method
        console.log(`handlePaymentThatRequiresCustomerAction - throwing ${confirmCardResult.error.message}`)
        setStripeErrorMessage(confirmCardResult.error.message)
      } else {
        if (confirmCardResult.paymentIntent.status === 'succeeded') {
          // handled invoice.payment_succeeded webhook.
          // There's a risk of the customer closing the window before callback
          pollForPlanChange()
          console.log('handlePaymentThatRequiresCustomerAction - confirmCardPayment PI succeeded')
        } else {
          setStripeErrorMessage('Code 943: We were unable to charge your card at this time. Please try another card.')
        }
      }
    }
  }

  return {
    stripeLoading,
    stripeErrorMessage,
    handlePaymentThatRequiresCustomerAction
  }
}
