import React, { useState, useEffect } from 'react'
import { CakeIcon, FilmIcon } from '@heroicons/react/solid'
import PropTypes from 'prop-types'
import useQuery from '@/hooks/useQuery'

import Loading from '@/shared/Loading'
import Card from '@/shared/Card'

const RoleCastRow = ({ role }) => {
  return <li className='bg-white dark:bg-gray-700 rounded-lg border border-gray-200 dark:border-gray-700'>
      <div className="p-1">
        <div className='flex justify-between'>
          <div className="relative flex items-start space-x-3 overflow-hidden">
            <div className="relative m-1">
              <img className="h-16 w-16 rounded-sm bg-gray-200 flex items-center justify-center ring-4 ring-cccblue" src={'https://ddppjbdexhxzj.cloudfront.net/avatars/CCC_Cover_UNSURE.svg'} alt="" />

              <span className="absolute -bottom-0.5 -right-1 bg-cccblue rounded-tl px-0.5 py-px">
                <FilmIcon className="h-4 w-4 text-white" aria-hidden="true" />
              </span>
            </div>
            <div className="min-w-0 flex-1">
              <div className='flex flex-col'>
                <div className="uppercase text-xs dark:text-gray-300 text-gray-500 mr-5 truncate">
                  {role.projectName}
                </div>
                <a href={`/myroles`} className="font-medium text-cccblue cursor-pointer truncate">
                  {role.name}
                </a>
              </div>
              <div className="mt-1 text-sm text-gray-600 dark:text-gray-400 flex">
                {role.workStatus !== 'Unfinished' && <span className='flex-shrink-0 inline-block px-2 text-white bg-green-500 text-xs font-medium rounded-full flex capitalize'>{role.workStatus}</span> }
                {role.workStatus === 'Unfinished' && <span className='flex-shrink-0 inline-block px-2 text-white bg-yellow-500 text-xs font-medium rounded-full flex capitalize'>{role.workStatus}</span> }
              </div>
              <a href={`/roles/${role.id}`} className="flex mt-1 text-gray-400 hover:text-gray-500 dark:text-gray-400 dark:hover:text-gray-300 items-center">
                <CakeIcon className="h-5 w-5" aria-hidden="true" />
                <span className="text-xs">Work on Role</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </li>
}

const NoRoles = () => {
    return <span>
      <a href='/' className="relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-2 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccblue flex justify-start items-center" >
        <CakeIcon className="h-6 w-6 text-gray-400" />
        <span className="text-sm font-medium text-gray-900 dark:text-gray-300"> You haven't been chosen for any roles recently.</span>
      </a>
    </span>
}

const RecentRoles = () => {
  const { getRequest } = useQuery()
  const [roles, setRoles] = useState([])
  const [roleCount, setRoleCount] = useState(0)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    getRequest('/api/v3/dashboard/roles', {}, (err, jsonData) => {
      setLoading(false)
      if (err) { /* handled in hook */ return }
      setRoles(jsonData.roles)
      setRoleCount(jsonData.rolesCount)
    })
  }, [])

  return <>
    <Card title={
      <span className='flex items-center'>
        <CakeIcon className="h-6 w-6 mr-1" />
        My Recent Roles ({roleCount})
      </span>
      } footer={<a href='/myroles'>All My Roles</a> } light>
      <ul className="space-y-3">
        { roles.map((role) => (
          <RoleCastRow key={`dashrole${role.id}`} role={role} />
        ))}
      </ul>
      { loading && <Loading /> }
      { !loading && roles.length === 0 && <NoRoles /> }
    </Card>
  </>
}
export default RecentRoles

RoleCastRow.propTypes = {
  role: PropTypes.object.isRequired
}
