import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { formattedTime } from '@/utils/date'
import WaveSurfer from 'wavesurfer.js'

import { PlayIcon, PauseIcon } from '@heroicons/react/solid'
import useQuery from '@/hooks/useQuery'

// http://wavesurfer-js.org/projects/
// Truly awesome stuff that could be done with audio editing right from the browser.
// Possible to record, cut, mix, annotate, straight from browser
// Possible to play file immediately, before even uplaoding it too, Look in imageuploadform URL.createObjectURL
//
// Possible to preload next song in the queue: https://wavesurfer-js.org/docs/methods.html

const formWaveSurferOptions = ref => ({
  container: ref,
  waveColor: '#eee',
  progressColor: '#f7784e',
  cursorColor: '#f7784e',
  // xhr: { mode: 'no-cors', method: 'GET'},
  barWidth: 3,
  barRadius: 3,
  responsive: true,
  height: 60,
  normalize: true, // If true, normalize by the maximum peak instead of 1.0.
  partialRender: true // Use the PeakCache to improve rendering speed of large waveforms.
})

const StandAloneAudioPlayer = ({ mediaUrl, autoPlay, objectId, objectKind }) => {
  const { putpostRequest } = useQuery()
  const [playing, setPlaying] = useState(false)
  const [mediaIsLoaded, setMediaIsLoaded] = useState(false)

  const waveformRef = useRef(null)
  const wavesurfer = useRef(null)
  const [duration, setDuration] = useState(0.0)
  const [currentPosition, setCurrentPosition] = useState(0.0)

  // create new WaveSurfer instance
  // On component mount and when url changes
  useEffect(() => {
    if (!mediaIsLoaded) { return }

    const options = formWaveSurferOptions(waveformRef.current)
    wavesurfer.current = WaveSurfer.create(options)

    if (mediaUrl) {
      wavesurfer.current.load(mediaUrl)
    }

    const data = { object_id: objectId, object_kind: objectKind }
    putpostRequest('/api/v3/media_objects', 'POST', data, () => { })

    wavesurfer.current.on('ready', function() {
      // https://wavesurfer-js.org/docs/methods.html
      // make sure object stillavailable when file loaded
      if (wavesurfer.current) {
        // wavesurfer.current.play()
        // setPlaying(true)
        wavesurfer.current.setVolume(1)
        setDuration(formattedTime(wavesurfer.current.getDuration()))
        handlePlayPause()
      }
    })

    wavesurfer.current.on('audioprocess', function() {
      if (wavesurfer.current) {
        const time = wavesurfer.current.getCurrentTime()
        const fTime = formattedTime(time)
        if (fTime !== currentPosition) {
          setCurrentPosition(fTime)
        }
      }
    })

    // Removes events, elements and disconnects Web Audio nodes.
    // when component unmount
    return () => wavesurfer.current.destroy()
  }, [mediaIsLoaded])

  useEffect(() => {
    if (!wavesurfer.current) { return }

    if (playing && wavesurfer.current.isPlaying()) { return }
    if (!playing && !wavesurfer.current.isPlaying()) { return }

    wavesurfer.current.playPause()
  }, [playing])

  const handlePlayPause = () => {
    setPlaying(!playing)
    wavesurfer.current.playPause()
  }

  // always need to have the wavesurfer id in the dom otherwise madness
  return <>
    <div className={'bg-cccblue text-white h-16 w-full rounded'}>
      <div className="w-full mx-auto px-3 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between">
          <div className="w-full flex-1 flex items-center justify-center">
            <div className='flex-1 flex items-center justify-center'>
              { !mediaIsLoaded && <div onClick={() => setMediaIsLoaded(true)} className="flex items-center">
                <PlayIcon className='h-16 w-16 cursor-pointer' />
              </div> }
              { mediaUrl && mediaIsLoaded && <>
                { !playing && <div onClick={handlePlayPause} className="flex items-center">
                  <PlayIcon className='h-16 w-16 cursor-pointer' />
                </div> }
                { playing && <div onClick={handlePlayPause} className="flex items-center">
                  <PauseIcon className='h-16 w-16 cursor-pointer' />
                </div> }
              </> }
              <div className='flex-grow hidden sm:block ml-3 cursor-pointer'>
                <div id="waveform" className='' ref={waveformRef} />
              </div>
            </div>

            { mediaUrl && mediaIsLoaded && <>
              <div className="flex ml-0 sm:ml-6 cursor-pointer gap-x-3">
                <div className='flex flex-col w-20'>
                  <span>{currentPosition}</span>
                  <span>{duration}</span>
                </div>
              </div>
            </> }
          </div>
        </div>
      </div>
    </div>
  </>
}

export default StandAloneAudioPlayer

StandAloneAudioPlayer.propTypes = {
  mediaUrl: PropTypes.string,
  autoPlay: PropTypes.bool,
  objectId: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.string.isRequired]),
  objectKind: PropTypes.string.isRequired
}
