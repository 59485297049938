import React, { useState, useEffect } from 'react'
import { debounce } from 'throttle-debounce'
import Loading from '@/shared/Loading'
import { useGlobalState } from '@/state'
import useQuery from '@/hooks/useQuery'
import Card from '@/shared/Card'
import PublicSubmissionRow from '@/pages/Users/PublicProfile/PublicSubmissionRow'

const PublicShowcase = () => {
  const [profileContainer] = useGlobalState('profileContainer')
  const { getRequest } = useQuery()
  const [submissions, setSubmissions] = useState([])
  const [voteIds, setVoteIds] = useState([])
  const { user } = profileContainer
  const [loading, setLoading] = useState(true)

  useEffect(() => { fetchData() }, [])

  const fetchData = debounce(300, () => {
    setLoading(true)
    getRequest(`/api/v3/users/${user.username}/showcase`, {}, (err, jsonData) => {
      setLoading(false)
      if (err) { /* hooks */ return }

      setVoteIds(jsonData.voteIds)
      setSubmissions(jsonData.submissions)
    })
  })

  return <>
    { loading && <Loading /> }
    { submissions.length > 0 && <Card light title='Showcased Submissions'>
      <>
        <ul className="space-y-3">
          {submissions.map((sub) => (
            <PublicSubmissionRow key={`pubsshow${sub.id}`} submission={sub} voteIds={voteIds} />
          ))}
        </ul>
      </>
    </Card> }

  </>
}

export default PublicShowcase
