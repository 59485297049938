import React from 'react'
import { Link } from 'react-router-dom'
import { useGlobalState } from '@/state'

import Card from '@/shared/Card'

const FillOutProfile = () => {
  const [dashboardContainer] = useGlobalState('dashboardContainer')
  console.log(dashboardContainer)
  const { stats } = dashboardContainer
  const { noDemos, noLabelings } = stats

  if (!noDemos && !noLabelings) { return null }

  return <>
    <Card title='Fill Out Your Profile'>
      { noLabelings && <>
        <div className="rounded-md bg-yellow-50 p-4 m-2 text-yellow-900">
          <div className="flex">
            <div className="flex-shrink-0">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
              </svg>
            </div>
            <div className="ml-3">
              <div className='pb-3 text-sm'>
                <Link to='/profile/about'>Add Some Tags!</Link>
              </div>
              <div className="text-sm font-medium text-yellow-800 flex justify-between items-center">
                <div>Add some tags to your profile so that people can uniquely search for you.</div>
              </div>
            </div>
          </div>
        </div>
      </> }
      { noDemos && <>
        <div className="rounded-md bg-yellow-50 p-4 m-2 text-yellow-900">
          <div className="flex">
            <div className="flex-shrink-0">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
              </svg>
            </div>
            <div className="ml-3">
              <div className='pb-3 text-sm'>
                <Link to='/profile/about'>Add a Demo!</Link>
              </div>
              <div className="text-sm font-medium text-yellow-800 flex justify-between items-center">
                <div>Add your demo so that people can listen to it on the Find Talent page.</div>
              </div>
            </div>
          </div>
        </div>
      </> }
    </Card>
  </>
}
export default FillOutProfile
