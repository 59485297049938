import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { FilmIcon, HeartIcon } from '@heroicons/react/solid'
import useQuery from '@/hooks/useQuery'

import Loading from '@/shared/Loading'
import Card from '@/shared/Card'

const ProjectFavoriteRow = ({ project }) => {
  const { putpostRequest } = useQuery()
  const [following, setFollowing] = useState(true)

  const unsave = () => {
    putpostRequest(`/api/v3/favorites/${project.id}/favorite_project`, 'POST', {}, (err, jsonData) => {
      if (err) { /* hook */ }
      setFollowing(!following)
    })
  }

  if (!following) return null

  return <li className='bg-white dark:bg-gray-700 rounded-lg border border-gray-200 dark:border-gray-700'>
      <div className="p-1">
        <div className='flex justify-between'>
          <div className="relative flex items-start space-x-3 overflow-hidden">
            <div className="relative m-1">
              <img className="h-16 w-16 rounded-sm bg-gray-200 flex items-center justify-center ring-4 ring-cccblue" src={project.publicImageUrl} alt={project.name} />

              <span className="absolute -bottom-0.5 -right-1 bg-cccblue rounded-tl px-0.5 py-px">
                <FilmIcon className="h-4 w-4 text-white" aria-hidden="true" />
              </span>
            </div>
            <div className="min-w-0 flex-1 truncate">
              <a href={`/projects/${project.slug}`} className="font-medium text-cccblue cursor-pointer truncate">
                {project.name}
              </a>
              <div className="mt-1 text-sm text-gray-600 dark:text-gray-400 flex">
                <span className="uppercase text-xs dark:text-gray-300 text-gray-500 mr-5">
                  Deadline: {project.deadline}
                </span>
                <span className="uppercase text-xs dark:text-gray-300 text-gray-500 mr-5">
                  Roles Open: {project.rolesAvailableCount}/{project.rolesCount}
                </span>
              </div>
              <button onClick={unsave} type="button" className="flex mt-1 text-gray-400 hover:text-gray-500 dark:text-gray-400 dark:hover:text-gray-300 items-center">
                <HeartIcon className="h-5 w-5" aria-hidden="true" />
                <span className="text-xs">Unfavorite</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </li>
}

const NoFavorites = () => {
    return <span>
      <a href='/' className="relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-2 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccblue flex justify-start items-center" >
        <HeartIcon className="h-6 w-6 text-gray-400" />
        <span className="text-sm font-medium text-gray-900 dark:text-gray-300"> You haven't favorited any open projects for later yet.</span>
      </a>
    </span>
}

const FavoritedProjects = () => {
  const { getRequest } = useQuery()
  const [projects, setProjects] = useState([])
  const [projectCount, setProjectCount] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    getRequest('/api/v3/dashboard/favorites', {}, (err, jsonData) => {
      setLoading(false)
      if (err) { /* handled in hook */ return }
      setProjects(jsonData.projects)
      setProjectCount(jsonData.projectCount)
    })
  }, [])

  return <>
    <Card title={
      <span className='flex items-center'>
        <HeartIcon className="h-6 w-6" />
        Favorited Projects ({projectCount})
      </span>
      } footer={<a href='/favorites'>All Favorites</a> } light>
      <ul className="space-y-3">
        { projects.map((pro) => (
          <ProjectFavoriteRow key={`dashprojfav${pro.id}`} project={pro} />
        ))}
      </ul>
      { loading && <Loading /> }
      { !loading && projects.length === 0 && <NoFavorites /> }
    </Card>
  </>
}
export default FavoritedProjects

ProjectFavoriteRow.propTypes = {
  project: PropTypes.object.isRequired
}
