import React from 'react'
import { Link } from 'react-router-dom'
import { useGlobalState } from '@/state'
import SVGIcon from '@/components/icon'

import Card from '@/shared/Card'

const AccountStats = () => {
  const [dashboardContainer] = useGlobalState('dashboardContainer')
  const [currentUser] = useGlobalState('currentUser')
  const { stats } = dashboardContainer

  return <>
    <Card title='Account Stats'>
      <ul className="grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2 lg:grid-cols-3">

        <li className="col-span-1 flex rounded-md bg-white dark:bg-gray-700 dark:hover:bg-gray-900 hover:bg-gray-100">
          <Link to='/submissions' className='flex w-full'>
            <div className='flex-shrink-0 flex items-center justify-center w-16 text-white text-sm font-medium rounded-l-md  border-t border-l border-b border-gray-200 dark:border-gray-700'>
              <SVGIcon name='icon-submissions' className='h-10 w-10' />
            </div>
            <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 dark:border-gray-700 rounded-r-md truncate">
              <div className="flex-1 px-4 py-2 text-sm truncate">
                <div className="text-gray-900 font-medium dark:text-gray-100"> Submissions </div>
                <p className="text-green-500">{stats.submissionsCount}</p>
                <p className="text-xs text-gray-500 dark:text-gray-400 hidden sm:block">Everything you ever applied for</p>
              </div>
            </div>
          </Link>
        </li>

        <li className="col-span-1 flex rounded-md bg-white dark:bg-gray-700 dark:hover:bg-gray-900 hover:bg-gray-100">
          <a href='/manage/projects' className='flex w-full'>
            <div className='flex-shrink-0 flex items-center justify-center w-16 text-white text-sm font-medium rounded-l-md  border-t border-l border-b border-gray-200 dark:border-gray-700'>
              <SVGIcon name='icon-manage-projects' className='h-10 w-10' />
            </div>
            <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 dark:border-gray-700 rounded-r-md truncate">
              <div className="flex-1 px-4 py-2 text-sm truncate">
                <div className="text-gray-900 font-medium dark:text-gray-100"> Projects Created </div>
                <p className="text-green-500">{stats.projectsCount}</p>
                <p className="text-xs text-gray-500 dark:text-gray-400 hidden sm:block">Manage projects & submissions</p>
              </div>
            </div>
          </a>
        </li>

        <li className="col-span-1 flex rounded-md bg-white dark:bg-gray-700 dark:hover:bg-gray-900 hover:bg-gray-100">
          <Link to='/credits' className='flex w-full'>
            <div className='flex-shrink-0 flex items-center justify-center w-16 text-white text-sm font-medium rounded-l-md  border-t border-l border-b border-gray-200 dark:border-gray-700'>
              <SVGIcon name='icon-credits' className='h-10 w-10' />
            </div>
            <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 dark:border-gray-700 rounded-r-md truncate">
              <div className="flex-1 px-4 py-2 text-sm truncate">
                <div className="text-gray-900 font-medium dark:text-gray-100"> Credits </div>
                <p className="text-green-500">{stats.creditsCount}</p>
                <p className="text-xs text-gray-500 dark:text-gray-400 hidden sm:block">Let your credits roll!</p>
              </div>
            </div>
          </Link>
        </li>

        <li className="col-span-1 flex rounded-md bg-white dark:bg-gray-700 dark:hover:bg-gray-900 hover:bg-gray-100">
          <Link to='/recommendations' className='flex w-full'>
            <div className='flex-shrink-0 flex items-center justify-center w-16 text-white text-sm font-medium rounded-l-md  border-t border-l border-b border-gray-200 dark:border-gray-700'>
              <SVGIcon name='icon-recommendation' className='h-10 w-10' />
            </div>
            <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 dark:border-gray-700 rounded-r-md truncate">
              <div className="flex-1 px-4 py-2 text-sm truncate">
                <div className="text-gray-900 font-medium dark:text-gray-100"> Recommendations </div>
                <p className="text-green-500">{stats.recommendationsCount}</p>
                <p className="text-xs text-gray-500 dark:text-gray-400 hidden sm:block">Let people vouch for you</p>
              </div>
            </div>
          </Link>
        </li>

        <li className="col-span-1 flex rounded-md bg-white dark:bg-gray-700 dark:hover:bg-gray-900 hover:bg-gray-100">
          <Link to={`/${currentUser.username}?tab=Followers`} className='flex w-full'>
            <div className='flex-shrink-0 flex items-center justify-center w-16 text-white text-sm font-medium rounded-l-md  border-t border-l border-b border-gray-200 dark:border-gray-700'>
              <SVGIcon name='icon-followers' className='h-10 w-10' />
            </div>
            <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 dark:border-gray-700 rounded-r-md truncate">
              <div className="flex-1 px-4 py-2 text-sm truncate">
                <div className="text-gray-900 font-medium dark:text-gray-100"> Followers </div>
                <p className="text-green-500">{stats.followersCount}</p>
                <p className="text-xs text-gray-500 dark:text-gray-400 hidden sm:block">People who subscribe to your updates</p>
              </div>
            </div>
          </Link>
        </li>

        <li className="col-span-1 flex rounded-md bg-white dark:bg-gray-700 dark:hover:bg-gray-900 hover:bg-gray-100">
          <Link to='/dashboard/profile_views' className='flex w-full'>
            <div className='flex-shrink-0 flex items-center justify-center w-16 text-white text-sm font-medium rounded-l-md  border-t border-l border-b border-gray-200 dark:border-gray-700'>
              <SVGIcon name='icon-talent-search' className='h-10 w-10' />
            </div>
            <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 dark:border-gray-700 rounded-r-md truncate">
              <div className="flex-1 px-4 py-2 text-sm truncate">
                <div className="text-gray-900 font-medium dark:text-gray-100"> Recent Profile Views </div>
                <p className="text-green-500">{stats.profileViewsCount}</p>
                <p className="text-xs text-gray-500 dark:text-gray-400 hidden sm:block">Profile Views in the last 90 days</p>
              </div>
            </div>
          </Link>
        </li>
      </ul>
    </Card>
  </>
}
export default AccountStats
