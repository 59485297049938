import React, { Fragment, useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useDebouncedEffect } from '@/utils/useDebouncedEffect'
import { EyeIcon } from '@heroicons/react/solid'
import Pagination from '@/shared/Pagination'
import Loading from '@/shared/Loading'
import { useGlobalState } from '@/state'
import useQuery from '@/hooks/useQuery'
import Card from '@/shared/Card'
import classNames from '@/utils/classNamesLocal'
import UserHoverCard from '@/shared/UserHoverCard'

const TabCount = ({ number, selected }) => {
  return <>
    <span className={classNames(
      selected ? 'bg-cccblue text-white dark:bg-white dark:text-cccblue' : 'bg-gray-200 text-gray-900',
      'hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block'
    )} >
      {number}
    </span>
  </>
}
TabCount.propTypes = {
  number: PropTypes.number,
  selected: PropTypes.bool
}

const NoRecommendations = () => {
  return (
    <span>
      <div className="relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccblue" >
        <EyeIcon className="mx-auto h-12 w-12 text-gray-400" />
        <span className="mt-2 block text-sm font-medium text-gray-900 dark:text-gray-300"> No public recommendations yet.</span>
      </div>
    </span>
  )
}

const recommendationTabs = [
  { name: 'Received' },
  { name: 'Given' }
]

const PublicRecommendationRow = ({ recommendation }) => {
  const handleContentClick = (e) => {
    const targetLink = e.target.closest('a')
    if (!targetLink) { return }

    const to = e.target.getAttribute('data-to')
    const isMention = e.target.getAttribute('data-mention')

    if (isMention === 'mention') {
      e.preventDefault()
      history.push('/' + to)
    }
  }
  return <li>
    <div className="flex space-x-4">
      <div className="flex-shrink-0">
        <img className="h-12 w-12 rounded-sm" src={recommendation.imageUrl} alt="" />
      </div>
      <div className="flex-1 min-w-0">
        <div className='flex items-center'>
          <UserHoverCard username={recommendation.username} displayName={recommendation.displayName} />
          <span className="uppercase ml-2 text-xs dark:text-gray-300 text-gray-500">
            <time dateTime={recommendation.createdAt}>{recommendation.createdAt}</time>
          </span>
        </div>
        <div className="mb-4">
          <p onClick={handleContentClick} className="text-sm dark:text-gray-300 text-gray-800" dangerouslySetInnerHTML={{ __html: recommendation.body }}></p>
        </div>
      </div>
    </div>
  </li>
}

PublicRecommendationRow.propTypes = {
  recommendation: PropTypes.object.isRequired
}

const Recommendations = ({ recommendations }) => {
  return <>
    <ul className="space-y-3">
      {recommendations.map((rec) => (
        <PublicRecommendationRow key={`pubrec${rec.id}`} recommendation={rec} />
      ))}
    </ul>
  </>
}

const PublicProjects = () => {
  const [profileContainer, setProfileContainer] = useGlobalState('profileContainer')
  const [givenMeta, setGivenMeta] = useState({})
  const [receivedMeta, setReceivedMeta] = useState({})
  const { getRequest } = useQuery()
  const { user, recommendationsGiven, recommendationsReceived } = profileContainer
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(1)
  const [currentTab, setCurrentTab] = useState('Received')
  const [contains] = useState('')
  const scrollRef = useRef(null)

  useDebouncedEffect(() => { fetchData() }, 200, [contains]) // TODO: search support
  useEffect(() => { fetchData() }, [page])

  const fetchData = () => {
    setLoading(true)
    const data = {
      contains: contains,
      page: contains.length > 0 ? 1 : page
    }
    getRequest(`/api/v3/users/${user.id}/recommendations`, data, (err, jsonData) => {
      setLoading(false)
      if (err) { /* hooks */ return }

      setProfileContainer({
        ...profileContainer,
        recommendationsGiven: jsonData.recommendationsGiven,
        recommendationsReceived: jsonData.recommendationsReceived
      })
      setGivenMeta(jsonData.metaGiven)
      setReceivedMeta(jsonData.metaReceived)
    })
  }

  return <>
    <Card light title='Recommendations'>
      <>
        <div>
          <div className="sm:hidden">
            <label htmlFor="tabs" className="sr-only" hidden> Select a tab </label>
            <select id="tabs" name="tabs" className="block w-full focus:ring-cccblue focus:border-cccblue border-gray-300 rounded-md" defaultValue={currentTab} onChange={(tab) => setCurrentTab(tab.name)}>
              {recommendationTabs.map((tab) => (
                <option key={`mobile${tab.name}`}>{tab.name}</option>
              ))}
            </select>
          </div>
          <div className="hidden sm:block">
            <nav className="relative z-0 rounded-lg shadow flex divide-x divide-gray-200" aria-label="Tabs">
              {recommendationTabs.map((tab, tabIdx) => (
                <div key={`desk${tab.name}`} onClick={() => setCurrentTab(tab.name)} className={classNames(
                  tab.name === currentTab ? 'text-gray-900 dark:text-gray-200 bg-gray-100 dark:bg-gray-700' : 'text-gray-500 hover:text-cccblue dark:text-gray-200 bg-gray-100 dark:bg-gray-700 dark:hover:text-cccblue',
                  tabIdx === 0 ? 'rounded-l-lg' : '',
                  tabIdx === recommendationTabs.length - 1 ? 'rounded-r-lg' : '',
                  'group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10 cursor-pointer'
                )}
                  aria-current={tab.name === currentTab ? 'page' : undefined}
                >
                  <span className='flex justify-center'>
                    {tab.name}
                    { tab.name === 'Received' && recommendationsReceived.length > 0 && <TabCount number={recommendationsReceived.length} selected={tab.name === currentTab} /> }
                    { tab.name === 'Given' && recommendationsGiven.length > 0 && <TabCount number={recommendationsGiven.length} selected={tab.name === currentTab} /> }
                  </span>
                  <span aria-hidden="true" className={classNames(tab.name === currentTab ? 'bg-cccblue' : 'bg-transparent', 'absolute inset-x-0 bottom-0 h-0.5')} />
                </div>
              ))}
            </nav>
          </div>
        </div>
        <div className='pb-5'>
          { currentTab === 'Given' && recommendationsGiven.length >= 0 && <Pagination meta={givenMeta} callback={setPage} scrollRef={scrollRef} /> }
          { currentTab === 'Received' && recommendationsReceived.length >= 0 && <Pagination meta={receivedMeta} callback={setPage} scrollRef={scrollRef} /> }
        </div>
        <div className="mt-2 sm:mt-6 min-w-0 flex-1">
          { currentTab === 'Received' && <Recommendations recommendations={recommendationsReceived} /> }
          { currentTab === 'Given' && <Recommendations recommendations={recommendationsGiven} /> }
        </div>
        { currentTab === 'Given' && recommendationsGiven.length === 0 && <NoRecommendations /> }
        { currentTab === 'Received' && recommendationsReceived.length === 0 && <NoRecommendations /> }
        { loading && <Loading /> }
      </>
    </Card>
  </>
}

Recommendations.propTypes = {
  recommendations: PropTypes.array.isRequired
}

export default PublicProjects
