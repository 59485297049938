import React, { useState, useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'

import Card from '@/shared/Card'

import TagAutocomplete from '@/shared/TagAutocomplete'
import { XIcon } from '@heroicons/react/solid'
import useQuery from '@/hooks/useQuery'

const RoleTags = ({roleId, roleKind}) => {
  const { getRequest, putpostRequest } = useQuery()
  const [state, setState] = useReducer(
    (state, newState) => (
      { ...state, ...newState }), {
        languages: [],
        interests: [],
        skills: [],
        demoCategories: [],
        softwares: [],
        hardwares: [],
        accents: [],
        genderAges: []
      }
    )
  const { languages, genderAges, accents, interests, hardwares, softwares, skills, demoCategories } = state

  useEffect(() => { fetchData() }, [])

  const fetchData = () => {
    getRequest('/api/v3/labelings/role_labels', { role_id: roleId }, (err, jsonData) => {
      if (err) { /* hooks */ return }

      setState({
        languages: jsonData.languages,
        skills: jsonData.skills,
        demoCategories: jsonData.demoCategories,
        interests: jsonData.interests,
        hardwares: jsonData.hardwares,
        softwares: jsonData.softwares,
        genderAges: jsonData.genderAges,
        accents: jsonData.accents
      })
    })
  }

  const addLabel = (label, labelKind) => {
    const data = {
      labeled_kind: 'Role',
      labeled_id: roleId
    }
    const labelData = {
      name: label,
      label_kind: labelKind
    }
    putpostRequest('/api/v3/labelings', 'POST', { labeling: data, label: labelData }, (err, jsonData) => {
      if (err) { /* hook */ return }

      if (labelKind === 'user-languages') {
        setState({ languages: [...languages, ...[jsonData.labeling]] })
      }
      if (labelKind === 'user-skills') {
        setState({ skills: [...skills, ...[jsonData.labeling]] })
      }
      if (labelKind === 'demo-categories') {
        setState({ demoCategories: [...demoCategories, ...[jsonData.labeling]] })
      }
      if (labelKind === 'user-interests') {
        setState({ interests: [...interests, ...[jsonData.labeling]] })
      }
      if (labelKind === 'user-accents') {
        setState({ accents: [...accents, ...[jsonData.labeling]] })
      }
      if (labelKind === 'user-hardwares') {
        setState({ hardwares: [...hardwares, ...[jsonData.labeling]] })
      }
      if (labelKind === 'user-softwares') {
        setState({ softwares: [...softwares, ...[jsonData.labeling]] })
      }
      if (labelKind === 'user-gender-age') {
        setState({ genderAges: [...genderAges, ...[jsonData.labeling]] })
      }
    })
  }

  const removeLabel = (labelingId, kind) => {
    if (kind === 'user-languages') {
      const labelings = languages.filter(labeling => labeling.id !== labelingId)
      setState({ languages: labelings })
    }
    if (kind === 'user-interests') {
      const labelings = interests.filter(labeling => labeling.id !== labelingId)
      setState({ interests: labelings })
    }
    if (kind === 'user-skills') {
      const labelings = skills.filter(labeling => labeling.id !== labelingId)
      setState({ skills: labelings })
    }
    if (kind === 'user-hardwares') {
      const labelings = hardwares.filter(labeling => labeling.id !== labelingId)
      setState({ hardwares: labelings })
    }
    if (kind === 'user-softwares') {
      const labelings = softwares.filter(labeling => labeling.id !== labelingId)
      setState({ softwares: labelings })
    }
    if (kind === 'demo-categories') {
      const labelings = demoCategories.filter(labeling => labeling.id !== labelingId)
      setState({ demoCategories: labelings })
    }
    if (kind === 'user-accents') {
      const labelings = accents.filter(labeling => labeling.id !== labelingId)
      setState({ accents: labelings })
    }
    if (kind === 'user-gender-age') {
      const labelings = genderAges.filter(labeling => labeling.id !== labelingId)
      setState({ genderAges: labelings })
    }

    putpostRequest(`/api/v3/labelings/${labelingId}`, 'DELETE', {}, (err, jsonData) => {
      if (err) { /* hook */ }
    })
  }

  return <>
        <div>
      <div className="md:grid md:grid-cols-4 md:gap-6 space-x-2">
        <div className="md:col-span-1">
          <div className="px-4 sm:px-0">
            <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-100">Tags</h3>
            <div className="mt-1 text-sm text-gray-600 dark:text-gray-300">
              List a bunch of stuff to help other people discover this role.
            </div>
            <div className="mt-4 text-sm text-gray-600 dark:text-gray-300 font-bold">
              Leave blank if you're considering anything. For example: if you are open to any Accent, leave it blank.
            </div>
          </div>
        </div>
        <Card>
          <>
         <div className="space-y-6 sm:space-y-5">
             { ['Voice Actor', 'Singer', 'Actor'].includes(roleKind) && <>
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="first_name" className="block text-sm font-medium text-gray-700 dark:text-gray-200 sm:mt-px sm:pt-2">
                  Voice Age & Gender
                  <p className='text-xs text-gray-500'>Searchable for Voice Actors, Singers, & Live Actors</p>
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2 max-w-xs">
                  <TagAutocomplete kind='user-gender-age' addLabel={addLabel} placeholder='Female Young Adult, Male Teen, Baby'/>
                  { genderAges.length > 0 && <>
                    <div className="mt-3 flex sm:items-center flex-wrap">
                      {genderAges.map((labeling, idx) => (
                        <span key={`lang${labeling.id}`} onClick={(id) => removeLabel(labeling.id, 'user-gender-age')} className="flex-shrink-0 inline-block px-2 mr-1 mb-1 py-0.5 text-white bg-cccorange dark:bg-cccpurple text-xs font-medium rounded-full flex cursor-pointer capitalize">{labeling.name} <XIcon className='w-4 h-4' /></span>
                      ))}
                    </div>
                  </> }
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="first_name" className="block text-sm font-medium text-gray-700 dark:text-gray-200 sm:mt-px sm:pt-2">
                  Accents
                  <p className='text-xs text-gray-500'>Searchable for Voice Actors, Singers, & Live Actors</p>
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2 max-w-xs">
                  <TagAutocomplete kind='user-accents' addLabel={addLabel} placeholder='Texan, Irish, French'/>
                  { accents.length > 0 && <>
                    <div className="mt-3 flex sm:items-center flex-wrap">
                      {accents.map((labeling, idx) => (
                        <span key={`lang${labeling.id}`} onClick={(id) => removeLabel(labeling.id, 'user-accents')} className="flex-shrink-0 inline-block px-2 mr-1 mb-1 py-0.5 text-white bg-cccorange dark:bg-cccpurple text-xs font-medium rounded-full flex cursor-pointer capitalize">{labeling.name} <XIcon className='w-4 h-4' /></span>
                      ))}
                    </div>
                  </> }
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="first_name" className="block text-sm font-medium text-gray-700 dark:text-gray-200 sm:mt-px sm:pt-2">
                  Voice Description
                  <p className='text-xs text-gray-500'>Searchable for Voice Actors, Singers, & Live Actors</p>
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2 max-w-xs">
                  <TagAutocomplete kind='demo-categories' addLabel={addLabel} placeholder='Warm, Funny, Robin Williams'/>
                  { demoCategories.length > 0 && <>
                    <div className="mt-3 flex sm:items-center flex-wrap">
                      {demoCategories.map((labeling, idx) => (
                        <span key={`democa${labeling}`} onClick={(id) => removeLabel(labeling.id, 'demo-categories')} className="flex-shrink-0 inline-block px-2 mr-1 mb-1 py-0.5 text-white bg-cccorange dark:bg-cccpurple text-xs font-medium rounded-full flex cursor-pointer capitalize">{labeling.name} <XIcon className='w-4 h-4' /></span>
                      ))}
                    </div>
                  </> }
                </div>
              </div>

             </> }

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="first_name" className="block text-sm font-medium text-gray-700 dark:text-gray-200 sm:mt-px sm:pt-2">
                Language this role can be perfomed in
                <p className='text-xs text-gray-500'>Searchable by anyone</p>
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2 max-w-xs">
                <TagAutocomplete kind='user-languages' addLabel={addLabel} placeholder='English, Japanese'/>
                { languages.length > 0 && <>
                  <div className="mt-3 flex sm:items-center flex-wrap">
                    {languages.map((labeling, idx) => (
                      <span key={`lang${labeling.id}`} onClick={(id) => removeLabel(labeling.id, 'user-languages')} className="flex-shrink-0 inline-block px-2 mr-1 mb-1 py-0.5 text-white bg-cccorange dark:bg-cccpurple text-xs font-medium rounded-full flex cursor-pointer capitalize">{labeling.name} <XIcon className='w-4 h-4' /></span>
                    ))}
                  </div>
                </> }
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="first_name" className="block text-sm font-medium text-gray-700 dark:text-gray-200 sm:mt-px sm:pt-2">
                Preferred Hardware Used
                <p className='text-xs text-gray-500'>Searchable for Everyone</p>
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2 max-w-xs">
                <TagAutocomplete kind='user-hardwares' addLabel={addLabel} placeholder='Scarlett FocusRite 2i2, Shure SM7b, Blue Yeti'/>
                { hardwares.length > 0 && <>
                  <div className="mt-3 flex sm:items-center flex-wrap">
                    {hardwares.map((labeling, idx) => (
                      <span key={`lang${labeling.id}`} onClick={(id) => removeLabel(labeling.id, 'user-hardwares')} className="flex-shrink-0 inline-block px-2 mr-1 mb-1 py-0.5 text-white bg-cccorange dark:bg-cccpurple text-xs font-medium rounded-full flex cursor-pointer capitalize">{labeling.name} <XIcon className='w-4 h-4' /></span>
                    ))}
                  </div>
                </> }
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="first_name" className="block text-sm font-medium text-gray-700 dark:text-gray-200 sm:mt-px sm:pt-2">
                Preferred Software Used
                <p className='text-xs text-gray-500'>Searchable for Everyone</p>
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2 max-w-xs">
                <TagAutocomplete kind='user-softwares' addLabel={addLabel} placeholder='ISDN, Audacity, Source Connect, Scrivener'/>
                { softwares.length > 0 && <>
                  <div className="mt-3 flex sm:items-center flex-wrap">
                    {softwares.map((labeling, idx) => (
                      <span key={`lang${labeling.id}`} onClick={(id) => removeLabel(labeling.id, 'user-softwares')} className="flex-shrink-0 inline-block px-2 mr-1 mb-1 py-0.5 text-white bg-cccorange dark:bg-cccpurple text-xs font-medium rounded-full flex cursor-pointer capitalize">{labeling.name} <XIcon className='w-4 h-4' /></span>
                    ))}
                  </div>
                </> }
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="first_name" className="block text-sm font-medium text-gray-700 dark:text-gray-200 sm:mt-px sm:pt-2">
                Skills
                <p className='text-xs text-gray-500'>Searchable for Everyone</p>
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2 max-w-xs">
                <TagAutocomplete kind='user-skills' addLabel={addLabel} placeholder='Figma, Reaper, Audacity, ADR'/>
                { skills.length > 0 && <>
                  <div className="mt-3 flex sm:items-center flex-wrap">
                    {skills.map((labeling, idx) => (
                      <span key={`lang${labeling.id}`} onClick={(id) => removeLabel(labeling.id, 'user-skills')} className="flex-shrink-0 inline-block px-2 mr-1 mb-1 py-0.5 text-white bg-cccorange dark:bg-cccpurple text-xs font-medium rounded-full flex cursor-pointer capitalize">{labeling.name} <XIcon className='w-4 h-4' /></span>
                    ))}
                  </div>
                </> }
              </div>
            </div>
          </div>
          </>

        </Card>
      </div>
    </div>

  </>
}

export default RoleTags

RoleTags.propTypes = {
  roleId: PropTypes.number.isRequired,
  roleKind: PropTypes.string.isRequired
}

