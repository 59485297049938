import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link, useParams, useHistory } from 'react-router-dom'

import { useForm } from 'react-hook-form'
import { debounce } from 'throttle-debounce'

import Loading from '@/shared/Loading'
import Card from '@/shared/Card'
import { randomToastSuccess } from '@/shared/Toast'

import useModal from '@/hooks/useModalV2'
import CreditTabs from '@/pages/Credits/Tabs'
import Modal from '@/shared/Modal'
import ImageUploadForm from '@/shared/ImageUploadForm'
import FroalaWrapper from '@/shared/FroalaWrapper'
import PrimaryButton from '@/shared/Buttons/Primary'
import { ExclamationCircleIcon, XIcon } from '@heroicons/react/solid'
import CreditCoverImage from '@/pages/Credits/CreditCoverImage'
import CreditAutocomplete from '@/shared/CreditAutocomplete'
import useQuery from '@/hooks/useQuery'
import { useGlobalState } from '@/state'

function generateArrayOfYears() {
  const max = new Date().getFullYear() + 3
  const min = max - 40
  const years = []

  for (let i = max; i >= min; i--) {
    years.push(i)
  }
  return years
}

const NewCreditForm = () => {
  const [, setToast] = useGlobalState('toast')
  const history = useHistory()
  const { putpostRequest } = useQuery()
  const [credit, setCredit] = useState(null)
  const { formState, register, watch, handleSubmit, setValue, setError } = useForm({
   defaultValues: { }
  })

  const { errors } = formState
  const [loading, setLoading] = useState(false)

  const onSubmit = debounce(300, (data) => {
    setLoading(true)

    putpostRequest('/api/v3/credits', 'POST', { credit: data }, (err, jsonData) => {
      setLoading(false)
      if (err) { // 422 code
        if (typeof err !== 'string') {
          Object.entries(err).forEach(([key, value]) => {
            setError(key, value)
          })
        }
        return
      }

      history.push(`/credits/${jsonData.credit.id}/edit`)

      setToast(<div className="ml-3 w-0 flex-1 pt-0.5">
        <p className="text-sm font-medium dark:text-white text-gray-800">Credit Created</p>
        <p className="mt-1 text-sm dark:text-gray-100 text-gray-500">Nice.</p>
      </div>)
    })
  })

  const onSelect = (autosuggestJson) => {
    if (typeof autosuggestJson === 'string') {
      setValue('name', autosuggestJson, { shouldDirty: true, shouldValidate: true })
      setCredit(null)
    } else {
      setValue('name', autosuggestJson.name, { shouldDirty: true, shouldValidate: true })
      setCredit(autosuggestJson)
    }
  }

  const nullifyCredit = () => {
    setValue('name', null, { shouldDirty: true, shouldValidate: false })
    setCredit(null)
  }

  const onKeyDown = (event, data) => {
    const { keyCode } = event
    if (keyCode !== 13 && keyCode !== 188 && keyCode !== 9) { nullifyCredit() }
  }

  const name = watch('name')
  const createText = name ? `Create "${name}"` : 'Create'

  return <div className='mt-4 sm:mt-20  h-80vh'>
    <Card
      footer={
      <div className='flex flex-col items-end'>
        <div className='ml-2'>
          <PrimaryButton className='w-full' onClick={handleSubmit(onSubmit)} loading={loading} disabled={!name} text={createText} />
        </div>
      </div>
      } >
      <form className='grid grid-cols-4'>
        <div className="col-span-4 sm:col-span-2 sm:col-start-2">
          <label htmlFor="school_name" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">Name of Production </label>
          <div className="mt-1 flex flex-col rounded-md shadow-sm">
            <div className='relative'>
              { name && <pre onClick={nullifyCredit} onKeyDown={onKeyDown} {...{ tabIndex: 0 }} className='placeholder-gray-500 placeholder-opacity-25 dark:placeholder-opacity-50 block w-full focus:ring-cccpurple dark:bg-gray-900 dark:text-white focus:border-cccpurple sm:text-sm border-gray-300 rounded-md flex p-2 cursor-pointer items-center'>{name} <XIcon className='w-4 h-4' /></pre> }
              { !name && <CreditAutocomplete onSelect={onSelect} placeholder="Spiderman: Phone Home" /> }
              <input type="hidden" {...register('name', { required: true }) } />
              { errors.name && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
              </div> }
            </div>
          </div>
          <p className="text-xs text-gray-500 dark:text-gray-400 mt-1">Name of the production you are credited in. Press ENTER after typing in the name.</p>
          { errors.name && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
        </div>
        { credit?.id && <div className="col-span-4 sm:col-span-2 sm:col-start-2 my-3">
          <div className='text-yellow-500'>There is already a credit named "{credit.name}". Are you sure you want to create another one?</div>
          <div className="sm:flex sm:justify-between sm:items-center p-2 bg-white dark:bg-gray-700">
            <div className="flex-shrink-0 text-gray-500 dark:text-gray-100 hidden sm:block">
              <img className='h-16 w-16 rounded-full' src={credit.imageUrl} alt={credit.name} />
            </div>
            <div className="flex-1 px-0 md:px-4 md:py-0 pb-2 w-full truncate">
              <div className='flex flex-col truncate'>
                <span className="uppercase text-xs dark:text-gray-300 text-gray-500 mr-5">
                  {credit.year}
                </span>
                <Link to={`/credits/${credit.slug}`} className="font-medium text-cccblue">{credit.name}</Link>
                { credit.stage && <span className='flex justify-start'>
                  <span className={'flex-shrink-0 inline-block px-2 py-0.5 text-white bg-cccblue text-xs font-medium rounded-full flex cursor-pointer capitalize'}>
                    {credit.stage}
                  </span>
                </span> }
              </div>
            </div>
          </div>
        </div> }
      </form>
    </Card>
  </div>
}

const CreditImage = ({ credit }) => {
  const [url, setUrl] = useState(credit.imageUrl)
  const [uploaded, setUploaded] = useState(false)
  const { openModal, closeModal, isOpen } = useModal()
  const { getRequest } = useQuery()

  const reloadPublicImageURL = () => {
    getRequest(`/api/v3/credits/${credit.id}/image`, {}, (err, jsonData) => {
      if (err) { return }
      setUrl(jsonData.imageUrl)
    })
  }

  return <>
    <div className="mt-1 flex justify-center p-3 hover:opacity-75 cursor-pointer border-2 border-gray-300 border-dashed rounded-sm relative">
      { !uploaded && <span>
          <img className='h-200 w-200 rounded-sm' src={url} onClick={openModal} alt={credit.name} />
        </span>
        }
      { uploaded && <div className='relative h-200 w-200 rounded-sm flex items-center justify-center dark:bg-gray-700'>
        <img className='h-200 w-200 rounded-sm' src={url} onClick={openModal} alt={credit.name} />
        <div className='absolute h-20 w-20 top-8'>
          <Loading noMessage noLoadingMessage />
        </div>
      </div> }
    </div>
    <Modal isOpen={isOpen} closeModal={closeModal} >
      <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white"> Edit Image</h3>
      <div className='flex justify-center'>
        <div className="mt-2">
          <ImageUploadForm
            kind='Credit'
            objectId={credit.id}
            url={url}
            setUrl={setUrl}
            success={reloadPublicImageURL}
            didUpload={setUploaded}
          />
        </div>
      </div>
    </Modal>
  </>
}

const TheForm = ({ initialCredit }) => {
  const [, setToast] = useGlobalState('toast')
  const [currentUser] = useGlobalState('currentUser')
  const { getRequest } = useQuery()
  const [credit, setCredit] = useState(initialCredit)
  const [actuallyDelete, setActuallyDelete] = useState(false)
  const [parentCredits, setParentCredits] = useState([])
  const { putpostRequest } = useQuery()
  const { formState, register, unregister, watch, handleSubmit, setValue, getValues, setError, setFocus } = useForm({
   defaultValues: {
      name: initialCredit.name,
      year: initialCredit.year || new Date().getFullYear(),
      stage: initialCredit.stage,
      url: initialCredit.url,
      is_episode: initialCredit.isEpisode ? 'true' : 'false',
      parent_id: initialCredit.parentId,
      description: initialCredit.description
    }
  })
  const years = generateArrayOfYears()

  const { errors } = formState
  const [loading, setLoading] = useState(false)
  const description = getValues().description

  useEffect(() => {
    setFocus('name')
  }, [setFocus])

  useEffect(() => {
    getRequest(`/api/v3/credits?is_episode=false&not_id=${credit.id}`, {}, (err, jsonData) => {
      if (err) { /* handled in hook */ return }
      setParentCredits(jsonData.credits)

      setValue('parent_id', initialCredit.parentId, { shouldDirty: false, shouldValidate: false })
    })
  }, [])

  useEffect(() => {
    register('description')
    return () => {
      unregister('description')
    }
  }, [register])

  const handleUpdateFroala = (key, val) => {
    setValue(key, val, { shouldDirty: true, shouldValidate: true })
  }

  const confirmDelete = () => {
    putpostRequest(`/api/v3/credits/${credit.id}`, 'DELETE', { }, (err, jsonData) => {
      setLoading(false)
      if (err) { // 422 code
        if (typeof err !== 'string') { // 500 code on API
          Object.entries(err).forEach(([key, value]) => {
            setError(key, value)
          })
        }
        return
      }

      window.location.href = '/credits'
    })
  }

  const keyPress = (e) => {
    if (e.target.name === 'name' && e.keyCode === 13) { e.preventDefault() }
    if ((e.metaKey || e.ctrlKey) && e.keyCode === 13) {
      if (typeof (e.target.name) === 'undefined') {
        e.target.blur() // froala hack to make it update model so it can save properly
      }
      handleSubmit(onSubmit)()
    }
  }

  const onSubmit = debounce(300, (data) => {
    setLoading(true)
    console.log('submitting')

    putpostRequest(`/api/v3/credits/${credit.id}`, 'PATCH', { credit: data }, (err, jsonData) => {
      setLoading(false)
      if (err) { // 422 code
        if (typeof err !== 'string') { // 500 code on API
          Object.entries(err).forEach(([key, value]) => {
            setError(key, value)
          })
        }
        return
      }

      setCredit({ ...jsonData.credit })
      setToast(randomToastSuccess())
    })
  })

  const isEpisode = watch('is_episode')

  return <>
    <div className="md:grid md:grid-cols-4 md:gap-6">
      <div className='md:grid-cols-1'>
        <Card title="Image">
          <CreditImage credit={credit} />
        </Card>

        <div className='mt-4' />
        <Card title='Cover Image'>
          <CreditCoverImage credit={credit} />
        </Card>
      </div>
      <Card title="General Information"
        footer={
          <div className='flex justify-end space-x-8 items-center'>
            { currentUser.id === credit.userId && <>
            <button className='text-red-500' onClick={() => setActuallyDelete(true)}>Delete</button>
            { actuallyDelete && <button className='text-red-500' onClick={confirmDelete}>Really delete? Cannot be undone</button> }
            </> }
            <div className='flex flex-rows justify-end items-end'>
              <div className='flex flex-col items-end w-min ml-2'>
                <div className="hidden sm:block w-min text-xs border border-gray-200 dark:border-gray-800 rounded px-2 font-sans text-gray-500 dark:text-gray-400">⌘/ctrl+ENTER</div>
                <PrimaryButton className='w-full' onClick={handleSubmit(onSubmit)} loading={loading} text='Save' />
              </div>
            </div>
          </div>
        } >
        <form>
          <div className="grid sm:grid-cols-4 gap-6">
            <div className="col-span-4 sm:col-span-2">
              <label htmlFor="school_name" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">Name of Production </label>
              <div className="mt-1 flex flex-col rounded-md shadow-sm">
                <div className='relative'>
                  <input onKeyDown={keyPress} type="text" className={errors.name ? 'errors' : ''} {...register('name', { required: true }) } placeholder="Cowboy Bebop: Diggin' Gates" />
                  { errors.name && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                  </div> }
                </div>
              </div>
              <p className="text-xs text-gray-500 dark:text-gray-400 mt-1">Name of the production you are credited in.</p>
              { errors.name && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
            </div>

            <div className='col-span-4 sm:col-span-1'>
              <div className="col-span-6 sm:col-span-3">
                <label htmlFor="country" className="block text-sm font-medium text-gray-700 dark:text-gray-200 flex justify-between">
                  <span>Year</span>
                </label>
                <select
                  {...register('year')}
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm"
                >
                  {years.map((year, idx) => (
                    <option key={year}>{year}</option>
                  ))}
                </select>
              </div>
            </div>

            <div className='col-span-4 sm:col-span-1'>
              <div className="col-span-6 sm:col-span-3">
                <label htmlFor="stage" className="block text-sm font-medium text-gray-700 dark:text-gray-200 flex justify-between">
                  <span>Stage of Development</span>
                </label>
                <select
                  {...register('stage')}
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm"
                >
                  <option>Announced</option>
                  <option>Production</option>
                  <option>Completed</option>
                </select>
              </div>
            </div>

            <div className="col-span-4">
              <label htmlFor="school_name" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">URL </label>
              <div className="mt-1 flex flex-col rounded-md shadow-sm">
                <div className='relative'>
                  <input onKeyDown={keyPress} type="text" className={errors.url ? 'errors' : ''} {...register('url', { required: true }) } placeholder="https://www.youtube.com/castingcallclub" />
                  { errors.url && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                  </div> }
                </div>
              </div>
              <p className="text-xs text-gray-500 dark:text-gray-400 mt-1">URL where people can go to see the production</p>
              { errors.url && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
            </div>

            <div className='col-span-4 sm:col-span-2'>
              <div className="col-span-6 sm:col-span-3">
                <label htmlFor="stage" className="block text-sm font-medium text-gray-700 dark:text-gray-200 flex justify-between">
                  <span>Is this an episode of a larger series?</span>
                </label>
                <select
                  {...register('is_episode')}
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm"
                >
                  <option value='false'>no</option>
                  <option value='true'>yes</option>
                </select>
                { errors.is_episode && <div className='mt-2 text-sm text-red-600'>{errors.is_episode[0]}</div> }
              </div>
            </div>

            { isEpisode === 'true' && <>
              <div className='col-span-4 sm:col-span-2'>
                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="stage" className="block text-sm font-medium text-gray-700 dark:text-gray-200 flex justify-between">
                    <span>Which Series? <Link to='/credits/new' className='text-xs'>Create New Series</Link></span>
                  </label>
                  <select
                    {...register('parent_id')}
                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm"
                  >
                    <option value={''}>---</option>
                    {parentCredits.map((parent, idx) => (
                      <option key={`parcre${parent.id}`} value={parent.id}>{parent.name} {parent.year}</option>
                    ))}
                  </select>
                  { errors.parent_id && <div className='mt-2 text-sm text-red-600'>{errors.parent_id[0]}</div> }
                </div>
              </div>
            </> }

            <div className="col-span-4 sm:col-span-4">
              <div className='mt-4'>
                <label htmlFor="pricing" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                  Description
                </label>
                <p className="text-xs text-gray-500  dark:text-gray-400 mb-1"> Any extra info you want to add. Any other links or notes. </p>
                <div className="mt-1 shadow-sm w-full min-w-full">
                  <FroalaWrapper
                    model={description}
                    keyPress={keyPress}
                    updateModel={(val) => handleUpdateFroala('description', val)}
                  />
                </div>
                <i className="mt-2 flex justify-end text-xs text-gray-500 dark:text-gray-400">
                  (Tip: You can add URLs and @mention your followers and your credits.)
                </i>
              </div>
            </div>
          </div>
        </form>

        { errors.base && <div className='mt-2 text-sm text-red-600'>{errors.base[0]}</div> }
      </Card>

    </div>
  </>
}

const CreditEdit = () => {
  const { id } = useParams()
  const [credit, setCredit] = useState(null)
  const { getRequest } = useQuery()

  useEffect(() => {
    getRequest(`/api/v3/credits/${id}/edit`, {}, (err, jsonData) => {
      if (err) { /* handled in hook */ return }
      setCredit(jsonData.credit)
    })
  }, [id])

  if (!credit) { return <Loading /> }

  return <div>
    <CreditTabs credit={credit} />
    <TheForm initialCredit={credit} />
    </div>
}

export { CreditEdit, NewCreditForm }

TheForm.propTypes = {
  initialCredit: PropTypes.object.isRequired
}

CreditImage.propTypes = {
  credit: PropTypes.object.isRequired
}
