import React, { useState } from 'react'
import PropTypes from 'prop-types'
import useQuery from '@/hooks/useQuery'
import SVGIcon from '@/components/icon'

const ProjectFollowButton = ({ currentUserId, currentlyFollowing, projectId, roleId }) => {
  const [following, setFollowing] = useState(currentlyFollowing)
  const { putpostRequest } = useQuery()

  const toggleFollow = () => {
    setFollowing(!following)
    putpostRequest(`/api/v3/follows/${projectId}/follow_project`, 'POST', {}, (err, jsonData) => {
      if (err) { return console.log(err) }
      // noop
    })
  }

  if (following) {
   return <button onClick={toggleFollow} className="w-full h-full gap-x-1 flex items-center justify-center px-4 py-3 border text-sm text-cccblue font-medium rounded-md shadow-sm bg-cccpurple bg-opacity-20">
      <SVGIcon name='icon-followers' className='h-6 w-6' />
      <span>Following!</span>
    </button>
  }

  return <button onClick={toggleFollow} className="w-full h-full gap-x-1 flex items-center justify-center px-4 py-3 border text-sm text-cccblue font-medium rounded-md shadow-sm bg-white dark:bg-gray-900">
      <SVGIcon name='icon-followers' className='h-6 w-6' />
      <span>Follow Updates</span>
    </button>
}

ProjectFollowButton.propTypes = {
  currentUserId: PropTypes.number.isRequired,
  currentlyFollowing: PropTypes.bool.isRequired,
  projectId: PropTypes.number,
  roleId: PropTypes.number
}

export default ProjectFollowButton
