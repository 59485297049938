import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { InformationCircleIcon } from '@heroicons/react/solid'

import { TippyTooltip } from '@/shared/ToolTip'
import { debounce } from 'throttle-debounce'
import { useGlobalState } from '@/state'
import useQuery from '@/hooks/useQuery'
import { randomToastSuccess } from '@/shared/Toast'
import PrimaryButton from '@/shared/Buttons/Primary'
import Card from '@/shared/Card'

const ProfileSocialMedia = () => {
  const [user, setUser] = useGlobalState('userContainerUser')
  const [, setToast] = useGlobalState('toast')
  const { putpostRequest } = useQuery()
  const { formState, reset, register, handleSubmit, getValues, setError } = useForm({
   defaultValues: {
     twitter: user.twitter,
     website: user.website,
     facebook: user.facebook,
     youtube: user.youtube,
     soundcloud: user.soundcloud,
     linkedin: user.linkedin,
     tumblr: user.tumblr,
     patreon: user.patreon,
     discord: user.discord,
     skype: user.skype,
     imdb: user.imdb
   }
  })

  const { isDirty } = formState
  const [loading, setLoading] = useState(false)

  const onSubmit = debounce(300, data => {
    setLoading(true)
    putpostRequest(`/api/v3/profiles/${user.profileId}`, 'PATCH', { profile: data }, (err, jsonData) => {
      setLoading(false)
      if (err) { // 422 code
        Object.entries(err).forEach(([key, value]) => {
          setError(key, value)
        })
        return
      }

      setUser({ ...user, ...getValues() })

      reset({ ...getValues() })

      setToast(randomToastSuccess())
    })
  })

  return (
    <div>
      <div className="md:grid md:grid-cols-4 md:gap-6 space-x-2">
        <div className="md:col-span-1">
          <div className="px-4 sm:px-0">
            <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-100">Social Media</h3>
            <p className="mt-1 text-sm text-gray-600 dark:text-gray-300">
              This information will be displayed publicly so be careful what you share.
            </p>
          </div>
        </div>
        <div className="mt-5 md:mt-0 md:col-span-3">
          <Card footer={<PrimaryButton onClick={handleSubmit(onSubmit)} loading={loading} disabled={!isDirty} isDirty={isDirty} text="Save" />}>
            <form>
              <div className="grid grid-cols-3 gap-6">
                <div className="col-span-3 sm:col-span-1">
                  <label htmlFor="company_website" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                    Twitter
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 dark:bg-gray-900 text-gray-500 text-sm">
                      @
                    </span>
                    <input type="text" {...register('twitter')} placeholder="CastingCallClub" />
                  </div>
                </div>
                <div className="col-span-3 sm:col-span-1">
                  <label htmlFor="company_website" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                    Website
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <input type="text" {...register('website')} placeholder="https://" />
                  </div>
                </div>
                <div className="col-span-3 sm:col-span-1">
                  <label htmlFor="company_website" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                    YouTube URL
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <input type="text" {...register('youtube')} placeholder="https://" />
                  </div>
                </div>
                <div className="col-span-3 sm:col-span-1">
                  <label htmlFor="company_website" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                    Facebook URL
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <input type="text" {...register('facebook')} placeholder="https://" />
                  </div>
                </div>
                <div className="col-span-3 sm:col-span-1">
                  <label htmlFor="company_website" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                    Soundcloud URL
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <input type="text" {...register('soundcloud')} placeholder="https://" />
                  </div>
                </div>
                <div className="col-span-3 sm:col-span-1">
                  <label htmlFor="company_website" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                    LinkedIn URL
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <input type="text" {...register('linkedin')} placeholder="https://" />
                  </div>
                </div>
                <div className="col-span-3 sm:col-span-1">
                  <label htmlFor="company_website" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                    Tumblr URL
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <input type="text" {...register('tumblr')} placeholder="https://" />
                  </div>
                </div>
                <div className="col-span-3 sm:col-span-1">
                  <label htmlFor="patreon" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">
                    <span>Paid Subscription URL</span>
                    <TippyTooltip content={<> If you accept paid subscriptions from Patreon, Ko-fi, BuyMeACoffee, etc, this is the place.</>}>
                      <span onClick={(e) => e.preventDefault() }><InformationCircleIcon className="h-5 w-5 text-gray-400" aria-hidden="true" /></span>
                    </TippyTooltip>
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <input type="text" {...register('patreon')} placeholder="https://" />
                  </div>
                </div>
                <div className="col-span-3 sm:col-span-1">
                  <label htmlFor="company_website" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                    Discord Handle
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <input type="text" {...register('discord')} placeholder="buf#0232" />
                  </div>
                </div>
                <div className="col-span-3 sm:col-span-1">
                  <label htmlFor="company_website" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                    Skype
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <input type="text" {...register('skype')} placeholder="CastingCallClub" />
                  </div>
                </div>
                <div className="col-span-3 sm:col-span-1">
                  <label htmlFor="company_website" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                    IMDB
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <input type="text" {...register('imdb')} placeholder="https://" />
                  </div>
                </div>

              </div>
            </form>
          </Card>
        </div>
      </div>
    </div>
  )
}

export default ProfileSocialMedia
