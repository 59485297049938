import React, { useState, useEffect, useRef } from 'react'
import { useDebouncedEffect } from '@/utils/useDebouncedEffect'
import Pagination from '@/shared/Pagination'
import Loading from '@/shared/Loading'
import { EyeIcon } from '@heroicons/react/solid'
import useQuery from '@/hooks/useQuery'
import Card from '@/shared/Card'
import PublicProjectSubmissionRow from '@/pages/PublicProjectSubmissionRow'

const NoResults = () => {
  return (
    <span>
      <div className="relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccblue" >
        <EyeIcon className="mx-auto h-12 w-12 text-gray-400" />
        <span className="mt-2 block text-sm font-medium text-gray-900 dark:text-gray-300"> This person hasn't listed any submissions as visible on their profile.</span>
      </div>
    </span>
  )
}

const PublicProjectSubmissionsIndex = (props) => {
  const [submissions, setSubmissions] = useState([])
  const [voteIds, setVoteIds] = useState([])
  const { roleId } = props
  const [meta, setMeta] = useState({})
  const { getRequest } = useQuery()
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [loadedOnce, setLoadedOnce] = useState(false)
  const [contains, setContains] = useState('')
  const scrollRef = useRef(null)

  useEffect(() => {
    document.getElementById(`role-submissions-button-${roleId}`).addEventListener('click', toggleShow)
  }, [])

  const toggleShow = () => {
    console.log('clicked')
    setLoadedOnce(true)
    const el = document.getElementById(`role-submissions-${roleId}`)
    if (el.classList.contains('hidden')) {
      el.classList.remove('hidden')
    } else {
      el.classList.add('hidden')
    }
  }

  useDebouncedEffect(() => { fetchData() }, 200, [contains])
  useEffect(() => { fetchData() }, [page, loadedOnce])

  const fetchData = () => {
    if (!loadedOnce) { return }

    setLoading(true)
    const data = {
      contains: contains,
      page: contains.length > 0 ? 1 : page
    }
    getRequest(`/api/v3/roles/${roleId}/public_submissions`, data, (err, jsonData) => {
      setLoading(false)
      if (err) { /* hooks */ return }

      setSubmissions(jsonData.submissions)
      setVoteIds(jsonData.voteIds)
      setMeta(jsonData.meta)
    })
  }

  if (document.getElementById(`role-submissions-${roleId}`).classList.contains('hidden')) { return null }

  return <>
    <Card light title='Public Submissions'>
      <>
        { !loading && submissions.length === 0 && <NoResults /> }
        <div className='pb-5' ref={scrollRef}>
          <input name='contains' type="text" onChange={(e) => setContains(e.target.value)}
            className="shadow-sm focus:ring-cccpurple dark:bg-gray-900 dark:text-white focus:border-cccpurple block w-full sm:text-sm border-gray-300 rounded-md mb-2"
            placeholder="Search by project name or role name. ex: How Zelda Got Her Groove Back"
          />
          { submissions.length >= 0 && <Pagination meta={meta} callback={setPage} scrollRef={scrollRef} /> }
        </div>
        <ul className="space-y-3">
          {submissions.map((sub) => (
            <PublicProjectSubmissionRow key={`pubsub${sub.id}`} submission={sub} voteIds={voteIds} image='user' commentsOpen={false} />
          ))}
        </ul>
        { loading && <Loading /> }
      </>
    </Card>
  </>
}

export default PublicProjectSubmissionsIndex
