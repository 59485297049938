import React, { useState, useEffect, useRef } from 'react'
import { useDebouncedEffect } from '@/utils/useDebouncedEffect'
import Pagination from '@/shared/Pagination'
import Loading from '@/shared/Loading'
import { SidebarAd, MobileAd, SidebarEducationAd, MobileEducationAd, SidebarVideomancyAd, MobileVideomancyAd} from '@/Layout/Ads'
import { useGlobalState } from '@/state'
import { EyeIcon, SortAscendingIcon } from '@heroicons/react/solid'
import useQuery from '@/hooks/useQuery'
import useDropdown from '@/hooks/useDropdown'
import Card from '@/shared/Card'
import RoleUserRow from '@/pages/MyRoles/RoleUserRow'
import Tabs from '@/shared/Tabs'

const NoResults = () => {
  return (
    <span>
      <div className="relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccblue" >
        <EyeIcon className="mx-auto h-12 w-12 text-gray-400" />
        <span className="mt-2 block text-sm font-medium text-gray-900 dark:text-gray-300"> This person hasn't listed any submissions as visible on their profile.</span>
      </div>
    </span>
  )
}

const MyRolesIndex = () => {
  const [currentUser] = useGlobalState('currentUser')
  const user = currentUser
  const [meta, setMeta] = useState({})
  const [roleUsers, setRoleUsers] = useState([])
  const { getRequest } = useQuery()
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(1)
  const [contains, setContains] = useState(null)
  const [talentStatuses, setTalentStatuses] = useState([])
  const [order, setOrder] = useState('updated_at')
  const [talentStatus, setTalentStatus] = useState('all')
  const [permissions, setPermissions] = useState('all')
  const [searchOpen, setSearchOpen] = useState(false)
  const scrollRef = useRef(null)
  const dropdownRef = useRef(false)
  const { dropdownOpen, setDropdownOpen, openDropdown } = useDropdown(dropdownRef)

  useDebouncedEffect(() => {
    setDropdownOpen(false)
    fetchData(true)
  }, 200, [contains, order, permissions, talentStatus])

  useEffect(() => { fetchData() }, [page])

  const fetchData = (newPage) => {
    setLoading(true)
    const data = {
      contains: contains,
      order_by: order,
      permissions: permissions,
      talent_status: talentStatus,
      page: newPage ? 1 : page
    }
    getRequest('/api/v3/role_users', data, (err, jsonData) => {
      setLoading(false)
      if (err) { /* hooks */ return }

      setRoleUsers(jsonData.roleUsers)
      setTalentStatuses(jsonData.talentStatuses)
      setMeta(jsonData.meta)
    })
  }
  const handleChange = (e) => {
    if (e.target.name === 'order') {
      setOrder(e.target.value)
    }
    if (e.target.name === 'permissions') {
      setPermissions(e.target.value)
    }
    if (e.target.name === 'contains') {
      setContains(e.target.value)
    }
  }

  const changeTab = (tab) => { setTalentStatus(tab) }

  const tablist = [
    { name: 'All My Roles', count: null, changeValue: 'all' },
    { name: 'Incompleted', count: talentStatuses?.incompleted, changeValue: 'incompleted' },
    { name: 'Completed', count: talentStatuses?.completed, changeValue: 'completed' }
  ]

  const orderMap = {
    updated_at: 'Latest Activity',
    created_at: 'Created At',
    played_count: 'Listened To Count',
    cached_votes_up: 'Most Upvoted',
    comments_count: 'Most Commented'
  }

  return <>
    <div className='flex flex-col gap-y-1'>
      <MobileVideomancyAd />
      <MobileEducationAd />
      <MobileAd adID={109} />
    </div>
    <div className='lg:block lg:flex lg:gap-x-2'>
      <div id='my_roles' className='max-w-6xl w-full'>
        <Card title='My Roles'>
          <>
            <div ref={scrollRef}>
              <div className='flex justify-between items-center'>
                <Tabs statuses={talentStatuses} tablist={tablist} currentTab={talentStatus} changeTab={changeTab} />
                <div className='flex space-x-2 items-center'>
                  <div className='relative flex items-center'>
                    <button onClick={openDropdown} className='py-1 px-2 text-xs sm:text-sm text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-cccblue focus:ring-2 focus:ring-blue-700 focus:text-cccblue dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white flex space-x-1 items-center w-48'>
                      <span className="sr-only" hidden>Open options</span>
                      <SortAscendingIcon className="h-4 w-4 sm:h-5 sm:w-5" aria-hidden="true" />
                      <span className='truncate'>{orderMap[order]}</span>
                    </button>
                    { dropdownOpen && <div ref={dropdownRef} tabIndex="-1" className={`absolute right-0 z-30 ${dropdownOpen ? '' : 'hidden opacity-0 scale-0'} py-2 overflow-y-auto transition origin-top-right bg-white border border-gray-900 dark:border-gray-100 rounded-md shadow-md dark:bg-gray-800 top-6 max-h-80`}>
                      <div onClick={() => setOrder('updated_at')} className='text-gray-700 dark:text-gray-100 flex px-4 py-2 text-sm cursor-pointer hover:text-cccblue-alt dark:hover:text-cccblue-alt' >{orderMap.updated_at}</div>
                      <div onClick={() => setOrder('created_at')} className='text-gray-700 dark:text-gray-100 flex px-4 py-2 text-sm cursor-pointer hover:text-cccblue-alt dark:hover:text-cccblue-alt' >{orderMap.created_at}</div>
                    </div> }
                  </div>
                </div>
              </div>
              { searchOpen && <div className='flex flex-col sm:flex-row mb-2'>
                <input name='contains' type="text" onChange={handleChange}
                  className="shadow-sm focus:ring-cccpurple dark:bg-gray-900 dark:text-white focus:border-cccpurple block w-full sm:text-sm border-gray-300 rounded-md"
                  placeholder="Search by project name or role name. ex: Goku"
                />
              </div> }
              { roleUsers.length >= 0 && <div className='mt-2'><Pagination meta={meta} callback={setPage} scrollRef={scrollRef} /></div> }
            </div>
            { !loading && roleUsers.length === 0 && <NoResults /> }
            { loading && <Loading /> }
            <ul className="space-y-3">
              {roleUsers.map((ru) => (
                <RoleUserRow key={`roleur${ru.id}`} roleUser={ru} />
              ))}
            </ul>
            { roleUsers.length >= 0 && <div className='mt-2'><Pagination meta={meta} callback={setPage} scrollRef={scrollRef} /></div> }
          </>
        </Card>
      </div>
      <div className='flex flex-col gap-y-1'>
        <SidebarVideomancyAd />
        <SidebarEducationAd />
        <SidebarAd adID={108} />
      </div>
    </div>
  </>
}

export default MyRolesIndex
