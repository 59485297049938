import React, { useState, useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'
import { useStripe, useElements, CardElement, Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { CreditCardIcon } from '@heroicons/react/solid'

import PrimaryButton from '@/shared/Buttons/Primary'
import useQuery from '@/hooks/useQuery'
import Loading from '@/shared/Loading'

const options = {
  hidePostalCode: true,
  style: {
    base: {
      'fontSize': '16px',
      'color': '#424770',
      'letterSpacing': '0.025em',
      'fontFamily': 'Source Code Pro, monospace',
      '::placeholder': {
        color: '#aab7c4'
      }
    },
    invalid: {
      color: '#9e2146'
    }
  }
}

const PaymentMethodIndex = (props) => {
  const { putpostRequest } = useQuery()
  const [paymentMethods, setPaymentMethods] = useState(props.paymentMethods)
  const [newPaymentMethod, setNewPaymentMethod] = useState(true)
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState(null)
  const [stripeReady, setStripeReady] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(props.errorMessage)
  const { reloadPaymentMethodsFromStripe } = props

  const stripe = useStripe()
  const elements = useElements()

  const assignDefaultPaymentMethod = () => {
    const defaultPaymentMethod = paymentMethods.find(paymentMethod => paymentMethod.defaultPaymentMethod)
    if (defaultPaymentMethod) { setSelectedPaymentMethodId(defaultPaymentMethod.id) }
  }

  useEffect(() => {
    if (paymentMethods.length > 0) { setNewPaymentMethod(false) }
    assignDefaultPaymentMethod()
  }, [paymentMethods])

  useEffect(() => {
    if (newPaymentMethod === true) {
      setSelectedPaymentMethodId(null)
    } else {
      assignDefaultPaymentMethod()
    }
  }, [newPaymentMethod])

  useEffect(() => {
    setStripeReady(Boolean(selectedPaymentMethodId))
  }, [selectedPaymentMethodId])

  const onNewPaymentMethodChange = (e) => {
    if (e.error) {
      setErrorMessage('This appears to be an invalid card number.')
    } else {
      setErrorMessage(null)
      if (e.complete) {
        setStripeReady(true)
      } else {
        setStripeReady(false)
      }
    }
  }

  const createStripePaymentMethod = async () => {
    setLoading(true)
    const stripePayload = await stripe.createPaymentMethod({ type: 'card', card: elements.getElement(CardElement) })
    setLoading(false)

    if (stripePayload.error || !stripePayload.paymentMethod || !stripePayload.paymentMethod.id) {
      // spam slack channel with stripePayload.error.message, current_user.id
      setErrorMessage(stripePayload.error.message)
      return
    }
    const newCard = {
      ...stripePayload.paymentMethod.card,
      id: stripePayload.paymentMethod.id,
      ccExpYear: stripePayload.paymentMethod.card.exp_year,
      ccExpMonth: stripePayload.paymentMethod.card.exp_month
    }
    setPaymentMethods([...paymentMethods, newCard])
    return stripePayload.paymentMethod.id
  }

  const changeCard = async () => {
    setLoading(true)
    setErrorMessage(null)
    const paymentMethodId = selectedPaymentMethodId || await createStripePaymentMethod()
    /* The createStripePaymentMethod method will handle error messaging if it fails to create a payment method */
    setLoading(false)
    if (paymentMethodId) {
      setSelectedPaymentMethodId(paymentMethodId)
      markAsDefault(paymentMethodId)
    }
  }

  const markAsDefault = async (paymentMethodId) => {
    setLoading(true)
    const data = {
      payment_method_id: paymentMethodId
    }

    putpostRequest('/api/v3/stripe/subscriptions/update_default_payment_method', 'POST', { subscriptions: data }, async (err, response) => {
      console.log(response)
      setLoading(false)
      if (err) { setErrorMessage(err) }
      if (response && !response.requiresAction) {
        reloadPaymentMethodsFromStripe()
      }
    })
  }

  return <>
    <label htmlFor="card-element" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">
      Credit or Debit Card
    </label>
    <div className='mt-1'>
      { !newPaymentMethod && !loading && <>
        { paymentMethods.length > 0 && <>
          <ul role="list" className="flex flex-col">
            { paymentMethods.map((pm, idx) => (
              <li key={pm.id} onClick={() => setSelectedPaymentMethodId(pm.id)} className="relative cursor-pointer">
                <dl className="mt-3 p-3 bg-gray-50 hover:bg-gray-100 dark:bg-gray-750 dark:hover:bg-gray-800 text-gray-600 dark:text-gray-300 border-2 rounded-md">
                  <dd className="flex flex-col sm:flex-row sm:items-center text-sm justify-start sm:justify-between font-medium sm:mr-6">
                    <div className='flex justify-start'>
                      <CreditCardIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                      <span className='whitespace-nowrap text-sm capitalize'>{pm.brand} ending in <strong>{pm.last4}</strong></span>
                      <span className="text-sm font-medium ml-3">{pm.ccExpMonth}/{pm.ccExpYear}</span>
                    </div>
                    { (selectedPaymentMethodId === pm.id) && <span className="inline-flex items-center px-3 rounded-full text-sm font-medium bg-green-100 text-green-800">Selected</span> }
                  </dd>
                </dl>
              </li>
            ))}
          </ul>
          <div className='cursor-pointer text-cccblue hover:text-cccblue-light dark:hover:text-cccblue-light text-sm py-3' onClick={() => setNewPaymentMethod(true)}>Add New Payment Method</div>
        </> }
      </> }
      { newPaymentMethod && <>
        <CardElement id='card-element' options={options} onChange={onNewPaymentMethodChange} />
        { paymentMethods.length > 0 && <>
          <div className='cursor-pointer text-cccblue hover:text-cccblue-light text-sm py-3' onClick={() => setNewPaymentMethod(false)}>Use A Saved Card</div>
        </> }
      </>}
      { errorMessage && <div className='mt-2 text-sm text-red-600'>{errorMessage}</div> }
    </div>

    <div className="flex justify-start items-center">
      <PrimaryButton onClick={changeCard} loading={loading} disabled={!stripeReady} text="Make Default Payment Method" />
    </div>
    <span className='text-xs text-gray-400 dark:text-gray-400'>We use Stripe to securely process transactions</span>
  </>
}

export default function ChangeCardForm () {
  const stripePromise = window.stripeKey ? loadStripe(window.stripeKey) : undefined
  const [, setErrorMessage] = useState(null)
  const { getRequest } = useQuery()
  const [state, setState] = useReducer(
    (state, newState) => (
      { ...state, ...newState }), {
        paymentMethods: [],
        loadingPaymentMethods: true,
        existingSubscription: null
      }
    )
  const { paymentMethods, loadingPaymentMethods } = state

  useEffect(() => {
    fetchPaymentMethods()
  }, [])

  const fetchPaymentMethods = () => {
    getRequest('/api/v3/stripe/payment_methods', {}, (err, jsonData) => {
      if (err) {
        setErrorMessage(err.errors)
        setState({ loadingPaymentMethods: false })
      } else {
        setErrorMessage(null)
        setState({ paymentMethods: jsonData.paymentMethods, loadingPaymentMethods: false })
      }
    })
  }

  if (loadingPaymentMethods) {
    return <Loading full />
  }

  return (
    <Elements stripe={stripePromise}>
      <PaymentMethodIndex paymentMethods={paymentMethods} reloadPaymentMethodsFromStripe={fetchPaymentMethods} />
    </Elements>
  )
}

PaymentMethodIndex.propTypes = {
  paymentMethods: PropTypes.array,
  reloadPaymentMethodsFromStripe: PropTypes.func,
  errorMessage: PropTypes.string
}
