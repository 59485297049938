import React from 'react'
import { Switch, Route, Link } from 'react-router-dom'

import Breadcrumbs from '@/Layout/Breadcrumbs'
import PageTitle from '@/Layout/PageTitle'

import AchievementsIndex from '@/pages/AchievementsIndex'

function breadCrumbs() {
  const crumbs = [
    { to: '/achievements', name: 'Achievements' }
  ]
  return crumbs
}

function pageTitle() {
  //const location = useLocation()
  //const activePath = location.pathname

  const titleInfo = {
    title: 'Achievements',
    buttons: []
  }

  return titleInfo
}

export default function AchievementsContainer(props) {
  const crumbs = breadCrumbs()
  const titleInfo = pageTitle()

  return <>
    <Breadcrumbs>
      { crumbs.map((crumb, index) => (
        <Link key={`${index}${crumb.name}`} to={crumb.to} className="text-xs sm:text-sm text-gray-500 hover:text-gray-700 dark:text-gray-100">
          {crumb.name}
        </Link>
      ))}
    </Breadcrumbs>
    <PageTitle pagetitle={titleInfo.title}>
      {titleInfo.buttons}
    </PageTitle>
    <Switch>
      <Route {...props} exact path={['/achievements']}>
        <AchievementsIndex />
      </Route>
    </Switch>
  </>
}
