import React, { Fragment, useState, useEffect } from 'react'
import useQuery from '@/hooks/useQuery'

import classNames from '@/utils/classNamesLocal'
import { SVGIcon } from '@/components/icon'
import Card from '@/shared/Card'
import Loading from '@/shared/Loading'

// key matches jbuilder file
const userMetaList = {
  'voiceActor': { image: 'voiceactor', text: 'Voice Actor', to_db: 'voice_actor' },
  'liveActor': { image: 'actor', text: 'Actor', to_db: 'live_actor' },
  'singer': { image: 'singer', text: 'Singer', to_db: 'singer' },
  'artist': { image: 'artist', text: 'Artist', to_db: 'artist' },
  'animator': { image: 'animator', text: 'Animator', to_db: 'animator' },
  'videoEditor': { image: 'video-editor', text: 'Video Editor', to_db: 'video_editor' },
  'musicComposer': { image: 'musiccomposer', text: 'Music Composer', to_db: 'music_composer' },
  'audioEngineer': { image: 'audio-engineer', text: 'Audio Engineer', to_db: 'audio_engineer' },
  'writer': { image: 'writer', text: 'Writer', to_db: 'writer' },
  'director': { image: 'director', text: 'Director', to_db: 'director' },
  'agent': { image: 'agent', text: 'Agent', to_db: 'agent' },
  'producer': { image: 'producer', text: 'Producer', to_db: 'producer' }
}

const OnboardingUserMeta = () => {
  const { getRequest, putpostRequest } = useQuery()
  const [userMeta, setUserMeta] = useState({})
  const [initialLoading, setInitialLoading] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => { fetchData() }, [])

  const fetchData = () => {
    setInitialLoading(true)
    getRequest('/api/v3/user_metas', {}, (err, jsonData) => {
      setInitialLoading(false)
      if (err) { /* hook */ return }
      setUserMeta(jsonData.userMeta)
    })
  }

  const toggleUserMeta = (key) => {
    const val = userMeta[key]

    setLoading(key)
    const data = {
      [userMetaList[key].to_db]: !val
    }
    putpostRequest('/api/v3/user_metas/update_meta', 'POST', { user_meta: data }, (err, jsonData) => {
      setLoading(false)
      if (err) { /* hook */ return }

      setUserMeta({ ...userMeta, [key]: !val })
    })
  }

  if (initialLoading) { return <Loading /> }

  return (<>
    <div className='text-gray-800 dark:text-gray-200'>
      <div className="md:grid md:grid-cols-3 md:gap-6">
        <div className="md:col-span-1">
          <div className="px-4 sm:px-0">
            <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-100">I am a....</h3>
          </div>
        </div>
        <Card>
          <ul className="mt-3 grid grid-cols-2 gap-5 sm:gap-6 sm:grid-cols-2 lg:grid-cols-4">
            { Object.keys(userMetaList).map((key, idx) => (
              <li key={`role${key}`} className="col-span-1 flex shadow-sm rounded-md">
                { loading === key && <Loading noMessage noLoadingMessage /> }
                { loading !== key && <>
                  <button onClick={() => toggleUserMeta(key)}
                    className={classNames(userMeta[key] ? 'bg-cccorange bg-opacity-20 hover:bg-cccorange-alt text-gray-900 dark:text-white' : 'dark:text-gray-200 dark:bg-gray-700 bg-gray-100 hover:bg-gray-200 dark:hover:bg-gray-750 ', 'w-full inline-flex items-center px-4 py-2 text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccpurple')}>
                  <SVGIcon name={userMetaList[key].image} width='40' height='40' autoDark/> <span className='ml-2'>{userMetaList[key].text}</span>
                </button>
                </> }
              </li>
            ))}
          </ul>
        </Card>
      </div>
    </div>
  </>)
}

export default OnboardingUserMeta

OnboardingUserMeta.propTypes = { }
