import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { XIcon, ExclamationCircleIcon, CakeIcon } from '@heroicons/react/solid'
import { debounce } from 'throttle-debounce'
import { useForm } from 'react-hook-form'

import useQuery from '@/hooks/useQuery'
import { useGlobalState } from '@/state'
import Card from '@/shared/Card'
import PrimaryButton from '@/shared/Buttons/Primary'
import TipTapPrivatePost from '@/shared/TipTapPrivatePost'
import UserAutocomplete from '@/shared/UserAutocomplete'

const PrivatePostForm = () => {
  const [, setToast] = useGlobalState('toast')
  const [currentUser] = useGlobalState('currentUser')
  const [customError, setCustomError] = useState(null)
  const [showUpgrade, setShowUpgrade] = useState(false)
  const history = useHistory()
  const [users, setUsers] = useState([])
  const { putpostRequest } = useQuery()
  const { formState, register, unregister, handleSubmit, setValue, getValues, setError } = useForm({
   defaultValues: {
     title: null,
     body: null
    }
  })

  const { errors } = formState
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    register('body', { required: true })
    register('recipient_ids', { required: true })
    return () => {
      unregister('body')
      unregister('recipient_ids')
    }
  }, [register])

  const onSubmit = debounce(300, (data) => {
    setLoading(true)

    const formData = {
      post: {
        body: data.body,
        recipient_ids: data.recipient_ids,
        title: data.title
      }
    }

    putpostRequest('/api/v3/posts', 'POST', formData, (err, jsonData) => {
      setLoading(false)
      if (err) { // 422 code
        if (typeof err === 'string') { // 422 custom error
          setCustomError(err)
        }
        if (typeof err !== 'string') { // 500 code on API
          Object.entries(err).forEach(([key, value]) => {
            setError(key, value)
          })
        }
        return
      }

      history.push(`/private_messages/${jsonData.id}`)

      setToast(<div className="ml-3 w-0 flex-1 pt-0.5">
        <p className="text-sm font-medium dark:text-white text-gray-800">Message sent!</p>
        <p className="mt-1 text-sm dark:text-gray-100 text-gray-500">This message will be available for you to see in your Messages</p>
      </div>)
    })
  })

  const keyPress = (e) => {
    if (e.target.name === 'name' && e.keyCode === 13) { e.preventDefault() }
    if ((e.metaKey || e.ctrlKey) && e.keyCode === 13) {
      handleSubmit(onSubmit)()
    }
  }

  const handleUpdateTipTap = (key, val) => {
    setValue(key, val, { shouldDirty: true, shouldValidate: true })
  }

  useEffect(() => {
    const userIds = users.map(user => user.id)
    setValue('recipient_ids', userIds.join(','), { shouldDirty: true, shouldValidate: false })
    if (userIds.length > 1 && !currentUser.canStartGroupMessages) {
      setShowUpgrade(true)
    } else {
      setShowUpgrade(false)
    }
  }, [users])

  const addUser = (autosuggestUser) => {
    setUsers([...users, ...[autosuggestUser]])
  }
  const removeUser = (userId) => {
    const userList = users.filter(user => user.id !== userId)
    setUsers(userList)
  }

  const body = getValues().body

  return <div className='max-w-xl mx-auto'>
    <Card title="Direct Message"
      footer={
        <div className='flex flex-rows justify-end items-end'>
          <div className='flex flex-col items-end w-min ml-2'>
            <div className="hidden sm:block w-min text-xs border border-gray-200 dark:border-gray-800 rounded px-2 font-sans text-gray-500 dark:text-gray-400">⌘/ctrl+ENTER</div>
            <PrimaryButton className='w-full' onClick={handleSubmit(onSubmit)} loading={loading} text='Save' />
          </div>
        </div>
      } >
      <form onKeyDown={keyPress}>
        <div className="grid sm:grid-cols-4 gap-6">
          <div className="col-span-4 sm:col-span-3">
            <label htmlFor="school_name" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">Start a Direct Message with </label>
            <UserAutocomplete addUser={addUser} placeholder='Buford'/>
            { errors.recipient_ids && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
            { users.length > 0 && <>
              <div className="mt-3 flex sm:items-center flex-wrap">
                { users.map((user) => (
                  <div key={`userpostpri${user.id}`} onClick={() => removeUser(user.id)} className='bg-white dark:bg-gray-800 text-gray-700 dark:text-gray-200 relative flex items-start p-2 space-x-3 cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-900'>
                    <img className="h-10 w-10 rounded-sm bg-gray-200 flex items-center justify-center ring-4 ring-cccorange" src={user.imageUrl} alt="" />
                    <div className="min-w-0 flex-1">
                      <div className="text-sm flex flex-col">
                        <div className="font-medium">{user.displayName}</div>
                        <div className="">@{user.username}</div>
                      </div>
                    </div>
                    <XIcon className='w-4 h-4' />
                  </div>
                ))}
              </div>
            </> }
          </div>
          <div className="col-span-4 sm:col-span-3">
            <label htmlFor="school_name" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">Title </label>
            <div className="mt-1 flex flex-col rounded-md shadow-sm">
              <div className='relative'>
                <input onKeyDown={keyPress} type="text" className={errors.subject ? 'errors' : ''} {...register('title', { required: true }) } placeholder="Hello operator" />
                { errors.title && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                </div> }
              </div>
            </div>
            { errors.title && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
          </div>

          <div className="col-span-4 sm:col-span-4">
            <div className='mt-4'>
              <label htmlFor="pricing" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                The Actual Message
              </label>
              <div className="">
                <TipTapPrivatePost html={body} updateHtml={(val) => handleUpdateTipTap('body', val)} />
              </div>
              { errors.body && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
            </div>
          </div>
        </div>
      </form>
      { customError && <div className='mt-2 text-sm text-red-600'>{customError}</div> }
      { showUpgrade && <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 m-4">
        <div className="flex">
          <div className="flex-shrink-0 text-yellow-800">
            <CakeIcon className='h-5 w-5' />
          </div>
          <div className="ml-3">
            <p className="text-sm text-yellow-700"> Hi! Only premium members can <b>start</b> a group message. Anyone can be in a group message, however.</p>
            <p className="text-sm text-yellow-700"> Please support Casting Call Club by <a href='/subscriptions'>upgrading</a>.</p>
          </div>
        </div>
      </div> }
    </Card>
  </div>
}

export default PrivatePostForm

PrivatePostForm.propTypes = {
  // recipient: PropTypes.object.isRequired
}
