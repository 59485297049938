import React, { useState, useEffect, useRef } from 'react'
import { debounce } from 'throttle-debounce'
import { useGlobalState } from '@/state'
import { useForm } from 'react-hook-form'
import DefaultButton from '@/shared/Buttons/Default'
import PrimaryButton from '@/shared/Buttons/Primary'
import TipTapWysiwyg from '@/shared/TipTapWysiwyg'
import { EyeIcon, ExclamationCircleIcon } from '@heroicons/react/solid'
import useModal from '@/hooks/useModalV2'
import Loading from '@/shared/Loading'
import useQuery from '@/hooks/useQuery'
import Modal from '@/shared/Modal'
import Pagination from '@/shared/Pagination'
import Card from '@/shared/Card'
import ProjectUpdateEdit from '@/pages/ManageProjects/ProjectUpdateEdit'

const UpdateRow = ({update}) => {
  const { openModal, closeModal, isOpen, willClose } = useModal()
  const { getRequest, putpostRequest } = useQuery()
  const [ deleted, setDeleted ] = useState(false)
  if (deleted) return null

  return <li className='bg-gray-50 dark:bg-gray-700 rounded-md p-2 flex justify-between items-center'>
    <span className='text-gray-900 dark:text-gray-200'>{update.title}</span>
    <div className='flex justify-end space-x-2 items-center'>
      <ProjectUpdateEdit initialUpdate={update} deletePu={() => setDeleted(true)} />
    </div>
  </li>
}

const ProjectUpdatesIndex = () => {
  const [manageProjectContainer] = useGlobalState('manageProjectContainer')
  const { project } = manageProjectContainer

  const { openModal, closeModal, isOpen, willClose } = useModal()
  const { getRequest, putpostRequest } = useQuery()
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [newRoleKind, setNewRoleKind] = useState(null)
  const scrollRef = useRef(null)
  const [updates, setUpdates] = useState([])
  const [meta, setMeta] = useState({})
  const tipTapIdx = useRef(1)
  const [, setToast] = useGlobalState('toast')
  const [customError, setCustomError] = useState(null)
  const [ confirmDelete, setConfirmDelete ] = useState(false)
  const [ pu, setPu ] = useState(null)
  const { formState, register, reset, watch, unregister, handleSubmit, setValue, getValues, setError } = useForm({
   defaultValues: {
     title: '',
     body: ''
    }
  })

  const { errors } = formState

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = () => {
    setLoading(true)
    setNewRoleKind(null)
    getRequest(`/api/v3/manage/projects/${project.id}/updates`, {}, (err, jsonData) => {
      if (err) { /* handled in hook */ return }
      setLoading(false)
      setUpdates(jsonData.updates)
      setMeta(jsonData.meta)
    })
  }

  useEffect(() => {
    register('body', { required: true })
    return () => {
      unregister('body')
    }
  }, [register])

  const handleUpdateTipTap = (key, val) => {
    setValue(key, val, { shouldDirty: true, shouldValidate: true })
  }

  const onSubmit = debounce(300, (data) => {
    setLoading(true)

    const formData = {
      project_update: {
        body: data.body,
        project_id: project.id,
        title: data.title
      }
    }

    putpostRequest(`/api/v3/project_updates`, 'POST', formData, (err, jsonData) => {
      setLoading(false)
      if (err) { // 422 code
        if (typeof err === 'string') { // 422 custom error
          setCustomError(err)
        }
        if (typeof err !== 'string') { // 500 code on API
          Object.entries(err).forEach(([key, value]) => {
            setError(key, value)
          })
        }
        return
      }
      setToast(<div className="ml-3 w-0 flex-1 pt-0.5">
        <p className="text-sm font-medium dark:text-white text-gray-800">You've created <strike>a monster</strike> an update!</p>
        <p className="mt-1 text-sm dark:text-gray-100 text-gray-500">It's alive!</p>
      </div>)
      reset()
      addPu(jsonData.projectUpdate)
      closeModal()
    })
  })

  const addPu = (pu) => {
    setUpdates([...updates, ...[pu]])
  }

  const body = watch('body')

  return <div className='col-span-1'>
    <Modal isOpen={isOpen} closeModal={closeModal} >
      <div className="flex flex-col text-center items-center">
        <h2 className="mb-8 text-xs font-semibold tracking-widest text-gray-800 dark:text-gray-200 uppercase title-font">Edit Update</h2>
      </div>
      <div className="col-span-4 sm:col-span-2">
        <label htmlFor="school_name" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">Subject of Update </label>
        <div className="mt-1 flex flex-col rounded-md shadow-sm">
          <div className='relative'>
            <input type="text" className={errors.title ? 'errors' : ''} {...register('title', { required: true }) } placeholder="We've extended the deadline" />
            { errors.title && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
            </div> }
          </div>
        </div>
        { errors.title && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
      </div>

      <div className='col-span-2 mt-2'>
        <label htmlFor="body" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
          What would you like to tell everyone?
        </label>
        <TipTapWysiwyg
          html={body}
          idx={tipTapIdx.current}
          updateHtml={(val) => handleUpdateTipTap('body', val)}
          placeholder='Prepare yourself for the most important update of the century: there are cookies in the lobby.'
        />
        { errors.body && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
      </div>
      <div className="rounded-md bg-blue-50 mt-4 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <svg className="h-5 w-5 text-blue-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fillRule="evenodd" d="M19 10.5a8.5 8.5 0 11-17 0 8.5 8.5 0 0117 0zM8.25 9.75A.75.75 0 019 9h.253a1.75 1.75 0 011.709 2.13l-.46 2.066a.25.25 0 00.245.304H11a.75.75 0 010 1.5h-.253a1.75 1.75 0 01-1.709-2.13l.46-2.066a.25.25 0 00-.245-.304H9a.75.75 0 01-.75-.75zM10 7a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
            </svg>
          </div>
          <div className="ml-3 flex-1 md:flex md:justify-between">
            <p className="text-sm text-blue-700">Project Updates are emailed once a day, but they are available immediately on your project page. <br /> <br /> Everyone who follows your project (including those who enter a submission) will receive an email.</p>
          </div>
        </div>
      </div>
      { customError && <div className='mt-2 text-sm text-red-600'>{customError}</div> }
      <div className="flex justify-between space-x-2 mt-4">
        <div className="flex justify-end space-x-2">
          <DefaultButton onClick={closeModal} text='Cancel' />
          <PrimaryButton onClick={handleSubmit(onSubmit)} text='Save' />
        </div>
      </div>
    </Modal>
    <Card title={
      <div className='flex justify-between items-center'>
        <div className='font-medium leading-6 text-cccorange dark:text-cccpurple'>Project Updates</div>
        <div className='text-cccblue hover:text-cccblue-alt cursor-pointer' onClick={openModal}>Create Update</div>
      </div>
      }>
      <div ref={scrollRef}>
        { loading && <Loading /> }
        { updates.length === 0 && !loading && <NoResults /> }
        <ul className="space-y-3">
          {updates.map((up) => (
            <UpdateRow key={`manprouprow${up.id}`} update={up} />
          ))}
        </ul>
        { updates.length >= 0 && <div className='mt-2'><Pagination meta={meta} callback={setPage} scrollRef={scrollRef} /></div> }
      </div>
    </Card>
  </div>
}

const NoResults = () => {
  return (
    <span>
      <div className="relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccblue" >
        <EyeIcon className="mx-auto h-12 w-12 text-gray-400" />
        <span className="mt-2 block text-sm font-medium text-gray-900 dark:text-gray-300"> Project Updates will send an email to everyone who follows your project, and the update will be live on your project page. <br /> <br /> Everyone who follows your project (including those who enter a submission) will receive an email.</span>
      </div>
    </span>
  )
}

export default ProjectUpdatesIndex
