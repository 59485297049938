import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { EyeIcon, EyeOffIcon, FingerPrintIcon, CheckIcon } from '@heroicons/react/solid'
import useDropdown from '@/hooks/useDropdown'

import useQuery from '@/hooks/useQuery'

const Permission = ({ submission }) => {
  const [selected, setSelected] = useState(submission.permissions)
  const { putpostRequest } = useQuery()
  const dropdownRef = useRef(false)
  const { dropdownOpen, setDropdownOpen, openDropdown } = useDropdown(dropdownRef)

  const changePermission = (perm) => {
    setSelected(perm)
    setDropdownOpen(false)
    const data = { permissions: perm }
    putpostRequest(`/api/v3/auditions/${submission.id}`, 'PATCH', { audition: data }, (err, jsonData) => {
      if (err) { /* hook */ }
    })
  }
  return <>
    <div className='relative flex items-center'>
      <div onClick={openDropdown} className="relative z-0 inline-flex items-center space-x-1 rounded-md cursor-pointer text-gray-400 hover:text-gray-500 dark:text-gray-400 dark:hover:text-gray-300">
        { selected === 'unlisted' && <FingerPrintIcon className="h-5 w-5" aria-hidden="true" /> }
        { selected === 'private' && <EyeOffIcon className="h-5 w-5" aria-hidden="true" /> }
        { selected === 'public' && <EyeIcon className="h-5 w-5" aria-hidden="true" /> }
        <span className='capitalize hidden sm:block text-xs'>{selected}</span>
      </div>
      { dropdownOpen && <div ref={dropdownRef} tabIndex="-1" className={`origin-top-left sm:origin-top-right absolute z-10 right-0 mt-2 w-64 rounded-md bg-white dark:bg-gray-800 divide-y divide-gray-200 ring-1 ring-black ring-opacity-5 focus:outline-none  ${dropdownOpen ? '' : 'hidden opacity-0 scale-0'} py-2 overflow-y-auto transition border border-gray-900 dark:border-gray-100 rounded-md top-6 max-h-80`}>
        <div className="flex flex-col  cursor-default select-none relative p-2 text-sm hover:text-cccblue text-gray-900 dark:text-gray-100" onClick={() => changePermission('public')}>
          <EyeIcon className="h-5 w-5" aria-hidden="true" />
          <div className="flex justify-between">
            <p className='font-normal capitalize'>Public</p>
            { selected === 'public' && <CheckIcon className="h-5 w-5 text-cccblue" aria-hidden="true" /> }
          </div>
          <span className='text-xs sm:text-sm text-gray-500 dark:text-gray-200 dark:bg-gray-800'>This submission will appear on your profile and can be visited by the public.</span>
        </div>
        <div className="flex flex-col cursor-default select-none relative p-2 text-sm hover:text-cccblue text-gray-900 dark:text-gray-100" onClick={() => changePermission('unlisted')}>
          <FingerPrintIcon className="h-5 w-5" aria-hidden="true" />
          <div className="flex justify-between">
            <p className='font-normal capitalize'>Unlisted</p>
            { selected === 'unlisted' && <CheckIcon className="h-5 w-5 text-cccblue" aria-hidden="true" /> }
          </div>
          <span className='text-xs sm:text-sm text-gray-500 dark:text-gray-200 dark:bg-gray-800'>This submission will not appear on your profile, but can be seen with a direct link</span>
        </div>
        <div className="flex flex-col cursor-default select-none relative p-2 text-sm hover:text-cccblue text-gray-900 dark:text-gray-100" onClick={() => changePermission('private')}>
          <EyeOffIcon className="h-5 w-5" aria-hidden="true" />
          <div className="flex justify-between">
            <p className='font-normal capitalize'>Private</p>
            { selected === 'private' && <CheckIcon className="h-5 w-5 text-cccblue" aria-hidden="true" /> }
          </div>
          <span className='text-xs sm:text-sm text-gray-500 dark:text-gray-200 dark:bg-gray-800'>This submission will not appear on your profile and can only be visited by you and the project owner.</span>
        </div>
      </div> }
    </div>
  </>
}

export default Permission

Permission.propTypes = {
  submission: PropTypes.object.isRequired
}
