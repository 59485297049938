import React from 'react'
import { Switch, Route, useLocation, Link } from 'react-router-dom'

import Breadcrumbs from '@/Layout/Breadcrumbs'
import PageTitle from '@/Layout/PageTitle'
import { MailIcon } from '@heroicons/react/solid'

import PrivatePostsIndex from '@/pages/Posts/PrivatePostsIndex'
import PrivatePostForm from '@/pages/Posts/PrivatePostForm'

function breadCrumbs() {
  // const location = useLocation()
  // const activePath = location.pathname
  // const [submission] = useGlobalState('submission')
  // will need this ^ when adding show/edit func, see creditscontainer
  const crumbs = [
    { to: '/private_messages', name: 'Messages' }
  ]
  return crumbs
}

function pageTitle() {
  const location = useLocation()
  const activePath = location.pathname

  const titleInfo = {
    title: 'Messages',
    buttons: []
  }
  if (activePath === '/private_messages/new') { titleInfo.title = 'New Private Message' }
  if (!activePath.includes('/private_messages/new')) {
    titleInfo.buttons = [
      <Link key='editcredit' to='/private_messages/new' className="ml-3 inline-flex justify-center px-4 py-2 shadow-sm text-sm font-medium rounded-md text-white dark:text-gray-200 dark:hover:bg-cccblue-alt bg-cccblue hover:bg-cccblue-alt hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 mb-2">
      <MailIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
      New Private Message
    </Link>
    ]
  }

  return titleInfo
}

export default function PrivatePostsContainer(props) {
  const crumbs = breadCrumbs()
  const titleInfo = pageTitle()

  return <>
    <Breadcrumbs>
      { crumbs.map((crumb, index) => (
        <Link key={`${index}${crumb.name}`} to={crumb.to} className="text-xs sm:text-sm text-gray-500 hover:text-gray-700 dark:text-gray-100">
          {crumb.name}
        </Link>
      ))}
    </Breadcrumbs>
    <PageTitle pagetitle={titleInfo.title}>
      {titleInfo.buttons}
    </PageTitle>
    <Switch>
      <Route {...props} exact path={['/private_messages/new']}>
        <PrivatePostForm />
      </Route>
      <Route {...props} exact path={['/private_messages', '/private_messages/:id']}>
        <PrivatePostsIndex />
      </Route>
    </Switch>
  </>
}
