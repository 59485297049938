import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useGlobalState } from '@/state'
import PublicSubmissionRow from '@/pages/Users/PublicProfile/PublicSubmissionRow'
import useQuery from '@/hooks/useQuery'
import { DuplicateIcon, StarIcon, HeartIcon } from '@heroicons/react/solid'
import { CopyToClipboard } from 'react-copy-to-clipboard'

const SubmissionShow = () => {
  const { id } = useParams()
  const [, setToast] = useGlobalState('toast')
  const [submission, setSubmission] = useGlobalState('submission')
  const [voteIds, setVoteIds] = useState([])
  const [following, setFollowing] = useState(false)
  const [favorited, setFavorited] = useState(false)
  const { getRequest, putpostRequest } = useQuery()

  useEffect(() => {
    getRequest(`/api/v3/auditions/${id}`, {}, (err, jsonData) => {
      if (err) { /* handled in hook */ return }
      setVoteIds(jsonData.voteIds)
      setFollowing(jsonData.isFollowing)
      setFavorited(jsonData.isFavorited)
      setSubmission(jsonData.submission)
    })

    window.scrollTo(0, 0)
  }, [id])

  const follow = () => {
    putpostRequest(`/api/v3/follows/${submission.userId}/follow_user`, 'POST', {}, (err, jsonData) => {
      if (err) {
        if (typeof err === 'string') { // 422 custom error
          setToast(<div className="ml-3 w-0 flex-1 pt-0.5">
            <p className="text-sm font-medium text-red-500 dark:text-red-500">Hol up</p>
            <p className="mt-1 text-sm dark:text-gray-100 text-gray-500">{err}</p>
          </div>)
        }
        return
      }
      setFollowing(!following)
      setToast(<div className="ml-3 w-0 flex-1 pt-0.5">
        <p className="text-sm font-medium dark:text-white text-gray-800">{!following === true ? 'Following!' : 'Stopped following' }</p>
        <p className="mt-1 text-sm dark:text-gray-100 text-gray-500">{!following === true ? 'You will see activity from this person in your dashboard.' : 'You will no longer see activity about this person'}</p>
      </div>)
    })
  }

  const favorite = () => {
    putpostRequest(`/api/v3/favorites/${submission.roleId}/favorite_role`, 'POST', {}, (err, jsonData) => {
      if (err) {
        if (typeof err === 'string') { // 422 custom error
          setToast(<div className="ml-3 w-0 flex-1 pt-0.5">
            <p className="text-sm font-medium text-red-500 dark:text-red-500">Hol up</p>
            <p className="mt-1 text-sm dark:text-gray-100 text-gray-500">{err}</p>
          </div>)
        }
        return
      }
      setFavorited(!favorited)
      setToast(<div className="ml-3 w-0 flex-1 pt-0.5">
        <p className="text-sm font-medium dark:text-white text-gray-800">{!favorited === true ? 'Favorited!' : 'Unfavorited' }</p>
        <p className="mt-1 text-sm dark:text-gray-100 text-gray-500">{!favorited === true ? 'This will show up in your favorites. How exciting.' : 'How do you think this role feels now that it is not your favorite?'}</p>
      </div>)
    })
  }

  const showCopiedNotification = () => {
    setToast(<div className="ml-3 w-0 flex-1 pt-0.5">
      <p className="text-sm font-medium dark:text-green-300 text-green-600">Copied</p>
      <p className="mt-1 text-sm dark:text-green-300 text-green-400">You did it! It's copied. You can paste it anywhere you want now.</p>
    </div>)
  }

  if (!submission?.id) { return null }

  return <>
  <div className="grid grid-cols-3 sm:space-x-5 space-y-4 sm:space-y-0">
    <section className="col-span-3 sm:col-span-2">
      <div className="max-w-5xl mx-auto">

        <div className="p-1 sm:p-3 md:col-span-2 border-t-2 shadow border-cccorange dark:border-cccpurple-alt bg-white dark:bg-gray-800 dark:bg-gray-800">
          <div>
            <div className='flex justify-between'>
              <div className="relative flex items-start space-x-3 overflow-hidden">
                <div className="relative m-1">
                  <img className="h-16 w-16 rounded-sm bg-gray-200 flex items-center justify-center ring-4 ring-cccblue" src={submission.roleImageUrl} alt={submission.roleName} />

                  <span className="absolute -bottom-0.5 bg-white rounded-sm px-0.5 py-px">
                    <img className="h-6 w-6 text-white" src={submission.roleKindImageUrl} alt={submission.roleKind} />
                  </span>
                </div>
                <div className="min-w-0 flex-1">
                  <div className="font-medium leading-6 text-cccorange dark:text-cccpurple"> {submission.roleName} </div>
                  <div className="uppercase text-xs dark:text-gray-300 text-gray-500 mr-5 truncate"> {submission.roleKind} </div>
                  <div className="mt-1 text-sm text-gray-600 dark:text-gray-400 flex items-center">
                    <span className={`flex-shrink-0 inline-block px-2 text-white ${submission.roleStatus === 'open' ? 'bg-green-500' : 'bg-yellow-500'} text-xs font-medium rounded-full flex capitalize`}>
                      {submission.roleStatus}
                    </span>
                    { submission.roleStatus === 'closed' && <>
                      <span className='mx-1'>-</span>
                      { submission.roleCastToName && <>
                        <Link to={`/${submission.roleCastToUsername}`}> cast to {submission.roleCastToName}</Link>
                      </> }
                      { !submission.roleCastToName && <> cast offsite </>}
                    </> }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='flex flex-col sm:flex-row justify-between items-start'>
            <div>
              <dt className="text-sm font-medium text-gray-500">Voice Description</dt>
              <dd className="mt-1 text-sm text-gray-900">
                <ul className="flex sm:items-center flex-wrap">
                  <li className="flex-shrink-0 inline-block px-2 mr-1 mb-1 py-0.5 text-white bg-cccorange dark:bg-cccpurple text-xs font-medium rounded-full flex capitalize">{submission.roleGender}</li>
                  <li className="flex-shrink-0 inline-block px-2 mr-1 mb-1 py-0.5 text-white bg-cccorange dark:bg-cccpurple text-xs font-medium rounded-full flex capitalize">{submission.roleAge}</li>
                  <li className="flex-shrink-0 inline-block px-2 mr-1 mb-1 py-0.5 text-white bg-cccorange dark:bg-cccpurple text-xs font-medium rounded-full flex capitalize">Accent: {submission.roleAccent}</li>
                </ul>
              </dd>
            </div>
            <div>
              <dt className="text-sm font-medium text-gray-500">Language</dt>
              <dd className="mt-1 text-sm text-gray-900">
                <ul className="flex sm:items-center flex-wrap">
                  <li className="flex-shrink-0 inline-block px-2 mr-1 mb-1 py-0.5 text-white bg-cccorange dark:bg-cccpurple text-xs font-medium rounded-full flex capitalize">{submission.roleLanguage}</li>
                </ul>
              </dd>
            </div>
            { submission.roleSignificance && <div>
              <dt className="text-sm font-medium text-gray-500">Significance</dt>
              <dd className="mt-1 text-sm text-gray-900">
                <ul className="flex sm:items-center flex-wrap">
                  <li className="flex-shrink-0 inline-block px-2 mr-1 mb-1 py-0.5 text-white bg-cccorange dark:bg-cccpurple text-xs font-medium rounded-full flex capitalize">{submission.roleSignificance}</li>
                </ul>
              </dd>
            </div> }
            <div>
              <dt className="text-sm font-medium text-gray-500">Budget</dt>
              <dd className={`mt-1 text-sm ${submission.roleBudget === 'Zero budget' ? 'text-gray-900 dark:text-gray-300' : 'text-green-500'}`}>
                {submission.roleBudget}
              </dd>
            </div>
          </div>
          <div className="mt-2 text-gray-700 dark:text-gray-300 min-w-full prose prose-sm" dangerouslySetInnerHTML={{ __html: submission.roleCharacteristics }} />
          <ul>
            {submission.roleLines.map((line, idx) => (
              <li key={`${submission.id}lin${idx}`} className={`p-2 dark:text-gray-300 text-sm ${idx % 2 === 0 ? 'bg-gray-200 dark:bg-gray-900' : 'bg-gray-100 dark:bg-gray-800'}`} dangerouslySetInnerHTML={{ __html: line.line }} />
            ))}
          </ul>
          <section className='mt-2 bg-cccblue bg-opacity-5 border-l-2 border-cccblue dark:bg-gray-700 px-2 py-2 sm:px-3 shadow-sm'>
            <ul>
              <PublicSubmissionRow submission={submission} voteIds={voteIds} commentsOpen={true} image='user' />
            </ul>
          </section>
        </div>
      </div>
    </section>
    <aside className='col-span-3 sm:col-span-1'>

      <div className="rounded-md bg-yellow-50 p-2">
        <div className="flex">
          <div className="ml-3">
            <div className='pb-3 text-sm'>
              <b>Share</b> this submission with your friends, your mom, and your friends' mom's!
            </div>
            <div className="text-sm font-medium text-yellow-800">
              <CopyToClipboard text={`https://cstng.cc/s/${submission.id}`} onCopy={showCopiedNotification} >
                <span className='flex flex-col sm:flex-row justify-center items-center cursor-pointer space-x-1'>
                  <DuplicateIcon className="h-4 w-4 text-gray-400" aria-hidden="true" />
                  <pre className='bg-gray-50'>{`https://cstng.cc/s/${submission.id}`}</pre>
                </span>
              </CopyToClipboard>
            </div>
          </div>
        </div>
      </div>

      <div className='mt-4'>
        <a href={`/projects/${submission.projectSlug}`} className="w-full col-span-3 flex items-center justify-center bg-gradient-to-r from-cccblue to-cccpurple bg-origin-border px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white hover:text-white hover:from-cccpurple hover:to-cccblue">Back to Project Page</a>
        <div className='flex justify-between space-x-2 mt-2'>
          <Link to={`/submissions/${submission.nextSubmissionId}`} className="w-full flex items-center justify-center p-2 border text-sm font-medium rounded-md shadow-sm bg-white dark:bg-gray-800">Next Submission</Link>
          <Link to={`/submissions/${submission.randomSubmissionId}`} className="w-full flex items-center justify-center p-2 border text-sm font-medium rounded-md shadow-sm bg-white dark:bg-gray-800">Random Submission</Link>
        </div>
        <button onClick={follow} type="button" className="inline-flex w-full mt-2 space-x-1 justify-center p-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white dark:bg-gray-800 dark:text-gray-200 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccblue" >
          <StarIcon className="h-4 w-4 sm:h-5 sm:w-5 text-gray-400" aria-hidden="true" />
          <span className='hidden sm:block'>{following ? 'Unfollow' : `Follow ${submission.userDisplayName}` }</span>
        </button>
        <button onClick={favorite} type="button" className="inline-flex w-full mt-2 space-x-1 justify-center p-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white dark:bg-gray-800 dark:text-gray-200 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccblue" >
          <HeartIcon className="h-4 w-4 sm:h-5 sm:w-5 text-gray-400" aria-hidden="true" />
          <span className='hidden sm:block'>{favorited ? 'Unfavorite' : `Favorite ${submission.roleName}` }</span>
        </button>
      </div>
      <div className="mt-2 md:col-span-2 border-t-2 shadow border-cccorange dark:border-cccpurple-alt bg-cccblue bg-opacity-5 dark:bg-gray-800 dark:bg-gray-800 p-1 sm:p-3">
      <div className="font-medium leading-6 text-cccorange dark:text-cccpurple"> {submission.userDisplayName} for {submission.roleName}</div>
      <Link to={`/projects/${submission.projectSlug}`}>{submission.projectName}</Link>
        <div className='grid grid-cols-3 space-x-2'>
          <div className="aspect-w-1 aspect-h-1">
            <img className="object-cover shadow-md rounded rounded-lg" src={submission.userImageUrl} alt={submission.userDisplayName} />
          </div>
          <div className="aspect-w-1 aspect-h-1">
            <img className="object-cover shadow-md rounded rounded-lg" src={submission.roleImageUrl} alt={submission.roleName} />
          </div>
          <div className="aspect-w-1 aspect-h-1">
            <img className="object-cover shadow-md rounded rounded-lg" src={submission.projectImageUrl} alt={submission.projectName} />
          </div>
        </div>
      </div>
      <div className="mt-2 md:col-span-2 border-t-2 shadow border-cccorange dark:border-cccpurple-alt bg-cccblue bg-opacity-5 dark:bg-gray-800 dark:bg-gray-800 p-1 sm:p-3">
        <div className="font-medium leading-6 text-cccorange dark:text-cccpurple">
          About <Link to={`/${submission.username}`}>{submission.userDisplayName}</Link>
        </div>

        <div className="flex items-center space-x-2">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5 text-gray-400" aria-hidden="true">
            <path fillRule="evenodd" d="M7 4a3 3 0 016 0v4a3 3 0 11-6 0V4zm4 10.93A7.001 7.001 0 0017 8a1 1 0 10-2 0A5 5 0 015 8a1 1 0 00-2 0 7.001 7.001 0 006 6.93V17H6a1 1 0 100 2h8a1 1 0 100-2h-3v-2.07z" clipRule="evenodd"></path>
          </svg>
          <span className="text-gray-900 dark:text-gray-100 text-sm font-medium">{submission.userAuditionsCount}<span className="text-gray-500"> submissions</span></span>
        </div>
        <div className="flex items-center space-x-2">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5 text-gray-400" aria-hidden="true">
            <path fillRule="evenodd" d="M18 3a1 1 0 00-1.447-.894L8.763 6H5a3 3 0 000 6h.28l1.771 5.316A1 1 0 008 18h1a1 1 0 001-1v-4.382l6.553 3.276A1 1 0 0018 15V3z" clipRule="evenodd"></path>
          </svg>
          <span className="text-gray-900 dark:text-gray-100 text-sm font-medium">{submission.userProjectsCount}<span className="text-gray-500"> projects created</span></span>
        </div>
        <div className="flex items-center space-x-2">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5 text-gray-400" aria-hidden="true">
            <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z" clipRule="evenodd"></path>
          </svg>
          <span className="text-gray-900 dark:text-gray-100 text-sm font-medium"><span className="text-gray-500">Last seen</span> {submission.userLastSeen}<span className="text-gray-500"> ago</span></span>
        </div>
        <dt className="text-sm font-medium text-gray-500 mt-4">Credits</dt>
        <ul className="space-y-3">
          {submission.userCredits.length === 0 && <>
            <li className="bg-gray-100 dark:bg-gray-700 px-4 py-4 sm:px-6  rounded-lg shadow-sm dark:text-gray-300">
              Not credited in any productions yet.
            </li>
          </> }
          {submission.userCredits.map((uc, idx) => (
            <li key={`uc${uc.id}`} className="bg-gray-100 dark:bg-gray-700 px-4 py-4 sm:px-6  rounded-lg shadow-sm">
              <div className="sm:flex sm:justify-between sm:items-center">
                <div className="flex-shrink-0 text-gray-500 dark:text-gray-100 hidden sm:block"><img className="h-16 w-16 rounded-full" src={uc.imageUrl} alt={uc.name} /></div>
                <div className="flex-1 px-0 md:px-4 md:py-0 pb-2 w-full truncate">
                  <div className="flex flex-col truncate">
                    <span className="uppercase text-xs dark:text-gray-300 text-gray-500 mr-5 flex items-center">{uc.year}</span>
                    <span className="max-w-0">
                      <span aria-expanded="false">
                        <div><Link className="font-medium text-cccblue" to={`/credits/${uc.slug}`}>{uc.name}</Link></div>
                      </span>
                    </span>
                    <span className="dark:text-gray-300 text-gray-500">
                      {uc.creditType}
                      <span> {uc.role && <>({uc.role})</> }</span>
                    </span>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
        <div className='text-gray-700 dark:text-gray-300'>
          <div className='py-4 prose max-h-64 overflow-y-hidden relative fade-bottom' dangerouslySetInnerHTML={{ __html: submission.userDescription }} />
        </div>
        <Link className='py-4 text-sm' to={`/${submission.username}`}>View Full Profile</Link>
      </div>
    </aside>
  </div>
  </>
}

export default SubmissionShow
