import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import Card from '@/shared/Card'
import useQuery from '@/hooks/useQuery'
import { useGlobalState } from '@/state'
import { MicrophoneIcon, ChatAltIcon, TicketIcon } from '@heroicons/react/solid'

const ActivityRow = ({ activity, showTimeline }) => {
  return <li>
      <div className="relative pb-8">
        { showTimeline && <span className="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200 dark:bg-gray-800" aria-hidden="true" /> }
        <div className="relative flex items-start space-x-3">
          {activity.action === 'cast' && <>
            <div className="relative">
              <img className="h-10 w-10 rounded-sm bg-gray-200 flex items-center justify-center ring-4 ring-white dark:ring-gray-800" src={activity.imageUrl} alt="" />

              <span className="absolute -bottom-0.5 -right-1 bg-white rounded-tl px-0.5 py-px dark:bg-gray-800">
                <TicketIcon className="h-4 w-4 text-gray-600 dark:text-gray-400" aria-hidden="true" />
              </span>
            </div>
            <div className="min-w-0 flex-1">
              <div>
                <p className="mt-0.5 text-sm text-gray-500">Was assigned a role {activity.createdAt === 'now' ? 'just now' : `${activity.createdAt} ago`}</p>
                <p className="mt-0.5 text-sm text-gray-500 truncate">for
                  <a href={activity.url} className="ml-1 font-medium text-cccblue cursor-pointer">
                    {activity.on}
                  </a>
                </p>
              </div>
            </div>
          </> }
          {activity.action === 'audition' && <>
            <div className="relative">
              <img className="h-10 w-10 rounded-sm bg-gray-200 flex items-center justify-center ring-4 ring-white dark:ring-gray-800" src={activity.imageUrl} alt="" />

              <span className="absolute -bottom-0.5 -right-1 bg-white rounded-tl px-0.5 py-px dark:bg-gray-800">
                <MicrophoneIcon className="h-4 w-4 text-gray-600 dark:text-gray-400" aria-hidden="true" />
              </span>
            </div>
            <div className="min-w-0 flex-1">
              <div>
                <p className="mt-0.5 text-sm text-gray-500">Sent a submission {activity.createdAt === 'now' ? 'just now' : `${activity.createdAt} ago`}</p>
                <p className="mt-0.5 text-sm text-gray-500 truncate">on
                  <a href={activity.url} className="ml-1 font-medium text-cccblue cursor-pointer">
                    {activity.on}
                  </a>
                </p>
              </div>
            </div>
          </> }
          {activity.action === 'comment' && <>
            <div className="relative">
              <img className="h-10 w-10 rounded-sm bg-gray-200 flex items-center justify-center ring-4 ring-white dark:ring-gray-800" src={activity.imageUrl} alt="" />

              <span className="absolute -bottom-0.5 -right-1 bg-white rounded-tl px-0.5 py-px dark:bg-gray-800">
                <ChatAltIcon className="h-4 w-4 text-gray-600 dark:text-gray-400" aria-hidden="true" />
              </span>
            </div>
            <div className="min-w-0 flex-1">
              <div>
                <p className="mt-0.5 text-sm text-gray-500">Commented {activity.createdAt === 'now' ? 'just now' : `${activity.createdAt} ago`}</p>
                <p className="mt-0.5 text-sm text-gray-500 truncate">on
                  <a href={activity.url} className="ml-1 font-medium text-cccblue cursor-pointer">
                    {activity.on}
                  </a>
                </p>
              </div>
              <div className="mt-2 text-sm text-gray-600 dark:text-gray-400 truncate">
                <p>{activity.comment}</p>
              </div>
            </div>
          </> }
        </div>
      </div>
    </li>
}

const PublicActivity = () => {
  const [profileContainer] = useGlobalState('profileContainer')
  const { getRequest } = useQuery()
  const [activities, setActivities] = useState([])
  const { user } = profileContainer

  useEffect(() => { fetchData() }, [])

  const fetchData = () => {
    getRequest(`/api/v3/users/${user.username}/activities`, {}, (err, jsonData) => {
      if (err) { /* hooks */ return }

      setActivities(jsonData.activities)
    })
  }

  return (
    <Card light title='Recent Activity'>
      <ul role="list">
        {activities.map((act, idx) => (
          <ActivityRow key={`act${act.id}`} activity={act} showTimeline={idx !== activities.length - 1} />
        ))}
      </ul>
    </Card>
  )
}

export default PublicActivity

ActivityRow.propTypes = {
  activity: PropTypes.object,
  showTimeline: PropTypes.bool
}
