import React, { useState, useEffect } from 'react'
import { debounce } from 'throttle-debounce'
import PropTypes from 'prop-types'
import { SVGIcon } from '@/components/icon'

import useQuery from '@/hooks/useQuery'

import Card from '@/shared/Card'
import Loading from '@/shared/Loading'

const OmniAuthRow = ({ providerName, omniAuthAccounts }) => {
  const connected = omniAuthAccounts.find(({ provider }) => provider === providerName)
  const oauths = {
    google_oauth2: {
      name: 'Google',
      url: '/auth/google_oauth2?settings_page=true'
    },
    discord: {
      name: 'Discord',
      url: '/auth/discord?settings_page=true'
    },
    linkedin: {
      name: 'LinkedIn',
      url: '/auth/linkedin?settings_page=true'
    },
    facebook: {
      name: 'Facebook',
      url: '/auth/facebook?settings_page=true'
    }
  }

  return <>
    <div className="grid grid-cols-3 gap-6 p-4 bg-white dark:bg-gray-700 rounded-sm">
      <div className='col-span-1 flex items-center text-gray-900 dark:text-gray-100 space-x-2'>
        <span><SVGIcon name={providerName} width='27' height='27' autoDark/></span>
        <span>{oauths[providerName].name}</span>
      </div>
      <div className='col-span-1 text-gray-800 dark:text-gray-200'>
        { connected && <span className='text-green-500 flex flex-col'>
          <span>Connected</span>
          <span>{connected.createdAt}</span>
        </span> }
        { !connected && <span>Not Connected</span> }
      </div>
      <div className='col-span-1 text-gray-800 dark:text-gray-200'>
        { !connected && <a href={oauths[providerName].url} className="font-medium text-cccblue hover:text-cccblue-alt"> Connect </a> }
      </div>
    </div>
  </>
}

const OmniAuthSettings = () => {
  const [omniAuthAccounts, setOmniAuthAccounts] = useState([])
  const { getRequest } = useQuery()
  const [loading, setLoading] = useState(true)

  useEffect(() => { fetchData() }, [])

  const fetchData = debounce(300, () => {
    setLoading(true)
    getRequest('/api/v3/omni_auth_accounts', {}, (err, jsonData) => {
      setLoading(false)
      if (err) { /* hooks */ return }
      setOmniAuthAccounts(jsonData.omniAuthAccounts)
    })
  })

  return <>
    <div className="md:col-span-1 mb-3">
      <div className="px-0 sm:px-4">
        <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-100">Connected Accounts</h3>
        <p className="mt-1 text-sm text-gray-600 dark:text-gray-300">
          You can login with any connected account.
        </p>
        <p className="mt-1 text-sm text-gray-600 dark:text-gray-300">
          You will get a "verified" badge when you connect an account.
        </p>
      </div>
    </div>
    <Card title='Connected Acounts'>
      { loading && <Loading /> }
      { !loading && <>
        <OmniAuthRow providerName='google_oauth2' omniAuthAccounts={omniAuthAccounts} />
        <OmniAuthRow providerName='facebook' omniAuthAccounts={omniAuthAccounts} />
        <OmniAuthRow providerName='discord' omniAuthAccounts={omniAuthAccounts} />
        <OmniAuthRow providerName='linkedin' omniAuthAccounts={omniAuthAccounts} />
      </> }
    </Card>
  </>
}

export default OmniAuthSettings

OmniAuthRow.propTypes = {
  providerName: PropTypes.string.isRequired,
  omniAuthAccounts: PropTypes.array.isRequired
}
