import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Switch } from '@headlessui/react'

import classNames from '@/utils/classNamesLocal'
import { useForm } from 'react-hook-form'
import { debounce } from 'throttle-debounce'

import Card from '@/shared/Card'
import { randomToastSuccess } from '@/shared/Toast'

import FroalaWrapper from '@/shared/FroalaWrapper'
import TipTapProject from '@/shared/TipTapProject'
import PrimaryButton from '@/shared/Buttons/Primary'
import { ExclamationCircleIcon, CheckCircleIcon } from '@heroicons/react/solid'
import useQuery from '@/hooks/useQuery'
import { useGlobalState } from '@/state'

const categories = {
  Animation: 'Anime, cartoons, stop-motion, 2d, 3d',
  Commercial: 'Explainer video, TV commercial, online ad, photography, audio ad, corporate Video, e-learning, phone, automated menus',
  Fandub: 'Literally just fandubs',
  Film: 'Feature film, short film, student film, scripted show, reality show, documentary',
  Music: 'Songs, background music, sound effects',
  Podcast: 'Podcasts, radio dramas, hosts',
  Roleplay: 'Roleplays, script reads',
  Videogame: 'Videogames',
  VisualNovel: 'Audiobook, visual novels, light novels, comics',
  Other: 'Your project does not fit in the other categories',
  Unsure: 'Our moderators will select a category for you. Your project will not show up in search until we do.'
}

const catImages = {
  Animation: 'https://ddppjbdexhxzj.cloudfront.net/icons/ICONS-animation.svg',
  Commercial: 'https://ddppjbdexhxzj.cloudfront.net/icons/ICONS-commercial.svg',
  Fandub: 'https://ddppjbdexhxzj.cloudfront.net/icons/ICONS-fan.svg',
  Film: 'https://ddppjbdexhxzj.cloudfront.net/icons/ICONS-machinima.svg',
  Music: 'https://ddppjbdexhxzj.cloudfront.net/icons/ICONS-music.svg',
  Narration: 'https://ddppjbdexhxzj.cloudfront.net/icons/ICONS-narration.svg',
  Podcast: 'https://ddppjbdexhxzj.cloudfront.net/icons/ICONS-podcast.svg',
  Roleplay: 'https://ddppjbdexhxzj.cloudfront.net/icons/ICONS-roleplay.svg',
  Videogame: 'https://ddppjbdexhxzj.cloudfront.net/icons/ICONS-videogame.svg',
  VisualNovel: 'https://ddppjbdexhxzj.cloudfront.net/icons/ICONS-visualnovel.svg',
  Other: 'https://ddppjbdexhxzj.cloudfront.net/icons/ICONS-other.svg',
  Unsure: 'https://ddppjbdexhxzj.cloudfront.net/icons/ICONS-unsure.svg'
}

const classifications = {
  Original: 'Made a project from your own inner workings? We salute you, o brave one.',
  Fan: "Made a project as a fan from a pre-existing entity? Here's to those who improve on others' work."
}

const clImages = {
  Original: 'https://ddppjbdexhxzj.cloudfront.net/icons/ICONS-original.svg',
  Fan: "https://ddppjbdexhxzj.cloudfront.net/icons/ICONS-fabdub.svg"
}

const CategoryCell = ({ selected, category, handleCategoryClick }) => {
  return <div className={`p-2 col-span-2 sm:col-span-1 cursor-pointer flex flex-col rounded-md border-t-4 border-solid border-2 hover:border-cccorange dark:hover:border-cccpurple ${(selected) ? 'border-cccorange dark:border-cccpurple' : 'border-gray-500'}`} onClick={() => handleCategoryClick(category)}>
    <div className="flex justify-start items-center space-x-2 text-gray-800 dark:text-gray-100">
      <img style={{ height: 40, width: 40 }} src={catImages[category]} /> {category}
      { selected && <CheckCircleIcon className='h-4 w-4 text-green-500' /> }
    </div>
    <p className="text-sm text-gray-400 dark:text-gray-300">{categories[category]}</p>
  </div>
}

const ClassificationCell = ({ selected, classification, handleClassificationClick }) => {
  return <div className={`p-2 col-span-1 cursor-pointer flex flex-col rounded-md border-t-4 border-solid border-2 hover:border-cccorange dark:hover:border-cccpurple ${(selected) ? 'border-cccorange dark:border-cccpurple' : 'border-gray-500'}`} onClick={() => handleClassificationClick(classification)}>
    <div className="flex justify-start items-center space-x-2 text-gray-800 dark:text-gray-100">
      <img style={{ height: 40, width: 40 }} src={clImages[classification]} /> {classification}
      { selected && <CheckCircleIcon className='h-4 w-4 text-green-500' /> }
    </div>
    <p className="text-sm text-gray-400 dark:text-gray-300">{classifications[classification]}</p>
  </div>
}

const OptionalInfoForm = ({ initialProject }) => {
  const [, setToast] = useGlobalState('toast')
  const [currentUser,] = useGlobalState('currentUser')
  const [project, setProject] = useState(initialProject)
  const [formattedVideoUrl, setFormattedVideoUrl] = useState(initialProject.youtubeUrl)
  const tipTapIdx = useRef(1)
  const { putpostRequest, getRequest } = useQuery()
  const { formState, reset, register, unregister, watch, handleSubmit, setValue, getValues, setError, setFocus } = useForm({
   defaultValues: {
      youtube_url: initialProject.youtubeUrl,
      nsfw: initialProject.nsfw,
      location: initialProject.location,
      location_required: initialProject.locationRequired,
      description: initialProject.description,
      classification: initialProject.classification,
      category: initialProject.category
    }
  })
  const { isDirty, errors } = formState
  const [loading, setLoading] = useState(false)
  const description = getValues().description

  useEffect(() => {
    register('description')
    register('category', { required: true })
    register('classification', { required: true })
    return () => {
      unregister('description')
      unregister('category')
      unregister('classification')
    }
  }, [register])

  const handleUpdateFroala = (key, val) => {
    setValue(key, val, { shouldDirty: true, shouldValidate: true })
  }

  const handleUpdateTipTap = (key, val) => {
    setValue(key, val, { shouldDirty: true, shouldValidate: true })
  }

  const handleCategoryClick = (cat) => {
    setValue('category', cat, { shouldDirty: true, shouldValidate: true })
  }
  const handleClassificationClick = (cl) => {
    setValue('classification', cl, { shouldDirty: true, shouldValidate: true })
  }

  const keyPress = (e) => {
    if (e.target.name === 'name' && e.keyCode === 13) { e.preventDefault() }
    if ((e.metaKey || e.ctrlKey) && e.keyCode === 13) {
      if (typeof (e.target.name) === 'undefined') {
        e.target.blur() // froala hack to make it update model so it can save properly
      }
      handleSubmit(onSubmit)()
    }
  }

  const handleUpdateSwitch = (key) => {
    setValue(key, !getValues()[key], { shouldDirty: true, shouldValidate: true })
  }

  const onSubmit = debounce(300, (data) => {
    setLoading(true)

    console.log(data)
    putpostRequest(`/api/v3/manage/projects/${project.id}`, 'PATCH', { project: data }, (err, jsonData) => {
      setLoading(false)
      if (err) { // 422 code
        if (typeof err !== 'string') { // 500 code on API
          Object.entries(err).forEach(([key, value]) => {
            setError(key, value)
          })
        }
        return
      }

      reset({ ...getValues(), description: jsonData.project.description + ' ' }) // resetting this without this + ' ' hack kills froala somehow
      setFormattedVideoUrl(jsonData.project.youtubeUrl)
      setProject({ ...jsonData.project })
      setToast(randomToastSuccess())
    })
  })

  const category = watch('category')
  const classification = watch('classification')
  const locationRequired = watch('location_required')
  const nsfw = watch('nsfw')

  return <>
    <div className="md:grid md:grid-cols-4 md:gap-6">
      <div className='md:grid-cols-1 flex flex-col justify-between'>
        <div>
          <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-100">Category</h3>
          <p className="mt-1 text-sm text-gray-600 dark:text-gray-300">Categories help talent filter projects that they would be best suited for.</p>
          <h3 className="mt-5 text-lg font-medium leading-6 text-gray-900 dark:text-gray-100">Classification</h3>
          <p className="mt-1 text-sm text-gray-600 dark:text-gray-300">Classifications are a filter for talent to identify projects they want to pursue.</p>
        </div>
        <div>
          <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-100">Description</h3>
          <p className="mt-1 text-sm text-gray-600 dark:text-gray-300">It is important your description contains no misspellings and looks professional. If talent thinks that your work is sloppy then they are less likely to apply. Please see the examples if you need some help on what to put in your description.</p>
          <div className='flex flex-col space-y-2 text-sm'>
            <a href='https://buf.notion.site/Indie-Studio-a1f2bd927bc446799ee1cf67366a0f67' target="_blank" rel="noreferrer" className='text-gray-900 dark:text-gray-100 hover:underline'>Indie Studio Sample</a>
            <a href='https://buf.notion.site/Fan-Dub-Sample-d3a5d6d6ae6d41b9881b277840bcb7ed' target="_blank" rel="noreferrer" className='text-gray-900 dark:text-gray-100 hover:underline'>Fan Dub Sample</a>
            <a href='https://buf.notion.site/Small-Original-Sample-dc1a3999617148bab774e082a1b7bb08' target="_blank" rel="noreferrer" className='text-gray-900 dark:text-gray-100 hover:underline'>Small Original Sample</a>
          </div>
        </div>
      </div>
      <Card title="Optional Information"
        footer={
          <div className='flex justify-end space-x-8 items-center'>
            <div className='flex flex-rows justify-end items-end'>
              <div className='flex flex-col items-end w-min ml-2'>
                <PrimaryButton className='w-full' disabled={!isDirty} onClick={handleSubmit(onSubmit)} loading={loading} isDirty={isDirty} text='Save' />
              </div>
            </div>
          </div>
        } >
        <form>
          <div className="grid sm:grid-cols-3 gap-6">
            <div className="col-span-3 sm:col-span-3">
              <label htmlFor="about" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                Project Category
              </label>
              <div className="mt-1 grid grid-cols-4 gap-3">
                { Object.keys(categories).map((cat, idx) => <CategoryCell key={`c${cat}`} handleCategoryClick={handleCategoryClick} category={cat} selected={category === cat} />)}
              </div>
            </div>
            <div className="col-span-3 sm:col-span-3">
              <label htmlFor="about" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                Project Classification
              </label>
              <div className="mt-1 grid grid-cols-2 gap-3">
                { Object.keys(classifications).map((cl, idx) => <ClassificationCell key={`cl${cl}`} handleClassificationClick={handleClassificationClick} classification={cl} selected={classification === cl} />)}
              </div>
            </div>

            <div className='mt-3 col-span-2'>
              <label htmlFor="about" className="block text-sm font-medium flex flex-col text-gray-700 dark:text-gray-300">
                <span>Content & Location</span>
              </label>
              <Switch.Group as="div" className="col-span-1 flex items-center justify-between mb-2">
                <Switch.Label as="span" className="flex-grow flex flex-col" >
                  <span className="text-sm text-gray-700 dark:text-gray-300">This project is NSFW (nudity, gore, extremely sensitive topics)</span>
                  <span className="text-xs text-gray-600 dark:text-gray-400">Please note that if your project violates our <a href="/tos" target="_blank">terms of service</a>, your project will be removed.</span>
                </Switch.Label>
                <Switch checked={nsfw} onChange={() => handleUpdateSwitch('nsfw')}
                  className={classNames(nsfw ? 'bg-cccpurple' : 'bg-gray-300 dark:bg-gray-500',
                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccpurple')} >
                  <span className="sr-only" hidden>NSFW content</span>
                  <span aria-hidden="true" className={classNames(nsfw ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200')}
                  />
                </Switch>
              </Switch.Group>
            </div>

            <div className='mt-3 col-span-2'>
              <Switch.Group as="div" className="col-span-1 flex items-center justify-between mb-2">
                <Switch.Label as="span" className="flex-grow flex flex-col" >
                  <span className="text-sm text-gray-700 dark:text-gray-300">Talent is required to work on-site</span>
                </Switch.Label>
                <Switch checked={locationRequired} onChange={() => handleUpdateSwitch('location_required')}
                  className={classNames(locationRequired ? 'bg-cccpurple' : 'bg-gray-300 dark:bg-gray-500',
                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccpurple')} >
                  <span className="sr-only" hidden>Talent is required to work on-site</span>
                  <span aria-hidden="true" className={classNames(locationRequired ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200')}
                  />
                </Switch>
              </Switch.Group>

              { locationRequired && <>
                <label htmlFor="location" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex flex-col justify-start">
                  <span>Production Location</span>
                </label>
                <div className="mt-1 flex flex-col rounded-md shadow-sm">
                  <div className='relative'>
                    <input onKeyDown={keyPress} type="text" className={errors.location ? 'errors' : ''} {...register('location', { required: true }) } placeholder="New York" />
                    { errors.location && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                      <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                    </div> }
                  </div>
                </div>
                { errors.location && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
              </> }
            </div>
            <div className="col-span-3 sm:col-span-3">
              <label htmlFor="youtube_url" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">Video URL (Loom or YouTube) </label>
              <p className="text-xs text-gray-500 dark:text-gray-400 mt-1">Make sure you get the <b>embed</b> link. e.g. (https://www.youtube.com/embed/DiYTaQ-Mgck)
  </p>
              <div className="mt-1 flex flex-col rounded-md shadow-sm">
                <div className='relative'>
                  <input onKeyDown={keyPress} type="text" className={errors.youtube_url ? 'errors' : ''} {...register('youtube_url') } placeholder="https://www.loom.com/embed/...." />
                  { errors.video_url && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                  </div> }
                </div>
              </div>

              <div className="min-w-0 flex-1 mt-2 flex sm:items-center  flex-wrap sm:flex-nowrap">
                {formattedVideoUrl && formattedVideoUrl.includes('loom.com') && <>
                  <div style={{ position: 'relative', paddingBottom: '56.25%', height: '0' }} className='w-full'>
                    <iframe src={`${formattedVideoUrl}?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true`}
                      frameBorder="0" webkitallowfullscreen='true' mozallowfullscreen='true' allowFullScreen
                      style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' }}
                    ></iframe>
                  </div>
                </>}
                {formattedVideoUrl && formattedVideoUrl.includes('youtube.com') && <>
                  <iframe width="560" height="315" src={formattedVideoUrl} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>

                </>}
              </div>
            </div>

            <div className="col-span-3 sm:col-span-2">
              <label htmlFor="about" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                Project Description
              </label>
              { !currentUser.canUploadImages && <div className="rounded-md bg-blue-50 p-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <svg className="h-5 w-5 text-blue-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fillRule="evenodd" d="M19 10.5a8.5 8.5 0 11-17 0 8.5 8.5 0 0117 0zM8.25 9.75A.75.75 0 019 9h.253a1.75 1.75 0 011.709 2.13l-.46 2.066a.25.25 0 00.245.304H11a.75.75 0 010 1.5h-.253a1.75 1.75 0 01-1.709-2.13l.46-2.066a.25.25 0 00-.245-.304H9a.75.75 0 01-.75-.75zM10 7a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
                    </svg>
                  </div>
                  <div className="ml-3 flex-1 md:flex md:justify-between">
                    <p className="text-sm text-blue-700">You can add images by <b>dragging</b> or <b>pasting</b> them into the editor. <a href='/subscriptions' target='_blank' className='underline'>Upgrade to Premium</a> to enable this feature.</p>
                  </div>
                </div>
              </div> } 
              { currentUser.canUploadImages && <div className="rounded-md bg-blue-50 p-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <svg className="h-5 w-5 text-blue-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fillRule="evenodd" d="M19 10.5a8.5 8.5 0 11-17 0 8.5 8.5 0 0117 0zM8.25 9.75A.75.75 0 019 9h.253a1.75 1.75 0 011.709 2.13l-.46 2.066a.25.25 0 00.245.304H11a.75.75 0 010 1.5h-.253a1.75 1.75 0 01-1.709-2.13l.46-2.066a.25.25 0 00-.245-.304H9a.75.75 0 01-.75-.75zM10 7a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
                    </svg>
                  </div>
                  <div className="ml-3 flex-1 md:flex md:justify-between">
                    <p className="text-sm text-blue-700">You can add images by <b>dragging</b> or <b>pasting</b> them into the editor.</p>
                  </div>
                </div>
              </div> } 
              <div className="mt-1 shadow-sm w-full min-w-full">
                <TipTapProject
                  html={description}
                  idx={tipTapIdx.current}
                  imageUploadSupported={currentUser.canUploadImages}
                  updateHtml={(val) => handleUpdateTipTap('description', val)}
                  placeholder='Provide a detailed description of your project.'
                />
              </div>
            </div>
          </div>
        </form>

        { errors.base && <div className='mt-2 text-sm text-red-600'>{errors.base[0]}</div> }
      </Card>

    </div>
  </>
}

export default OptionalInfoForm

OptionalInfoForm.propTypes = {
  initialProject: PropTypes.object.isRequired
}
