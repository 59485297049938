import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { MicrophoneIcon, CheckCircleIcon, BanIcon, ClipboardListIcon } from '@heroicons/react/solid'
import useQuery from '@/hooks/useQuery'

import Loading from '@/shared/Loading'
import PlayPauseButton from '@/shared/Buttons/PlayPauseButton'
import Card from '@/shared/Card'
import UserHoverCard from '@/shared/UserHoverCard'

const UnreviewedSubmissionRow = ({ sub }) => {
  const { putpostRequest } = useQuery()
  const [reviewed, setReviewed] = useState(false)

  const review = (action) => {
    putpostRequest(`/api/v3/auditions/${sub.id}/change_review_status`, 'POST', { status: action }, (err, jsonData) => {
      if (err) { /* hook */ }
      setReviewed(true)
    })
  }

  if (reviewed) return null

  return <li className='bg-white dark:bg-gray-700 rounded-lg border border-gray-200 dark:border-gray-700'>
      <div className="p-1">
        <div className='flex justify-between'>
          <div className="flex items-start space-x-3 overflow-hidden truncate">
            <div className="relative m-1">
              <img className="h-10 w-10 rounded-sm bg-gray-200 flex items-center justify-center ring-4 ring-cccpurple" src={sub.publicImageUrl} alt={sub.projectName} />

              <span className="absolute -bottom-0.5 -right-1 bg-cccpurple rounded-tl px-0.5 py-px">
                <MicrophoneIcon className="h-4 w-4 text-white" aria-hidden="true" />
              </span>
            </div>
            <div className="min-w-0 flex-1">
              <div className='flex items-center'>
                <PlayPauseButton mediaUrl={sub.audioUrl} objectId={sub.id} objectKind='Audition' />
                <div className='flex flex-col'>
                  <div className='ml-2'>
                    <UserHoverCard username={sub.username} displayName={sub.displayName} />
                  </div>
                  <a href={`/auditions/${sub.id}`} className="ml-1 font-medium text-cccblue cursor-pointer truncate">
                    {sub.projectName}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-0.5 text-sm text-gray-500 ml-1">
            {sub.timeAgo}
          </div>
        </div>
        <div className="mt-2 flex space-x-8">
          <div className="flex space-x-6">
            <button onClick={() => review('shortlist')} type="button" className="flex space-x-1 text-gray-400 hover:text-gray-500 dark:text-gray-400 dark:hover:text-gray-300 items-center">
              <ClipboardListIcon className="h-5 w-5" aria-hidden="true" />
              <span className="text-xs">Shortlist</span>
            </button>
          </div>
          <div className="flex space-x-6">
            <button onClick={() => review('reject')} type="button" className="flex space-x-1 text-gray-400 hover:text-gray-500 dark:text-gray-400 dark:hover:text-gray-300 items-center">
              <BanIcon className="h-5 w-5" aria-hidden="true" />
              <span className="text-xs">Dismiss</span>
            </button>
          </div>
          <div className="flex space-x-6">
            <button onClick={() => review('cast')} type="button" className="flex space-x-1 text-gray-400 hover:text-gray-500 dark:text-gray-400 dark:hover:text-gray-300 items-center">
              <CheckCircleIcon className="h-5 w-5" aria-hidden="true" />
              <span className="text-xs">Mark as Chosen</span>
            </button>
          </div>
        </div>
      </div>
    </li>
}

const NoSubmissions = () => {
    return <span>
      <a href='/manage/projects' className="relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-2 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccblue flex justify-start items-center" >
        <MicrophoneIcon className="h-6 w-6 text-gray-400" />
        <span className="text-sm font-medium text-gray-900 dark:text-gray-300"> You have no unreviewed submissions.</span>
      </a>
    </span>
}

const UnreviewedProjectSubmissions = () => {
  const { getRequest } = useQuery()
  const [submissions, setSubmissions] = useState([])
  const [submissionsCount, setSubmissionsCount] = useState(0)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    getRequest('/api/v3/dashboard/unreviewed_submissions', {}, (err, jsonData) => {
      setLoading(false)
      if (err) { /* handled in hook */ return }
      setSubmissions(jsonData.submissions)
      setSubmissionsCount(jsonData.submissionsCount)
    })
  }, [])

  // TODO: should make an all reviewed auditions page and link to it from here
  return <>
    <Card title={
      <span className='flex items-center'>
        <MicrophoneIcon className="h-6 w-6 mr-1" />
        Unreviewed Project Submissions ({submissionsCount})
      </span>
      } footer={<a href='/manage/projects'>All Project Submissions</a> } light>
      <ul className="space-y-3">
        { submissions.map((sub) => (
          <UnreviewedSubmissionRow key={`dashsub${sub.id}`} sub={sub} />
        ))}
      </ul>
      { loading && <Loading /> }
      { !loading && submissions.length === 0 && <NoSubmissions /> }
    </Card>
  </>
}
export default UnreviewedProjectSubmissions

UnreviewedSubmissionRow.propTypes = {
  sub: PropTypes.object.isRequired
}
