import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { EditorContent, useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import Placeholder from '@tiptap/extension-placeholder'
import Link from '@tiptap/extension-link'
import ImageExtension from '@tiptap/extension-image'
import { ViewListIcon } from '@heroicons/react/solid'
import Underline from '@tiptap/extension-underline'
import axios from 'axios'

import useQuery from '@/hooks/useQuery'


const MenuBar = ({ editor }) => {
  if (!editor) {
    return null
  }

  return (
    <div className='w-full flex flex-wrap border-b border-gray-200 text-sm text-gray-600 dark:text-gray-300'>
      <div tabIndex='-1' onClick={() => editor.chain().focus().toggleBold().run()} className={`outline-none focus:outline-none border-r border-gray-200 w-8 h-8 cursor-pointer inline-flex items-center justify-center ${editor.isActive('bold') ? 'text-cccblue' : 'hover:text-cccblue'}`} > B </div>
      <div tabIndex='-1' onClick={() => editor.chain().focus().toggleItalic().run()} className={`outline-none focus:outline-none border-r border-gray-200 w-8 h-8 cursor-pointer inline-flex items-center justify-center ${editor.isActive('italic') ? 'text-cccblue' : 'hover:text-cccblue'}`} >I </div>
      <div tabIndex='-1' onClick={() => editor.chain().focus().toggleUnderline().run()} className={`outline-none focus:outline-none border-r border-gray-200 w-8 h-8 cursor-pointer inline-flex items-center justify-center ${editor.isActive('underline') ? 'text-cccblue' : 'hover:text-cccblue'}`} > U </div>
      <div tabIndex='-1' onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()} className={`outline-none focus:outline-none border-r border-gray-200 w-8 h-8 cursor-pointer inline-flex items-center justify-center ${editor.isActive('heading', { level: 1 }) ? 'text-cccblue' : 'hover:text-cccblue'}`} > h1 </div>
      <div tabIndex='-1' onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()} className={`outline-none focus:outline-none border-r border-gray-200 w-8 h-8 cursor-pointer inline-flex items-center justify-center ${editor.isActive('heading', { level: 2 }) ? 'text-cccblue' : 'hover:text-cccblue'}`} > h2 </div>
      <div tabIndex='-1' onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()} className={`outline-none focus:outline-none border-r border-gray-200 w-8 h-8 cursor-pointer inline-flex items-center justify-center ${editor.isActive('heading', { level: 3 }) ? 'text-cccblue' : 'hover:text-cccblue'}`} > h3 </div>
      <div tabIndex='-1' onClick={() => editor.chain().focus().toggleBulletList().run()} className={`outline-none focus:outline-none border-r border-gray-200 w-8 h-8 cursor-pointer inline-flex items-center justify-center ${editor.isActive('bulletList') ? 'text-cccblue' : 'hover:text-cccblue'}`} > <ViewListIcon className='h-5 w-5 mx-auto' /> </div>
      <div tabIndex='-1' onClick={() => editor.chain().focus().toggleCodeBlock().run()} className={`outline-none focus:outline-none border-r border-gray-200 w-8 h-8 cursor-pointer inline-flex items-center justify-center ${editor.isActive('codeBlock') ? 'text-cccblue' : 'hover:text-cccblue'}`} > {'<>'} </div>
      <div tabIndex='-1' onClick={() => editor.chain().focus().setHorizontalRule().run()} className='outline-none focus:outline-none border-r border-gray-200 w-8 h-8 cursor-pointer inline-flex items-center justify-center hover:text-cccblue'> -- </div>
      <div tabIndex='-1' onClick={() => editor.chain().focus().toggleStrike().run()} className={`outline-none focus:outline-none border-r border-gray-200 w-8 h-8 cursor-pointer inline-flex items-center justify-center ${editor.isActive('strike') ? 'text-cccblue' : 'hover:text-cccblue'}`} > S̶ </div>
      <div tabIndex='-1' onClick={() => editor.chain().focus().unsetAllMarks().run()} className='outline-none focus:outline-none border-r border-gray-200 w-8 h-8 cursor-pointer inline-flex items-center justify-center hover:text-cccblue'> T̷ </div>
    </div>
  )
}

const ProgressBar = ({ progressPercentage }) => {
  let color = 'bg-cccpurple-alt'
  if (progressPercentage > 30 && progressPercentage <= 50) {
    color = 'bg-gradient-to-r from-cccpurple-alt to-cccpurple'
  } else if (progressPercentage > 50 && progressPercentage <= 70) {
    color = 'bg-gradient-to-r from-cccpurple-alt via-cccpurple to-cccblue'
  } else if (progressPercentage > 70) {
    color = 'bg-gradient-to-r from-cccpurple via-cccblue to-cccorange'
  }
  return (
    <div className='h-2 w-full bg-gray-300'>
      <div style={{ width: `${progressPercentage}%` }} className={`h-full ${color}`}> </div>
    </div>
  )
}

const TipTapProject = ({ html, updateHtml, idx, placeholder, imageUploadSupported }) => {

  const { getRequest } = useQuery()
  const [s3, setS3] = useState(imageUploadSupported ? null : true)
  useEffect(() => {
    getRequest('/api/v3/image_uploads/amazon_hash', {}, (err, jsonData) => {
      if (err) { return }
      setS3(jsonData)
    })
  }, [])
  if (!s3) { return null }

  return <TipTapProjectEditor html={html} updateHtml={updateHtml} idx={idx} placeholder={placeholder} s3={s3} imageUploadSupported={imageUploadSupported} />
}


const TipTapProjectEditor = ({ html, updateHtml, idx, placeholder, s3, imageUploadSupported }) => {
  const [progressPercentage, setProgressPercentage] = useState(0.0)

  async function uploadImageToS3(file) {
    var data = new FormData()
    var key = `image_uploads/${crypto.randomUUID()}`
    var urlbase = `https://${s3.bucket}.s3.amazonaws.com`

    data.append('key', key)
    data.append('AWSAccessKeyId', s3.access_key)
    data.append('acl', 'public-read')
    data.append('policy', s3.policy)
    data.append('signature', s3.signature)
    data.append('Content-Type', file.type)
    data.append('Content-Encoding', 'base64')
    data.append('file', file)
    const response = await axios.post(urlbase, data, axiosConfig)
    setProgressPercentage(0.0)
    return urlbase + '/' + key
  }

  const axiosConfig = {
    onUploadProgress: progressEvent => setProgressPercentage((progressEvent.loaded / progressEvent.total) * 100),
    headers: { 'X-CSRF-TOKEN': (document.head.querySelector('[name~=csrf-token]') || {}).content }
  }

  const extensions = [
    StarterKit,
    Underline,
    Link.configure({ openOnClick: false }),
    Placeholder.configure({ placeholder: placeholder })
  ]
  if (imageUploadSupported) { extensions.push(ImageExtension) }

  const editor = useEditor({
    extensions: extensions,
    onUpdate({ editor }) {
      updateHtml(editor.getHTML())
    },
    editorProps: {
      attributes: {
        class: 'wysiwyg prose prose-sm m-1 sm:m-3 focus:outline-none dark:text-gray-100' // outline color is in application_v3.scss
      },
      handlePaste: function(view, event, slice) {
        const hasFiles = event.clipboardData && event.clipboardData.files && event.clipboardData.files.length
        if (!hasFiles) { return }

        const images = Array.from( event.clipboardData.files).filter(file => /image/i.test(file.type))
        if (images.length === 0) { return }

        event.preventDefault()
        if (images.length > 5) {
          alert('You can only paste up to 5 images at a time.') 
          return
        }

        if (!imageUploadSupported) {
          alert('This is a premium feature. Please upgrade to use this feature.') 
          return
        }

        const { schema } = view.state

        images.forEach(async image => {
          let filesize = ((image.size/1024)/1024).toFixed(4); // get the filesize in MB
          if (filesize > 10) { // check valid image type under 10MB
            window.alert("Images need to be less than 10mb in size.");
            return true;
          }
          const reader = new FileReader();

          const node = schema.nodes.image.create({
            src: await uploadImageToS3(image),
          });
          const transaction = view.state.tr.replaceSelectionWith(node)
          view.dispatch(transaction)
        })

      },
      handleDrop: function(view, event, slice, moved) {
        if (!moved) { // if dropping external files
          const hasFiles = event.dataTransfer && event.dataTransfer.files && event.dataTransfer.files.length
          if (!hasFiles) { return false } // not handled

          const images = Array.from(event.dataTransfer.files) .filter(file => (/image/i).test(file.type));
          if (images.length === 0) { return }

          event.preventDefault();
          if (images.length > 5) {
            alert('You can only paste up to 5 images at a time.') 
            return
          }

          if (!imageUploadSupported) {
            alert('This is a premium feature. Please upgrade to use this feature.') 
            return
          }

          const { schema } = view.state;
          const coordinates = view.posAtCoords({ left: event.clientX, top: event.clientY });

          images.forEach(async image => {
            let filesize = ((image.size/1024)/1024).toFixed(4); // get the filesize in MB
            if (filesize > 10) { // check valid image type under 10MB
              window.alert("Images need to be less than 10mb in size.");
              return true;
            }
            const reader = new FileReader();

            if(uploadImageToS3) {
              const node = schema.nodes.image.create({
                src: await uploadImageToS3(image),
              });
              const transaction = view.state.tr.insert(coordinates.pos, node);
              view.dispatch(transaction)
            } else {
              // this will never be called, but I would like to figure out how to set the image from the local image file here and then replace the image when it is finished uploading
              reader.onload = readerEvent => {
                const node = schema.nodes.image.create({
                  src: readerEvent.target.result,
                });
                const transaction = view.state.tr.insert(coordinates.pos, node);
                view.dispatch(transaction)
              };
              reader.readAsDataURL(image)
            }
          })

          return

          /// below works, but chonky
          // below never gets called but it's a reference on how to work with images and local files

          let file = event.dataTransfer.files[0]; // the dropped file
          let filesize = ((file.size/1024)/1024).toFixed(4); // get the filesize in MB
          if (filesize > 10) { // check valid image type under 10MB
            window.alert("Images need to be less than 10mb in size.");
            return true; // handled
          }
          if (file.type !== "image/jpeg" && file.type !== "image/png") { // check valid image type
            window.alert("Images need to be in jpg or png format");
            return true; // handled
          }
          // check the dimensions
          let _URL = window.URL || window.webkitURL;
          let img = new Image(); /* global Image */
          img.src = _URL.createObjectURL(file);
          img.onload = function () {
            if (this.width > 5000 || this.height > 5000) {
              window.alert("Your images need to be less than 5000 pixels in height and width."); // display alert
            } else {
              // valid image so upload to server
              // uploadImage will be your function to upload the image to the server or s3 bucket somewhere
              uploadImage(file).then(function(response) { // response is the image url for where it has been saved
                // pre-load the image before responding so loading indicators can stay
                // and swaps out smoothly when image is ready
                let image = new Image();
                image.src = response;
                image.onload = function() {
                  // place the now uploaded image in the editor where it was dropped
                  const { schema } = view.state;
                  const coordinates = view.posAtCoords({ left: event.clientX, top: event.clientY });
                  const node = schema.nodes.image.create({ src: response }); // creates the image element
                  const transaction = view.state.tr.insert(coordinates.pos, node); // places it in the correct position
                  return view.dispatch(transaction);
                }
              }).catch(function(error) {
                if (error) {
                  window.alert("There was a problem uploading your image, please try again.");
                }
              });
            }
          };
          return true; // handled
        }
        return false; // not handled use default behaviour
      }
    },
    content: html
  })

  useEffect(() => {
    if (idx > 1) { editor?.commands.clearContent(true) }
  }, [idx])

  return (
    <div className="bg-white dark:bg-gray-900 border border-gray-200 rounded-md">
      { progressPercentage > 0 && <ProgressBar progressPercentage={progressPercentage} /> }
      <MenuBar editor={editor} />
      <EditorContent editor={editor} />
    </div>
  )
}

export default TipTapProject

TipTapProject.propTypes = {
  html: PropTypes.string,
  updateHtml: PropTypes.func.isRequired,
  idx: PropTypes.number
}
