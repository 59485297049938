import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useGlobalState } from '@/state'
import useQuery from '@/hooks/useQuery'
import SVGIcon from '@/components/icon'

import classNames from '@/utils/classNamesLocal'
import PublicProfileGeneral from '@/pages/Users/PublicProfile/PublicProfileGeneral'
import PublicProfileSubmissions from '@/pages/Users/PublicProfile/PublicProfileSubmissions'
import PublicFollowers from '@/pages/Users/PublicProfile/PublicFollowers'
import PublicFollowings from '@/pages/Users/PublicProfile/PublicFollowings'
import PublicProjects from '@/pages/Users/PublicProfile/PublicProjects'
import GeneralOptions from '@/pages/Users/PublicProfile/GeneralOptions'
import Sidebar from '@/pages/Users/PublicProfile/Sidebar'
import DirectMessageModal from '@/shared/DirectMessageModal'
import RecommendationModal from '@/shared/RecommendationModal'

const publicProfileTabs = [
  { name: 'Profile' },
  { name: 'Followers' },
  { name: 'Following' },
  { name: 'Submissions' },
  { name: 'Projects' }
]

const TabCount = ({ number, selected }) => {
  return <>
    <span className={classNames(
      selected ? 'bg-cccblue text-white dark:bg-white dark:text-cccblue' : 'bg-gray-200 text-gray-900',
      'hidden ml-2 py-0.5 px-1.5 rounded-full text-xs font-medium md:inline-block'
    )} >
      {number}
    </span>
  </>
}

TabCount.propTypes = {
  number: PropTypes.number,
  selected: PropTypes.bool
}

const PublicProfile = () => {
  const [profileContainer] = useGlobalState('profileContainer')
  const urlParams = new window.URLSearchParams(window.location.search)
  const { putpostRequest } = useQuery()
  const [, setToast] = useGlobalState('toast')
  const defaultTab = urlParams.get('tab')
  const [currentTab, setCurrentTab] = useState(defaultTab || 'Profile')
  const { user, isFollowing, blocked, blocking } = profileContainer
  const [following, setFollowing] = useState(isFollowing)

  const changeMobileTab = (e) => { setCurrentTab(e.target.value) }

  const follow = () => {
    putpostRequest(`/api/v3/follows/${user.id}/follow_user`, 'POST', {}, (err, jsonData) => {
      if (err) {
        if (typeof err === 'string') { // 422 custom error
          setToast(<div className="ml-3 w-0 flex-1 pt-0.5">
            <p className="text-sm font-medium text-red-500 dark:text-red-500">Hol up</p>
            <p className="mt-1 text-sm dark:text-gray-100 text-gray-500">{err}</p>
          </div>)
        }
        return
      }
      setFollowing(!following)
      setToast(<div className="ml-3 w-0 flex-1 pt-0.5">
        <p className="text-sm font-medium dark:text-white text-gray-800">{!following === true ? 'Following!' : 'Stopped following' }</p>
        <p className="mt-1 text-sm dark:text-gray-100 text-gray-500">{!following === true ? 'You will see activity from this person in your dashboard.' : 'You will no longer see activity about this person'}</p>
      </div>)
    })
  }

  if (blocked) {
    return <div key={`profile${user.id}`}>
      <main className="flex items-center justify-center">
        <h3 className='mt-5 text-gray-900 dark:text-gray-100'>This person has blocked you.</h3>
      </main>
    </div>
  }

  return (<div key={`profile${user.id}`}>
      <div className="grid grid-cols-3 sm:space-x-5 space-y-4 sm:space-y-0">
        <section className='col-span-3 sm:col-span-2'>
          {/* Profile header */}
          <div>
            <div>
              <img className="h-40 w-full object-cover lg:h-48 shadow-md rounded rounded-lg" src={user.coverPublicImageUrl} alt="" />
            </div>
            <div className="max-w-5xl mx-auto">
              <div className="-mt-12 ml-6 sm:-mt-16 sm:flex sm:items-end">
                <div className="flex items-end w-full justify-between">
                  <img className="h-24 w-24 rounded-sm ring-4 ring-white dark:ring-gray-700 sm:h-32 sm:w-32" src={user.largeImageUrl} alt={user.displayName} />
                  <div className='flex justify-end space-x-1'>
                    <GeneralOptions user={user} blocking={blocking} />
                    <button onClick={follow} type="button" className="inline-flex space-x-1 justify-center p-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white dark:bg-gray-800 dark:text-gray-200 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccblue" >
                      <SVGIcon name='icon-followers' className='h-7 w-7 sm:h-5 sm:w-5' />
                      <span className='hidden sm:block'>{following ? 'Unfollow' : 'Follow' }</span>
                    </button>
                    <DirectMessageModal recipient={user} />
                    <RecommendationModal user={user} />
                  </div>
                </div>
                <div className="flex-1 flex flex-col sm:flex-row sm:items-center sm:justify-end sm:space-x-3 space-y-1">

                </div>
              </div>
              <div className='mt-4' />
              { user.isStaff && <div className='inline-flex justify-center p-1 m-1 shadow-sm text-sm text-white font-medium rounded-md bg-gradient-to-r from-cccpurple via-cccblue to-cccorange text-white'>CCC Staff Member</div> }
              <div>
                <div className="sm:hidden">
                  <label htmlFor="tabs" className="sr-only" hidden> Select a tab </label>
                  <select id="tabs" name="tabs" className="block w-full focus:ring-cccblue focus:border-cccblue border-gray-300 rounded-md" defaultValue={currentTab} onChange={changeMobileTab}>
                    {publicProfileTabs.map((tab) => (
                      <option key={`mobile${tab.name}`}>{tab.name}</option>
                    ))}
                  </select>
                </div>
                <div className="hidden sm:block">
                  <nav className="relative z-0 rounded-lg shadow flex divide-x divide-gray-200" aria-label="Tabs">
                    {publicProfileTabs.map((tab, tabIdx) => (
                      <div key={`desk${tab.name}`} onClick={() => setCurrentTab(tab.name)} className={classNames(
                        tab.name === currentTab ? 'text-gray-900 dark:text-gray-200 dark:bg-gray-800' : 'text-gray-500 hover:text-cccblue dark:text-gray-200 dark:bg-gray-800 dark:hover:text-cccblue',
                        tabIdx === 0 ? 'rounded-l-lg' : '',
                        tabIdx === publicProfileTabs.length - 1 ? 'rounded-r-lg' : '',
                        'group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10 cursor-pointer'
                      )}
                        aria-current={tab.name === currentTab ? 'page' : undefined}
                      >
                        <span className='flex justify-center'>
                          {tab.name}
                          { tab.name === 'Followers' && user.followersCount > 0 && <TabCount number={user.followersCount} selected={tab.name === currentTab} /> }
                          { tab.name === 'Following' && user.followingCount > 0 && <TabCount number={user.followingCount} selected={tab.name === currentTab} /> }
                          { tab.name === 'Submissions' && user.auditionsCount > 0 && <TabCount number={user.auditionsCount} selected={tab.name === currentTab} /> }
                          { tab.name === 'Projects' && user.projectsCount > 0 && <TabCount number={user.projectsCount} selected={tab.name === currentTab} /> }
                        </span>
                        <span aria-hidden="true" className={classNames(tab.name === currentTab ? 'bg-cccblue' : 'bg-transparent', 'absolute inset-x-0 bottom-0 h-0.5')} />
                      </div>
                    ))}
                  </nav>
                </div>
              </div>
              <div className="mt-2 sm:mt-6 min-w-0 flex-1">
                { currentTab === 'Profile' && <PublicProfileGeneral /> }
                { currentTab === 'Followers' && <PublicFollowers /> }
                { currentTab === 'Following' && <PublicFollowings /> }
                { currentTab === 'Submissions' && <PublicProfileSubmissions /> }
                { currentTab === 'Projects' && <PublicProjects /> }
              </div>
            </div>
          </div>
        </section>
        <Sidebar />
      </div>
    </div>
  )
}
export default PublicProfile
