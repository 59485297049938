import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'

import Loading from '@/shared/Loading'
import useQuery from '@/hooks/useQuery'
import { ChevronDoubleRightIcon, StarIcon } from '@heroicons/react/solid'

import RoleIndex from '@/pages/ManageProjects/ProjectEdit/RoleIndex'
import AboutYou from '@/pages/ManageProjects/AboutYou'
import RequiredInfoForm from '@/pages/ManageProjects/ProjectEdit/RequiredInformation'
import OptionalInfoForm from '@/pages/ManageProjects/ProjectEdit/OptionalInformation'
import ProjectImage from '@/pages/ManageProjects/ProjectEdit/ProjectImage'

const DoneStep = () => {
  return <>
    <div className="absolute inset-0 flex items-center" aria-hidden="true">
      <div className="h-0.5 w-full bg-cccblue"></div>
    </div>
    <div className="relative flex h-8 w-8 items-center justify-center rounded-full bg-cccblue">
      <svg className="h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
        <path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
      </svg>
    </div>
  </>
}

const DoingStep = () => {
  return <>
    <div className="absolute inset-0 flex items-center" aria-hidden="true">
      <div className="h-0.5 w-full bg-cccblue"></div>
    </div>
    <div className="relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-cccblue bg-white" aria-current="step">
      <span className="h-2.5 w-2.5 rounded-full bg-cccblue" aria-hidden="true"></span>
    </div>
  </>
}
const FutureStep = () => {
  return <>
    <div className="absolute inset-0 flex items-center" aria-hidden="true">
      <div className="h-0.5 w-full bg-cccblue"></div>
    </div>
    <div className="group relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-cccblue bg-white">
    </div>
  </>
}

const Step = ({step, thisStep, totalSteps}) => {
  const styles = thisStep === totalSteps ? '' : ' pr-8 sm:pr-20'
  return <li className={`relative ${styles}`}>
    { thisStep < step && <DoneStep /> }
    { thisStep === step && <DoingStep /> }
    { thisStep > step && <FutureStep /> }
  </li>
}

const ProjectEdit = () => {
  const { id } = useParams()
  const [project, setProject] = useState(null)
  const { getRequest, putpostRequest } = useQuery()
  const [step, setStep] = useState(1)
  const [loading, setLoading] = useState(false)
  const totalSteps = 4

  let stepName = 'Add a Role'
  if (step === 2) { stepName = 'Project Details' }
  if (step === 3) { stepName = 'About You' }
  if (step === 4) { stepName = 'Publish!' }

  useEffect(() => {
    getRequest(`/api/v3/manage/projects/${id}/edit`, {}, (err, jsonData) => {
      if (err) { /* handled in hook */ return }
      setProject(jsonData.project)
    })
  }, [id])

  const publish = () => {
    const data = { status: 'open' }

    setLoading(true)
    putpostRequest(`/api/v3/manage/projects/${project.id}/change_status`, 'POST', data, (err, jsonData) => {
      setLoading(false)
      if (err) { /* hook */ return }

      if (jsonData.status === 'open') { window.location.href = `/manage/projects/${project.id}?published=true` }
    })
  }

  if (!project) { return <Loading /> }

  return <div className='mt-5'>

    <RequiredInfoForm initialProject={project} />
    { step > 1 && <>
      <div className='mt-10' />
      <RoleIndex project={project} />
    </> }
    { step > 2 && <>
      <div className='mt-10' />
      <OptionalInfoForm initialProject={project} />
      <div className='mt-10' />
      <ProjectImage project={project} />
    </> }
    { step > 3 && <>
      <div className='mt-10' />
      <AboutYou />
    </> }

    <div className='mt-10 flex flex-col justify-center items-center space-y-5 text-gray-900 dark:text-gray-100 bg-cccblue bg-opacity-20 p-2 rounded-md'>
      <nav aria-label="Progress">
        <ol role="list" className="flex items-center">
          <Step step={step} thisStep={1} totalSteps={totalSteps} />
          <Step step={step} thisStep={2} totalSteps={totalSteps} />
          <Step step={step} thisStep={3} totalSteps={totalSteps} />
          <Step step={step} thisStep={4} totalSteps={totalSteps} />
        </ol>
      </nav>
      <div className=''>
        { step < totalSteps && <button onClick={() => setStep(step + 1)} className="px-4 py-2 text-sm font-medium rounded-md text-white hover:text-white bg-gradient-to-r from-cccpurple via-cccblue to-cccorange hover:from-cccpurple hover:to-cccorange flex items-center justify-center space-x-2" >
          <span>Next step: {stepName}</span>
          <ChevronDoubleRightIcon className='h-4 w-4' />
        </button> }
        { step === totalSteps && <button onClick={publish} className="px-4 py-2 text-sm font-medium rounded-md text-white hover:text-white bg-gradient-to-r from-cccpurple via-cccblue to-cccorange hover:from-cccpurple hover:to-cccorange flex items-center justify-center space-x-2" >
          <StarIcon className='h-4 w-4 animate-bounce' />
          <span>Publish!</span>
          <StarIcon className='h-4 w-4 animate-bounce' />
        </button> }
      </div>
    </div>
  </div>
}

export default ProjectEdit

ProjectImage.propTypes = {
  project: PropTypes.object.isRequired
}
