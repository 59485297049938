import React, { useState, useEffect } from 'react'
import { ResponsiveContainer, LineChart, YAxis, XAxis, Tooltip, Legend, CartesianGrid, Line } from 'recharts'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useGlobalState } from '@/state'
import { TrendingUpIcon, ExclamationIcon } from '@heroicons/react/solid'
import useQuery from '@/hooks/useQuery'

import Card from '@/shared/Card'
import LineChartSample from '@/LineChartSample'
import Loading from '@/shared/Loading'

const ProfileStatsSample = () => {
  return <Card title={
      <span className='flex items-center'>
        <TrendingUpIcon className="h-6 w-6 mr-1" />
        Profile Stats
      </span>
      } footer={<Link to='/dashboard/profile_views'>Detailed Stats</Link> } light>
    <div className='relative flex justify-center'>
      <div className="absolute z-10 align-bottom bg-white dark:bg-gray-700 rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all my-2 sm:my-8 align-middle max-w-lg w-full p-2 sm:p-6">
        <div className="sm:flex sm:items-start">
          <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-cccorange dark:bg-cccpurple sm:mx-0 sm:h-10 sm:w-10">
            <ExclamationIcon className="h-6 w-6 text-white" aria-hidden="true" />
          </div>
          <div className="mt-3 text-center dark:text-gray-100 sm:mt-0 sm:ml-4 sm:text-left">
            These are sample stats
            <div className="mt-2">
              <p className="text-sm text-gray-500 dark:text-gray-300">
                Full profile analytics is a premium feature. Premium features keep CCC's lights on. Please consider upgrading.
              </p>
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-4 sm:ml-10 sm:pl-4 sm:flex">
          <Link to='/subscriptions' className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-cccblue text-base hover:text-white font-medium text-white sm:w-auto sm:text-sm" >
            Fancy! Can I see the plans?
          </Link>
        </div>
      </div>
    </div>
    <div className='opacity-20'>
      <LineChartSample />
    </div>
  </Card>
}

const ProfileLineChart = ({ stats }) => {
  const [theme] = useGlobalState('theme')
  const margins = { top: 0, right: 0, left: 0, bottom: 0 }
  let fillColor = '#6D6E71'
  if (theme === 'dark') { fillColor = '#E5E7EB' }

  const renderColorfulLegendText = (value, entry) => {
    const { color } = entry

    return <span style={{ color }}>{value}</span>
  }

  return <>
    <div style={{ minWidth: '300px', height: '315px' }}>
      <ResponsiveContainer margin={margins} >
        <LineChart data={stats} margin={{ top: 0, right: 0, left: -30, bottom: 0 }} >
          <YAxis padding={margins} margin={margins} stroke={fillColor} />
          <XAxis padding={{ left: 0 }} dataKey="date" stroke={fillColor} />
          <Tooltip />
          <Legend formatter={renderColorfulLegendText} />
          <CartesianGrid stroke={fillColor} />
          <Line
            name="Profile Views" type="monotone" dataKey="Profile Views" stroke="#ff7300" strokeWidth={3}
            activeDot={{ stroke: '#ff7300', strokeWidth: 2, r: 10 }}
            dot={{ stroke: '#ff7300', strokeWidth: 2, r: 10 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  </>
}

const ProfileStats = () => {
  const [currentUser] = useGlobalState('currentUser')
  const { getRequest } = useQuery()
  const [loading, setLoading] = useState(false)
  const [stats, setStats] = useState([])

  const loadStats = () => {
    setLoading(true)
    getRequest('/api/v3/dashboard/stats', {}, (err, jsonData) => {
      setLoading(false)
      if (err) { /* hook */ }
      setStats(jsonData.data)
    })
  }

  useEffect(() => {
    if (currentUser.canViewProfileAnalytics) { loadStats() }
  }, [])

  if (!currentUser.canViewProfileAnalytics) { return <ProfileStatsSample /> }

  return <Card title={
      <span className='flex items-center'>
        <TrendingUpIcon className="h-6 w-6 mr-1" />
        Profile Stats
      </span>
      } footer={<Link to='/dashboard/profile_views'>Detailed Stats</Link> } light>
      <ProfileLineChart stats={stats} />
    {loading && <Loading /> }
  </Card>
}
export default ProfileStats

ProfileLineChart.propTypes = {
  stats: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
}
