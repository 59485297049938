import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useGlobalState } from '@/state'
import useQuery from '@/hooks/useQuery'

import PublicUserRow from '@/pages/Users/PublicProfile/PublicUserRow'
import Card from '@/shared/Card'
import StatusPill from '@/shared/StatusPill'
import LineChartSample from '@/LineChartSample'

const FeaturedUsers = () => {
  const [featuredUsers, setFeaturedUsers] = useState([])
  const { getRequest } = useQuery()

  useEffect(() => {
    getRequest('/api/v3/users/featured', { sample: true }, (err, jsonData) => {
      if (err) { /* hooks */ return }
      setFeaturedUsers(jsonData.featuredUsers)
    })
  }, [])

  if (featuredUsers.length === 0) { return null }

  return <Card light title='Featured Members'>
    <ul className="space-y-3">
      {featuredUsers.map((user, idx) => (
        <PublicUserRow key={`fuser${user.id}`} user={user} followingUserIds={[]} />
      ))}
    </ul>
  </Card>
}

const PerksIndex = () => {
  const { getRequest } = useQuery()
  const [currentUser] = useGlobalState('currentUser')
  const [clcPromoCode, setClcPromoCode] = useState(null)

  useEffect(() => {
    if (currentUser.gold === 'master' || currentUser.gold === 'business') {
      getRequest('/api/v3/subscriptions/clc_promo_code', {}, (err, jsonData) => {
        if (err) { /* hooks */ return }
        setClcPromoCode(jsonData.clcPromoCode)
      })
    }
  }, [])

  let planName = 'Free'
  if (['maker', 'apprentice', 'master', 'gold', 'business'].includes(currentUser.gold)) { planName = currentUser.gold }
  if (['silver', 'dollar'].includes(currentUser.gold)) { planName = 'Legacy Silver/Dollar' }

  return <div className='mt-3'>
    <div className="md:grid md:grid-cols-4 md:gap-6">
      <div className="md:col-span-1">
        <div className="p-4 sm:px-0 flex justify-between">
          <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-100">Your Plan</h3>
          <StatusPill status={`${planName === 'Free' ? 'default' : 'success'}`} text={planName} />
        </div>
      </div>
      <Card title='Plan Details' footer={<Link to='/subscriptions'>View Plans</Link> }>
        <div className="grid grid-cols-3 gap-6">
          <div className='col-span-3'>
            <div className="sm:rounded-md">
              {planName === 'Free' && <>
                <p className='prose prose-sm sm:prose dark:text-gray-300'>Thanks so much for trying out Casting Call Club. Remember that CCC is free to use as long as you want. It really helps us out when you upgrade to a premium plan so we can hire moderators, source paid projects for you, and continue to keep CCC up and operational.</p>
                <p className='prose prose-sm sm:prose mt-3 dark:text-gray-300'>If you decide to upgrade, you'll get the perks outlined below!</p>
              </> }
              {currentUser.gold && <>
                <p className='prose prose-sm sm:prose dark:text-gray-300'>Thanks so much for supporting Casting Call Club! We are always striving to make CCC a better place for everyone and it's people like you who make it happen. It really helps us out when you upgrade to a premium plan so we can hire moderators, source paid projects for you, and continue to keep CCC up and operational.</p>
                <p className='prose prose-sm sm:prose mt-3 text-green-500 dark:text-green-400'>Because you're a premium member supporting CCC, you'll get the perks outlined below!</p>
              </> }
            </div>
          </div>
        </div>
      </Card>
      <div className="md:col-span-1" />
    </div>

    <div className="md:grid md:grid-cols-4 md:gap-6 py-2 sm:py-0">
      <div className="md:col-span-1 py-4 sm:py-0">
        <div className="p-4 sm:px-0">
          <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-100">General Perks</h3>
          <p className="mt-1 text-sm text-gray-600 dark:text-gray-300">
            Neat general enhancements for the Apprentice, Maker, and Business Plans
          </p>
        </div>
      </div>
      <Card title='General' light>
        <div className='col-span-3 p-3 bg-gray-100 rounded-lg dark:bg-gray-700'>
          <div className='flex justify-center'>
            <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-100 text-center">No Ads </h3>
          </div>
          <div className='grid grid-cols-3 mt-2 flex items-center'>
            <div className='sm:col-span-1 col-span-3 py-2 sm:py-0 text-sm text-gray-700 dark:text-gray-300 mr-2'>
              You can use an ad-blocker or get a premium plan to be ad-free.
            </div>
            <div className='sm:col-span-2 col-span-3'>
              <div className='overflow-hidden shadow-lg rounded-lg aspect-w-3 aspect-h-2'>
                <img src='https://ddppjbdexhxzj.cloudfront.net/kitkat-ad.jpg' className='object-cover w-full' />
              </div>
            </div>
          </div>
        </div>
        <div className='col-span-3 p-3 bg-gray-100 rounded-lg dark:bg-gray-700'>
          <div className='flex justify-center'>
            <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-100 text-center">Monthly cccCoins </h3>
          </div>
          <div className='grid grid-cols-3 mt-2 flex items-center'>
            <div className='sm:col-span-1 col-span-3 py-2 sm:py-0 text-sm text-gray-700 dark:text-gray-300 mr-2'>
              You can use cccCoins to basically save the planet by giving cute awards to other people on the site, which not only makes them feel really nice, but it also gifts them <b>premium</b> access!
            </div>
            <div className='sm:col-span-2 col-span-3'>

              <div className='grid grid-cols-3 my-4'>
                <a to='/coins' className='flex w-full col-span-3 sm:col-span-2'>
                  <div className={'bg-cccblue flex-shrink-0 flex items-center justify-center w-16 text-white text-sm font-medium rounded-l-md'}>
                    <img className='h-10 w-10' src="https://ddppjbdexhxzj.cloudfront.net/icons/CCC_COIN.svg" />
                  </div>
                  <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
                    <div className="flex-1 px-4 py-2 text-sm truncate">
                      <div className="text-gray-900 font-medium hover:text-gray-600"> CccCoins </div>
                      <p className="text-gray-500">You have {currentUser.coins} CccCoins</p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </Card>
      <div className="md:col-span-1" />
      <Card title='Profile' footer={<Link to='/profile'>Edit Profile</Link> } light>
        <div className="grid grid-cols-3 gap-6">

          <div className='col-span-3 p-3 bg-gray-100 rounded-lg dark:bg-gray-700'>
            <div className='flex justify-center'>
              <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-100 text-center">Profile Analytics </h3>
            </div>
            <div className='mt-2'>
              <LineChartSample />
            </div>
          </div>

          <div className='col-span-3 p-3 bg-gray-100 rounded-lg dark:bg-gray-700'>
            <div className='flex justify-center items-center'>
              <StatusPill status='new' text='New' />
              <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-100 text-center">Promote Your Profile </h3>
            </div>
            <div className='grid grid-cols-3 mt-2 flex items-center'>
              <div className='sm:col-span-1 col-span-3 py-2 sm:py-0 text-sm text-gray-700 dark:text-gray-300 mr-2'>
                If you have a <b>Business or Maker</b> plan, you will appear on other people's profile as a Featured Member. The algorithms will do their best to match which profiles you appear on to similar members.
              </div>
              <div className='sm:col-span-2 col-span-3'>
                <FeaturedUsers />
              </div>
            </div>
          </div>

          <div className='col-span-3 p-3 bg-gray-100 rounded-lg dark:bg-gray-700'>
            <div className='flex justify-center'>
              <h3 className="sm:text-lg font-medium leading-6 text-gray-900 dark:text-gray-100 text-center">Message People Who Don't Follow You</h3>
            </div>
            <div className='grid grid-cols-3 mt-2 flex items-center'>
              <div className='sm:col-span-1 col-span-3 py-2 sm:py-0 text-sm text-gray-700 dark:text-gray-300 mr-2'>
                You can DM people without slow rate limits and DM people who aren't following you.
              </div>
              <div className='sm:col-span-2 col-span-3'>
                <div className='overflow-hidden shadow-lg rounded-lg aspect-w-3 aspect-h-2'>
                  <img src='https://ddppjbdexhxzj.cloudfront.net/perks/message-non-followers.png' className='object-cover w-full' />
                </div>
              </div>
            </div>
          </div>

          <div className='col-span-3 p-3 bg-gray-100 rounded-lg dark:bg-gray-700'>
            <div className='flex justify-center'>
              <h3 className="sm:text-lg font-medium leading-6 text-gray-900 dark:text-gray-100 text-center">Profile Promoted Higher in Find Talent </h3>
            </div>
            <div className='grid grid-cols-3 mt-2 flex items-center'>
              <div className='sm:col-span-1 col-span-3 py-2 sm:py-0 text-sm text-gray-700 dark:text-gray-300 mr-2'>
                Your profile will appear higher in the Find Talent search results when people are looking for a specific person.
              </div>
              <div className='sm:col-span-2 col-span-3'>
                <div className='overflow-hidden shadow-lg rounded-lg aspect-w-3 aspect-h-1 w-full'>
                  <img src='https://ddppjbdexhxzj.cloudfront.net/perks/find-talent-featured.jpg' className='object-cover' />
                </div>
              </div>
            </div>
          </div>

          <div className='col-span-3 p-3 bg-gray-100 rounded-lg dark:bg-gray-700'>
            <div className='flex justify-center'>
              <h3 className="sm:text-lg font-medium leading-6 text-gray-900 dark:text-gray-100 text-center">Hide Other Profiles </h3>
            </div>

            <div className='grid grid-cols-3 mt-2 flex items-center'>
              <div className='sm:col-span-1 col-span-3 py-2 sm:py-0 text-sm text-gray-700 dark:text-gray-300 mr-2'>
                If you have the Apprentice, Maker, or Business Plan, you will be able to turn off other Featured Profiles so that they do not appear on your profile.
              </div>
              <div className='sm:col-span-2 col-span-3'>
                <div className='overflow-hidden shadow-lg rounded-lg aspect-w-5 aspect-h-1 w-full'>
                  <img src='https://ddppjbdexhxzj.cloudfront.net/perks/toggle-featured-profiles.png' className='object-cover' />
                </div>
              </div>
            </div>
          </div>

          <div className='col-span-3 p-3 bg-gray-100 rounded-lg dark:bg-gray-700'>
            <h3 className="sm:text-lg font-medium leading-6 text-gray-900 dark:text-gray-100 text-center">Unlimited Demos & Samples</h3>

            <div className='grid grid-cols-3 mt-2 flex items-center'>
              <div className='sm:col-span-1 col-span-3 py-2 sm:py-0 text-sm text-gray-700 dark:text-gray-300 mr-2'>
                Free memberships can only show off one demo or sample at a time.
              </div>
              <div className='sm:col-span-2 col-span-3'>
                <div className='overflow-hidden shadow-lg rounded-lg aspect-w-3 aspect-h-1 w-full'>
                  <img src='https://ddppjbdexhxzj.cloudfront.net/perks/multiple-demos.png' className='object-cover' />
                </div>
              </div>
            </div>
          </div>

        </div>
      </Card>
      <div className="md:col-span-1" />
    </div>

    <div className="md:grid md:grid-cols-4 md:gap-6 mt-3">
      <div className="md:col-span-1">
        <div className="p-4 sm:px-0">
          <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-100">Submissions</h3>
          <p className="mt-1 text-sm text-gray-600 dark:text-gray-300">
            Submission & audition enhancements for the Apprentice, Maker, and Business Plans
          </p>
        </div>
      </div>
      <Card title='Submission Perks' footer={<Link to='/submissions'>View Submissions</Link> }>
        <div className="grid grid-cols-3 gap-6">
          <div className='col-span-3 p-3 bg-gray-100 rounded-lg dark:bg-gray-700'>
            <h3 className="sm:text-lg font-medium leading-6 text-gray-900 dark:text-gray-100 text-center">Submission Analytics</h3>

            <div className='grid grid-cols-3 mt-2 flex items-center'>
              <div className='sm:col-span-1 col-span-3 py-2 sm:py-0 text-sm text-gray-700 dark:text-gray-300 mr-2'>
                Reveal number of interactions and if the project owner interacted with <i>all</i> submissions, even if it's not yours.
              </div>
              <div className='sm:col-span-2 col-span-3'>
                <div className='overflow-hidden shadow-lg rounded-lg aspect-w-3 aspect-h-1 w-full'>
                  <img src='https://ddppjbdexhxzj.cloudfront.net/perks/submission-analytics.png' className='object-cover' />
                </div>
              </div>
            </div>
          </div>
          <div className='col-span-3 p-3 bg-gray-100 rounded-lg dark:bg-gray-700'>
            <div className='flex justify-center items-center'>
              <h3 className="sm:text-lg font-medium leading-6 text-gray-900 dark:text-gray-100 text-center">Showcase Your Best Auditions </h3>
            </div>

            <div className='grid grid-cols-3 mt-2 flex items-center'>
              <div className='sm:col-span-1 col-span-3 py-2 sm:py-0 text-sm text-gray-700 dark:text-gray-300 mr-2'>
                Choose which submissions you want to appear at the top of your public profile
              </div>
              <div className='sm:col-span-2 col-span-3'>
                <div className='overflow-hidden shadow-lg rounded-lg aspect-w-3 aspect-h-1 w-full'>
                  <img src='https://ddppjbdexhxzj.cloudfront.net/perks/showcase-submissions.png' className='object-cover' />
                </div>
              </div>
            </div>
          </div>

          <div className='col-span-3 p-3 bg-gray-100 rounded-lg dark:bg-gray-700'>
            <div className='flex justify-center'>
              <h3 className="sm:text-lg font-medium leading-6 text-gray-900 dark:text-gray-100 text-center">More Achievement Flair </h3>
            </div>

            <div className='grid grid-cols-3 mt-2 flex items-center'>
              <div className='sm:col-span-1 col-span-3 py-2 sm:py-0 text-sm text-gray-700 dark:text-gray-300 mr-2'>
                Make your submissions stand out more by listing more achievements
              </div>
              <div className='sm:col-span-2 col-span-3'>
                <div className='overflow-hidden shadow-lg rounded-lg aspect-w-3 aspect-h-2 w-full'>
                  <img src='https://ddppjbdexhxzj.cloudfront.net/perks/multiple-achievements.png' className='object-cover' />
                </div>
              </div>
            </div>
          </div>

        </div>
      </Card>
      <div className="md:col-span-1" />
    </div>

    <div className="md:grid md:grid-cols-4 md:gap-6 mt-3">
      <div className="md:col-span-1">
        <div className="p-4 sm:px-0">
          <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-100">Projects</h3>
          <p className="mt-1 text-sm text-gray-600 dark:text-gray-300">
            Project enhancements for the Apprentice, Maker, and Business plans
          </p>
        </div>
      </div>
      <Card title='Project Perks' footer={<Link to='/subscriptions'>View Plans</Link> }>
        <div className="grid grid-cols-3 gap-6">
          <div className='col-span-3 p-3 bg-gray-100 rounded-lg dark:bg-gray-700'>
            <div className='flex justify-center'>
              <h3 className="sm:text-lg font-medium leading-6 text-gray-900 dark:text-gray-100 text-center">Paid Project Emails </h3>
            </div>
            <div className='grid grid-cols-3 mt-2 flex items-center'>
              <div className='sm:col-span-1 col-span-3 py-2 sm:py-0 text-sm text-gray-700 dark:text-gray-300 mr-2'>
                Our moderators will email you as soon as a new paid project pops up into the system.
              </div>
              <div className='sm:col-span-2 col-span-3'>
                <div className='overflow-hidden shadow-lg rounded-lg aspect-w-1 aspect-h-1 w-full'>
                  <img src='https://ddppjbdexhxzj.cloudfront.net/perks/paid-project-email.png' className='object-cover' />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-3 gap-6">
          <div className='col-span-3 p-3 bg-gray-100 rounded-lg dark:bg-gray-700'>
            <div className='flex justify-center'>
              <h3 className="sm:text-lg font-medium leading-6 text-gray-900 dark:text-gray-100 text-center">Project Promoted Higher in Search </h3>
            </div>
            <div className='grid grid-cols-3 mt-2 flex items-center'>
              <div className='sm:col-span-1 col-span-3 py-2 sm:py-0 text-sm text-gray-700 dark:text-gray-300 mr-2'>
                <b>Business</b> plan only. Your projects will appear higher and <i>featured</i> in the search results when people are browsing through open projects.
              </div>
              <div className='sm:col-span-2 col-span-3'>
                <div className='overflow-hidden shadow-lg rounded-lg aspect-w-3 aspect-h-1 w-full'>
                  <img src='https://ddppjbdexhxzj.cloudfront.net/perks/featured-project-search.png' className='object-cover' />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-3 gap-6">
          <div className='col-span-3 p-3 bg-gray-100 rounded-lg dark:bg-gray-700'>
            <div className='flex justify-center'>
              <h3 className="sm:text-lg font-medium leading-6 text-gray-900 dark:text-gray-100 text-center">Private Projects & Passwords </h3>
            </div>
            <div className='grid grid-cols-3 mt-2 flex items-center'>
              <div className='sm:col-span-1 col-span-3 py-2 sm:py-0 text-sm text-gray-700 dark:text-gray-300 mr-2'>
                If you already have a private roster or have a sensitive project that you don't want revealed to the public.
              </div>
              <div className='sm:col-span-2 col-span-3'>
                <div className='overflow-hidden shadow-lg rounded-lg aspect-w-3 aspect-h-1 w-full'>
                  <img src='https://ddppjbdexhxzj.cloudfront.net/perks/private-password-projects.png' className='object-cover' />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-3 gap-6">
          <div className='col-span-3 p-3 bg-gray-100 rounded-lg dark:bg-gray-700'>
            <div className='flex justify-center items-center'>
              <StatusPill status='new' text='New' />
              <h3 className="sm:text-lg font-medium leading-6 text-gray-900 dark:text-gray-100 text-center">Project Analytics </h3>
            </div>
            <div className='mt-2'>
              <LineChartSample />
            </div>
          </div>
        </div>

        <div className="grid grid-cols-3 gap-6">
          <div className='col-span-3 p-3 bg-gray-100 rounded-lg dark:bg-gray-700'>
            <div className='flex justify-center'>
              <h3 className="sm:text-lg font-medium leading-6 text-gray-900 dark:text-gray-100 text-center">More Open Projects </h3>
            </div>
            <div className='grid grid-cols-3 mt-2 flex items-center'>
              <div className='sm:col-span-1 col-span-3 py-2 sm:py-0 text-sm text-gray-700 dark:text-gray-300 mr-2'>
                You can have more than one open project at a time, great for those who need to find talent for multiple things at once.
              </div>
              <div className='sm:col-span-2 col-span-3'>
                <div className='overflow-hidden shadow-lg rounded-lg aspect-w-1 aspect-h-1 w-full'>
                  <img src='https://ddppjbdexhxzj.cloudfront.net/perks/more-open-projects.png' className='object-cover' />
                </div>
              </div>
            </div>
          </div>
        </div>

      </Card>
      <div className="md:col-span-1" />
    </div>

    <div className="md:grid md:grid-cols-4 md:gap-6 mt-3">
      <div className="md:col-span-1">
        <div className="p-4 sm:px-0">
          <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-100">Education</h3>
          <p className="mt-1 text-sm text-gray-600 dark:text-gray-300">
            <b>Business</b> plan only. For those who want to fill their knowledge gaps, master their craft, or want to get more opportunities from the community
          </p>
          <p className="mt-1 text-sm text-gray-600 dark:text-gray-300">
            In partnership with...
            <img className='h-10 mx-auto' src='https://clc-content.b-cdn.net/closing-credits-logo.svg' />
          </p>
          <span className='text-sm'>
            The Business Plan gives you access to the <a href='https://www.closingcredits.com/pricing'>Closing Credits Base Plan</a>.
          </span>
          { (planName === 'master' || planName === 'business') && <>
            <div className="mt-1 text-sm text-green-500">
              Thanks for being on the Business Plan. To get your Education Perks, please follow these instructions:
              <ul className='mt-2 text-gray-700 dark:text-gray-300'>
                <li>1. Go to <a target="_blank" href='https://www.closingcredits.com/' rel="noreferrer">Closing Credits</a> and click 'enroll'.</li>
                <li>2. When you get to the Course Selection page, and click 'Enter Promo Code' or click <a target="_blank" href='https://www.closingcredits.com/promo_code' rel="noreferrer">this link</a>.</li>
                <li>3. Enter this as your promo code: {clcPromoCode} </li>
              </ul>
            </div>
          </> }
        </div>
      </div>
      <Card title='Education Perks' footer={<a href='https://www.closingcredits.com'>Go to www.closingcredits.com</a> }>
        <div className="grid grid-cols-3 gap-6">
          <div className='col-span-3 p-3 bg-gray-100 rounded-lg dark:bg-gray-700'>
            <h3 className="sm:text-lg font-medium leading-6 text-gray-900 dark:text-gray-100 text-center">Private Job Board</h3>

            <div className='grid grid-cols-3 mt-2 flex items-center'>
              <div className='sm:col-span-1 col-span-3 py-2 sm:py-0 text-sm text-gray-700 dark:text-gray-300 mr-2'>
                Jobs sourced by the Closing Credits in-house studio specifically for members.
              </div>
              <div className='sm:col-span-2 col-span-3'>
                <div className='overflow-hidden shadow-lg rounded-lg aspect-w-2 aspect-h-1 w-full'>
                  <img src='https://ddppjbdexhxzj.cloudfront.net/perks/private-job-board.png' className='object-cover' />
                </div>
              </div>
            </div>
          </div>

          <div className='col-span-3 p-3 bg-gray-100 rounded-lg dark:bg-gray-700'>
            <h3 className="sm:text-lg font-medium leading-6 text-gray-900 dark:text-gray-100 text-center">Dedicated Live Events</h3>
            <div className='grid grid-cols-3 mt-2 flex items-center'>
              <div className='sm:col-span-1 col-span-3 py-2 sm:py-0 text-sm text-gray-700 dark:text-gray-300 mr-2'>
                Live panels, workshops, AMAs, seminars, or community sessions with special guests from around the industry.
              </div>
              <div className='sm:col-span-2 col-span-3'>
                <div className='overflow-hidden shadow-lg rounded-lg aspect-w-1 aspect-h-1 w-full'>
                  <img src='https://ddppjbdexhxzj.cloudfront.net/perks/closingcredits-events.png' className='object-cover' />
                </div>
              </div>
            </div>
          </div>

          <div className='col-span-3 p-3 bg-gray-100 rounded-lg dark:bg-gray-700'>
            <h3 className="sm:text-lg font-medium leading-6 text-gray-900 dark:text-gray-100 text-center">Curated Content Library</h3>
            <div className='grid grid-cols-3 mt-2 flex items-center'>
              <div className='sm:col-span-1 col-span-3 py-2 sm:py-0 text-sm text-gray-700 dark:text-gray-300 mr-2'>
                Closing Credits has spent hundreds of hours with industry experts to unlock their knowledge in their heads, and curate that content in one place.
              </div>
              <div className='sm:col-span-2 col-span-3'>
                <div className='overflow-hidden shadow-lg rounded-lg aspect-w-2 aspect-h-1 w-full'>
                  <img src='https://ddppjbdexhxzj.cloudfront.net/perks/closingcredits-content.png' className='object-cover' />
                </div>
              </div>
            </div>
          </div>

          <div className='col-span-3 p-3 bg-gray-100 rounded-lg dark:bg-gray-700'>
            <h3 className="sm:text-lg font-medium leading-6 text-gray-900 dark:text-gray-100 text-center">Private Community Chat</h3>
            <div className='grid grid-cols-3 mt-2 flex items-center'>
              <div className='sm:col-span-1 col-span-3 py-2 sm:py-0 text-sm text-gray-700 dark:text-gray-300 mr-2'>
                Sync up with like-minded peers and join a network that is actively going places.
              </div>
              <div className='sm:col-span-2 col-span-3'>
                <div className='overflow-hidden shadow-lg rounded-lg aspect-w-2 aspect-h-1 w-full'>
                  <img src='https://ddppjbdexhxzj.cloudfront.net/perks/closingcredits-chat.png' className='object-cover' />
                </div>
              </div>
            </div>
          </div>

        </div>
      </Card>
    </div>
  </div>
}
export default PerksIndex
