import React from 'react'
import PropTypes from 'prop-types'
import StandAloneAudioPlayer from '@/shared/StandAloneAudioPlayer'

const LoggedOutSubmissionShow = ({ submission }) => {
  return <>
      { submission.submission.audioUrl && <StandAloneAudioPlayer mediaUrl={submission.submission.audioUrl} objectId={submission.submission.id} objectKind='Audition' /> }
  </>
}

export default LoggedOutSubmissionShow

LoggedOutSubmissionShow.propTypes = {
  submission: PropTypes.object.isRequired
}
