import React, { useEffect, useRef } from 'react'
import { Switch, Route, useLocation, Link } from 'react-router-dom'
import Confetti from 'react-confetti'

import Breadcrumbs from '@/Layout/Breadcrumbs'
import PageTitle from '@/Layout/PageTitle'
import useQuery from '@/hooks/useQuery'
import { useGlobalState } from '@/state'
import Loading from '@/shared/Loading'
import { EmojiHappyIcon } from '@heroicons/react/solid'

import DashboardIndex from '@/pages/Dashboard/DashboardIndex'
import DashboardProfileViews from '@/pages/Dashboard/DashboardProfileViews'

function breadCrumbs() {
  const location = useLocation()
  const activePath = location.pathname
  const crumbs = [
    { to: '/dashboard', name: 'Dashboard' }
  ]
  if (activePath === '/dashboard/profile_views') {
    crumbs.push({ to: '/dashboard/profile_views', name: 'Profile Stats' })
  }
  return crumbs
}

function pageTitle() {
  const location = useLocation()
  const activePath = location.pathname
  const titleInfo = {
    title: 'Dashboard',
    buttons: []
  }
  if (activePath === '/dashboard/profile_views') { titleInfo.title = 'Profile Stats' }

  return titleInfo
}

export default function DashboardContainer(props) {
  const crumbs = breadCrumbs()
  const titleInfo = pageTitle()
  const [, setToast] = useGlobalState('toast')
  const { getRequest } = useQuery()
  const [dashboardContainer, setDashboardContainer] = useGlobalState('dashboardContainer')
  const scrollRef = useRef(null)

  const urlParams = new window.URLSearchParams(window.location.search)
  const welcome = urlParams.get('welcome')
  const emailVerified = urlParams.get('email_verified')

  useEffect(() => {
    getRequest('/api/v3/dashboard', {}, (err, jsonData) => {
      if (err) { /* handled in hook */ return }
      setDashboardContainer({
        ...dashboardContainer,
        stats: jsonData.dashboard.stats,
        userId: jsonData.dashboard.userId
      })
    })

    scrollRef?.current?.scrollIntoView()
  }, [])

  useEffect(() => {
    if (welcome) {
      setToast(<>
        <div className="flex-shrink-0">
          <EmojiHappyIcon className="h-6 w-6 text-green-500" aria-hidden="true" />
        </div>
        <div className="ml-3 w-0 flex-1 pt-0.5">
          <p className="text-sm font-medium dark:text-white text-gray-800">The love is real.</p>
          <p className="mt-1 text-sm dark:text-gray-100 text-gray-500">It's great to have you here. We'll send you an email confirmation as well</p>
        </div>
      </>)
    }
  }, [welcome])

  useEffect(() => {
    if (emailVerified) {
      setToast(<>
        <div className="flex-shrink-0">
          <EmojiHappyIcon className="h-6 w-6 text-green-500" aria-hidden="true" />
        </div>
        <div className="ml-3 w-0 flex-1 pt-0.5">
          <p className="text-sm font-medium dark:text-white text-gray-800">Now witness the firepower of this fully armed and operational casting call club.</p>
          <p className="mt-1 text-sm dark:text-gray-100 text-gray-500">You can now create projects, send in submissions, make comments, build your profile, plus way more.</p>
        </div>
      </>)
    }
  }, [emailVerified])

  if (!dashboardContainer.userId) {
    return <Loading />
  }

  return <>
    <div ref={scrollRef} />
    { welcome && <Confetti width={window.innerWidth} gravity={0.05} colors={['#f7784e', '#00b0e7', '#6452a2']} numberOfPieces={300} recycle={false} height={window.innerHeight} /> }
    { emailVerified && <Confetti width={window.innerWidth} gravity={0.05} colors={['#f7784e', '#00b0e7', '#6452a2']} numberOfPieces={300} recycle={false} height={window.innerHeight} /> }
    <Breadcrumbs>
      { crumbs.map((crumb, index) => (
        <Link key={`${index}${crumb.name}`} to={crumb.to} className="text-xs sm:text-sm text-gray-500 hover:text-gray-700 dark:text-gray-100">
          {crumb.name}
        </Link>
      ))}
    </Breadcrumbs>
    <PageTitle pagetitle={titleInfo.title}>
      {titleInfo.buttons}
    </PageTitle>
    <Switch>
      <Route {...props} exact path={['/dashboard']}>
        <DashboardIndex />
      </Route>
      <Route {...props} exact path={['/dashboard/profile_views']}>
        <DashboardProfileViews />
      </Route>
    </Switch>
  </>
}
