import React, { useState, useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { LocationMarkerIcon, ExclamationCircleIcon } from '@heroicons/react/solid'
import { Link } from 'react-router-dom'
import { TippyTooltip } from '@/shared/ToolTip'
import TipTapProject from '@/shared/TipTapProject'

import useQuery from '@/hooks/useQuery'
import { debounce } from 'throttle-debounce'
import { useGlobalState } from '@/state'
import { randomToastSuccess } from '@/shared/Toast'
import FroalaWrapper from '@/shared/FroalaWrapper'
import PrimaryButton from '@/shared/Buttons/Primary'
import Card from '@/shared/Card'

import ProfileGeneralAvatar from '@/pages/Users/ProfileGeneralAvatar'
import ProfileGeneralCover from '@/pages/Users/ProfileGeneralCover'

const ProfileGeneralBasic = () => {
  const [user, setUser] = useGlobalState('userContainerUser')
  const [currentUser, setCurrentUser] = useGlobalState('currentUser')
  const tipTapIdx = useRef(1)
  const [, setToast] = useGlobalState('toast')
  const { putpostRequest } = useQuery()
  const { formState, reset, register, unregister, handleSubmit, setValue, getValues, setError } = useForm({
   defaultValues: {
      description: user.description,
      headline: user.headline
    }
  })

  const { isDirty, errors } = formState
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    register('description', { required: false })
    register('headline', { required: false })
    return () => {
      unregister('description')
      unregister('headline')
    }
  }, [register])

  const handleUpdateFroala = (key, val) => {
    setValue(key, val, { shouldDirty: true, shouldValidate: true })
  }
  const handleUpdateTipTap = (key, val) => {
    setValue(key, val, { shouldDirty: true, shouldValidate: true })
  }

  const onSubmit = debounce(300, data => {
    setLoading(true)

    putpostRequest(`/api/v3/users/${user.id}`, 'PATCH', { user: data }, (err, jsonData) => {
      setLoading(false)
      if (err) { // 422 code
        Object.entries(err).forEach(([key, value]) => {
          setError(key, value)
        })
        return
      }

      setUser(jsonData.user)

      if (getValues().display_name !== currentUser.displayName) {
        setCurrentUser({ ...currentUser, displayName: getValues().display_name })
      }
      reset({
        ...getValues(),
        headline: jsonData.user.headline + ' ',
        description: jsonData.user.description + ' '
      }) // resetting this without this + ' ' hack kills froala somehow
      setToast(randomToastSuccess())
    })
  })

  const headline = getValues().headline
  const description = getValues().description

  return (
    <div>
      <div className="md:grid md:grid-cols-5 md:gap-6 space-x-2">
        <div className="md:col-span-2">
          <div className="px-4 sm:px-0">
            <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-100">General</h3>
            <p className="my-1 text-sm text-gray-600 dark:text-gray-300">
              This information will be displayed on your profile. You can set your profile visibility settings in <Link to='/profile/options'>options</Link>
            </p>

            <div className="w-full mt-1 hidden md:block max-w-md mx-auto rounded-lg bg-white shadow p-5 text-gray-800 dark:text-gray-300 dark:bg-gray-800">
              <div className="w-full flex mb-4">
                <div className="overflow-hidden rounded-sm w-12 h-12">
                  <img src={user.publicImageUrl} alt="" />
                </div>
                <div className="pl-3 flex flex-col">
                  <h6 className="font-bold text-md dark:text-gray-100">{user.displayName}</h6>
                  <a href="" className="text-xs">@{user.username}</a>
                </div>
                <div className="w-12 text-right">
                  <i className="mdi mdi-twitter text-blue-400 text-3xl"></i>
                </div>
              </div>
              { user.headline && <div className="w-full mb-4">
                <p className="text-sm" dangerouslySetInnerHTML={{ __html: user.headline }}></p>
              </div> }
              <div className="w-full space-x-5 flex justify-items-start items-center">
                {user.location && <span className="text-gray-500 flex flex-shrink">
                  <LocationMarkerIcon className="flex-shrink-0 h-5 w-5 mr-1" aria-hidden="true" /> <span className='text-sm'> {user.location}</span>
                </span> }
                <a href="" className="text-gray-500 text-sm"><span className='text-lg text-gray-800 dark:text-gray-100'>{user.followingCount}</span> Following</a>
                <a href="" className="text-gray-500 text-sm"><span className='text-lg text-gray-800 dark:text-gray-100'>{user.followersCount}</span> Followers</a>
              </div>
            </div>

          </div>
        </div>
        <Card footer={<PrimaryButton onClick={handleSubmit(onSubmit)} loading={loading} disabled={!isDirty} isDirty={isDirty} text="Save" />}>
          <form>
            <div className="grid grid-cols-3 gap-6">
              <div className="col-span-3 sm:col-span-1">
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Avatar</label>
                <div className="mt-1 flex items-center">
                  <ProfileGeneralAvatar />
                </div>
              </div>
              <div className="col-span-3 sm:col-span-1">
                <label htmlFor="display_name" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">
                  <span>Display Name</span>

                  <TippyTooltip content={<>Required Field</>}>
                    <span onClick={(e) => e.preventDefault() }><ExclamationCircleIcon className="h-5 w-5 text-gray-400" aria-hidden="true" /></span>
                  </TippyTooltip>
                </label>
                <div className="mt-1 flex flex-col rounded-md shadow-sm">
                  <div className='relative'>
                    <input type="text" className={errors.display_name ? 'errors' : ''} {...register('display_name', { required: true }) } defaultValue={user.displayName} placeholder="Buford" />
                    { errors.display_name && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                      <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                    </div> }
                  </div>
                </div>
                { errors.display_name?.type === 'required' && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
                { errors.display_name ? errors.display_name[0].includes('too long') && <div className='mt-2 text-sm text-red-600'>{errors.display_name[0]}</div> : null }
              </div>
              <div className="col-span-3 sm:col-span-1">
                <label htmlFor="location" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Location
                </label>
                <div className="mt-1 flex rounded-md shadow-sm flex flex-col">
                  <div className='relative'>
                    <input {...register('location') } className={errors.location ? 'errors' : ''} type="text" placeholder="San Francisco" defaultValue={user.location}/>
                    { errors.location && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                      <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                    </div> }
                  </div>
                </div>
                { errors.location && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
              </div>
            </div>
            <div className='mt-4'>
              <label htmlFor="headline" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                Short Headline
              </label>
              <div className="mt-1 shadow-sm w-full min-w-full">
                <FroalaWrapper
                  hideButtons={true}
                  showCharacterCounter={true}
                  model={headline}
                  updateModel={(val) => handleUpdateFroala('headline', val)}
                />
              </div>
              <i className="mt-2 flex justify-end text-xs text-gray-500 dark:text-gray-400">
                 (Tip: You can add URLs and @mention your followers and your credits.)
              </i>
              { errors.headline && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
            </div>

            <div className='mt-4'>
              <label htmlFor="about" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                About
              </label>
              { !currentUser.canUploadImages && <div className="rounded-md bg-blue-50 p-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <svg className="h-5 w-5 text-blue-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fillRule="evenodd" d="M19 10.5a8.5 8.5 0 11-17 0 8.5 8.5 0 0117 0zM8.25 9.75A.75.75 0 019 9h.253a1.75 1.75 0 011.709 2.13l-.46 2.066a.25.25 0 00.245.304H11a.75.75 0 010 1.5h-.253a1.75 1.75 0 01-1.709-2.13l.46-2.066a.25.25 0 00-.245-.304H9a.75.75 0 01-.75-.75zM10 7a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
                    </svg>
                  </div>
                  <div className="ml-3 flex-1 md:flex md:justify-between">
                    <p className="text-sm text-blue-700">You can add images by <b>dragging</b> or <b>pasting</b> them into the editor. <a href='/subscriptions' target='_blank' className='underline'>Upgrade to Premium</a> to enable this feature.</p>
                  </div>
                </div>
              </div> } 
              { currentUser.canUploadImages && <div className="rounded-md bg-blue-50 p-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <svg className="h-5 w-5 text-blue-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fillRule="evenodd" d="M19 10.5a8.5 8.5 0 11-17 0 8.5 8.5 0 0117 0zM8.25 9.75A.75.75 0 019 9h.253a1.75 1.75 0 011.709 2.13l-.46 2.066a.25.25 0 00.245.304H11a.75.75 0 010 1.5h-.253a1.75 1.75 0 01-1.709-2.13l.46-2.066a.25.25 0 00-.245-.304H9a.75.75 0 01-.75-.75zM10 7a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
                    </svg>
                  </div>
                  <div className="ml-3 flex-1 md:flex md:justify-between">
                    <p className="text-sm text-blue-700">You can add images by <b>dragging</b> or <b>pasting</b> them into the editor.</p>
                  </div>
                </div>
              </div> } 
              <div className="mt-1 shadow-sm w-full min-w-full">
                <TipTapProject
                  html={description}
                  idx={tipTapIdx.current}
                  imageUploadSupported={currentUser.canUploadImages}
                  updateHtml={(val) => handleUpdateTipTap('description', val)}
                  placeholder='Tell us about yourself.'
                />
              </div>
              <i className="mt-2 flex justify-end text-xs text-gray-500 dark:text-gray-400">
                 (Tip: You can add URLs and @mention your followers and your credits.)
              </i>
            </div>
            <ProfileGeneralCover />
          </form>
        </Card>
      </div>
    </div>
  )
}

export default ProfileGeneralBasic
