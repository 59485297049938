import React, { useState, useEffect } from 'react'
import { debounce } from 'throttle-debounce'
import { ExclamationCircleIcon, ExclamationIcon } from '@heroicons/react/solid'

import { useForm } from 'react-hook-form'
import useQuery from '@/hooks/useQuery'
import { useGlobalState } from '@/state'
import PrimaryButton from '@/shared/Buttons/Primary'

import Card from '@/shared/Card'

const EmailSettings = () => {
  const { putpostRequest, getRequest } = useQuery()
  const [email, setEmail] = useState(null)
  const [, setToast] = useGlobalState('toast')
  const [loading, setLoading] = useState(false)
  const [customError, setCustomError] = useState(null)
  const { formState, register, reset, handleSubmit, setError } = useForm({
   defaultValues: { }
  })

  const { isDirty, errors } = formState

  const keyPress = (e) => {
    if (e.target.name === 'name' && e.keyCode === 13) { e.preventDefault() }
    if ((e.metaKey || e.ctrlKey) && e.keyCode === 13) {
      if (typeof (e.target.name) === 'undefined') {
        e.target.blur() // froala hack to make it update model so it can save properly
      }
      handleSubmit(onSubmit)()
    }
  }
  useEffect(() => {
    getRequest('/api/v3/users/email', {}, (err, jsonData) => {
      if (err) { /* 422 */ return }
      setEmail(jsonData.email)
    })
  }, [])

  const onSubmit = debounce(300, (data) => {
    setLoading(true)
    setCustomError(null)

    putpostRequest('/api/v3/users/change_email', 'POST', data, (err, jsonData) => {
      setLoading(false)
      if (err) { // 422 code
        if (typeof err !== 'string') { // 500 code on API
          Object.entries(err).forEach(([key, value]) => {
            setError(key, value)
          })
        }
        if (typeof err === 'string') { setCustomError(err) }
        return
      }

      setEmail({
        email: jsonData.email,
        verifiedEmail: false
      })
      reset()
      setToast(<div className="ml-3 w-0 flex-1 pt-0.5">
        <p className="text-sm font-medium dark:text-green-300 text-green-600">Email Changed</p>
        <p className="mt-1 text-sm dark:text-green-300 text-green-400">Please click the link in the email we just sent you to verify your email.</p>
      </div>)
    })
  })

  const resendVerificationEmail = () => {
    setCustomError(null)
    putpostRequest('/api/v3/users/resend_email_verification', 'POST', {}, (err, _) => {
      if (err) { /* 422 */
        if (typeof err === 'string') { setCustomError(err) }
        return
      }
      setToast(<div className="ml-3 w-0 flex-1 pt-0.5">
        <p className="text-sm font-medium dark:text-green-300 text-green-600">Check Your Inbox!</p>
        <p className="mt-1 text-sm dark:text-green-300 text-green-400">Please click the link in the email we just sent you to verify your email.</p>
      </div>)
    })
  }

  return <>
    <div className="md:col-span-1">
      <div className="px-0 sm:px-4 sm:px-0 mb-3">
        <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-100">Email</h3>
        <p className="mt-1 text-sm text-gray-600 dark:text-gray-300">
          Change your email address to update all communications sent to you by Casting Call Club.
        </p>
      </div>
    </div>
    <Card title='Email' footer={
      <div className='flex flex-rows justify-end items-end'>
        <div className='flex flex-col items-end w-min ml-2'>
          <div className='flex flex-col items-end w-min ml-2'>
            <PrimaryButton className='w-full' onClick={handleSubmit(onSubmit)} disabled={!isDirty} loading={loading} text={<div className='flex flex-col'>
              Save
              { isDirty && <div className="hidden sm:block w-min text-xs px-2 font-sans text-gray-50">⌘/ctrl+ENTER</div> }
            </div>} />
          </div>
        </div>
      </div>
      } >
        <form>
          <div className="grid sm:grid-cols-4 gap-6 bg-white rounded-sm dark:bg-gray-700 p-4">
            { Boolean(email) && <>
              <div className="col-span-4 sm:col-span-1 text-gray-800 dark:text-gray-100"> Current Email: </div>
              <div className="col-span-4 sm:col-span-2 text-gray-800 dark:text-gray-100"> {email.email} </div>
              <div className="col-span-4 sm:col-span-1 flex flex-col">
                {email.verifiedEmail && <span className='text-green-500'>Verified</span> }
                {!email.verifiedEmail && <span className='text-red-500'>Not Verified</span> }
                {!email.verifiedEmail && <a className='cursor-pointer' onClick={resendVerificationEmail}>Resend Verification Email</a> }
              </div>
              {!email.verifiedEmail && <div className="col-span-4 rounded-md bg-yellow-50 p-4 mt-10">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                  </div>
                  <div className="ml-3">
                    <h3 className="text-sm font-medium text-yellow-800">Verify your email</h3>
                    <div className="mt-2 text-sm text-yellow-700"> You will be unable to send messages, upload submissions, or really do much of anything until you verify your email </div>
                  </div>
                </div>
              </div> }
            </> }
          </div>
          <div className="grid sm:grid-cols-4 mt-3">
            <div className="col-span-4 sm:col-span-2">
              <label htmlFor="email" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">Change Email </label>
              <div className="mt-1 flex flex-col rounded-md shadow-sm">
                <div className='relative'>
                  <input onKeyDown={keyPress} type="text" className={errors.email ? 'errors' : ''} {...register('email', { required: true }) } placeholder="sally@sample.com" />
                  { errors.email && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                  </div> }
                </div>
              </div>
              <p className="text-xs text-gray-800 dark:text-gray-100">Changing your email means you will need to re-verify.</p>
              { errors.email && <div className='mt-2 text-sm text-red-600'>This field is required</div> }
              { customError && <div className='mt-2 text-sm text-red-600'>{customError}</div> }
            </div>
          </div>
        </form>
    </Card>
  </>
}
export default EmailSettings
