import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { ExclamationCircleIcon } from '@heroicons/react/solid'
import { debounce } from 'throttle-debounce'
import { useForm } from 'react-hook-form'
import SVGIcon from '@/components/icon'

import useQuery from '@/hooks/useQuery'
import { useGlobalState } from '@/state'
import useModal from '@/hooks/useModalV2'
import Modal from '@/shared/Modal'
import Card from '@/shared/Card'
import PrimaryButton from '@/shared/Buttons/Primary'
import TipTapPrivatePost from '@/shared/TipTapPrivatePost'

const DirectMessageModal = ({ recipient }) => {
  const { openModal, closeModal, isOpen } = useModal()
  const [, setToast] = useGlobalState('toast')
  const [customError, setCustomError] = useState(null)
  const { putpostRequest } = useQuery()
  const { formState, register, reset, unregister, handleSubmit, setValue, getValues, setError } = useForm({
   defaultValues: {
     subject: null,
     body: null
    }
  })

  const { errors } = formState
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    register('body', { required: true })
    return () => {
      unregister('body')
    }
  }, [register])

  const onSubmit = debounce(300, (data) => {
    setLoading(true)

    const formData = {
      post: {
        body: data.body,
        recipient_ids: [recipient.id].join(','),
        title: data.subject
      }
    }

    putpostRequest('/api/v3/posts', 'POST', formData, (err, jsonData) => {
      setLoading(false)
      if (err) { // 422 code
        if (typeof err === 'string') { // 422 custom error
          setCustomError(err)
        }
        if (typeof err !== 'string') { // 500 code on API
          Object.entries(err).forEach(([key, value]) => {
            setError(key, value)
          })
        }
        return
      }

      setToast(<div className="ml-3 w-0 flex-1 pt-0.5">
        <p className="text-sm font-medium dark:text-white text-gray-800">Message sent!</p>
        <p className="mt-1 text-sm dark:text-gray-100 text-gray-500">This message will be available for you to see in your Messages</p>
      </div>)
      reset({ ...getValues(), body: ' ' }) // resetting this without this hack kills froala somehow
      closeModal()
    })
  })

  const keyPress = (e) => {
    if (e.target.name === 'name' && e.keyCode === 13) { e.preventDefault() }
    if ((e.metaKey || e.ctrlKey) && e.keyCode === 13) {
      if (typeof (e.target.name) === 'undefined') {
        e.target.blur() // froala hack to make it update model so it can save properly
      }
      handleSubmit(onSubmit)()
    }
  }

  const handleUpdateTipTap = (key, val) => {
    setValue(key, val, { shouldDirty: true, shouldValidate: true })
  }

  const body = getValues().body

  return <>
    <button onClick={openModal} type="button" className="inline-flex space-x-1 justify-center p-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white dark:bg-gray-800 dark:text-gray-200 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccblue">
      <SVGIcon name='icon-messages' className='h-7 w-7 sm:h-5 sm:w-5' />
      <span className='hidden sm:block'>Message</span>
    </button>
    <Modal isOpen={isOpen} closeModal={closeModal} >
      <Card title="Direct Message"
        footer={
          <div className='flex flex-rows justify-end items-end'>
            <div className='flex flex-col items-end w-min ml-2'>
              <div className="hidden sm:block w-min text-xs border border-gray-200 dark:border-gray-800 rounded px-2 font-sans text-gray-500 dark:text-gray-400">⌘/ctrl+ENTER</div>
              <PrimaryButton className='w-full' onClick={handleSubmit(onSubmit)} loading={loading} text='Save' />
            </div>
          </div>
        } >
        <form>
          <div className="grid sm:grid-cols-4 gap-6">
            <div className="col-span-4 sm:col-span-3">
              <label htmlFor="school_name" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">Subject </label>
              <div className="mt-1 flex flex-col rounded-md shadow-sm">
                <div className='relative'>
                  <input onKeyDown={keyPress} type="text" className={errors.subject ? 'errors' : ''} {...register('subject', { required: true }) } placeholder="Hello operator" />
                  { errors.subject && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                  </div> }
                </div>
              </div>
              { errors.subject && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
            </div>

            <div className="col-span-4 sm:col-span-4">
              <div className='mt-4'>
                <label htmlFor="pricing" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                  The Actual Message
                </label>
                <div className="mt-1 shadow-sm w-full min-w-full">
                  <TipTapPrivatePost html={body} updateHtml={(val) => handleUpdateTipTap('body', val)} />
                </div>
                { errors.body && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
              </div>
            </div>
          </div>
        </form>
        { customError && <div className='mt-2 text-sm text-red-600'>{customError}</div> }
      </Card>
    </Modal>
  </>
}

export default DirectMessageModal

DirectMessageModal.propTypes = {
  recipient: PropTypes.object.isRequired
}
