import React from 'react'
import { ResponsiveContainer, LineChart, YAxis, XAxis, Tooltip, Legend, CartesianGrid, Line } from 'recharts'
import { useGlobalState } from '@/state'

const fakenews = {
'data': [
  { 'Views': 22, 'date': 'Nov 02' },
  { 'Views': 30, 'date': 'Nov 03' },
  { 'Views': 79, 'date': 'Nov 04' },
  { 'Views': 23, 'date': 'Nov 05' },
  { 'Views': 25, 'date': 'Nov 06' },
  { 'Views': 73, 'date': 'Nov 07' },
  { 'Views': 15, 'date': 'Nov 08' },
  { 'Views': 12, 'date': 'Nov 09' },
  { 'Views': 25, 'date': 'Nov 10' },
  { 'Views': 44, 'date': 'Nov 11' },
  { 'Views': 71, 'date': 'Nov 12' },
  { 'Views': 13, 'date': 'Nov 13' },
  { 'Views': 62, 'date': 'Nov 14' },
  { 'Views': 59, 'date': 'Nov 15' },
  { 'Views': 95, 'date': 'Nov 16' },
  { 'Views': 68, 'date': 'Nov 17' },
  { 'Views': 88, 'date': 'Nov 18' },
  { 'Views': 23, 'date': 'Nov 19' }
],
'lines': ['date']
}

const LineChartSample = () => {
  const colors = ['#E57373', '#64B5F6', '#ffd54f', '#81C784', '#7986cb', '#64B5F6', '#4db6ac', '#ba68c8']
  const [theme] = useGlobalState('theme')
  let fillColor = '#6D6E71'
  if (theme === 'dark') {
    fillColor = '#E5E7EB'
  }

  const renderColorfulLegendText = (value, entry) => {
  const { color } = entry

    return <span style={{ color }}>{value}</span>
  }
  return <>
    <div style={{ minWidth: '300px', height: '315px' }}>
      <ResponsiveContainer
          margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
      >
        <LineChart data={fakenews.data} margin={{ top: 0, right: 0, left: -25, bottom: 0 }} >
          <YAxis
            padding={{ top: 0, right: 0, left: 0, bottom: 0 }}
            margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
            stroke={fillColor}
          />
          <XAxis padding={{ left: 0 }} dataKey="date" stroke={fillColor} />
          <Tooltip />
          <Legend formatter={renderColorfulLegendText} />
          <CartesianGrid stroke={fillColor} />
          <Line
            name="Views" type="monotone" dataKey="Views" stroke="#ff7300" strokeWidth={3}
            activeDot={{ stroke: '#ff7300', strokeWidth: 2, r: 10 }}
            dot={{ stroke: '#ff7300', strokeWidth: 2, r: 10 }}
          />
          { fakenews.lines.map((line, index) => <>
            {line !== 'data' && <Line key={line + index} type="monotone" dataKey={line} stroke={colors[index]} /> }
          </>
          )}
        </LineChart>
      </ResponsiveContainer>
    </div>
  </>
}
export default LineChartSample
