import React from 'react'
import { Switch, Route, Link, useLocation } from 'react-router-dom'
import { useGlobalState } from '@/state'

import Breadcrumbs from '@/Layout/Breadcrumbs'
import PageTitle from '@/Layout/PageTitle'

import SubmissionsIndex from '@/pages/Submissions/SubmissionsIndex'
import SubmissionShow from '@/pages/Submissions/SubmissionShow'

function breadCrumbs() {
   const location = useLocation()
   const activePath = location.pathname
  // will need this ^ when adding show/edit func, see creditscontainer
  const [submission] = useGlobalState('submission')
  const crumbs = [
    { to: '/submissions', name: 'Submissions' }
  ]
  if (submission?.id && activePath.includes('/submissions/')) {
    crumbs.push({ to: `/submissions/${submission.id}`, name: `${submission.userDisplayName} for ${submission.roleName}` })
  }
  return crumbs
}

function pageTitle() {
  const [submission] = useGlobalState('submission')
  const location = useLocation()
  const activePath = location.pathname

  const titleInfo = {
    title: 'Submissions',
    buttons: []
  }

  if (submission?.id && activePath.includes('/submissions/')) {
    titleInfo.title = `${submission.userDisplayName} for ${submission.roleName}`
  }
  return titleInfo
}

export default function SubmissionsContainer(props) {
  const crumbs = breadCrumbs()
  const titleInfo = pageTitle()

  return <>
    <Breadcrumbs>
      { crumbs.map((crumb, index) => (
        <Link key={`${index}${crumb.name}`} to={crumb.to} className="text-xs sm:text-sm text-gray-500 hover:text-gray-700 dark:text-gray-100">
          {crumb.name}
        </Link>
      ))}
    </Breadcrumbs>
    <PageTitle pagetitle={titleInfo.title}>
      {titleInfo.buttons}
    </PageTitle>
    <Switch>
      <Route {...props} exact path={['/submissions']}>
        <SubmissionsIndex />
      </Route>
      <Route {...props} exact path={['/submissions/:id']}>
        <SubmissionShow />
      </Route>
    </Switch>
  </>
}
