import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { RadioGroup, Switch } from '@headlessui/react'

import classNames from '@/utils/classNamesLocal'
import { format } from 'date-fns-tz'
import { useForm } from 'react-hook-form'
import { debounce } from 'throttle-debounce'
import DatePicker from 'react-datepicker'

import { TippyTooltip } from '@/shared/ToolTip'
import Card from '@/shared/Card'
import { randomToastSuccess } from '@/shared/Toast'

import TipTapTextarea from '@/shared/TipTapTextarea'
import PrimaryButton from '@/shared/Buttons/Primary'
import { ExclamationCircleIcon, CheckCircleIcon } from '@heroicons/react/solid'
import useQuery from '@/hooks/useQuery'
import { useGlobalState } from '@/state'

const deadlineTimes = ['00:00', '00:30', '01:00', '01:30', '02:00', '02:30', '03:00', '03:30', '04:00', '04:30',
  '05:00', '05:30', '06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '09:00', '09:30', '10:00', '10:30',
  '11:00', '11:30', '12:00', '12:30']

const RequiredInfoForm = ({ initialProject }) => {
  const [, setToast] = useGlobalState('toast')
  const tipTapIdx = useRef(1)
  const [project, setProject] = useState(initialProject)
  const [deadlineDate, setDeadlineDate] = useState(initialProject.id ? new Date(initialProject.deadlineDate) : new Date())
  const [timezones, setTimezones] = useState([])
  const [payment, setPayment] = useState(initialProject.payment)
  const { putpostRequest, getRequest } = useQuery()
  const { formState, reset, register, unregister, watch, handleSubmit, setValue, getValues, setError, setFocus } = useForm({
   defaultValues: {
      name: initialProject.name,
      payment: initialProject.payment,
      payment_details: initialProject.paymentDetails,
      payment_range: initialProject.paymentRange,
      payment_interval: initialProject.paymentInterval,
      payment_currency: initialProject.paymentCurrency,
      payment_deferred_type: initialProject.paymentDeferredType,
      payment_amount_min: initialProject.paymentAmountMin,
      payment_amount_max: initialProject.paymentAmountMax,
      expected_commitment_amount: initialProject.expectedCommitmentAmount,
      expected_commitment_interval: initialProject.expectedCommitmentInterval,
      time_zone: initialProject.timeZone,
      deadline_date: initialProject.deadlineDate || new Date(),
      deadline_time: deadlineTimes.includes(initialProject.deadlineTime) ? initialProject.deadlineTime : deadlineTimes[0],
      deadline_ampm: initialProject.deadlineAmpm
    }
  })
  const { isDirty, errors } = formState
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getRequest('/api/v3/static/timezones', {}, (err, jsonData) => {
      if (err) { /* handled in hook */ return }

      if (jsonData.timezones) {
        setTimezones(jsonData.timezones)
        setValue('time_zone', initialProject.timeZone, { shouldDirty: false, shouldValidate: false })
      }
    })
  }, [])

  useEffect(() => {
    setFocus('name')
  }, [setFocus])

  useEffect(() => {
    register('deadline_date', { required: true })
    register('payment', { required: true })
    register('payment_details')
    return () => {
      unregister('deadline_date')
      unregister('payment')
      unregister('payment_details')
    }
  }, [register])

  const handleDateChange = (date) => {
    setDeadlineDate(date)
    setValue('deadline_date', format(date, 'yyyy-MM-dd'), { shouldDirty: true, shouldValidate: true })
  }

  const handlePaymentChange = (value) => {
    setPayment(value)
    setValue('payment', value, { shouldDirty: true, shouldValidate: true })
  }

  const handleUpdateSwitch = (key) => {
    setValue(key, !getValues()[key], { shouldDirty: true, shouldValidate: true })
  }

  const keyPress = (e) => {
    if (e.target.name === 'name' && e.keyCode === 13) { e.preventDefault() }
    if ((e.metaKey || e.ctrlKey) && e.keyCode === 13) {
      if (typeof (e.target.name) === 'undefined') {
        e.target.blur() // froala hack to make it update model so it can save properly
      }
      handleSubmit(onSubmit)()
    }
  }

  const onSubmit = debounce(300, (data) => {
    setLoading(true)

    console.log(data)
    putpostRequest(`/api/v3/manage/projects/${project.id}`, 'PATCH', { project: data }, (err, jsonData) => {
      setLoading(false)
      if (err) { // 422 code
        if (typeof err !== 'string') { // 500 code on API
          Object.entries(err).forEach(([key, value]) => {
            setError(key, value)
          })
        }
        return
      }

      reset({ ...getValues() }) // resetting this without this hack kills froala somehow
      setProject({ ...jsonData.project })
      setToast(randomToastSuccess())
    })
  })

  const handleUpdateTipTap = (key, val) => {
    setValue(key, val, { shouldDirty: true, shouldValidate: true })
  }

  const paymentDetails = watch('payment_details')
  const paymentCurrency = watch('payment_currency')
  const paymentRange = watch('payment_range')
  const paymentAmountMax = watch('payment_amount_max')
  const paymentInterval = watch('payment_interval')
  const expectedCommitmentInterval = watch('expected_commitment_interval')
  const expectedCommitmentAmount = watch('expected_commitment_amount')
  const paymentAmountMin = watch('payment_amount_min')

  let totalPay = null
  let hourlyRate = null
  let lowRate = false
  let currencySymbol = '$'
  if (paymentCurrency === 'GBP') { currencySymbol = '£' }
  if (paymentCurrency === 'EUR') { currencySymbol = '€' }

  if (parseInt(paymentAmountMax, 10) >= 0 && parseInt(expectedCommitmentAmount, 10) >= 0) {
    const multiplier = expectedCommitmentInterval === 'hours' ? 1 : 8
    totalPay = paymentAmountMax * expectedCommitmentAmount * multiplier
    if (paymentInterval === 'Flat Rate') {
      totalPay = paymentAmountMax
      hourlyRate = totalPay / multiplier / expectedCommitmentAmount
    } else if (paymentInterval === 'Hourly') {
      hourlyRate = paymentAmountMax
      totalPay = hourlyRate * expectedCommitmentAmount * multiplier
    } else if (paymentInterval === 'Daily') {
      hourlyRate = paymentAmountMax / 8
      totalPay = hourlyRate * expectedCommitmentAmount * multiplier
    } else if (paymentInterval === 'Weekly') {
      hourlyRate = paymentAmountMax / 40
      totalPay = hourlyRate * expectedCommitmentAmount * multiplier
    } else if (paymentInterval === 'Monthly') {
      hourlyRate = paymentAmountMax / 160
      totalPay = hourlyRate * expectedCommitmentAmount * multiplier
    }
  }
  if (hourlyRate && hourlyRate < 20) { lowRate = true }

  return <>
    <div className="md:grid md:grid-cols-4 md:gap-6">
      <div className='md:grid-cols-1'>
        <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-100">Project Name</h3>
        <p className="mt-1 text-sm text-gray-600 dark:text-gray-300">Give your project a short, distinct name. This will be searchable unless you mark this project as unlisted. </p>
        <h3 className="mt-4 text-lg font-medium leading-6 text-gray-900 dark:text-gray-100">Deadline</h3>
        <div className="mt-2 text-sm text-gray-600 dark:text-gray-300"> It's standard to assign talent to roles at the time of deadline.</div>
        <div className="mt-2 text-sm text-gray-600 dark:text-gray-300"> People will be able to send in submissions to a role until the deadline, even if you assign someone to the role. The only way to prevent this is to close the role manually.</div>
        <div className="mt-2 text-sm text-gray-600 dark:text-gray-300"> It's highly recommended to set a short deadline and extend it if you need to.</div>
      </div>
      <Card title="Required Information"
        footer={
          <div className='flex justify-end space-x-8 items-center'>
            <div className='flex flex-rows justify-end items-end'>
              <div className='flex flex-col items-end w-min ml-2'>
                <PrimaryButton className='w-full' disabled={!isDirty} onClick={handleSubmit(onSubmit)} loading={loading} isDirty={isDirty} text='Save' />
              </div>
            </div>
          </div>
        } >
        <form>
          <div className="grid sm:grid-cols-3 gap-6">
            <div className="col-span-3 sm:col-span-2">
              <label htmlFor="school_name" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-start">
                Name of Project
                <TippyTooltip content={ <div className="rounded-md bg-yellow-50 p-2 sm:p-4 m-2"> <div className="mt-1 text-xs sm:text-sm text-gray-600 dark:text-gray-700 flex flex-col space-y-2"> Required Field </div> </div> }>
                  <ExclamationCircleIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </TippyTooltip>
              </label>
              <div className="mt-1 flex flex-col rounded-md shadow-sm">
                <div className='relative'>
                  <input onKeyDown={keyPress} type="text" className={errors.name ? 'errors' : ''} {...register('name', { required: true }) } placeholder="Goku vs Mario: The Love Story" />
                  { errors.name && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                  </div> }
                </div>
              </div>
              { errors.name && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
            </div>

            <div className="col-span-3 sm:col-span-3">
              <label htmlFor="deadline" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-start items-center">
                <span>Deadline</span>
                <TippyTooltip content={ <div className="rounded-md bg-yellow-50 p-2 sm:p-4 m-2"> <div className="mt-1 text-xs sm:text-sm text-gray-600 dark:text-gray-700 flex flex-col space-y-2"> This is required. This is the deadline for talent to get their submissions in. </div> </div> }>
                  <ExclamationCircleIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </TippyTooltip>
              </label>
              <div className="grid grid-cols-3 gap-x-5">
                <div className="col-span-1">
                  <div className='relative'>
                    <DatePicker
                      selected={deadlineDate}
                      onChange={handleDateChange}
                      selectsStart
                      startDate={deadlineDate}
                      endDate={new Date().setMonth(deadlineDate.getMonth() + 6)}
                      minDate={new Date()}
                      nextMonthButtonLabel=">"
                      previousMonthButtonLabel="<"
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <select
                    {...register('deadline_time', { required: true })}
                    className="block w-full py-2 px-3 border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm"
                  >
                    { deadlineTimes.map((time) => (
                      <option key={time} value={time}>{time}</option>
                    ))}
                  </select>
                </div>
                <div className="col-span-1">
                  <select
                    {...register('deadline_ampm', { required: true })}
                    className="block w-full py-2 px-3 border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm"
                  >
                    <option>AM</option>
                    <option>PM</option>
                  </select>
                </div>
              </div>
            </div>

            <div className='col-span-2'>
              <label htmlFor="time_zone" className="block text-sm font-medium text-gray-700 dark:text-gray-200 flex">
                <span>Project Timezone</span>
                <TippyTooltip content={ <div className="rounded-md bg-yellow-50 p-2 sm:p-4 m-2"> <div className="mt-1 text-xs sm:text-sm text-gray-600 dark:text-gray-700 flex flex-col space-y-2"> This is required. It will determine the exact minute the deadline closes. </div> </div> }>
                  <ExclamationCircleIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </TippyTooltip>
              </label>
              <select
                {...register('time_zone', { required: true })}
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm"
              >
                { timezones.map((tz) => (
                  <option key={tz.computer} value={tz.computer}>{tz.human.replace(/_/g, ' ')}</option>
                ))}
              </select>
            </div>
          </div>
          <div className='border-t border-cccblue m-4' />
          <div className='grid grid-cols-3'>
            <div className='col-span-2'>
              <label htmlFor="time_zone" className="block text-sm font-medium text-gray-700 dark:text-gray-200 flex">
                <span>Payment Information</span>
              </label>
              <RadioGroup value={payment} onChange={handlePaymentChange}>
                <RadioGroup.Label className="sr-only" hidden>Payment Information</RadioGroup.Label>
                <div className="p-2 rounded-sm bg-white dark:bg-gray-700">
                  <label htmlFor="payment" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                    Is this a paid project?
                    <p className="text-xs text-gray-500 dark:text-gray-400 mt-1">Paying different rates per role? You can specify other rates later</p>
                  </label>
                  <div className="mt-1 flex flex-col rounded-md shadow-sm">
                    <RadioGroup.Option value='paid' className={({ checked }) => classNames(checked ? 'bg-cccblue z-10' : 'border-gray-200', 'relative border p-4  w-full cursor-pointer focus:outline-none')}>
                      {({ active, checked }) => <>
                        <div className="flex items-center text-sm col-span-1">
                          <span className={classNames(
                            checked ? 'bg-cccorange ring-2 ring-white' : 'bg-white border-gray-300',
                            active ? 'ring-2 ring-white' : '',
                            'h-4 w-4 rounded-full border flex items-center justify-center'
                          )} aria-hidden="true" >
                            <span className="rounded-full bg-white w-1.5 h-1.5" />
                          </span>
                          <RadioGroup.Label as="span" className={classNames(checked ? 'text-white' : 'text-gray-900 dark:text-gray-200', 'ml-3 font-medium')} >
                            [Paid] Yes, I will pay talent for the work they do.
                          </RadioGroup.Label>
                        </div>
                      </>}
                    </RadioGroup.Option>
                    <RadioGroup.Option value='deferred' className={({ checked }) => classNames(checked ? 'bg-cccblue z-10' : 'border-gray-200', 'relative border p-4  w-full cursor-pointer focus:outline-none')}>
                      {({ active, checked }) => <>
                        <div className="flex items-center text-sm col-span-1">
                          <span className={classNames(
                            checked ? 'bg-cccorange ring-2 ring-white' : 'bg-white border-gray-300',
                            active ? 'ring-2 ring-white' : '',
                            'h-4 w-4 rounded-full border flex items-center justify-center'
                          )} aria-hidden="true" >
                            <span className="rounded-full bg-white w-1.5 h-1.5" />
                          </span>
                          <RadioGroup.Label as="span" className={classNames(checked ? 'text-white' : 'text-gray-900 dark:text-gray-200', 'ml-3 font-medium')} >
                            [Deferred] It depends on the project outcome.
                          </RadioGroup.Label>
                        </div>
                      </>}
                    </RadioGroup.Option>
                    <RadioGroup.Option value='unpaid' className={({ checked }) => classNames(checked ? 'bg-cccblue z-10' : 'border-gray-200', 'relative border p-4  w-full cursor-pointer focus:outline-none')}>
                      {({ active, checked }) => <>
                        <div className="flex items-center text-sm col-span-1">
                          <span className={classNames(
                            checked ? 'bg-cccorange ring-2 ring-white' : 'bg-white border-gray-300',
                            active ? 'ring-2 ring-white' : '',
                            'h-4 w-4 rounded-full border flex items-center justify-center'
                          )} aria-hidden="true" >
                            <span className="rounded-full bg-white w-1.5 h-1.5" />
                          </span>
                          <RadioGroup.Label as="span" className={classNames(checked ? 'text-white' : 'text-gray-900 dark:text-gray-200', 'ml-3 font-medium')} >
                            [Unpaid] This is an unpaid project.
                          </RadioGroup.Label>
                        </div>
                      </>}
                    </RadioGroup.Option>
                  </div>
                </div>
              </RadioGroup>
            </div>
            { payment === 'deferred' && <div className='mt-3 col-span-2 border border-gray-400 p-2 rounded-md'>
              <label htmlFor="payment_deferred_type" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                Will this be profit share or agreed amount?
              </label>
              <select
                {...register('payment_deferred_type')}
                className="block w-full py-2 px-3 border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm"
              >
                <option value='profit-share'>Profit Share</option>
                <option value='agreed-amount'>Agreed amount based on project success</option>
              </select>
            </div> }

            { payment === 'paid' && <div className='mt-3 col-span-3 border border-gray-400 p-2 rounded-md'>
              <label htmlFor="payment_interval" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                How much will they be paid?
              </label>
              <div className={`grid ${paymentRange ? 'grid-cols-4' : 'grid-cols-3'} gap-x-3`}>
                <div className='col-span-1'>
                  <select {...register('payment_interval', { required: true })}
                    className="block w-full py-2 px-3 border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm" >
                    <option>Flat Rate</option>
                    <option>Hourly</option>
                    <option>Daily</option>
                    <option>Weekly</option>
                    <option>Monthly</option>
                  </select>
                </div>
                <div className='col-span-1'>
                  <select {...register('payment_currency', { required: true })}
                    className="block w-full py-2 px-3 border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm" >
                    <option value='USD'>US Dollar</option>
                    <option value='GBP'>Pound Sterling</option>
                    <option value='CAD'>Canadian Dollar</option>
                    <option value='EUR'>Euro</option>
                    <option value='AUD'>Australian Dollar</option>
                  </select>
                </div>
                {paymentRange && <div className='col-span-1'>
                  <div className="relative">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                      <span className="text-gray-500 sm:text-sm">{currencySymbol}</span>
                    </div>
                    <input type="number" {...register('payment_amount_min', { required: true }) } className="block w-full rounded-md border-gray-300 pl-7 pr-12 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" placeholder="20" aria-describedby="price-currency" />
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                      <span className="text-gray-500 sm:text-sm" id="price-currency">{paymentCurrency}</span>
                    </div>
                  </div>
                  <span className='text-xs text-gray-500 dark:text-gray-400 mt-1'>Min</span>
                </div> }
                <div className='col-span-1'>
                  <div className="relative">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                      <span className="text-gray-500 sm:text-sm">{currencySymbol}</span>
                    </div>
                    <input type="number" {...register('payment_amount_max', { required: true }) } className="block w-full rounded-md border-gray-300 pl-7 pr-12 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" placeholder="20" aria-describedby="price-currency" />
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                      <span className="text-gray-500 sm:text-sm" id="price-currency">{paymentCurrency}</span>
                    </div>
                  </div>
                  {paymentRange && <span className='text-xs text-gray-500 dark:text-gray-400 mt-1'>Max</span> }
                </div>
              </div>
              <div className='mt-3 grid grid-cols-3'>
                <Switch.Group as="div" className="col-span-1 flex items-center justify-between mb-2">
                  <Switch.Label as="span" className="flex-grow flex flex-col" >
                    <span className="text-sm text-gray-700 dark:text-gray-300">Payment is in a range</span>
                  </Switch.Label>
                  <Switch checked={paymentRange} onChange={() => handleUpdateSwitch('payment_range')}
                    className={classNames(paymentRange ? 'bg-cccpurple' : 'bg-gray-300 dark:bg-gray-500',
                      'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccpurple')} >
                    <span className="sr-only" hidden>Payment is a range</span>
                    <span aria-hidden="true" className={classNames(paymentRange ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200')}
                    />
                  </Switch>
                </Switch.Group>
              </div>
              <div className='mt-4' />

              <label htmlFor="expected_commitment" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                How long do you expect the work to take?
              </label>
              <div className='grid grid-cols-3 gap-x-3'>
                <div className='col-span-1'>
                  <input type="number" {...register('expected_commitment_amount', { validate: { positive: v => parseInt(v,10) > 0 } }) } className="block w-full rounded-md border-gray-300 pl-7 pr-12 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" placeholder="2" aria-describedby="price-currency" />
                  { errors.expected_commitment_amount && <div className='mt-2 text-sm text-red-600'>Must be a positive number (greater than zero).</div> }
                </div>
                <div className='col-span-1'>
                  <select {...register('expected_commitment_interval')}
                    className="block w-full py-2 px-3 border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm" >
                    <option>hours</option>
                    <option>days</option>
                  </select>
                  <span className="text-xs text-gray-500 dark:text-gray-400">If it will take less than 1 hour, put 1 hour.</span>
                </div>
              </div>
              { totalPay !== null && <div className={`rounded-md ${lowRate ? 'bg-yellow-50' : 'bg-green-50'} p-2 sm:p-4 m-2`}>
                <div className={`mt-1 text-xs sm:text-sm ${lowRate ? 'text-yellow-600' : 'text-green-500'} flex flex-col space-y-2`}>
                  <div className='flex justify-start items-center space-x-5'>
                    { !lowRate && <CheckCircleIcon className='h-5 w-5' /> }
                    { lowRate && <ExclamationCircleIcon className="h-5 w-5" /> }
                    <div className='flex flex-col space-y-2'>
                      <span><b>Payment Rate:</b> {currencySymbol}{hourlyRate} {paymentCurrency}  / hour</span>
                      <span><b>Total Pay:</b> {currencySymbol}{totalPay} {paymentCurrency} for an estimated {expectedCommitmentAmount} {expectedCommitmentInterval} of work</span>
                      { lowRate && <span> This is a low payment amount and may result in fewer high quality submissions </span> }
                    </div>
                  </div>
                </div>
              </div> }
            </div> }
            <div className='col-span-2 mt-2'>
              <label htmlFor="payment_details" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                Any other payment or contract details?
              </label>
              <TipTapTextarea
                html={paymentDetails}
                idx={tipTapIdx.current}
                updateHtml={(val) => handleUpdateTipTap('payment_details', val)}
                placeholder='Example: Meals and travel included. OR, Requires NDA'
              />
            </div>
          </div>
        </form>

        { errors.base && <div className='mt-2 text-sm text-red-600'>{errors.base[0]}</div> }
      </Card>

    </div>
  </>
}

export default RequiredInfoForm

RequiredInfoForm.propTypes = {
  initialProject: PropTypes.object.isRequired
}
