import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import useQuery from '@/hooks/useQuery'
import { useGlobalState } from '@/state'

import Loading from '@/shared/Loading'
import { HeartIcon, ExclamationIcon } from '@heroicons/react/solid'
import Card from '@/shared/Card'
import ProjectMessageForm from '@/pages/ManageProjects/ProjectMessageForm'
import ProjectMessagePeopleInThisThread from '@/pages/ManageProjects/ProjectMessagePeopleInThisThread'

function useOnScreen(ref) {
  const [isIntersecting, setIntersecting] = useState(false)

  const observer = new IntersectionObserver(
    ([entry]) => setIntersecting(entry.isIntersecting)
  )

  useEffect(() => {
    observer.observe(ref.current)
    // Remove the observer as soon as the component is unmounted
    return () => { observer.disconnect() }
  }, [])

  return isIntersecting
}

const NoResults = () => {
  return (
    <span>
      <div className="relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccblue" >
        <HeartIcon className="mx-auto h-12 w-12 text-gray-400" />
        <span className="mt-2 block text-sm font-medium text-gray-900 dark:text-gray-300"> This message thread is oddly blank. I mean... why even start a thread if you're not going to say anything.</span>
      </div>
    </span>
  )
}

const MessageRow = ({ postMessage }) => {
  const [currentUser] = useGlobalState('currentUser')
  const { putpostRequest } = useQuery()
  const { userId } = postMessage
  const [showMenu, setShowMenu] = useState(false)
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [deleted, setDeleted] = useState(false)

  const destroy = () => {
    putpostRequest(`/api/v3/post_messages/${postMessage.id}`, 'DELETE', {}, (err, jsonData) => {
      if (err) { /* hook */ }
      setDeleted(true)
    })
  }

  if (deleted) return null

  const menuOn = () => {
    if (currentUser.id !== userId || showMenu) { return }
    setShowMenu(true)
  }
  const menuOff = () => { setShowMenu(false) }

  return <li className="relative p-2 hover:bg-gray-50 dark:hover:bg-gray-800" onMouseEnter={menuOn} onMouseLeave={menuOff}>
    <div className="relative flex items-start space-x-3">
      <img className="h-16 w-16 rounded-sm bg-gray-200 flex items-center justify-center ring-4 ring-cccorange" src={postMessage.publicImageUrl} alt="" />
      <div className="min-w-0 flex-1">
        <div className="text-sm flex justify-between">
          <Link to={`/${postMessage.userUsername}`} className="font-medium">{postMessage.userDisplayName}</Link>
          { !showMenu && <div className="mt-0.5 text-sm text-gray-500"> {postMessage.timeAgo} </div> }
          { showMenu && !confirmDelete && <div className="mt-0.5 text-sm text-red-500 cursor-pointer" onClick={() => setConfirmDelete(true)}> Delete </div> }
          { showMenu && confirmDelete && <div className="mt-0.5 text-sm text-red-500 cursor-pointer" onClick={destroy}> Really delete? Cannot be undone. </div> }
        </div>
        <div className="mt-1 text-sm text-gray-700 dark:text-gray-300 prose prose-sm" dangerouslySetInnerHTML={{ __html: postMessage.body }} />
      </div>
    </div>
  </li>
}

const ProjectMessagesIndex = () => {
  const [manageProjectContainer] = useGlobalState('manageProjectContainer')
  const { project } = manageProjectContainer
  const { postId } = project

  const [startsAt, setStartsAt] = useState(null)
  const [postMessages, setPostMessages] = useState([])
  const { getRequest } = useQuery()
  const [loading, setLoading] = useState(true)
  const scrollRef = useRef(null)
  const shouldScrollRef = useRef(false)

  const ref = useRef()
  const isVisible = useOnScreen(ref)
  useEffect(() => { loadMore() }, [isVisible])

  useEffect(() => {
    if (scrollRef.current && shouldScrollRef.current) {
      scrollRef.current.scrollIntoView()
      shouldScrollRef.current = false
    }
  }, [postMessages])

  useEffect(() => {
    if (!postId) { return }
    shouldScrollRef.current = false

    setLoading(true)
    getRequest(`/api/v3/posts/${postId}/post_messages`, {}, (err, jsonData) => {
      setLoading(false)
      if (err) { /* handled in hook */ return }

      shouldScrollRef.current = true
      setPostMessages(jsonData.postMessages)
      setStartsAt(jsonData.startsAt)
    })
  }, [postId])

  const loadMore = () => {
    if (!postId) { return }

    setLoading(true)
    getRequest(`/api/v3/posts/${postId}/post_messages`, { ends_at: startsAt }, (err, jsonData) => {
      setLoading(false)
      if (err) { /* handled in hook */ return }
      if (jsonData.postMessages.length > 0) {
        setPostMessages([...jsonData.postMessages, ...postMessages])
        setStartsAt(jsonData.startsAt)
      }
    })
  }

  const addPostMessage = (postMessage) => {
    shouldScrollRef.current = true
    setPostMessages([...postMessages, ...[postMessage]])
  }

  if (project.status === 'draft') {
    return <span ref={ref}>
      <button type="button" className="relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccblue" >
        <ExclamationIcon className="mx-auto h-12 w-12 text-gray-400" />
        <span className="mt-2 block text-sm font-medium text-gray-900 dark:text-gray-300"> Your project is in draft mode. Click the 'publish' button to make it live!</span>
      </button>
    </span>
  }

  return <div className='grid grid-cols-4'>
    <div className='sm:col-span-3 col-span-4'>
      <Card light title='Message Thread'>
        <div className='h-75vh overflow-y-auto flex flex-col'>
          <ul role="list" className='flex-grow overflow-y-auto bg-white dark:bg-gray-700 rounded-md'>
            <li ref={ref}></li>
            { loading && <Loading /> }
            {postMessages.map((pm, idx) => (
              <MessageRow key={`privpostmess${pm.id}`} postMessage={pm} />
            ))}
            <li ref={scrollRef}></li>
          </ul>
          { postMessages.length === 0 && !loading && <NoResults /> }
          <ProjectMessageForm addPostMessage={addPostMessage} postId={postId} />
        </div>
      </Card>
    </div>
    <div className='sm:col-span-1 col-span-4'>
      <ProjectMessagePeopleInThisThread postId={postId} projectUserId={project.userId} />
    </div>
  </div>
}

export default ProjectMessagesIndex

MessageRow.propTypes = {
  postMessage: PropTypes.object.isRequired
}
