import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useGlobalState } from '@/state'
import useQuery from '@/hooks/useQuery'
import DefaultButton from '@/shared/Buttons/Default'
import ManauallyAssignSomeoneModal from '@/pages/ManageProjects/ManageRoles/ManuallyAssignSomeoneModal'
import DeleteRoleUserModal from '@/pages/ManageProjects/ManageRoles/DeleteRoleUserModal'
import Modal from '@/shared/Modal'
import StatusPill from '@/shared/StatusPill'
import useModal from '@/hooks/useModalV2'
import { randomToastSuccess } from '@/shared/Toast'

const ManageRoleUserRow = ({roleUser, deleteRoleUser, role}) => {
  const { putpostRequest, getRequest } = useQuery()
  const [status, setStatus] = useState(roleUser.ownerStatus)
  const [, setToast] = useGlobalState('toast')
  const toggle = () => {
    const newStatus = status === 'incompleted' ? 'completed' : 'incompleted'
    setStatus(newStatus)
    putpostRequest(`/api/v3/role_users/${roleUser.id}`, 'PATCH', { role_user: { owner_status: newStatus } }, (err, jsonData) => {
      if (err) { /* hook */ return }

      setToast(randomToastSuccess())
    })
  }

  return <li className='bg-gray-50 dark:bg-gray-700 rounded-md p-2 flex justify-between items-center'>
    <span className='text-gray-900 dark:text-gray-200'>{roleUser.name}</span>
    <div className='flex justify-end space-x-2 items-center'>
      { status === 'incompleted' && <button onClick={toggle} className='inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium bg-cccblue text-white hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccpurple'>Mark as Done</button> }
      { status === 'completed' && <button onClick={toggle} className='inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white dark:bg-gray-900 dark:text-white dark:hover:bg-gray-800 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccpurple'>Mark as Not Done</button> }
      <DeleteRoleUserModal roleUser={roleUser} deleteRoleUser={deleteRoleUser} roleName={role.name} />
    </div>
  </li>
}

const ManageRolesRow = ({ role }) => {
  const { openModal, closeModal, isOpen } = useModal()
  const [, setToast] = useGlobalState('toast')
  const [roleUsers, setRoleUsers] = useState(role.roleUsers)
  const { putpostRequest, getRequest } = useQuery()
  const [status, setStatus] = useState(role.status)
  let pillColor = 'default'
  if (status === 'open') {
    pillColor = 'warning'
  } else if (status === 'closed') {
    pillColor = 'success'
  }

  const addRoleUser = (roleUser) => {
    setRoleUsers([...roleUsers, ...[roleUser]])
  }
  const deleteRoleUser = (roleUserId) => {
    const newRoleUsers = roleUsers.filter(uc => uc.id !== roleUserId)
    setRoleUsers(newRoleUsers)
  }

  const toggle = () => {
    const newStatus = status === 'closed' ? 'open' : 'closed'
    setStatus(newStatus)
    putpostRequest(`/api/v3/roles/${role.id}`, 'PATCH', { role: { status: newStatus } }, (err, jsonData) => {
      if (err) { /* hook */ return }

      setToast(<div className="ml-3 w-0 flex-1 pt-0.5">
        <p className="text-sm font-medium text-green-500">Saving....</p>
        <p className="mt-1 text-sm text-green-500">Please do not remove the cartridge from the game console.</p>
      </div>)
      closeModal()
    })
  }

  return <li className='bg-white dark:bg-gray-800 p-2 rounded-md relative' draggable>
    <Modal isOpen={isOpen} closeModal={closeModal} >
      <div className="flex flex-col text-center items-center">
        <h2 className="mb-8 text-xs font-semibold tracking-widest text-gray-800 dark:text-gray-200 uppercase title-font">Change role status</h2>
      </div>
        <p className='flex justify-center items-center text-gray-900 dark:text-gray-100'>{status === 'closed' ? 'Re-open' : 'Close'} this role?</p>
      <div className="rounded-md bg-blue-50 mt-4 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <svg className="h-5 w-5 text-blue-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fillRule="evenodd" d="M19 10.5a8.5 8.5 0 11-17 0 8.5 8.5 0 0117 0zM8.25 9.75A.75.75 0 019 9h.253a1.75 1.75 0 011.709 2.13l-.46 2.066a.25.25 0 00.245.304H11a.75.75 0 010 1.5h-.253a1.75 1.75 0 01-1.709-2.13l.46-2.066a.25.25 0 00-.245-.304H9a.75.75 0 01-.75-.75zM10 7a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
            </svg>
          </div>
          <div className="ml-3 flex-1 md:flex md:justify-between">
            <p className="text-sm text-blue-700">When a role is open, people can find it on the Search page. When it's closed, no one will be able to send in a submission.</p>
          </div>
        </div>
      </div>
      <div className="flex justify-end space-x-2 mt-4">
        <DefaultButton onClick={closeModal} text='Cancel' />
        <DefaultButton onClick={toggle} text={status === 'closed' ? 'Re-open' : 'Close'} />
      </div>
    </Modal>

    <div className='grid sm:grid-cols-2'>
      <div className='flex flex-col grid-cols-1 space-y-2'>
        <div className="flex sm:items-center  flex-wrap sm:flex-nowrap">
          <div className="flex-shrink-0 hidden sm:block">
            <img className='h-20 w-20 rounded-md' src={role.imageUrl} alt={role.name} />
          </div>
          <div className="min-w-0 flex-auto px-0 sm:px-4 md:gap-4 w-full">
            <div className='flex flex-col truncate space-y-2'>
              <span className='flex justify-start space-x-4'>
                <span className="inline-flex items-center rounded-md bg-cccblue-alt bg-opacity-20 px-2.5 py-0.5 text-sm font-medium text-cccblue">{role.kind}</span>
                <StatusPill status={pillColor} text={status} />
              </span>
              <div className="font-bold dark:text-gray-300">{role.name}</div>
            </div>
          </div>
        </div>
        <div>
          <DefaultButton onClick={openModal} text={`${status === 'closed' ? 'Re-open' : 'Close'} this role`} />
        </div>
      </div>
      <span className="grid-cols-1">
        <div className="bg-cccblue bg-opacity-20 p-2 rounded-md">
          <div className='flex flex-col text-gray-900 dark:text-gray-100'>
            <div>Assigned to this role:</div>
          </div>
          {roleUsers.length > 0 && <ul className='space-y-2'>
            {roleUsers.map((ru, idx) => (
              <ManageRoleUserRow key={`r${role.id}ru${ru.id}idx${idx}`} roleUser={ru} role={role} deleteRoleUser={deleteRoleUser} />
            ))}
          </ul> }
          {roleUsers.length === 0 && <div className='text-xs dark:text-gray-200'>No one yet</div> }
        </div>
        <div className="bg-cccblue bg-opacity-20 p-2 rounded-md mt-2">
          <ManauallyAssignSomeoneModal role={role} addRoleUser={addRoleUser} />
        </div>
      </span>
    </div>
  </li>
}

export default ManageRolesRow

ManageRolesRow.propTypes = {
  role: PropTypes.object.isRequired
}
