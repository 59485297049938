import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { useLocation, useHistory } from 'react-router-dom'

import { useGlobalState } from '@/state'
import useDropdown from '@/hooks/useDropdown'
import { MenuIcon } from '@heroicons/react/outline'

import classNames from '@/utils/classNamesLocal'

const styles = {
  desktop: {
    selectedTab: 'border-cccblue text-cccblue dark:text-white dark:bg-cccblue',
    unselectedTab: 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 dark:text-white',
    defaultTab: 'whitespace-nowrap py-2 px-2 border-b-2 font-medium text-sm rounded-t-md'
  },
  mobile: {
    selectedTab: 'border-cccblue text-cccblue',
    unselectedTab: 'text-gray-900 hover:bg-gray-50 hover:text-gray-900 dark:text-white',
    defaultTab: 'block rounded-md pt-2 px-3 text-base font-medium'
  }
}

const TabCount = ({ number, selected }) => {
  return <>
    <span className={classNames(
      selected ? 'bg-cccblue text-white dark:bg-white dark:text-cccblue' : 'bg-gray-200 text-gray-900',
      'hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block'
    )} >
      {number}
    </span>
  </>
}

const Tab = ({ url, name, view, closeDropdown }) => {
  const location = useLocation()
  const history = useHistory()
  const activePath = location.pathname
  const selected = activePath === url
  console.log(activePath, url)

  const handleOnClick = () => {
    closeDropdown()
    history.push(url)
  }

  return (
    <button onClick={handleOnClick} className={classNames(
        selected ? styles[view].selectedTab : styles[view].unselectedTab, styles[view].defaultTab
      )} aria-current={selected ? 'page' : undefined} aria-label={name}>
      {name}
    </button>
  )
}

export default function CreditTabs({ credit }) {
  const dropdownRef = useRef(false)
  const { dropdownOpen, setDropdownOpen, openDropdown } = useDropdown(dropdownRef)
  const handleClick = () => { setDropdownOpen(false) }
  const [currentUser] = useGlobalState('currentUser')

  if (!credit.editerUserIds.includes(currentUser?.id)) { return null }

  return <>
    <div className="lg:divide-y lg:divide-gray-200">
      <div className="border-b border-gray-200">
        <nav className="hidden lg:py-2 lg:flex lg:space-x-4" aria-label="Global">
          <Tab closeDropdown={handleClick} name='Manage Team' url={`/credits/${credit.id}/manage`} view='desktop' />
          <Tab closeDropdown={handleClick} name='Edit Production' url={`/credits/${credit.id}/edit`} view='desktop' />
          <Tab closeDropdown={handleClick} name='View Public Page' url={`/credits/${credit.slug}`} view='desktop' />
        </nav>
      </div>
      <div className="relative z-10 flex items-center lg:hidden">
        <button onClick={openDropdown} className="py-1 px-2 sm:text-sm text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white">
          <span className="sr-only" hidden>Open filter menu</span>
          <MenuIcon className="h-4 w-4 sm:h-5 sm:w-5" aria-hidden="true" />
        </button>
      </div>
    </div>
    { dropdownOpen && <div ref={dropdownRef} tabIndex="-1" className={`absolute left-0 z-30 ${dropdownOpen ? '' : 'hidden opacity-0 scale-0'} py-2 overflow-y-auto transition origin-top-right bg-white border border-gray-900 dark:border-gray-100 rounded-md shadow-md dark:bg-gray-800 top-6 max-h-80`}>
      <div className="pt-2 pb-3 px-2 space-y-1">
          <Tab closeDropdown={handleClick} name='Manage Team' url={`/credits/${credit.id}/manage`} view='mobile' />
          <Tab closeDropdown={handleClick} name='Edit Production' url={`/credits/${credit.id}/edit`} view='mobile' />
          <Tab closeDropdown={handleClick} name='View Public Page' url={`/credits/${credit.slug}`} view='mobile' />
      </div>
    </div> }
  </>
}

Tab.propTypes = {
  url: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  closeDropdown: PropTypes.func.isRequired,
  view: PropTypes.string.isRequired
}
TabCount.propTypes = {
  number: PropTypes.number,
  selected: PropTypes.bool
}
CreditTabs.propTypes = {
  credit: PropTypes.object.isRequired
}
