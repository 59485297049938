import { useState, useEffect } from 'react'

const useDropdown = (ref) => {
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const toggleDropdownOpen = () => { setDropdownOpen(!dropdownOpen) }

  const handleDropdownClick = e => {
    if (!dropdownOpen) { return }

    if (ref.current && !ref.current.contains(e.target)) { toggleDropdownOpen() }
  }

  const openDropdown = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setDropdownOpen(true)
  }

  useEffect(() => {
    document.addEventListener('click', handleDropdownClick)

    return () => {
      document.removeEventListener('click', handleDropdownClick)
    }
  })

  return {
    dropdownOpen,
    setDropdownOpen,
    openDropdown
  }
}

export default useDropdown
