import React, { useState, useEffect, useRef } from 'react'
import PrimaryButton from '@/shared/Buttons/Primary'
import CommentForm from '@/shared/CommentForm'
import CommentRow from '@/shared/CommentRow'
import { ChatAltIcon } from '@heroicons/react/solid'
import useModal from '@/hooks/useModalV2'
import Loading from '@/shared/Loading'
import useQuery from '@/hooks/useQuery'
import Modal from '@/shared/Modal'
import Card from '@/shared/Card'

const PublicProjectCommentsIndex = (props) => {
  const { projectId } = props
  const { openModal, closeModal, isOpen } = useModal()

  const { getRequest } = useQuery()
  const [loading, setLoading] = useState(false)
  const scrollRef = useRef(null)
  const [comments, setComments] = useState([])

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = () => {
    closeModal()
    setLoading(true)
    getRequest(`/api/v3/manage/projects/${projectId}/comments`, {}, (err, jsonData) => {
      if (err) { /* handled in hook */ return }
      setLoading(false)
      setComments(jsonData.comments)
    })
  }

  return <div className='mt-3'>
    <Modal isOpen={isOpen} closeModal={closeModal} >
      <Card title="Add a Comment">
        <CommentForm commentableId={projectId} commentableType='Project' addChildtoParent={fetchData} setHide={closeModal} />
      </Card>
    </Modal>
    <Card title={
      <div className='flex justify-between items-center'>
        <div className='font-medium leading-6 text-cccorange dark:text-cccpurple'>Project Comments</div>
      </div>
      } footer={
    <div className='flex flex-rows justify-end items-end'>
      <div className='flex flex-col items-end w-min ml-2'>
        <PrimaryButton className='w-full' onClick={openModal} text='Add a Comment' />
      </div>
    </div>
    } >
      <div ref={scrollRef}>
        { loading && <Loading /> }
        { comments.length === 0 && !loading && <NoResults /> }
        <ul className="space-y-3">
          {comments.map((comment, idx) => (
            <CommentRow key={`manprocomm${comment.id}`} initialComment={comment} />
          ))}
        </ul>
      </div>
    </Card>
  </div>
}

const NoResults = () => {
  return (
    <span>
      <div className="relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccblue" >
        <ChatAltIcon className="mx-auto h-12 w-12 text-gray-400" />
        <span className="mt-2 block text-sm font-medium text-gray-900 dark:text-gray-300"> There are no comments on this project yet. Be the first? Take that mighty step. DO IT NOW.</span>
      </div>
    </span>
  )
}

export default PublicProjectCommentsIndex
