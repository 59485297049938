import React, { useState, useReducer, useEffect } from 'react'
import PropTypes from 'prop-types'

import { useForm } from 'react-hook-form'
import { debounce } from 'throttle-debounce'

import Card from '@/shared/Card'
import { randomToastSuccess } from '@/shared/Toast'

import useModal from '@/hooks/useModalV2'
import Loading from '@/shared/Loading'
import Modal from '@/shared/Modal'
import FroalaWrapper from '@/shared/FroalaWrapper'
import ImageUploadForm from '@/shared/ImageUploadForm'
import PrimaryButton from '@/shared/Buttons/Primary'
import { ExclamationCircleIcon, XCircleIcon } from '@heroicons/react/solid'
import useQuery from '@/hooks/useQuery'
import { useGlobalState } from '@/state'

function generateArrayOfYears() {
  const max = new Date().getFullYear() + 1
  const min = max - 40
  const years = []

  for (let i = max; i >= min; i--) {
    years.push(i)
  }
  return years
}

const EducationImage = ({ education, setEducation }) => {
  const [url, setUrl] = useState(education.imageUrl)
  const [uploaded, setUploaded] = useState(false)
  const { openModal, closeModal, isOpen } = useModal()
  const { getRequest } = useQuery()

  const reloadPublicImageURL = () => {
    getRequest(`/api/v3/educations/${education.id}/image`, {}, (err, jsonData) => {
      if (err) { return }
      setUrl(jsonData.imageUrl)
      setEducation({ ...education, imageUrl: jsonData.imageUrl })
    })
  }

  return <>
    <div className="mt-1 flex justify-center p-3 hover:opacity-75 cursor-pointer border-2 border-gray-300 border-dashed rounded-full relative">
      { !uploaded && <span>
          <img className='h-20 w-20 rounded-full' src={url} onClick={openModal} alt={education.courseName} />
        </span>
        }
      { uploaded && <div className='relative h-20 w-20 rounded-full flex items-center justify-center dark:bg-gray-700'>
        <img className='h-20 w-20 rounded-full' src={url} onClick={openModal} alt={education.courseName} />
        <div className='absolute h-20 w-20 top-8'>
          <Loading noMessage noLoadingMessage />
        </div>
      </div> }
    </div>
    <Modal isOpen={isOpen} closeModal={closeModal} >
      <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white"> Edit Image</h3>
      <div className='flex justify-center'>
        <div className="mt-2">
          <ImageUploadForm
            kind='Education'
            objectId={education.id}
            url={url}
            setUrl={setUrl}
            success={reloadPublicImageURL}
            didUpload={setUploaded}
          />
        </div>
      </div>
    </Modal>
  </>
}

const EducationRowPreview = ({ education }) => {
  return <>
    <div className='bg-white dark:bg-gray-800 px-4 py-4 sm:px-6 rounded-lg shadow-lg '>
      <div className={'flex flex-col sm:flex-row sm:items-centerflex-wrap'}>
        <div className="min-w-0 flex-1 flex sm:items-center  flex-wrap sm:flex-nowrap">
          <div className="flex-shrink-0 hidden sm:block">
            <img className="h-16 w-16 rounded-full" src={education.imageUrl} />
          </div>
          <div className="min-w-0 flex-auto px-0 sm:px-4 md:gap-4">
            <div className='truncate'>
              <span className="uppercase text-xs dark:text-gray-300 text-gray-500 mr-5">{education.schoolName} - {education.year}</span>
              <p className='truncate text-cccblue'><a href={education.url} target="_blank" rel="noreferrer" className="font-medium text-cccblue truncate">{education.courseName}</a></p>
              { education.instructor && <div className="text-xs text-gray-500 dark:text-gray-300">Instructed by
                <span className="ml-1 text-sm font-medium text-gray-900 dark:text-gray-200"> {education.instructor} </span>
              </div> }
            </div>
          </div>
        </div>
      </div>
      <div className="text-gray-500 dark:text-gray-300 text-sm mt-3" dangerouslySetInnerHTML={{ __html: education.description }} />
    </div>
  </>
}

const EducationRow = (props) => {
  const { updateIndex } = props
  const { openModal, closeModal, isOpen } = useModal()
  const [, setToast] = useGlobalState('toast')
  const { putpostRequest } = useQuery()
  const [education, setEducation] = useState(props.education)
  const [loading, setLoading] = useState(false)
  const [deleteConfirm, setDeleteConfirm] = useState(false)

  const deleteEducation = () => {
    setLoading(true)
    putpostRequest(`/api/v3/educations/${education.id}`, 'DELETE', { }, (err, jsonData) => {
      setLoading(false)
      if (err) { /* hook */ return }

      if (typeof (updateIndex) === 'function') {
        updateIndex()
        setToast(<div className="ml-3 w-0 flex-1 pt-0.5">
          <p className="text-sm font-medium dark:text-white text-gray-800">Education Deleted</p>
          <p className="mt-1 text-sm dark:text-gray-100 text-gray-500">Yep, it's gone.</p>
        </div>)
      }
    })
  }

  if (loading) { return <Loading /> }

  return <>
    <div className='bg-white dark:bg-gray-700 px-4 py-4 sm:px-6 rounded-lg '>
      <div className={'flex flex-col sm:flex-row sm:items-centerflex-wrap'}>
        <div className="min-w-0 flex-1 flex sm:items-center  flex-wrap sm:flex-nowrap">
          <div className="flex-shrink-0 hidden sm:block">
            <EducationImage education={education} setEducation={setEducation} />
          </div>
          <div className="min-w-0 flex-auto px-0 sm:px-4 md:gap-4 w-full">
            <div>
              <span className="uppercase text-xs dark:text-gray-300 text-gray-500 mr-5">{education.schoolName} - {education.year}</span>
              <p className='truncate text-cccblue'><a href={education.url} target="_blank" rel="noreferrer" className="font-medium text-cccblue">{education.courseName}</a></p>
              { education.instructor && <div className="text-xs text-gray-500 dark:text-gray-300">Instructed by
                <span className="ml-1 text-sm font-medium text-gray-900 dark:text-gray-200"> {education.instructor} </span>
              </div> }
            </div>
          </div>
        </div>
        <Modal full isOpen={isOpen} closeModal={closeModal} >
          <div className='relative mb-1'>
            <span className="sr-only" hidden>Close Modal</span>
            <XCircleIcon onClick={closeModal} className='cursor-pointer w-7 h-7 absolute -top-5 -right-5 text-gray-500 dark:text-gray-400' />
          </div>
          <EducationForm education={education} setEducation={setEducation} />
        </Modal>
        <div className='my-3 sm:mx-0'>
          <span className="ml-4 flex-shrink-0 flex items-start space-x-4">
            <button onClick={openModal} type="button" className="rounded-md font-medium text-cccblue hover:text-cccblue-alt">
              Edit
            </button>
            <span className="text-gray-300" aria-hidden="true">|</span>
            { !deleteConfirm && <button type="button" onClick={() => setDeleteConfirm(true)} className="rounded-md font-medium text-cccblue hover:text-cccblue-alt"> Delete </button> }
            { deleteConfirm && <button type="button" onClick={deleteEducation} className="rounded-md font-medium text-red-500"> Really delete this? </button> }
          </span>
        </div>
      </div>
      <div className="text-gray-500 dark:text-gray-300 text-sm mt-3" dangerouslySetInnerHTML={{ __html: education.description }} />
    </div>
  </>
}

const fakeEducation = {
  year: '2021',
  imageUrl: 'https://clc-content.b-cdn.net/closing-credits-logo.svg',
  courseName: 'Voice Acting 101',
  schoolName: 'Closing Credits',
  instructor: 'John Wang',
  url: 'https://www.closingcredits.com',
  description: 'I booked my first paying job after taking this course. <a target="_blank" href="https://www.closingcredits.com/courses/voice-acting-101">Voice Acting 101</a> changed the fundamentals on the way I speak.'
}

const EducationForm = ({ education, setEducation, updateIndex }) => {
  const [, setToast] = useGlobalState('toast')
  const years = generateArrayOfYears()
  const { putpostRequest } = useQuery()
  const { formState, register, unregister, handleSubmit, setValue, getValues, setError, setFocus } = useForm({
   defaultValues: {
      school_name: education?.schoolName,
      course_name: education?.courseName,
      description: education?.description,
      year: education?.year,
      instructor: education?.instructor,
      url: education?.url
    }
  })

  const { errors } = formState
  const [loading, setLoading] = useState(false)
  const description = getValues().description

  useEffect(() => {
    setFocus('school_name')
  }, [setFocus])

  useEffect(() => {
    register('description')
    return () => {
      unregister('description')
    }
  }, [register])

  const handleUpdateFroala = (key, val) => {
    setValue(key, val, { shouldDirty: true, shouldValidate: true })
  }

  const keyPress = (e) => {
    if ((e.metaKey || e.ctrlKey) && e.keyCode === 13) {
      handleSubmit(onSubmit)()
    }
  }

  const onSubmit = debounce(300, data => {
    setLoading(true)

    let URL = '/api/v3/educations'
    let action = 'POST'
    if (education?.id) {
      URL = `/api/v3/educations/${education.id}`
      action = 'PATCH'
    }

    putpostRequest(URL, action, { education: data }, (err, jsonData) => {
      setLoading(false)
      if (err) { // 422 code
        if (typeof err !== 'string') { // 500 code on API
          Object.entries(err).forEach(([key, value]) => {
            setError(key, value)
          })
        }
        return
      }

      setEducation({ ...jsonData.education })
      if (typeof (updateIndex) === 'function') {
        updateIndex()
        setToast(<div className="ml-3 w-0 flex-1 pt-0.5">
          <p className="text-sm font-medium dark:text-white text-gray-800">Education Added</p>
          <p className="mt-1 text-sm dark:text-gray-100 text-gray-500">So educational. 0_0</p>
        </div>)
      } else {
        setToast(randomToastSuccess())
      }
    })
  })

  return <>
    <div className="md:grid md:grid-cols-3 md:gap-6">
      <div className="md:col-span-1">
        <div className="px-4 sm:px-0 hidden sm:block">
          <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-100">Education</h3>
          {education?.id && <EducationRowPreview education={education} /> }
          {!education?.id && <span className='dark:text-gray-400'>
            <div className='mb-2'>Sample education preview</div>
            <EducationRowPreview education={fakeEducation} />
          </span> }
        </div>
      </div>
      <Card footer={
        <div className='flex flex-col items-end'>
          <div className='flex flex-col items-end w-min ml-2'>
            <div className="hidden sm:block mt-1 w-min text-xs border border-gray-200 dark:border-gray-800 rounded px-2 font-sans text-gray-500 dark:text-gray-400">⌘/ctrl+ENTER</div>
            <PrimaryButton className='w-full' onClick={handleSubmit(onSubmit)} loading={loading} text='Save' />
          </div>
        </div>
        } >
        <form>
          <div className="grid sm:grid-cols-4 gap-6">
            <div className="col-span-4 sm:col-span-3">
              <label htmlFor="school_name" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">Institution Name </label>
              <div className="mt-1 flex flex-col rounded-md shadow-sm">
                <div className='relative'>
                  <input onKeyDown={keyPress} type="text" className={errors.school_name ? 'errors' : ''} {...register('school_name') } placeholder="Closing Credits" />
                  { errors.school_name && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                  </div> }
                </div>
              </div>
              <p className="text-xs text-gray-500 dark:text-gray-400 mt-1">Name of the school or group, like Closing Credits, Art Institute of LA, Toastmasters</p>
              { errors.school_name && <div className='mt-2 text-sm text-red-600'>This field has an error.</div> }
            </div>

            <div className='col-span-1'>
              <div className="col-span-6 sm:col-span-3">
                <label htmlFor="country" className="block text-sm font-medium text-gray-700 dark:text-gray-200 flex justify-between">
                  <span>Year</span>
                </label>
                <select
                  {...register('year')}
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm"
                >
                  {years.map((year, idx) => (
                    <option key={year}>{year}</option>
                  ))}
                </select>
              </div>
            </div>

            <div className="col-span-4 sm:col-span-2">
              <label htmlFor="school_name" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between"> Course Name </label>
              <div className="mt-1 flex flex-col rounded-md shadow-sm">
                <div className='relative'>
                  <input onKeyDown={keyPress} type="text" autoFocus className={errors.course_name ? 'errors' : ''} {...register('course_name') } placeholder="Voice Acting 101" />
                  { errors.course_name && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                  </div> }
                </div>
              </div>
              <p className="text-xs text-gray-500 dark:text-gray-400 mt-1"> Name of the course you took, like Voice Acting 101, Bachelors of Fine Arts, Private Coaching </p>
              { errors.course_name && <div className='mt-2 text-sm text-red-600'>This field has an error.</div> }
            </div>
            <div className="col-span-4 sm:col-span-2">
              <label htmlFor="instructor" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between"> Instructor's Name </label>
              <div className="mt-1 flex flex-col rounded-md shadow-sm">
                <div className='relative'>
                  <input type="text" onKeyDown={keyPress} className={errors.instructor ? 'errors' : ''} {...register('instructor') } placeholder="John Wang" />
                  { errors.instructor && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                  </div> }
                </div>
              </div>
              <p className="text-xs text-gray-500 dark:text-gray-400 mt-1"> e.g. Brendan Hunter, Deb Munro, June Yoon</p>
              { errors.instructor && <div className='mt-2 text-sm text-red-600'>This field has an error.</div> }
            </div>

            <div className="col-span-3 sm:col-span-2">
              <label htmlFor="url" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">
                <span>URL</span>
              </label>
              <div className="mt-1 flex flex-col rounded-md shadow-sm">
                <div className='relative'>
                  <input type="text" onKeyDown={keyPress} className={errors.url ? 'errors' : ''} {...register('url') } placeholder="https://www.closingcredits.com" />
                  { errors.url && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                  </div> }
                </div>
              </div>
            </div>

            <div className="col-span-4 sm:col-span-4">
                <div className='mt-4'>
                  <label htmlFor="pricing" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                    Description
                  </label>
                  <p className="text-xs text-gray-500  dark:text-gray-400 mb-1"> Any extra info you want to add, like if you graduated valedictorian or were a TA. Any other links or notes. </p>
                  <div className="mt-1 shadow-sm w-full min-w-full">
                    <FroalaWrapper
                      model={description}
                      keyPress={keyPress}
                      updateModel={(val) => handleUpdateFroala('description', val)}
                    />
                  </div>
                  <i className="mt-2 flex justify-end text-xs text-gray-500 dark:text-gray-400">
                    (Tip: You can add URLs and @mention your followers and your credits.)
                  </i>
                </div>
              </div>

            <div className="col-span-3">
            </div>
          </div>
        </form>
      </Card>
    </div>
  </>
}

const ProfileEducationIndex = () => {
  const { getRequest } = useQuery()
  const { openModal, closeModal, isOpen } = useModal()
  const [state, setState] = useReducer(
    (state, newState) => (
      { ...state, ...newState }), {
        educations: [],
        loadingEducations: true
      }
    )
  const { educations, loadingEducations } = state

  useEffect(() => { fetchData() }, [])

  const fetchData = () => {
    getRequest('/api/v3/educations', {}, (err, jsonData) => {
      if (err) {
        setState({ loadingEducations: false })
      } else {
        setState({ educations: jsonData.educations, loadingEducations: false })
      }
    })
  }

  const updateIndex = () => {
    closeModal()
    fetchData()
  }

  if (loadingEducations) {
    return <Loading />
  }

  return <>
    <Modal full isOpen={isOpen} closeModal={closeModal} >
      <div className='relative mb-1'>
        <span className="sr-only" hidden>Close Modal</span>
        <XCircleIcon onClick={closeModal} className='cursor-pointer w-7 h-7 absolute -top-5 -right-5 text-gray-500 dark:text-gray-400' />
      </div>
      <EducationForm education={{}} setEducation={() => {}} updateIndex={updateIndex} />
    </Modal>
    <div>
      <div className="md:grid md:grid-cols-3 md:gap-6">
        <div className="md:col-span-1">
          <div className="px-4 sm:px-0">
            <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-100">Education</h3>
            <p className="mt-1 text-sm text-gray-600 dark:text-gray-300">
              This information will be displayed on your profile. You can add classes you've taken, coaching, degrees, online video courses, or anything you feel appropriate.
            </p>
          </div>
        </div>
        <div className="mt-5 md:mt-0 md:col-span-2">
          { educations.length === 0 && <>
            <section aria-labelledby="my-course-content" onClick={openModal}>
              <button type="button" className="relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" >
                <svg xmlns="http://www.w3.org/2000/svg" className="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path d="M12 14l9-5-9-5-9 5 9 5z" />
                  <path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z" />
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222" />
                </svg>
                <span className="mt-2 block text-sm font-medium text-gray-900 dark:text-gray-300">Add any creator specific education here, like courses you've taken from Closing Credits, Art School, Film School, or coaches you've had in the past.</span>
              </button>
            </section>
          </> }
          { educations.length > 0 && <>
            <Card header={<div className='text-cccblue hover:text-cccblue-alt cursor-pointer' onClick={openModal}>Add Education</div>}>
              <div className="grid grid-cols-3 gap-6">
                <div className='col-span-3'>
                  <div className="sm:rounded-md">
                    <ul className="space-y-3">
                      { educations.map((edu, idx) => (
                        <EducationRow key={`edu${edu.id}`} education={edu} updateIndex={updateIndex} />
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </Card>
          </> }
        </div>
      </div>
    </div>
  </>
}

export default ProfileEducationIndex

EducationRow.propTypes = {
  education: PropTypes.object.isRequired,
  updateIndex: PropTypes.func
}
EducationForm.propTypes = {
  education: PropTypes.object.isRequired,
  setEducation: PropTypes.func,
  updateIndex: PropTypes.func
}
EducationImage.propTypes = {
  education: PropTypes.object.isRequired,
  setEducation: PropTypes.func
}
EducationRowPreview.propTypes = {
  education: PropTypes.object.isRequired
}
