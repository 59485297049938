import React, { useState, useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { ExclamationIcon, ArrowDownIcon } from '@heroicons/react/solid'
import { useGlobalState } from '@/state'

import useQuery from '@/hooks/useQuery'
import PlanSelector from '@/pages/SubscriptionForm/PlanSelector'
import ExistingPlan from '@/pages/SubscriptionForm/ExistingPlan'
import Loading from '@/shared/Loading'

const BraintreeLegacyNotice = ({ sub }) => {
  const [currentUser] = useGlobalState('currentUser')
  const [cancel, setCancel] = useState(false)
  const [, setToast] = useGlobalState('toast')
  const [didCancel, setDidCancel] = useState(false)
  const [loading, setLoading] = useState(false)
  const { putpostRequest } = useQuery()

  const reallyCancel = () => {
    setLoading(true)
    putpostRequest('/api/v3/subscriptions/cancel_braintree', 'POST', {}, (err, jsonData) => {
      if (err) { console.log('toast errors'); return }
      setLoading(false)
      if (jsonData.canceled) {
        setDidCancel(true)
        setToast(<div className="ml-3 w-0 flex-1 pt-0.5">
          <p className="text-sm font-medium dark:text-white text-gray-800">Canceled Plan</p>
          <p className="mt-1 text-sm dark:text-gray-100 text-gray-500">Thanks so much for supporting CCC while you did. You are awesome and I hope to see you around again.</p>
        </div>)
      }
    })
  }

  if (loading) return <Loading />

  if (didCancel) {
    return <div className="rounded-md bg-green-50 p-4 mt-10">
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-green-800">Cancellation Successful</h3>
          <div className="mt-2 text-sm text-green-700">
            I hate to see you go, but I'm happy you stopped by. You won't be charged again. You can continue to use your paid plan until your billing cycle ends.
          </div>
        </div>
      </div>
    </div>
  }

  return <>
    <div className="rounded-md bg-yellow-50 p-4 mt-10">
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h2>We're making changes</h2>
          <h3 className="text-sm font-medium text-yellow-800">You are on a legacy plan that is going away soon: {currentUser.gold}</h3>
          <div className="mt-2 text-sm text-yellow-700">
            You can continue your subscription as is, but once it expires or if you upgrade the new plans, you cannot go back.
            { currentUser.gold === 'gold' && <p className='mt-2'>'gold' plan is equivalent to the 'apprentice' plan in the new model.</p> }
          </div>
          <div className="mt-2 text-sm text-yellow-700">
            The next billing date will be {sub.nextBillingDate} for the amount of ${sub.nextBillingAmount} USD unless you <b onClick={() => setCancel(true)} aria-label='cancel' className='cursor-pointer font-bold text-cccblue'>cancel</b>
          </div>
          <div className="mt-2 text-sm text-yellow-700">
            The new plans are below.
            <ArrowDownIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
          </div>
        </div>
      </div>
    </div>
    { cancel && <div className="rounded-md bg-red-50 p-4 mt-10">
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-800">Cancel your plan?</h3>
          <div className="mt-2 text-sm text-red-700">
            Please confirm you'd really like to cancel your plan. This cannot be undone because this plan is going away.
          </div>
          <div className="mt-2 text-sm text-red-700">
            <button className='font-bold' onClick={reallyCancel} aria-label='confirm cancel'>Yes, really cancel the subscription</button>
          </div>
        </div>
      </div>
    </div> }
  </>
}

const LegacyOnlyNotice = ({ info }) => {
  const [currentUser] = useGlobalState('currentUser')

  return <>
    <div className="rounded-md bg-yellow-50 p-4 mt-10">
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h2>We're making changes</h2>
          <h3 className="text-sm font-medium text-yellow-800">You are on a legacy plan that is going away soon: {currentUser.gold}</h3>
          <div className="mt-2 text-sm text-yellow-700">
            You can continue your plan as is, but once it expires or if you upgrade the new plans, you cannot go back.
            { currentUser.gold === 'gold' && <p className='mt-2'>Your plan is equivalent to the 'apprentice' plan in the new model.</p> }
          </div>
          <div className="mt-2 text-sm text-yellow-700">
            Your plan will <b>expire</b> on {info.planEndsAt}. <b>You will not be charged again.</b>
          </div>
          <div className="mt-2 text-sm text-yellow-700">
            The new plans are below.
            <ArrowDownIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
          </div>
        </div>
      </div>
    </div>
  </>
}

export default function SubscriptionForm () {
  const [loading, setLoading] = useState(true)
  const { getRequest } = useQuery()
  const [info, setInfo] = useState(null)

  useEffect(() => {
    // credits/plan could come in through webhook. need refresh
    getRequest('/api/v3/subscriptions', {}, (err, jsonData) => {
      if (err) { /* hook */ return }
      setInfo(jsonData.info)
      setLoading(false)
    })
  }, [])

  if (loading) return <Loading />

  if (info?.newPlan || info?.noSub) {
    return <>
      <StripeSubscriptionHandler />
    </>
  } else if (info?.legacyPlanOnly) {
    return <>
      <LegacyOnlyNotice info={info} />
      <StripeSubscriptionHandler />
    </>
  } else if (info?.currentBraintreePlan) {
    return <>
      <BraintreeLegacyNotice sub={info?.sub} />
      <StripeSubscriptionHandler />
    </>
  } else {
    return <>error please email moderators@castingcall.club</>
  }
}

const StripeSubscriptionHandler = () => {
  const stripePromise = window.stripeKey ? loadStripe(window.stripeKey) : undefined
  const [errorMessage, setErrorMessage] = useState(null)
  const [updateExisting, setUpdateExisting] = useState(false)
  const [subscriptionContainer] = useGlobalState('subscriptionContainer')
  const { getRequest } = useQuery()
  const [state, setState] = useReducer(
    (state, newState) => (
      { ...state, ...newState }), {
        paymentMethods: [],
        loadingPaymentMethods: true
      }
    )
  const { paymentMethods, loadingPaymentMethods } = state
  const { existingSubscription } = subscriptionContainer

  useEffect(() => { fetchPaymentMethods() }, [])

  const fetchPaymentMethods = () => {
    getRequest('/api/v3/stripe/payment_methods', {}, (err, jsonData) => {
      if (err) {
        setErrorMessage(err.errors)
        setState({ loadingPaymentMethods: false })
      } else {
        setErrorMessage(null)
        setState({ paymentMethods: jsonData.paymentMethods, loadingPaymentMethods: false })
      }
    })
  }

  if (loadingPaymentMethods) { return <Loading /> }

  if (existingSubscription && !updateExisting) {
    return <ExistingPlan setUpdateExisting={setUpdateExisting} />
  }

  return <>
    <Elements stripe={stripePromise}>
      <PlanSelector
        paymentMethods={paymentMethods}
        errorMessage={errorMessage}
        setUpdateExisting={setUpdateExisting}
      />
    </Elements>
  </>
}

BraintreeLegacyNotice.propTypes = {
  sub: PropTypes.object.isRequired
}
LegacyOnlyNotice.propTypes = {
  info: PropTypes.object.isRequired
}
