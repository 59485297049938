import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import useQuery from '@/hooks/useQuery'
import { useForm } from 'react-hook-form'
import { useGlobalState } from '@/state'
import { randomToastSuccess } from '@/shared/Toast'
import { ExclamationCircleIcon } from '@heroicons/react/solid'
import Card from '@/shared/Card'
import PrimaryButton from '@/shared/Buttons/Primary'

const SubmissionUrl = ({ project, submission, selectedRole }) => {
  const [, setToast] = useGlobalState('toast')
  const { putpostRequest } = useQuery()
  const { formState, register, handleSubmit, setError, setFocus } = useForm({
   defaultValues: {
      remote_audition_link: submission?.remoteLink
    }
  })
  const { isDirty, errors } = formState
  const [loading, setLoading] = useState(false)

  const onSubmit = (data) => {
    setLoading(true)

    const formData = {
      ...data,
      role_id: selectedRole.id,
    }
    let url = '/api/v3/auditions'
    let action = 'POST'
    if (submission?.id) {
      url = `/api/v3/auditions/${submission.id}`
      action = 'PATCH'
    }

    putpostRequest(url, action, { audition: formData }, (err, jsonData) => {
      setLoading(false)
      if (err) { // 422 code
        if (typeof err !== 'string') { // 500 code on API
          Object.entries(err).forEach(([key, value]) => {
            setError(key, value)
          })
        }
        return
      }

      if (submission?.id) {
        setToast(randomToastSuccess())
      } else {
        window.location.href = `/submissions/${jsonData.id}/edit?success=true`
      }
    })
  }

  useEffect(() => {
    setFocus('remote_audition_link')
  }, [setFocus])

  return <div className='max-w-xl mx-auto mt-6'>
    <Card title="Link Submission"
      footer={
        <div className='flex flex-rows justify-end items-center py-2 gap-x-2'>
          <PrimaryButton onClick={handleSubmit(onSubmit)} loading={loading} text='Save' />
        </div>
      } >
      <div className="grid sm:grid-cols-4 gap-6">
        <div className="col-span-4 sm:col-span-4">
          <div className='mt-4'>
            <label htmlFor="youtube_url" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">Link URL </label>
            <p className="text-xs text-gray-500 dark:text-gray-400 mt-1">Make sure you include the <b>https://</b> link. e.g. (https://www.youtube.com/embed/DiYTaQ-Mgck)
            </p>
            <div className="mt-1 flex flex-col rounded-md shadow-sm">
              <div className='relative'>
                <input type="text" className={errors.remote_audition_link ? 'errors' : ''} {...register('remote_audition_link', { required: true }) } placeholder="https://www.loom.com/embed/...." />
                { errors.remote_audition_link && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                </div> }
              </div>
            </div>
          </div>
        </div>
      </div>
    </Card>
  </div>
}

export default SubmissionUrl

SubmissionUrl.propTypes = {
  project: PropTypes.object.isRequired,
  selectedRole: PropTypes.object.isRequired,
  submission: PropTypes.object
}
