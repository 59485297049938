import React, { Fragment, useState, useEffect, useRef } from 'react'
import { useDebouncedEffect } from '@/utils/useDebouncedEffect'
import { EyeIcon } from '@heroicons/react/solid'
import Pagination from '@/shared/Pagination'
import Loading from '@/shared/Loading'
import { useGlobalState } from '@/state'
import useQuery from '@/hooks/useQuery'
import Card from '@/shared/Card'
import PublicUserRow from '@/pages/Users/PublicProfile/PublicUserRow'

const NoFollowers = () => {
  return (
    <span>
      <div className="relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccblue" >
        <EyeIcon className="mx-auto h-12 w-12 text-gray-400" />
        <span className="mt-2 block text-sm font-medium text-gray-900 dark:text-gray-300"> This person isn't following anyone yet. Honestly, they really should. There are lots of fine people out there worth connecting with.</span>
      </div>
    </span>
  )
}

const PublicFollowings = () => {
  const [profileContainer, setProfileContainer] = useGlobalState('profileContainer')
  const [meta, setMeta] = useState({})
  const { getRequest } = useQuery()
  const { user, followings, followingUserIds } = profileContainer
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(1)
  const [contains, setContains] = useState('')
  const scrollRef = useRef(null)

  useDebouncedEffect(() => { fetchData() }, 200, [contains])
  useEffect(() => { fetchData() }, [page])

  const fetchData = () => {
    setLoading(true)
    const data = {
      contains: contains,
      page: contains.length > 0 ? 1 : page
    }
    getRequest(`/api/v3/follows/${user.id}/following`, data, (err, jsonData) => {
      setLoading(false)
      if (err) { /* hooks */ return }

      setProfileContainer({
        ...profileContainer,
        followings: jsonData.following,
        followingUserIds: jsonData.followingUserIds
      })
      setMeta(jsonData.meta)
    })
  }

  return <>
    { user.followingCount === 0 && <NoFollowers /> }
    { <Card light title='Following'>
      <>
        <div className='pb-5' ref={scrollRef}>
          <input name='contains' type="text" onChange={(e) => setContains(e.target.value)}
            className="shadow-sm focus:ring-cccpurple dark:bg-gray-900 dark:text-white focus:border-cccpurple block w-full sm:text-sm border-gray-300 rounded-md mb-2"
            placeholder="Search by username or display name"
          />
          { followings.length >= 0 && <Pagination meta={meta} callback={setPage} scrollRef={scrollRef} /> }
        </div>
        <ul className="space-y-3">
          {followings.map((follow) => (
            <PublicUserRow key={`pubfollowinguser${follow.id}`} user={follow} followingUserIds={followingUserIds} />
          ))}
        </ul>
        { loading && <Loading /> }
      </>
    </Card> }

  </>
}

export default PublicFollowings
