import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { FlagIcon } from '@heroicons/react/solid'
import { debounce } from 'throttle-debounce'
import { useForm } from 'react-hook-form'

import useQuery from '@/hooks/useQuery'
import { useGlobalState } from '@/state'
import useModal from '@/hooks/useModalV2'
import Modal from '@/shared/Modal'
import Card from '@/shared/Card'
import PrimaryButton from '@/shared/Buttons/Primary'
import FroalaWrapper from '@/shared/FroalaWrapper'

const FlagModal = ({ projectId, callback }) => {
  const { openModal, closeModal, isOpen } = useModal()
  const [, setToast] = useGlobalState('toast')
  const [customError, setCustomError] = useState(null)
  const { putpostRequest } = useQuery()
  const { formState, register, reset, unregister, handleSubmit, setValue, getValues, setError } = useForm({
   defaultValues: {
     how_known: 'Spam',
     body: null
    }
  })

  const { errors } = formState
  const [loading, setLoading] = useState(false)

  const openModalAndCallback = () => {
    openModal()
    if (typeof (callback) !== 'undefined') { callback() }
  }
  useEffect(() => {
    register('body', { required: true })
    return () => {
      unregister('body')
    }
  }, [register])

  const onSubmit = debounce(300, (data) => {
    setLoading(true)

    const formData = {
      flag: {
        message: data.body,
        reason: data.how_known
      }
    }

    putpostRequest(`/api/v3/projects/${projectId}/flag`, 'POST', formData, (err, jsonData) => {
      setLoading(false)
      if (err) { // 422 code
        if (typeof err === 'string') { // 422 custom error
          setCustomError(err)
        }
        if (typeof err !== 'string') { // 500 code on API
          Object.entries(err).forEach(([key, value]) => {
            setError(key, value)
          })
        }
        return
      }
      setToast(<div className="ml-3 w-0 flex-1 pt-0.5">
        <p className="text-sm font-medium dark:text-white text-gray-800">Flag sent!</p>
        <p className="mt-1 text-sm dark:text-gray-100 text-gray-500">A moderator of the site will be notified. Let the hammer of justice be swift and absolute!</p>
      </div>)
      reset({ ...getValues(), body: ' ' }) // resetting this without this hack kills froala somehow
      closeModal()
    })
  })

  const keyPress = (e) => {
    if (e.target.name === 'name' && e.keyCode === 13) { e.preventDefault() }
    if ((e.metaKey || e.ctrlKey) && e.keyCode === 13) {
      if (typeof (e.target.name) === 'undefined') {
        e.target.blur() // froala hack to make it update model so it can save properly
      }
      handleSubmit(onSubmit)()
    }
  }

  const handleUpdateFroala = (key, val) => {
    setValue(key, val, { shouldDirty: true, shouldValidate: true })
  }

  const body = getValues().body

  return <>
    <button onClick={openModalAndCallback} type="button" className="text-gray-700 dark:text-gray-100 flex px-4 py-2 text-xs cursor-pointer hover:text-cccblue-alt dark:hover:text-cccblue-alt" >
      <FlagIcon className="mr-3 h-4 w-4 text-gray-400" aria-hidden="true" />
      <span>Flag Project</span>
    </button>
    <Modal isOpen={isOpen} closeModal={closeModal} >
      <Card title="Flag"
        footer={
          <div className='flex flex-rows justify-end items-end'>
            <div className='flex flex-col items-end w-min ml-2'>
              <div className="hidden sm:block w-min text-xs border border-gray-200 dark:border-gray-800 rounded px-2 font-sans text-gray-500 dark:text-gray-400">⌘/ctrl+ENTER</div>
              <PrimaryButton className='w-full' onClick={handleSubmit(onSubmit)} loading={loading} text='Save' />
            </div>
          </div>
        } >
        <form>
          <div className="grid sm:grid-cols-4 gap-6">
            <div className="col-span-3 sm:col-span-3">
              <label htmlFor="country" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                Why are you flagging this project?
              </label>
              <div className="mt-1">
                <select {...register('flag_reason', { required: true })}
                  className="shadow-sm dark:bg-gray-900 dark:text-white focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                >
                  <option value="Spam">Spam</option>
                  <option value="Inappropriate">Inappropriate</option>
                  <option value="AI">Farming Voices for AI</option>
                </select>
              </div>
            </div>

            <div className="col-span-4 sm:col-span-4">
              <div className='mt-4'>
                <label htmlFor="pricing" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                  Further Information
                </label>
                <span className='text-xs'>Please list any information that can help us take swifter action. Add a link to the offending content if possible.</span>
                <div className="mt-1 shadow-sm w-full min-w-full">
                  <FroalaWrapper
                    model={body}
                    heightMin={100}
                    placeholderText="I have personally witnessed this person kick a puppy."
                    keyPress={keyPress}
                    updateModel={(val) => handleUpdateFroala('body', val)}
                  />
                </div>
                { errors.body && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
              </div>
            </div>
          </div>
        </form>
        { customError && <div className='mt-2 text-sm text-red-600'>{customError}</div> }
      </Card>
    </Modal>
  </>
}

export default FlagModal

FlagModal.propTypes = {
  projectId: PropTypes.number.isRequired
}
