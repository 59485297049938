import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import Card from '@/shared/Card'
import StandAloneAudioPlayer from '@/shared/StandAloneAudioPlayer'
import Loading from '@/shared/Loading'

const LoggedOutPublicDemoRow = ({ demo }) => {
  return <>
    <div className='bg-white dark:bg-gray-700 px-4 py-4 sm:px-6 rounded-lg '>
      <div className={'flex flex-col sm:flex-row sm:items-centerflex-wrap'}>
        <div className="font-medium text-cccpurple truncate">{demo.name}</div>
      </div>
      <div className="min-w-0 flex-1 flex sm:items-center  flex-wrap">
        {demo.labelings.map((labeling, idx) => (
          <span key={`la${labeling.id}`} className="flex-shrink-0 inline-block px-2 mr-1 mb-1 py-0.5 text-white bg-cccorange dark:bg-cccpurple text-xs font-medium rounded-full flex cursor-pointer capitalize">{labeling.name}</span>
        ))}
      </div>

      { demo.kind === 'Audio' && <>
        { demo.filePathProcessing && <Loading noMessage noLoadingMessage /> }
        { !demo.filePathProcessing && <StandAloneAudioPlayer mediaUrl={demo.audioUrl} objectId={demo.id} objectKind='Demo' /> }
      </> }

      { demo.kind === 'Link' && <>
        <p className='truncate mt-1'>
          <a target="_blank" href={demo.remoteUrl} className="text-cccblue hover:text-cccblue-alt" alt={demo.remoteUrl} rel="noreferrer"> Link to document &rarr; </a>
        </p>
      </> }

      { (demo.kind === 'YouTube Video' || demo.kind === 'Loom Video') && <div className='flex flex-col w-full justify-center'>
        <div className="aspect-w-16 aspect-h-9 w-full">
          {demo.remoteUrl.includes('loom.com') && <>
            <div style={{ position: 'relative', paddingBottom: '56.25%', height: '0' }} className='w-full'>
              <iframe src={`${demo.remoteUrl}?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true`}
                frameBorder="0" webkitallowfullscreen='true' mozallowfullscreen='true' allowFullScreen
                className='w-full h-full'></iframe>
            </div>
          </>}
          {demo.remoteUrl.includes('youtube.com') && <>
            <iframe src={demo.remoteUrl} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen className='w-full h-full'></iframe>

          </>}
        </div>
      </div> }
      <div className="text-gray-500 dark:text-gray-300 text-sm mt-3" dangerouslySetInnerHTML={{ __html: demo.description }} />
    </div>
  </>
}

const LoggedOutDemosIndex = ({ initDemos }) => {
  const { demos } = initDemos
  return <>
    { demos.length > 0 && <Card light title='Demos & Samples'>
      { demos.map((demo) => (
        <LoggedOutPublicDemoRow key={`pubdemo${demo.id}`} demo={demo} />
      ))}
    </Card> }
  </>
}

export default LoggedOutDemosIndex

LoggedOutPublicDemoRow.propTypes = {
  demo: PropTypes.object.isRequired
}
LoggedOutDemosIndex.propTypes = {
  initDemos: PropTypes.object.isRequired
}
