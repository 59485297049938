import React from 'react'
import { useHistory } from 'react-router-dom'
import { SearchCircleIcon } from '@heroicons/react/solid'

const NoResults = () => {
  const history = useHistory()
  const newCredit = () => {
    history.push('/credits/new')
  }
  return (
    <span>
      <button onClick={newCredit} type="button" className="relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccblue" >
        <SearchCircleIcon className="mx-auto h-12 w-12 text-gray-400" />
        <span className="mt-2 block text-sm font-medium text-gray-900 dark:text-gray-300"> I didn't find any credits for you. Add some! </span>
        <div className="mt-2 block text-sm font-medium text-cccblue cursor-pointer"> Add My Production Credits </div>
      </button>
    </span>
  )
}

export default NoResults
