import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'

import Loading from '@/shared/Loading'
import useQuery from '@/hooks/useQuery'

import RoleIndex from '@/pages/ManageProjects/ProjectEdit/RoleIndex'
import RequiredInfoForm from '@/pages/ManageProjects/ProjectEdit/RequiredInformation'
import OptionalInfoForm from '@/pages/ManageProjects/ProjectEdit/OptionalInformation'
import ProjectImage from '@/pages/ManageProjects/ProjectEdit/ProjectImage'

const ProjectEdit = () => {
  const { id } = useParams()
  const [project, setProject] = useState(null)
  const { getRequest } = useQuery()

  useEffect(() => {
    getRequest(`/api/v3/manage/projects/${id}/edit`, {}, (err, jsonData) => {
      if (err) { /* handled in hook */ return }
      setProject(jsonData.project)
    })
  }, [id])

  if (!project) { return <Loading /> }

  return <div>
    <RequiredInfoForm initialProject={project} />
    <div className='mt-20' />
    <RoleIndex project={project} />
    <div className='mt-20' />
    <OptionalInfoForm initialProject={project} />
    <div className='mt-20' />
    <ProjectImage project={project} />
  </div>
}

export default ProjectEdit

ProjectImage.propTypes = {
  project: PropTypes.object.isRequired
}
