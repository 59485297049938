import { createConsumer } from "@rails/actioncable"
const consumer = createConsumer()
consumer.subscriptions.create("NotificationsChannel", {
  connected() {
    console.log('connected to notifications channel')
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    console.log('disconnected from notifications channel')
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    // Called when there's incoming data on the websocket for this channel
    console.log(data);
    console.log(data.data.id)

    if (data.data.id) { showPopup(data.data.type, data.data.id) }
  }
});

function showPopup(message, id) {
  const toast = document.getElementById('popup-area')
  const toastMessage = document.getElementById('toast-message')
  const a = document.createElement('a')
  const anchorId = `notification-${id}`
  if (id === 'profileview') {
    a.href = '/dashboard/profile_views'
  } else if (id === 'private_message') {
    a.href = '/private_messages'
  } else {
    a.href = '/notifications'
  }
  a.id = anchorId
  a.target = '_blank'
  a.innerHTML = message
  a.classList.add('opacity-100', 'translate-y-0', 'ease-in', 'duration-500', 'w-full', 'flex', 'flex-col', 'items-center', 'space-y-4', 'p-4', 'shadow-2xl', 'border', 'border-2', 'border-cccorange', 'capitalize', 'bg-white', 'dark:bg-gray-700')
  toast.prepend(a)

  setTimeout(() => hidePopup(anchorId), 20000)
}

function hidePopup(anchorId) {
  const a = document.getElementById(anchorId)
  a.classList.remove('opacity-100', 'translate-y-0', 'ease-in', 'duration-200')
  a.classList.add('hidden', 'translate-y-1', 'ease-out', 'duration-150')
  setTimeout(() => removePopup(anchorId), 10000)
}

function removePopup(anchorId) {
  const a = document.getElementById(anchorId)
  a.remove()
}
