import React, { useState, Fragment, useRef } from 'react'
import PropTypes from 'prop-types'
import { DotsHorizontalIcon, BanIcon } from '@heroicons/react/solid'

import { useGlobalState } from '@/state'
import FlagModal from '@/shared/FlagModal'
import useQuery from '@/hooks/useQuery'
import useDropdown from '@/hooks/useDropdown'

const GeneralOptions = ({ user, blocking }) => {
  const [, setToast] = useGlobalState('toast')
  const { putpostRequest } = useQuery()
  const [blocked, setBlocked] = useState(blocking)
  const dropdownRef = useRef(false)
  const { dropdownOpen, setDropdownOpen, openDropdown } = useDropdown(dropdownRef)

  const block = () => {
    putpostRequest(`/api/v3/blocked_users/${user.id}/block`, 'POST', {}, (err, jsonData) => {
      if (err) {
        if (typeof err === 'string') { // 422 custom error
          setToast(<div className="ml-3 w-0 flex-1 pt-0.5">
            <p className="text-sm font-medium text-red-500 dark:text-red-500">Hol up</p>
            <p className="mt-1 text-sm dark:text-gray-100 text-gray-500">{err}</p>
          </div>)
        }
        return
      }
      setBlocked(!blocked)
      setDropdownOpen(false)
      setToast(<div className="ml-3 w-0 flex-1 pt-0.5">
        <p className="text-sm font-medium dark:text-white text-gray-800">{!blocked === true ? 'Blocked' : 'Unblock' }</p>
        <p className="mt-1 text-sm dark:text-gray-100 text-gray-500">{!blocked === true ? 'This person can no longer comment on your submissions, message you, or view your profile' : 'This person is no longer blocked'}</p>
      </div>)
    })
  }

  return <div className='relative flex items-center'>
    <button onClick={openDropdown} className='-m-2 p-2 rounded-full flex items-center text-gray-400 hover:text-gray-600 dark:hover:text-gray-200'>
      <span className="sr-only" hidden>Open options</span>
      <DotsHorizontalIcon className="h-5 w-5" aria-hidden="true" />
    </button>

    <div ref={dropdownRef} id='dropdownOptionsMenu' tabIndex="-1" className={`absolute right-0 z-30 ${dropdownOpen ? '' : 'hidden opacity-0 scale-0'} w-32 py-2 overflow-y-auto transition origin-top-right bg-white border border-gray-100 rounded-md shadow-md dark:border-gray-700 dark:bg-gray-700 top-6 max-h-80`}>
      <FlagModal user={user} callback={() => setDropdownOpen(false) } />
      <div onClick={block} className='text-gray-700 dark:text-gray-100 flex px-4 py-2 text-sm cursor-pointer hover:text-cccblue-alt dark:hover:text-cccblue-alt' >
        { blocked && <>
          <BanIcon className="mr-3 h-5 w-5 text-red-500" aria-hidden="true" />
          <span className='text-red-500'>Unblock</span>
        </> }
        { !blocked && <>
          <BanIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
          <span>Block</span>
        </> }
      </div>
    </div>
  </div>
}

GeneralOptions.propTypes = {
  user: PropTypes.object,
  blocking: PropTypes.bool
}

export default GeneralOptions
