import React, { useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useGlobalState } from '@/state'
import { TrendingUpIcon } from '@heroicons/react/solid'
import useQuery from '@/hooks/useQuery'

import Card from '@/shared/Card'
import UserHoverCard from '@/shared/UserHoverCard'

const ProfileViewerUser = ({ viewerUser }) => {
  return <li>
    <div className="flex items-center space-x-4">
      <div className="flex-shrink-0">
        <img className="h-12 w-12 rounded-sm" src={viewerUser.publicImageUrl} alt="" />
      </div>
      <div className="flex-1 flex-col items-center">
        <span className='max-w-0'><UserHoverCard username={viewerUser.username} displayName={viewerUser.displayName} /></span>
        <p className="text-sm text-gray-500 truncate">{'@' + viewerUser.username}</p>
      </div>
      <div className='text-sm text-gray-500'> {viewerUser.timeAgo} </div>
    </div>
  </li>
}

const ProfileViewerUserBlank = () => {
  return <li>
    <div className="flex items-center space-x-4 opacity-20">
      <div className="flex-shrink-0">
        <img className="h-12 w-12 rounded-sm" src={'https://ddppjbdexhxzj.cloudfront.net/avatars/CCC_Cover_UNSURE.svg'} alt="" />
      </div>
      <div className="flex-1 flex-col items-center">
        <div className="max-w-sm w-40 rounded-md h-2 bg-gray-500"></div>
        <p className="text-sm text-gray-500 truncate flex justify-start items-center">@<div className="max-w-sm w-32 rounded-md h-2 bg-gray-500"></div>
        </p>
      </div>
      <div className='text-sm text-gray-500'> 2d </div>
    </div>
  </li>
}

const ProfileViewerAnon = ({ viewerUser }) => {
  return <li>
    <div className="flex items-center space-x-4">
      <div className="flex-shrink-0">
        <img className="h-12 w-12 rounded-sm" src={'https://ddppjbdexhxzj.cloudfront.net/avatars/CCC_Cover_UNSURE.svg'} alt="" />
      </div>
      <div className="flex-1 flex-col items-center">
        <p className="text-sm text-gray-500 truncate">Anonymous Viewer</p>
      </div>
      <div className='text-sm text-gray-500'> {viewerUser?.timeAgo || '2d' }</div>
    </div>
  </li>
}

const WhoViewedMyProfile = () => {
  const [dashboardContainer, setDashboardContainer] = useGlobalState('dashboardContainer')
  const [currentUser] = useGlobalState('currentUser')
  const { getRequest } = useQuery()
  const { profileViewers } = dashboardContainer
  const scrollRef = useRef(null)

  useEffect(() => {
    getRequest('/api/v3/dashboard/whoviewed', {}, (err, jsonData) => {
      if (err) { /* handled in hook */ return }
      setDashboardContainer({
        ...dashboardContainer,
        stats: jsonData.dashboard.stats,
        profileViewers: jsonData.dashboard.profileViewers
      })
    })

    scrollRef?.current?.scrollIntoView()
  }, [])

  return <>
    <Card title={
      <span className='flex items-center'>
        <TrendingUpIcon className="h-6 w-6 mr-1" />
        Who Viewed My Profile
      </span>
      }
      footer={<Link to='/dashboard/profile_views'>All Viewers</Link> } light>
      <ul className="space-y-3">
        { profileViewers && profileViewers.map((pv) => (
          <span key={`pv${pv.id}`}>
            { pv.id === 'Anonymous' ? <ProfileViewerAnon viewerUser={pv} /> : <ProfileViewerUser key={`profileview${pv.id}`} viewerUser={pv} />
            }
          </span>
        ))}
        { !currentUser.canViewProfileAnalytics && <>
          <ProfileViewerUserBlank />
          <ProfileViewerUserBlank />
        </> }
        { currentUser.canViewProfileAnalytics && profileViewers.length < 2 && <>
          <ProfileViewerAnon />
        </> }
      </ul>
    </Card>
  </>
}

export default WhoViewedMyProfile

ProfileViewerAnon.propTypes = {
  viewerUser: PropTypes.object
}

ProfileViewerUser.propTypes = {
  viewerUser: PropTypes.object.isRequired
}
