import React from 'react'

import ProfileGeneralBasic from '@/pages/Users/ProfileGeneralBasic'
import ProfileWorkingInformation from '@/pages/Users/ProfileWorkingInformation'
import ProfileSocialMedia from '@/pages/Users/ProfileSocialMedia'
import ProfileEducationIndex from '@/pages/Users/ProfileEducationIndex'

const ProfileGeneral = () => {
  return (<>
    <ProfileGeneralBasic />

    <div className="py-5">
      <div className="hidden sm:block" aria-hidden="true">
        <div className="border-t border-gray-200" />
      </div>
    </div>

    <ProfileEducationIndex />

    <div className="py-5">
      <div className="hidden sm:block" aria-hidden="true">
        <div className="border-t border-gray-200" />
      </div>
    </div>

    <ProfileWorkingInformation />

    <div className="py-5">
      <div className="hidden sm:block" aria-hidden="true">
        <div className="border-t border-gray-200" />
      </div>
    </div>

    <ProfileSocialMedia />
  </>
  )
}
export default ProfileGeneral
