import React, { useReducer, useEffect } from 'react'

import Loading from '@/shared/Loading'
import useQuery from '@/hooks/useQuery'
import { useGlobalState } from '@/state'
import { XIcon } from '@heroicons/react/solid'
import Card from '@/shared/Card'

import TagAutocomplete from '@/shared/TagAutocomplete'

const ProfileEquipmentIndex = () => {
  const { getRequest, putpostRequest } = useQuery()
  const [currentUser] = useGlobalState('currentUser')
  const [state, setState] = useReducer(
    (state, newState) => (
      { ...state, ...newState }), {
        hardwares: [],
        softwares: [],
        loadingEquips: true
      }
    )
  const { hardwares, softwares, loadingEquips } = state

  useEffect(() => { fetchData() }, [])

  const fetchData = () => {
    getRequest('/api/v3/labelings/equipments', {}, (err, jsonData) => {
      if (err) {
        setState({ loadingEquips: false })
      } else {
        setState({
          hardwares: jsonData.hardwares,
          softwares: jsonData.softwares,
          loadingEquips: false
        })
      }
    })
  }

  const addLabel = (label, labelKind) => {
    const data = {
      labeled_kind: 'User',
      labeled_id: currentUser.id
    }
    const labelData = {
      name: label,
      label_kind: labelKind
    }
    putpostRequest('/api/v3/labelings', 'POST', { labeling: data, label: labelData }, (err, jsonData) => {
      if (err) { /* hook */ return }

      if (labelKind === 'user-softwares') {
        setState({ softwares: [...softwares, ...[jsonData.labeling]] })
      }
      if (labelKind === 'user-hardwares') {
        setState({ hardwares: [...hardwares, ...[jsonData.labeling]] })
      }
    })
  }

  const removeLabel = (labelingId, kind) => {
    if (kind === 'user-hardwares') {
      const labelings = hardwares.filter(labeling => labeling.id !== labelingId)
      setState({ hardwares: labelings })
    }
    if (kind === 'user-softwares') {
      const labelings = softwares.filter(labeling => labeling.id !== labelingId)
      setState({ softwares: labelings })
    }

    putpostRequest(`/api/v3/labelings/${labelingId}`, 'DELETE', {}, (err, jsonData) => {
      if (err) { /* hook */ }
    })
  }

  if (loadingEquips) {
    return (
      <section aria-labelledby="loading" className='p-5'>
        <button type="button" className="relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" >
          <svg xmlns="http://www.w3.org/2000/svg" className="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19.428 15.428a2 2 0 00-1.022-.547l-2.387-.477a6 6 0 00-3.86.517l-.318.158a6 6 0 01-3.86.517L6.05 15.21a2 2 0 00-1.806.547M8 4h8l-1 1v5.172a2 2 0 00.586 1.414l5 5c1.26 1.26.367 3.414-1.415 3.414H4.828c-1.782 0-2.674-2.154-1.414-3.414l5-5A2 2 0 009 10.172V5L8 4z" />
          </svg>
          <span className="mt-2 block text-sm font-medium text-gray-900 dark:text-gray-300">
            Equipment
            <Loading />
          </span>
        </button>
      </section>
    )
  }

  return <>
    <div>
      <div className="md:grid md:grid-cols-4 md:gap-6 space-x-2">
        <div className="md:col-span-1">
          <div className="px-4 sm:px-0">
            <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-100">Equipment</h3>
            <div className="mt-1 text-sm text-gray-600 dark:text-gray-300">
              What hardware & software do you use for your work?
              <div className='text-gray-500 dark:text-gray-500'>
                <ul>
                  <li>- Voice actors/singers tend to share their microphones, booth setup, and recording software like audacity.</li>
                  <li>- Audio engineers/musicians/illustrators/writers tend to share what software they use like Figma, Reaper, Scrivener, etc.</li>
                  <li>- If you use Source Connect or similar, please add it here.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <Card>
           <div className="space-y-6 sm:space-y-5">
            <div className="mt-5 md:mt-0 md:col-span-2 space-y-6 sm:space-y-5">
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="first_name" className="block text-sm font-medium text-gray-700 dark:text-gray-200 sm:mt-px sm:pt-2">
                  Hardware
                  <p className='text-xs text-gray-500'>Microphones, Interfaces, Home Booth, Computer, etc</p>
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2 max-w-xs">
                  <TagAutocomplete kind='user-hardwares' addLabel={addLabel} placeholder='Scarlett FocusRite 2i2, Shure SM7b, Blue Yeti'/>
                  { hardwares.length > 0 && <>
                    <div className="mt-3 flex sm:items-center flex-wrap">
                      {hardwares.map((labeling, idx) => (
                        <span key={`lang${labeling.id}`} onClick={(id) => removeLabel(labeling.id, 'user-hardwares')} className="flex-shrink-0 inline-block px-2 mr-1 mb-1 py-0.5 text-white bg-cccorange dark:bg-cccpurple text-xs font-medium rounded-full flex cursor-pointer capitalize">{labeling.name} <XIcon className='w-4 h-4' /></span>
                      ))}
                    </div>
                  </> }
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="first_name" className="block text-sm font-medium text-gray-700 dark:text-gray-200 sm:mt-px sm:pt-2">
                  Software
                  <p className='text-xs text-gray-500'>Computer software like Audacity, Reaper, Figma, Notion, Source Connect</p>
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2 max-w-xs">
                  <TagAutocomplete kind='user-softwares' addLabel={addLabel} placeholder='ISDN, Audacity, Source Connect, Scrivener'/>
                  { softwares.length > 0 && <>
                    <div className="mt-3 flex sm:items-center flex-wrap">
                      {softwares.map((labeling, idx) => (
                        <span key={`lang${labeling.id}`} onClick={(id) => removeLabel(labeling.id, 'user-softwares')} className="flex-shrink-0 inline-block px-2 mr-1 mb-1 py-0.5 text-white bg-cccorange dark:bg-cccpurple text-xs font-medium rounded-full flex cursor-pointer capitalize">{labeling.name} <XIcon className='w-4 h-4' /></span>
                      ))}
                    </div>
                  </> }
                </div>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </div>
  </>
}

export default ProfileEquipmentIndex
