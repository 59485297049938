import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { ResponsiveContainer, LineChart, YAxis, XAxis, Tooltip, Legend, CartesianGrid, Line } from 'recharts'
import Confetti from 'react-confetti'

import { TrendingUpIcon, ExclamationIcon, DuplicateIcon, QuestionMarkCircleIcon } from '@heroicons/react/solid'
import { TippyTooltip } from '@/shared/ToolTip'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import useQuery from '@/hooks/useQuery'
import { useGlobalState } from '@/state'
import LineChartSample from '@/LineChartSample'
import Card from '@/shared/Card'

import Loading from '@/shared/Loading'
import ProjectStats from '@/pages/ManageProjects/ProjectStats'
import ProjectUpdatesIndex from '@/pages/ManageProjects/ProjectUpdatesIndex'
import ProjectCommentsIndex from '@/pages/ManageProjects/ProjectCommentsIndex'
import FeatureModal from '@/pages/ManageProjects/FeatureModal'
import ListingModal from '@/pages/ManageProjects/ListingModal'

const PayMe = () => {
  return <div className='mt-1'>
    <Card title='Add-ons'>
      <div className='flex justify-center'>
        <h3 className="sm:text-lg font-medium leading-6 text-gray-900 dark:text-gray-100 text-center">Project Promoted Higher in Search </h3>
      </div>
      <div className='mt-2'>
        <div className='py-2 sm:py-0 text-sm text-gray-700 dark:text-gray-300 mr-2'>
          <b>Business</b> plan only. Your projects will appear higher and <i>featured</i> in the search results when people are browsing through open projects.
        </div>
        <div className='mt-2'>
          <div className='overflow-hidden shadow-lg rounded-lg aspect-w-3 aspect-h-1 w-full'>
            <img src='https://ddppjbdexhxzj.cloudfront.net/perks/featured-project-search.png' className='object-cover' />
          </div>
        </div>
      </div>
      <div className="grid grid-cols-3 gap-6">
        <div className='col-span-3 p-3 bg-gray-100 rounded-lg dark:bg-gray-700'>
          <div className='flex justify-center'>
            <h3 className="sm:text-lg font-medium leading-6 text-gray-900 dark:text-gray-100 text-center">Project Analytics </h3>
          </div>
          <div className='mt-2'>
            <div className='py-2 sm:py-0 text-sm text-gray-700 dark:text-gray-300 mr-2'>
              <b>Any premium</b> plan. See who is viewing your project and chart data on views.
            </div>
            <div className='relative flex justify-center'>
              <div className="absolute z-10 align-bottom bg-white dark:bg-gray-900 rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all my-2 sm:my-8 align-middle max-w-lg w-full p-2 sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-cccorange dark:bg-cccpurple sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center dark:text-gray-100 sm:mt-0 sm:ml-4 sm:text-left">
                    These are sample stats
                    <div className="mt-2">
                      <p className="text-sm text-gray-500 dark:text-gray-300">
                        Full project analytics is a premium feature. Premium features keep CCC's lights on. Please consider upgrading.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:ml-10 sm:pl-4 sm:flex">
                  <Link to='/subscriptions' className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-cccblue text-base hover:text-white font-medium text-white sm:w-auto sm:text-sm" >
                    Fancy! Can I see the plans?
                  </Link>
                </div>
              </div>
            </div>
            <div className='opacity-20'>
              <LineChartSample />
            </div>
          </div>
        </div>
      </div>
    </Card>
  </div>
}

const ProjectLineChart = ({ stats }) => {
  const [theme] = useGlobalState('theme')
  const margins = { top: 0, right: 0, left: 0, bottom: 0 }
  let fillColor = '#6D6E71'
  if (theme === 'dark') { fillColor = '#E5E7EB' }

  const renderColorfulLegendText = (value, entry) => {
    const { color } = entry

    return <span style={{ color }}>{value}</span>
  }

  if (typeof (stats) === 'undefined') return <div className='text-sm dark:text-gray-300'><i>Our computers haven't crunched all this data yet. They're probably sitting around eating chips. I'll go and check on them to get these stats done in about 5 minutes</i></div>

  return <>
    <div style={{ minWidth: '300px', height: '315px' }}>
      <ResponsiveContainer margin={margins} >
        <LineChart data={stats} margin={{ top: 0, right: 0, left: -30, bottom: 0 }} >
          <YAxis padding={margins} margin={margins} stroke={fillColor} />
          <XAxis padding={{ left: 0 }} dataKey="date" stroke={fillColor} />
          <Tooltip />
          <Legend formatter={renderColorfulLegendText} />
          <CartesianGrid stroke={fillColor} />
          <Line
            name="Views" type="monotone" dataKey="Views" stroke="#ff7300" strokeWidth={3}
            activeDot={{ stroke: '#ff7300', strokeWidth: 2, r: 5 }}
            dot={{ stroke: '#ff7300', strokeWidth: 2, r: 5 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  </>
}

const AddOns = () => {
  const [currentUser] = useGlobalState('currentUser')
  const [manageProjectContainer] = useGlobalState('manageProjectContainer')
  const { project } = manageProjectContainer

  const { getRequest } = useQuery()
  const [loading, setLoading] = useState(false)
  const [stats, setStats] = useState([])

  const loadStats = () => {
    setLoading(true)
    getRequest(`/api/v3/manage/projects/${project.id}/stats`, {}, (err, jsonData) => {
      setLoading(false)
      if (err) { /* hook */ }
      setStats(jsonData.data)
    })
  }

  useEffect(() => {
    if (currentUser.canViewProfileAnalytics) { loadStats() }
  }, [])

  if (!currentUser.canViewProfileAnalytics) { return <PayMe /> }

  return <div className='col-span-1'>
    <Card title={
      <span className='flex items-center'>
        <TrendingUpIcon className="h-6 w-6 mr-1" />
        Analytics
      </span>
      } footer={<Link to={`/manage/projects/${project.id}/detailed_stats`}>Detailed Stats</Link> } light>
      <ProjectLineChart stats={stats} />
      {loading && <Loading /> }
    </Card>
  </div>
}

const ManageProjectsDashboard = () => {
  const [, setToast] = useGlobalState('toast')
  const [manageProjectContainer] = useGlobalState('manageProjectContainer')
  const { project } = manageProjectContainer
  const urlParams = new window.URLSearchParams(window.location.search)
  const published = urlParams.get('published')

  const showCopiedNotification = () => {
    setToast(<div className="ml-3 w-0 flex-1 pt-0.5">
      <p className="text-sm font-medium dark:text-green-300 text-green-600">Copied</p>
      <p className="mt-1 text-sm dark:text-green-300 text-green-400">That was the best copying I've ever seen. Quick -- paste it somewhere!</p>
    </div>)
  }

  if (project.status === 'draft') {
    return <span>
      <button type="button" className="relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccblue" >
        <ExclamationIcon className="mx-auto h-12 w-12 text-gray-400" />
        <span className="mt-2 block text-sm font-medium text-gray-900 dark:text-gray-300"> Your project is in draft mode. Click the 'publish' button to make it live!</span>
      </button>
    </span>
  }

  return <div>
    { published && <Confetti width={window.innerWidth} gravity={0.05} colors={['#f7784e', '#00b0e7', '#6452a2']} numberOfPieces={300} recycle={false} height={window.innerHeight} /> }
    <div className="grid grid-cols-2 sm:grid-cols-4 gap-x-1 gap-y-1 sm:gap-y-0 sm:gap-x-4">
      <div className='flex flex-col text-gray-900 dark:text-gray-100 bg-cccblue bg-opacity-20 p-2 rounded-md'>
        <div className='flex justify-between items-center'>
          <b>Published At:</b>
          <TippyTooltip content={ <div className="text-gray-600 dark:text-gray-300"> The date you clicked publish. </div> }>
            <QuestionMarkCircleIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </TippyTooltip>
        </div>
        <span className='text-xs'>{project.listedAt}</span>
      </div>
      <div className='flex flex-col text-gray-900 dark:text-gray-100 bg-cccblue bg-opacity-20 p-2 rounded-md'>
        <div className='flex justify-between items-center'>
          <b>Listing Expires:</b>
          <TippyTooltip content={ <div className="text-gray-600 dark:text-gray-300 flex flex-col space-y-4">
            <div> This project will not be searchable on the main 'Open Projects' area after this date. Even if your project is not listed, it does not impact your deadline.</div>
            <div> Premium members can list their project for up to 12 weeks, while free members get 5 weeks.</div>
          </div> }>
            <QuestionMarkCircleIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </TippyTooltip>
        </div>
        <span className='text-xs'>{project.listingExpiresAt}</span>
      </div>
      <div className='flex flex-col text-gray-900 dark:text-gray-100 bg-cccblue bg-opacity-20 p-2 rounded-md'>
        <div className='flex justify-between items-center'>
          <b>Deadline:</b>
          <TippyTooltip content={ <div className="text-gray-600 dark:text-gray-300 flex flex-col space-y-4">
            <div> This is the date you set. Talent have until this date to send in a submission.</div>
            <div>  You can extend your deadline as long as you need to and you can continue to send people to your project to find collaborators.</div>
          </div> }>
            <QuestionMarkCircleIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </TippyTooltip>
        </div>
        <div className='flex justify-between items-center'>
          <span className='text-xs'>{project.deadline}</span>
          <Link to={`/manage/projects/${project.id}/edit`} className='text-xs'>edit</Link>
        </div>
      </div>
      <div className='flex flex-col text-gray-900 dark:text-gray-100 bg-cccblue bg-opacity-20 p-2 rounded-md'>
        <b>Status:</b>
        <span className='text-xs capitalize'>{project.status}</span>
      </div>
    </div>
    <div className='mt-4 flex flex-col sm:flex-row sm:space-x-2 space-y-2 sm:space-y-0'>
      <div className='flex-1 flex'>
        <FeatureModal />
      </div>
      <div className='flex-1 flex'>
        <div className="rounded-md bg-cccpurple bg-opacity-10 p-2 border-cccpurple border-l-4">
          <div className="flex">
            <div>
              <div className='pb-3 text-sm text-cccpurple'>
                <b>Copy</b> your public project link and share it on your social networks.
              </div>
              <div className="text-sm font-medium text-cccpurple">
                <CopyToClipboard text={`https://cstng.cc/projects/${project.slug}`} onCopy={showCopiedNotification} >
                  <span className='flex flex-col sm:flex-row justify-center items-center cursor-pointer space-x-1 rounded-md bg-gray-50'>
                    <DuplicateIcon className="h-5 w-5 text-cccpurple" aria-hidden="true" />
                    <span className='font-mono'>{`https://cstng.cc/projects/${project.slug}`}</span>
                  </span>
                </CopyToClipboard>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='flex-1 flex'>
        <ListingModal />
      </div>
    </div>
    <div className='mt-4' />
    <ProjectStats />
    <div className='grid grid-cols-1 sm:grid-cols-2 mt-2 gap-x-4'>
      <ProjectUpdatesIndex />
      <AddOns />
    </div>
    <ProjectCommentsIndex />
  </div>
}
export default ManageProjectsDashboard
