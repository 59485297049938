import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { useForm } from 'react-hook-form'
import { debounce } from 'throttle-debounce'

import Card from '@/shared/Card'
import { randomToastSuccess } from '@/shared/Toast'

import TagAutocomplete from '@/shared/TagAutocomplete'
import FroalaWrapper from '@/shared/FroalaWrapper'
import AudioUploadForm from '@/shared/AudioUploadForm'
import PrimaryButton from '@/shared/Buttons/Primary'
import { ExclamationCircleIcon, XIcon } from '@heroicons/react/solid'
import useQuery from '@/hooks/useQuery'
import { useGlobalState } from '@/state'

import PublicDemoRow from '@/pages/Users/PublicProfile/PublicDemoRow'

const DemoForm = ({ demo }) => {
  const [, setToast] = useGlobalState('toast')
  const { getRequest } = useQuery()
  const [thisDemo, setThisDemo] = useState(demo || {})
  const { putpostRequest } = useQuery()
  const { formState, register, unregister, watch, handleSubmit, setValue, getValues, setError, setFocus } = useForm({
   defaultValues: {
      name: thisDemo.name,
      description: thisDemo.description,
      kind: thisDemo.kind || 'Audio',
      remote_url: thisDemo.remoteUrl
    }
  })

  const { errors } = formState
  const [loading, setLoading] = useState(false)
  const description = getValues().description

  const updateFilePathInfo = ({ audioUrl, audioFileName }) => {
    setThisDemo({ ...thisDemo, ...{ audioUrl: audioUrl, audioFileName: audioFileName, filePathProcessing: true } })
  }

  const fetchFilePathInfo = () => {
    getRequest(`/api/v3/demos/${thisDemo.id}`, {}, (err, jsonData) => {
      if (err) { return }
      setThisDemo({ ...thisDemo, ...{ audioUrl: jsonData.demo.audioUrl, audioFileName: jsonData.demo.audioFileName, filePathProcessing: false } })
    })
  }

  useEffect(() => {
    setFocus('name')
  }, [setFocus])

  useEffect(() => {
    register('description')
    return () => {
      unregister('description')
    }
  }, [register])

  const handleUpdateFroala = (key, val) => {
    setValue(key, val, { shouldDirty: true, shouldValidate: true })
  }

  const keyPress = (e) => {
    if (e.target.name === 'name' && e.keyCode === 13) { e.preventDefault() }
    if ((e.metaKey || e.ctrlKey) && e.keyCode === 13) {
      if (typeof (e.target.name) === 'undefined') {
        e.target.blur() // froala hack to make it update model so it can save properly
      }
      handleSubmit(onSubmit)()
    }
  }
  // TODO: when you create a new demo, and then immediately edit it, it does not update the index

  const onSubmit = debounce(300, (data) => {
    setLoading(true)

    let URL = '/api/v3/demos'
    let action = 'POST'
    if (thisDemo.id) {
      URL = `/api/v3/demos/${thisDemo.id}`
      action = 'PATCH'
    }

    putpostRequest(URL, action, { demo: data }, (err, jsonData) => {
      setLoading(false)
      if (err) { // 422 code
        if (typeof err !== 'string') { // 500 code on API
          Object.entries(err).forEach(([key, value]) => {
            setError(key, value)
          })
        }
        return
      }

      setThisDemo({ ...jsonData.demo })
      // setDemo({ ...jsonData.demo })
      if (action === 'POST') {
        setToast(<div className="ml-3 w-0 flex-1 pt-0.5">
          <p className="text-sm font-medium dark:text-white text-gray-800">Demo Created</p>
          <p className="mt-1 text-sm dark:text-gray-100 text-gray-500">How demonstrative. :D</p>
        </div>)
        return
      }

      setToast(randomToastSuccess())
    })
  })

  const addLabel = (label, labelKind) => {
    const data = {
      labeled_kind: 'Demo',
      labeled_id: thisDemo.id
    }
    const labelData = {
      name: label,
      label_kind: labelKind
    }
    putpostRequest('/api/v3/labelings', 'POST', { labeling: data, label: labelData }, (err, jsonData) => {
      if (err) { /* hook */ return }

      const labelings = [...thisDemo.labelings, ...[jsonData.labeling]]
      console.log(labelings)

      setThisDemo({ ...thisDemo, ...{ labelings: labelings } })
    })
  }

  const removeLabel = (labelingId) => {
    const labelings = thisDemo.labelings.filter(labeling => labeling.id !== labelingId)
    setThisDemo({ ...thisDemo, ...{ labelings: labelings } })

    putpostRequest(`/api/v3/labelings/${labelingId}`, 'DELETE', {}, (err, jsonData) => {
      if (err) { /* hook */ }
    })
  }

  if (typeof (thisDemo.id) === 'undefined') {
    return <>
      <Card
        footer={
        <div className='flex flex-col items-end'>
          <div className='flex flex-col items-end w-min ml-2'>
            <div className="hidden sm:block mt-1 w-min text-xs border border-gray-200 dark:border-gray-800 rounded px-2 font-sans text-gray-500 dark:text-gray-400">⌘/ctrl+ENTER</div>
            <PrimaryButton className='w-full' onClick={handleSubmit(onSubmit)} loading={loading} text='Next' />
          </div>
        </div>
        } >
        <form className='grid grid-cols-4'>
          <div className="col-span-4 sm:col-span-2 sm:col-start-2">
            <label htmlFor="school_name" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">Name of Demo </label>
            <div className="mt-1 flex flex-col rounded-md shadow-sm">
              <div className='relative'>
                <input onKeyDown={keyPress} type="text" className={errors.name ? 'errors' : ''} {...register('name', { required: true }) } placeholder="Character Reel" />
                { errors.name && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                </div> }
              </div>
            </div>
            <p className="text-xs text-gray-500 dark:text-gray-400 mt-1">Name of your demo, sampler, etc. This will be shown when you use this demo to apply/audition for projects</p>
            { errors.name && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
          </div>
        </form>
      </Card>
    </>
  }

  const kind = watch('kind')

  return <>
    <div className="md:grid md:grid-cols-3 md:gap-6">
      <div className="md:col-span-1">
        <div className="px-4 sm:px-0 hidden sm:block">
          <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-100 mb-2">Demo Preview</h3>
          <PublicDemoRow demo={thisDemo} />
        </div>
      </div>
      <Card title="General Information"
        footer={
        <div className='flex flex-rows justify-end items-end'>
          <div className='flex flex-col items-end w-min ml-2'>
            <div className="hidden sm:block w-min text-xs border border-gray-200 dark:border-gray-800 rounded px-2 font-sans text-gray-500 dark:text-gray-400">⌘/ctrl+ENTER</div>
            <PrimaryButton className='w-full' onClick={handleSubmit(onSubmit)} loading={loading} text='Save' />
          </div>
        </div>
        } >
        <form>
          <div className="grid sm:grid-cols-4 gap-6">
            <div className="col-span-4 sm:col-span-3">
              <label htmlFor="school_name" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">Name of Demo </label>
              <div className="mt-1 flex flex-col rounded-md shadow-sm">
                <div className='relative'>
                  <input onKeyDown={keyPress} type="text" className={errors.name ? 'errors' : ''} {...register('name', { required: true }) } placeholder="Character Reel" />
                  { errors.name && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                  </div> }
                </div>
              </div>
              <p className="text-xs text-gray-500 dark:text-gray-400 mt-1">Name of your demo, sampler, etc. This will be shown when you use this demo to apply/audition for projects</p>
              { errors.name && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
            </div>

            <div className='col-span-1'>
              <div className="col-span-6 sm:col-span-3">
                <label htmlFor="country" className="block text-sm font-medium text-gray-700 dark:text-gray-200 flex justify-between">
                  <span>Type</span>
                </label>
                <select {...register('kind')}
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm" >
                    <option>Audio</option>
                    <option>YouTube Video</option>
                    <option>Loom Video</option>
                    <option value='Link'>Link to a document / site</option>
                </select>
              </div>
            </div>

            { (kind === 'YouTube Video' || kind === 'Loom Video') && <>
              <div className="col-span-4 sm:col-span-3">
                <label htmlFor="remote_url" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">URL of the {kind} </label>
                <div className="mt-1 flex flex-col rounded-md shadow-sm">
                  <div className='relative'>
                    <input onKeyDown={keyPress} type="text" className={errors.remote_url ? 'errors' : ''} {...register('remote_url', { required: true }) } placeholder="https://www.loom.com/embed/...." />
                    { errors.remote_url && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                      <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                    </div> }
                  </div>
                </div>
                { errors.name && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
              </div>
            </> }

            { kind === 'Link' && <>
              <div className="col-span-4 sm:col-span-3">
                <label htmlFor="remote_url" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">URL for the document or site </label>
                <div className="mt-1 flex flex-col rounded-md shadow-sm">
                  <div className='relative'>
                    <input onKeyDown={keyPress} type="text" className={errors.remote_url ? 'errors' : ''} {...register('remote_url', { required: true }) } placeholder="https://www.google.com/docs/..." />
                    { errors.remote_url && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                      <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                    </div> }
                  </div>
                </div>
                { errors.name && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
              </div>
            </> }

            <div className="col-span-4 sm:col-span-4">
              <div className='mt-4'>
                <label htmlFor="pricing" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                  Description
                </label>
                <p className="text-xs text-gray-500  dark:text-gray-400 mb-1"> Any extra info you want to add. Any other links or notes. </p>
                <div className="mt-1 shadow-sm w-full min-w-full">
                  <FroalaWrapper
                    model={description}
                    keyPress={keyPress}
                    updateModel={(val) => handleUpdateFroala('description', val)}
                  />
                </div>
                <i className="mt-2 flex justify-end text-xs text-gray-500 dark:text-gray-400">
                  (Tip: You can add URLs and @mention your followers and your credits.)
                </i>
              </div>
            </div>
          </div>
        </form>
      </Card>

      <div className="col-span-3 md:col-span-2 md:col-start-2">
        <Card className='mt-2' title="Search Tags" >
          <div className="col-span-4 sm:col-span-4">
            <label htmlFor="pricing" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
              Tags
            </label>
            <p className="text-xs text-gray-500  dark:text-gray-400 mb-1"> Help others find your demo by describing it with labels. </p>
            <TagAutocomplete kind='demo-categories' addLabel={addLabel} placeholder='Character, Warm, Funny, Dark' />

            <div className="min-w-0 mt-3 flex-1 flex sm:items-center  flex-wrap">
              {thisDemo.labelings.map((labeling, idx) => (
                <span key={`laform${labeling.id}`} onClick={(id) => removeLabel(labeling.id)} className="flex-shrink-0 inline-block px-2 mr-1 mb-1 py-0.5 text-white bg-cccorange dark:bg-cccpurple text-xs font-medium rounded-full flex cursor-pointer capitalize">{labeling.name} <XIcon className='w-4 h-4' /></span>
              ))}
            </div>
          </div>
        </Card>
      </div>

      { kind === 'Audio' && <>
        <div className="col-span-3 md:col-span-2 md:col-start-2">
          <Card title="Audio Information" >
            <div className="col-span-4 sm:col-span-4 justify-center text-center flex">
              <AudioUploadForm
                kind='Demo'
                objectId={thisDemo.id}
                onProcessingStarted={updateFilePathInfo}
                onSuccess={fetchFilePathInfo}
              />
            </div>

            { thisDemo.audioUrl && !thisDemo.filePathProcessing && <>
              <div className="col-span-4 sm:col-span-4 justify-center text-center flex">
                File: { thisDemo.audioFileName }
              </div>
            </> }
          </Card>
        </div>
      </> }

    </div>
  </>
}

export default DemoForm

DemoForm.propTypes = {
  demo: PropTypes.object.isRequired
}
