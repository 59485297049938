import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import useModal from '@/hooks/useModalV2'
import Loading from '@/shared/Loading'
import Modal from '@/shared/Modal'
import { XCircleIcon } from '@heroicons/react/solid'
import useQuery from '@/hooks/useQuery'
import { useGlobalState } from '@/state'
import PlayPauseButton from '@/shared/Buttons/PlayPauseButton'

import DemoForm from '@/pages/Users/ProfileDemos/DemoForm'

const DemoRow = (props) => {
  const { fetchData, demo } = props
  const { openModal, closeModal, isOpen, willClose } = useModal()
  const [, setToast] = useGlobalState('toast')
  const { putpostRequest } = useQuery()
  const [loading, setLoading] = useState(false)
  const [deleteConfirm, setDeleteConfirm] = useState(false)

  useEffect(() => {
    if (!willClose) { return }

    fetchData()
  }, [willClose])

  const deleteDemo = () => {
    setLoading(true)
    putpostRequest(`/api/v3/demos/${demo.id}`, 'DELETE', { }, (err, jsonData) => {
      setLoading(false)
      if (err) { /* hook */ return }

      if (typeof (fetchData) === 'function') {
        fetchData()
        setToast(<div className="ml-3 w-0 flex-1 pt-0.5">
          <p className="text-sm font-medium dark:text-white text-gray-800">Demo Deleted</p>
          <p className="mt-1 text-sm dark:text-gray-100 text-gray-500">Bye for now.</p>
        </div>)
      }
    })
  }

  if (loading) { return <Loading /> }

  return <>
    <div className='bg-white dark:bg-gray-700 px-4 py-4 sm:px-6 rounded-lg shadow-sm '>
      <div className={'flex flex-col sm:flex-row sm:items-centerflex-wrap'}>
        <div className="min-w-0 flex-1 flex sm:items-center flex-nowrap">
          { demo.kind === 'Audio' && <div className='mr-2'>
            { demo.filePathProcessing && <Loading noMessage noLoadingMessage /> }
            { !demo.filePathProcessing && <>
              <PlayPauseButton
                mediaUrl={demo.audioUrl}
                objectName={demo.name}
                objectId={demo.id}
                objectKind='Demo'
              />
            </> }
          </div> }
          <div className="min-w-0 flex-auto px-0">
            <div>
              <p className="font-medium text-cccpurple truncate">{demo.name}</p>
              { demo.kind === 'Audio' && <p className='text-sm text-gray-500 dark:text-gray-400 truncate'><b>File:</b> { demo.audioFileName }</p> }
              { demo.kind !== 'Audio' && <p className='text-sm text-gray-500 dark:text-gray-400 truncate'><b>{demo.kind}</b>: { demo.remoteUrl }</p> }
            </div>
          </div>
        </div>

        <Modal full isOpen={isOpen} closeModal={closeModal} >
          <div className='relative mb-1'>
            <span className="sr-only" hidden>Close Modal</span>
            <XCircleIcon onClick={closeModal} className='cursor-pointer w-7 h-7 absolute -top-5 -right-5 text-gray-500 dark:text-gray-400' />
          </div>
          <DemoForm demo={demo} />
        </Modal>
        <div className='my-3 sm:mx-0'>
          <span className="sm:ml-4 flex-shrink-0 flex items-start space-x-4">
            <button onClick={openModal} type="button" className="rounded-md font-medium text-cccblue hover:text-cccblue-alt">
              Edit
            </button>
            <span className="text-gray-300" aria-hidden="true">|</span>
            { !deleteConfirm && <button type="button" onClick={() => setDeleteConfirm(true)} className="rounded-md font-medium text-cccblue hover:text-cccblue-alt"> Delete </button> }
            { deleteConfirm && <button type="button" onClick={deleteDemo} className="rounded-md font-medium text-red-500"> Really delete this? </button> }
          </span>
        </div>
      </div>
      <div className="min-w-0 flex-1 flex sm:items-center mt-1 flex-wrap">
        {demo.labelings.map((labeling, idx) => (
          <span key={`rowla${labeling.id}`} className="flex-shrink-0 inline-block px-2 mr-1 mb-1 py-0.5 text-white bg-cccorange dark:bg-cccpurple text-xs font-medium rounded-full flex cursor-pointer capitalize">{labeling.name}</span>
        ))}
      </div>
      <div className="text-gray-500 dark:text-gray-300 text-sm mt-3" dangerouslySetInnerHTML={{ __html: demo.description }} />
    </div>
  </>
}

export default DemoRow

DemoRow.propTypes = {
  fetchData: PropTypes.func.isRequired,
  demo: PropTypes.object.isRequired
}
