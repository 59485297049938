import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { FlagIcon } from '@heroicons/react/solid'
import { debounce } from 'throttle-debounce'
import { useForm } from 'react-hook-form'

import useQuery from '@/hooks/useQuery'
import { useGlobalState } from '@/state'
import useModal from '@/hooks/useModalV2'
import Modal from '@/shared/Modal'
import Card from '@/shared/Card'
import PrimaryButton from '@/shared/Buttons/Primary'
import VideomancyPrompt from '@/pages/SubmissionUpload/VideomancyPrompt'

const NewFeatureAlert = ({ currentUser }) => {
  const { openModal, closeModal, isOpen, willClose } = useModal()
  const { putpostRequest, getRequest } = useQuery()
  const [submission, setSubmission] = useState(null)

  const hasCookie = () => {
    let cookieFound = false
    document.cookie.split(';').forEach(function (cookie) {
      if (cookie.trim().includes('newfeaturepopup')) {
        cookieFound = true
      }
    })
    return cookieFound
  }

  useEffect(() => {
    if (!hasCookie()) {
      getRequest(`/api/v3/auditions/new_feature`, {}, (err, jsonData) => {
        if (err) { return console.log(err) }
        setSubmission(jsonData.submission)
        openModal()
      })
    }
  }, [])

  useEffect(() => {
    if (willClose) {
      document.cookie = "newfeaturepopup=interacted; max-age=31536000; Secure";
    }
  }, [willClose])

  if (!currentUser) return null
  if (!submission) return null
  console.log(submission)

  return <>
    <Modal full isOpen={isOpen} closeModal={closeModal} >
      <div className='flex flex-col gap-y-2 items-center justify-center'>
        <h2 className='text-base text-center font-semibold text-cccorange tracking-wide uppercase'>New Feature Alert</h2>
        <div className='text-sm max-w-96 text-gray-700 dark:text-gray-200'>Hi, it's Buford. I just launched a new feature:</div>
      </div>
      <VideomancyPrompt initialSubmission={submission} />
      <div className='flex flex-col gap-y-2 items-center justify-center'>
        <div className='text-sm max-w-96 text-gray-700 dark:text-gray-200'>See what your latest submission will look like ☝️ ☝️</div>
      </div>
    </Modal>
  </>
}

export default NewFeatureAlert

NewFeatureAlert.propTypes = {
  currentUser: PropTypes.object.isRequired
}
