import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { useDebouncedEffect } from '@/utils/useDebouncedEffect'
import Pagination from '@/shared/Pagination'
import Loading from '@/shared/Loading'
import { useGlobalState } from '@/state'
import { SortAscendingIcon, SearchIcon } from '@heroicons/react/solid'
import PrimaryButton from '@/shared/Buttons/Primary'
import DefaultButton from '@/shared/Buttons/Default'
import useModal from '@/hooks/useModalV2'
import Modal from '@/shared/Modal'
import useQuery from '@/hooks/useQuery'
import useDropdown from '@/hooks/useDropdown'
import Card from '@/shared/Card'
import StatusPill from '@/shared/StatusPill'
import Tabs from '@/shared/Tabs'
import SVGIcon from '@/components/icon'

const ManageProjectRow = ({ project }) => {
  const { openModal, closeModal, isOpen } = useModal()
  const { putpostRequest } = useQuery()
  const [, setToast] = useGlobalState('toast')
  const [loading, setLoading] = useState(false)

  // use Statuspill
  let pillColor = 'default'
  if (project.status === 'open') {
    pillColor = 'warning'
  } else if (project.status === 'completed') {
    pillColor = 'success'
  }

  const copy = () => {
    setToast(<div className="ml-3 w-0 flex-1 pt-0.5">
      <p className="text-sm font-medium text-gray-900 dark:text-gray-100">Copying....</p>
      <p className="mt-1 text-sm text-gray-700 dark:text-gray-300">This usually takes 60 seconds...</p>
    </div>)
    putpostRequest(`/api/v3/manage/projects/${project.id}/copy_project`, 'POST', { }, (err, jsonData) => {
      setLoading(false)
      if (err) { /* hook */ return }
      console.log(jsonData)

      if (jsonData?.id) { window.location.href = '/manage/projects' }
      if (jsonData?.errors) {
        setToast(<div className="ml-3 w-0 flex-1 pt-0.5">
          <p className="text-sm font-medium text-gray-900 dark:text-gray-100">Error</p>
          <p className="mt-1 text-sm text-gray-700 dark:text-gray-300">{jsonData.errors}</p>
        </div>)
      }
    })
  }

  return <li className='bg-gray-100 dark:bg-gray-700 px-4 py-4 sm:px-6  rounded-lg shadow-sm'>
    <Modal isOpen={isOpen} closeModal={closeModal} >
      <div className="rounded-md bg-blue-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <svg className="h-5 w-5 text-blue-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fillRule="evenodd" d="M19 10.5a8.5 8.5 0 11-17 0 8.5 8.5 0 0117 0zM8.25 9.75A.75.75 0 019 9h.253a1.75 1.75 0 011.709 2.13l-.46 2.066a.25.25 0 00.245.304H11a.75.75 0 010 1.5h-.253a1.75 1.75 0 01-1.709-2.13l.46-2.066a.25.25 0 00-.245-.304H9a.75.75 0 01-.75-.75zM10 7a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
            </svg>
          </div>
          <div className="ml-3 text-sm text-blue-700">
            <div>Copying a project allows you to post it on the main 'open projects' search page again for the full length of time.</div>
            <div className='mt-3'>This will copy all roles and project details onto a new <b>draft</b> project. You will be able to edit the roles and project details before you publish it.</div>
          </div>
        </div>
      </div>
      <div className="flex justify-end space-x-2 mt-4">
        <DefaultButton onClick={closeModal} text='Cancel' />
        <PrimaryButton onClick={copy} loading={loading} text='Yes, copy it' />
      </div>
    </Modal>
    <div className="sm:flex sm:justify-between sm:items-center">
      <div className="flex-shrink-0 text-gray-500 dark:text-gray-100 hidden sm:block">
        <img className='h-16 w-16 rounded-full' src={project.imageUrl} alt={project.name} />
      </div>
      <div className="flex-1 px-0 md:px-4 md:py-0 pb-2 w-full">
        <div className='flex flex-col truncate'>
          <span className='flex justify-start'>
            <span className="uppercase text-xs dark:text-gray-300 text-gray-500 mr-5">
              Deadline: {project.deadline}
            </span>
            <StatusPill status={pillColor} text={project.status} />
          </span>
          <b className="font-medium dark:text-gray-300">{project.name}</b>
          <div className='flex justify-start space-x-5 items-center text-sm'>
            <Link to={`/manage/projects/${project.id}`} className="text-cccblue">Manage</Link>
            { project.slug && <a target="_blank" href={`/projects/${project.slug}`} className="text-cccblue" rel="noreferrer">View Public Page</a> }
            <Link to={`/manage/projects/${project.id}/edit`} className="text-cccblue">Edit</Link>
            { project.slug && <button onClick={openModal} className="text-cccblue">Copy Project</button> }
          </div>
        </div>
      </div>

      <div className='flex flex-col sm:flex-row justify-end items-center space-x-3 mt-2 text-gray-900 font-medium text-sm dark:text-gray-100 px-10 flex-wrap'>
        <Link to={`/manage/projects/${project.id}/submissions`} className='whitespace-nowrap flex justify-end items-center p-2 space-x-1 cursor-pointer hover:bg-gray-200 text-gray-700 dark:text-gray-400'>
          <SVGIcon name='icon-submissions' className='h-6 w-6' />
          <span><span className='text-green-500'>{project.submissionsCount}</span> submissions</span>
        </Link>
        <Link to={`/manage/projects/${project.id}/roles`} className='whitespace-nowrap flex justify-end items-center p-2 space-x-1 cursor-pointer hover:bg-gray-200 text-gray-700 dark:text-gray-400'>
          <SVGIcon name='icon-manage-projects' className='h-6 w-6' />
          <span><span className='text-green-500'>{project.rolesAvailableCount} / {project.rolesCount}</span> roles</span>
        </Link>
        <div className='whitespace-nowrap flex justify-end items-center p-2 space-x-1 hover:bg-gray-200 text-gray-700 dark:text-gray-400'>
          <SVGIcon name='icon-followers' className='h-6 w-6' />
          <span><span className='text-green-500'>{project.followersCount}</span> followers</span>
        </div>
      </div>
    </div>
  </li>
}

const ManageProjectsIndex = () => {
  const [meta, setMeta] = useState({})
  const [projects, setProjects] = useState([])
  const { getRequest } = useQuery()
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(1)
  const [contains, setContains] = useState(null)
  const [statuses, setStatuses] = useState([])
  const [order, setOrder] = useState('created_at')
  const [status, setStatus] = useState('all')
  const [permissions, setPermissions] = useState('all')
  const [searchOpen, setSearchOpen] = useState(false)
  const scrollRef = useRef(null)
  const dropdownRef = useRef(false)
  const { dropdownOpen, setDropdownOpen, openDropdown } = useDropdown(dropdownRef)

  useDebouncedEffect(() => {
    setDropdownOpen(false)
    fetchData(true)
  }, 200, [contains, order, permissions, status])

  useEffect(() => { fetchData() }, [page])

  const fetchData = (newPage) => {
    setLoading(true)
    const data = {
      contains: contains,
      order_by: order,
      permissions: permissions,
      status: status,
      page: newPage ? 1 : page
    }
    getRequest('/api/v3/manage/projects', data, (err, jsonData) => {
      setLoading(false)
      if (err) { /* hooks */ return }

      if (statuses.length === 0) { setStatuses(jsonData.statuses) }
      setProjects(jsonData.projects)
      setMeta(jsonData.meta)
    })
  }
  const handleChange = (e) => {
    if (e.target.name === 'order') {
      setOrder(e.target.value)
    }
    if (e.target.name === 'permissions') {
      setPermissions(e.target.value)
    }
    if (e.target.name === 'contains') {
      setContains(e.target.value)
    }
  }

  const changeTab = (tab) => { setStatus(tab) }

  const tablist = [
    { name: 'All Projects', count: null, changeValue: 'all' },
    { name: 'Open', count: statuses?.open, changeValue: 'open' },
    { name: 'Draft', count: statuses?.draft, changeValue: 'draft' },
    { name: 'Completed', count: statuses?.completed, changeValue: 'completed' }
  ]

  const orderMap = {
    created_at: 'Created At',
    updated_at: 'Latest Activity',
    listed_at: 'Listed At',
    deadline: 'Deadline'
  }

  return <>
    <div className='mt-1' />
    <Card title='My Projects'>
      <>
        <div ref={scrollRef}>
          <div className='flex justify-between items-center'>
            <Tabs statuses={statuses} tablist={tablist} currentTab={status} changeTab={changeTab} />
            <div className='flex space-x-2 items-center'>
              <div className='relative flex items-center'>
                <button onClick={openDropdown} className='py-1 px-2 text-xs sm:text-sm text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-cccblue focus:ring-2 focus:ring-blue-700 focus:text-cccblue dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white flex space-x-1 items-center w-32'>
                  <span className="sr-only" hidden>Open options</span>
                  <SortAscendingIcon className="h-4 w-4 sm:h-5 sm:w-5" aria-hidden="true" />
                  <span className='truncate'>{orderMap[order]}</span>
                </button>
                { dropdownOpen && <div ref={dropdownRef} tabIndex="-1" className={`absolute right-0 z-30 ${dropdownOpen ? '' : 'hidden opacity-0 scale-0'} py-2 overflow-y-auto transition origin-top-right bg-white border border-gray-900 dark:border-gray-100 rounded-md shadow-md dark:bg-gray-800 top-6 max-h-80`}>
                  <div onClick={() => setOrder('created_at')} className='text-gray-700 dark:text-gray-100 flex px-4 py-2 text-sm cursor-pointer hover:text-cccblue-alt dark:hover:text-cccblue-alt' >{orderMap.created_at}</div>
                  <div onClick={() => setOrder('deadline')} className='text-gray-700 dark:text-gray-100 flex px-4 py-2 text-sm cursor-pointer hover:text-cccblue-alt dark:hover:text-cccblue-alt' >{orderMap.deadline}</div>
                  <div onClick={() => setOrder('updated_at')} className='text-gray-700 dark:text-gray-100 flex px-4 py-2 text-sm cursor-pointer hover:text-cccblue-alt dark:hover:text-cccblue-alt' >{orderMap.updated_at}</div>
                  <div onClick={() => setOrder('listed_at')} className='text-gray-700 dark:text-gray-100 flex px-4 py-2 text-sm cursor-pointer hover:text-cccblue-alt dark:hover:text-cccblue-alt' >{orderMap.listed_at}</div>
                </div> }
              </div>
              <button onClick={() => setSearchOpen(!searchOpen)} className={`py-1 px-2 text-xs sm:text-sm text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-cccblue focus:ring-2 focus:ring-blue-700 focus:text-cccblue dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white ${searchOpen ? 'text-cccblue' : ''}`}>
                <span className="sr-only" hidden>Open search</span>
                <SearchIcon className="h-4 w-4 sm:h-5 sm:w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
          { searchOpen && <div className='flex flex-col sm:flex-row mb-2'>
            <input name='contains' type="text" onChange={handleChange}
              className="shadow-sm focus:ring-cccpurple dark:bg-gray-900 dark:text-white focus:border-cccpurple block w-full sm:text-sm border-gray-300 rounded-md"
              placeholder="Search by project name or role name. ex: Goku"
            />
          </div> }
          { projects.length >= 0 && <div className='mt-2'><Pagination meta={meta} callback={setPage} scrollRef={scrollRef} /></div> }
        </div>
        { loading && <Loading /> }
        <ul className="space-y-3">
          {projects.map((sub) => (
            <ManageProjectRow key={`manpro${sub.id}`} project={sub} />
          ))}
        </ul>
        { projects.length >= 0 && <div className='mt-2'><Pagination meta={meta} callback={setPage} scrollRef={scrollRef} /></div> }
      </>
    </Card>
  </>
}

export default ManageProjectsIndex
