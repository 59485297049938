import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { ExclamationIcon, EmojiHappyIcon, EmojiSadIcon } from '@heroicons/react/solid'
// import WhatsIncluded from 'pages/SubscriptionForm/WhatsIncluded'
import Downgrade from '@/pages/SubscriptionForm/Downgrade'

import DefaultButton from '@/shared/Buttons/Default'
import PrimaryButton from '@/shared/Buttons/Primary'
import useQuery from '@/hooks/useQuery'
import { useGlobalState } from '@/state'

import Loading from '@/shared/Loading'

const MasterNotice = () => {
  return <>
    <div className="rounded-md bg-yellow-50 p-4 mt-10">
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h2>The Master Plan has been renamed to the 'Business' Plan.</h2>
          <div className="mt-2 text-sm text-yellow-700">
            You can continue your plan as is, but once it expires or if you upgrade the new plans, you cannot go back. You have all the same features as the Business Plan.
          </div>
        </div>
      </div>
    </div>
  </>
}

const findSelectedPlan = (existingSubscription, subPlans) => {
  return subPlans.find(plan => plan.id === existingSubscription.subscriptionPlanId)
}

const ExistingPlan = (props) => {
  const { setUpdateExisting } = props
  const [, setToast] = useGlobalState('toast')
  const [subscriptionContainer] = useGlobalState('subscriptionContainer')
  const { subPlans, existingSubscription } = subscriptionContainer
  const { putpostRequest } = useQuery()
  const [canceling, setCanceling] = useState(false)
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const planDetails = findSelectedPlan(existingSubscription, subPlans)
  const isBraintree = Boolean(existingSubscription?.braintreeSubscriptionId)

  const toggleCanceling = () => { setCanceling(!canceling) }
  const changePaymentMethod = () => { window.location.href = '/change_card' }
  const revertCancel = () => {
    setLoading(true)
    putpostRequest('/api/v3/subscriptions/revert_cancel', 'POST', {}, async (err, jsonData) => {
      if (err) { /* hook */ }
      setLoading(false)
      setToast(<>
        <div className="flex-shrink-0">
          <EmojiHappyIcon className="h-6 w-6 text-green-500" aria-hidden="true" />
        </div>
        <div className="ml-3 w-0 flex-1 pt-0.5">
          <p className="text-sm font-medium dark:text-white text-gray-800">Phew! Welcome back!</p>
          <p className="mt-1 text-sm dark:text-gray-100 text-gray-500">You will receive an email confirmation.</p>
        </div>
      </>)
      history.push('/dashboard')
    })
  }
  const cancel = () => {
    setLoading(true)
    putpostRequest('/api/v3/subscriptions/cancel', 'POST', {}, async (err, jsonData) => {
      if (err) { /* hook */ }
      setLoading(false)
      setToast(<>
        <div className="flex-shrink-0">
          <EmojiSadIcon className="h-6 w-6 text-yellow-500" aria-hidden="true" />
        </div>
        <div className="ml-3 w-0 flex-1 pt-0.5">
          <p className="text-sm font-medium dark:text-white text-gray-800">Successfully canceled</p>
          <p className="mt-1 text-sm dark:text-gray-100 text-gray-500">You will receive an email confirmation.</p>
        </div>
      </>)
      history.push('/dashboard')
    })
  }

  if (loading) { return <Loading /> }

  if (canceling) {
    return <>
      <div className="rounded-md bg-red-50 p-4 mt-10 mb-10">
        <div className="flex">
          <div className="flex-shrink-0">
            <ExclamationIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-red-800">Cancel Subscription</h3>
            <div className="mt-2 text-sm text-red-700">
              <p>Are you sure you want to cancel?  If you cancel, previous charges will not be refunded, but you may continue to use the service until the end of the prepaid term on {existingSubscription.expiresAt}. You will not be charged again.</p>
            </div>
          </div>
        </div>
      </div>
      <DefaultButton onClick={toggleCanceling} text="Back" />
      <DefaultButton className='ml-5' onClick={cancel} text="Yep, go ahead and cancel" />
    </>
  }

  return <>
    <div className="mt-12 space-y-12 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-x-8">
      <div className='col-span-2'>
        <div className='pt-5'>
          <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-200">My Current Plan</h3>
        </div>

        { existingSubscription.plan === 'master' && <MasterNotice /> }
        { existingSubscription?.downgradeTo && <Downgrade existingSubscription={existingSubscription} /> }
        <div className="mt-5 border-t border-gray-200">
          <dl className="sm:divide-y sm:divide-gray-200">
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-2 sm:gap-4">
              <dt className="text-sm font-medium text-gray-500 dark:text-gray-300">Current Plan Name</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 dark:text-gray-200 capitalize">{planDetails.planName} {planDetails.billingInterval}</dd>
            </div>
            { !existingSubscription?.downgradeTo && <>
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-2 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500 dark:text-gray-300">Amount Charged On Next Billing Cycle</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 dark:text-gray-200">
                  ${planDetails.price} on {existingSubscription.nextBillingAt}
                </dd>
              </div>
            </> }
            { existingSubscription?.downgradeTo && <>
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-2 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500 dark:text-gray-300">New plan takes effect</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 dark:text-gray-200">{existingSubscription.expiresAt}</dd>
              </div>
            </> }
            { !existingSubscription?.downgradeTo && <>
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-2 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500 dark:text-gray-300">Subscription Cycle Renews</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 dark:text-gray-200">{existingSubscription.expiresAt}</dd>
              </div>
            </> }
          </dl>
        </div>
        <div className='flex items-center justify-start'>
          {existingSubscription.downgradeTo !== 'cancel' && <DefaultButton className='mr-5' onClick={toggleCanceling} text="Cancel Subscription" /> }
          {existingSubscription.downgradeTo !== 'cancel' && !isBraintree && <DefaultButton className='mr-5' onClick={changePaymentMethod} text="Change Payment Method" /> }
          {existingSubscription.downgradeTo === 'cancel' && <PrimaryButton className='mr-5' onClick={revertCancel} text="I Changed My Mind, I Want To Keep My Subscription" /> }
          {existingSubscription.downgradeTo !== 'cancel' && <PrimaryButton className='mr-5' onClick={() => setUpdateExisting(true)} text="View Plans" /> }
        </div>
      </div>
      <div className='col-span-1'>
        { /* <WhatsIncluded planId={existingSubscription.plan} /> */ }
      </div>
    </div>
  </>
}

export default ExistingPlan

ExistingPlan.propTypes = {
  setUpdateExisting: PropTypes.func.isRequired
}
