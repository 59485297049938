import React from 'react'
import { SparklesIcon } from '@heroicons/react/solid'

const NoShowcases = () => {
  return (
    <span>
      <button type="button" className="relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccblue" >
        <SparklesIcon className="mx-auto h-12 w-12 text-gray-400" />
        <span className="mt-2 block text-sm font-medium text-gray-900 dark:text-gray-300"> Nothing is currently showcased on your profile. If you have a great audition, sample, clip, or submission of any kind that you are particularly proud of and want to appear at the top of your submission list, search for it and click 'Showcase'.</span>
      </button>
    </span>
  )
}

export default NoShowcases
