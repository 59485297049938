import React, { useState, useEffect } from 'react'

import classNames from '@/utils/classNamesLocal'
import useQuery from '@/hooks/useQuery'

import Loading from '@/shared/Loading'
import Card from '@/shared/Card'
import { SVGIcon } from '@/components/icon'

import ProfileDemosIndex from '@/pages/Users/ProfileDemosIndex'
import ProfileTags from '@/pages/Users/ProfileTags'
import ProfileEquipmentsIndex from '@/pages/Users/ProfileEquipmentsIndex'

// key matches jbuilder file
const userMetaList = {
  'voiceActor': { image: 'voiceactor', text: 'Voice Actor', to_db: 'voice_actor' },
  'liveActor': { image: 'actor', text: 'Actor', to_db: 'live_actor' },
  'singer': { image: 'singer', text: 'Singer', to_db: 'singer' },
  'artist': { image: 'artist', text: 'Artist', to_db: 'artist' },
  'animator': { image: 'animator', text: 'Animator', to_db: 'animator' },
  'videoEditor': { image: 'video-editor', text: 'Video Editor', to_db: 'video_editor' },
  'musicComposer': { image: 'musiccomposer', text: 'Music Composer', to_db: 'music_composer' },
  'audioEngineer': { image: 'audio-engineer', text: 'Audio Engineer', to_db: 'audio_engineer' },
  'writer': { image: 'writer', text: 'Writer', to_db: 'writer' },
  'director': { image: 'director', text: 'Director', to_db: 'director' },
  'agent': { image: 'agent', text: 'Agent', to_db: 'agent' },
  'producer': { image: 'producer', text: 'Producer', to_db: 'producer' }
}

/*
const galleryImages = [
  {
    title: 'IMG_4985.HEIC',
    size: '3.9 MB',
    source:
      'https://images.unsplash.com/photo-1582053433976-25c00369fc93?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=512&q=80'
  }
  // More files...
]

const ContentRating = () => {
  const [enabled, setEnabled] = useState(false)
  return (
    <div>
      <div className="md:grid md:grid-cols-3 md:gap-6">
        <div className="md:col-span-1">
          <div className="px-4 sm:px-0">
            <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-100">Acceptable Work Content</h3>
            <p className="mt-1 text-sm text-gray-600 dark:text-gray-300">
              This information will be displayed publicly so be careful what you share.
            </p>
          </div>
        </div>
        <Card>
          https://www.esrb.org/ratings-guide/ for all the info
          <div className="grid grid-cols-3 gap-6">
            <div className='col-span-2'>
              <Switch.Group as="div" className="flex items-center justify-between">
                <Switch.Label as="span" className="flex-grow flex flex-col" passive>
                  <span className="text-sm font-medium text-gray-900 dark:text-gray-200">All Content</span>
                  <span className="text-sm text-gray-500">I'm okay to work on any project regardless of the content</span>
                </Switch.Label>
                <Switch
                  checked={enabled}
                  onChange={setEnabled}
                  className={classNames(
                    enabled ? 'bg-cccpurple' : 'bg-gray-200',
                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccpurple'
                  )}
                >
                  <span className="sr-only" hidden>All Content</span>
                  <span
                    aria-hidden="true"
                    className={classNames(
                      enabled ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 rounded-full bg-white dark:bg-gray-500 shadow transform ring-0 transition ease-in-out duration-200'
                    )}
                  />
                </Switch>
              </Switch.Group>
            </div>
            <div className='col-span-2'>
              <Switch.Group as="div" className="flex items-center justify-between">
                <Switch.Label as="span" className="flex-grow flex flex-col" passive>
                  <span className="text-sm font-medium text-gray-900 dark:text-gray-200">Substances</span>
                  <span className="text-sm text-gray-500">Alcohol, Drugs, Tobacco</span>
                </Switch.Label>
                <Switch
                  checked={enabled}
                  onChange={setEnabled}
                  className={classNames(
                    enabled ? 'bg-cccpurple' : 'bg-gray-200',
                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccpurple'
                  )}
                >
                  <span className="sr-only" hidden>Substances</span>
                  <span
                    aria-hidden="true"
                    className={classNames(
                      enabled ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 rounded-full bg-white dark:bg-gray-500 shadow transform ring-0 transition ease-in-out duration-200'
                    )}
                  />
                </Switch>
              </Switch.Group>
            </div>
            <div className='col-span-2 ml-10'>
              <Switch.Group as="div" className="flex items-center justify-between">
                <Switch.Label as="span" className="flex-grow flex flex-col" passive>
                  <span className="text-sm font-medium text-gray-900 dark:text-gray-200">Alcohol Reference</span>
                  <span className="text-sm text-gray-500">Reference to and/or images of alcoholic beverages</span>
                </Switch.Label>
                <Switch
                  checked={enabled}
                  onChange={setEnabled}
                  className={classNames(
                    enabled ? 'bg-cccpurple' : 'bg-gray-200',
                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccpurple'
                  )}
                >
                  <span className="sr-only" hidden>All Content</span>
                  <span
                    aria-hidden="true"
                    className={classNames(
                      enabled ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 rounded-full bg-white dark:bg-gray-500 shadow transform ring-0 transition ease-in-out duration-200'
                    )}
                  />
                </Switch>
              </Switch.Group>
            </div>
            <div className='col-span-2 ml-10'>
              <Switch.Group as="div" className="flex items-center justify-between">
                <Switch.Label as="span" className="flex-grow flex flex-col" passive>
                  <span className="text-sm font-medium text-gray-900 dark:text-gray-200">Tobacco Reference</span>
                  <span className="text-sm text-gray-500">Reference to and/or images of tobacco products</span>
                </Switch.Label>
                <Switch
                  checked={enabled}
                  onChange={setEnabled}
                  className={classNames(
                    enabled ? 'bg-cccpurple' : 'bg-gray-200',
                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccpurple'
                  )}
                >
                  <span className="sr-only" hidden>Tobacco Reference</span>
                  <span
                    aria-hidden="true"
                    className={classNames(
                      enabled ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 rounded-full bg-white dark:bg-gray-500 shadow transform ring-0 transition ease-in-out duration-200'
                    )}
                  />
                </Switch>
              </Switch.Group>
            </div>
          </div>
        </Card>
      </div>
    </div>
  )
}

const Gallery = () => {
  return (
    <div>
      <div className="md:grid md:grid-cols-3 md:gap-6">
        <div className="md:col-span-1">
          <div className="px-4 sm:px-0">
            <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-100">Gallery</h3>
            <p className="mt-1 text-sm text-gray-600 dark:text-gray-300">
              This information will be displayed publicly so be careful what you share.
            </p>
          </div>
        </div>
        <Card footer={<DefaultButton onClick={() => {}} text="Add Images" />}>
          <ul role="list" className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
            {galleryImages.map((file) => (
              <li key={file.source} className="relative">
                <div className="group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden">
                  <img src={file.source} alt="" className="object-cover pointer-events-none group-hover:opacity-75" />
                  <button type="button" className="absolute inset-0 focus:outline-none">
                    <span className="sr-only" hidden>View details for {file.title}</span>
                  </button>
                </div>
                <p className="mt-2 block text-sm font-medium text-gray-900 dark:text-gray-200 truncate pointer-events-none">{file.title}</p>
                <p className="block text-sm font-medium text-gray-500 pointer-events-none">{file.size}</p>
              </li>
            ))}
          </ul>
        </Card>
      </div>
    </div>
  )
}

*/

const ProfileAbout = () => {
  const { getRequest, putpostRequest } = useQuery()
  const [userMeta, setUserMeta] = useState({})
  const [initialLoading, setInitialLoading] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => { fetchData() }, [])

  const fetchData = () => {
    setInitialLoading(true)
    getRequest('/api/v3/user_metas', {}, (err, jsonData) => {
      setInitialLoading(false)
      if (err) { /* hook */ return }
      setUserMeta(jsonData.userMeta)
    })
  }

  const toggleUserMeta = (key) => {
    const val = userMeta[key]

    setLoading(key)
    const data = {
      [userMetaList[key].to_db]: !val
    }
    putpostRequest('/api/v3/user_metas/update_meta', 'POST', { user_meta: data }, (err, jsonData) => {
      setLoading(false)
      if (err) { /* hook */ return }

      setUserMeta({ ...userMeta, [key]: !val })
    })
  }

  if (initialLoading) { return <Loading /> }

  return (<>

    <div className='text-gray-800 dark:text-gray-200'>
      <div className="md:grid md:grid-cols-3 md:gap-6">
        <div className="md:col-span-1">
          <div className="px-4 sm:px-0">
            <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-100">What kind of creator am I?</h3>
            <p className="mt-1 text-sm text-gray-600 dark:text-gray-300">
              This will help people find you in the search
            </p>
          </div>
        </div>
        <Card>
          <ul className="mt-3 grid grid-cols-2 gap-5 sm:gap-6 sm:grid-cols-2 lg:grid-cols-4">
            { Object.keys(userMetaList).map((key, idx) => (
              <li key={`role${key}`} className="col-span-1 flex shadow-sm rounded-md">
                { loading === key && <Loading noMessage noLoadingMessage /> }
                { loading !== key && <>
                  <button onClick={() => toggleUserMeta(key)}
                    className={classNames(userMeta[key] ? 'bg-cccorange bg-opacity-20 hover:bg-cccorange-alt text-gray-900 dark:text-white' : 'dark:text-gray-200 dark:bg-gray-700 bg-gray-100 hover:bg-gray-200 dark:hover:bg-gray-750 ', 'w-full inline-flex items-center px-4 py-2 text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccpurple')}>
                  <SVGIcon name={userMetaList[key].image} width='40' height='40' autoDark/> <span className='ml-2'>{userMetaList[key].text}</span>
                </button>
                </> }
              </li>
            ))}
          </ul>
        </Card>
      </div>
    </div>

    <div className="py-5">
      <div className="hidden sm:block" aria-hidden="true">
        <div className="border-t border-gray-200" />
      </div>
    </div>

    <ProfileDemosIndex />

    <div className="py-5">
      <div className="hidden sm:block" aria-hidden="true">
        <div className="border-t border-gray-200" />
      </div>
    </div>

    {/*
    { false && userMeta?.animator && <>
      <div className="hidden sm:block" aria-hidden="true">
        <div className="py-5">
          <div className="border-t border-gray-200" />
        </div>
      </div>

      <Gallery />
    </> }

    <div className="hidden sm:block" aria-hidden="true">
      <div className="py-5">
        <div className="border-t border-gray-200" />
      </div>
    </div>

    <ContentRating />

    */ }

    <ProfileEquipmentsIndex />

    <div className="py-5">
      <div className="hidden sm:block" aria-hidden="true">
        <div className="border-t border-gray-200" />
      </div>
    </div>

    <ProfileTags />

  </>
  )
}
export default ProfileAbout
