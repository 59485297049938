import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { ChatAltIcon, MailIcon } from '@heroicons/react/solid'
import useQuery from '@/hooks/useQuery'

import Loading from '@/shared/Loading'
import Card from '@/shared/Card'
import UserHoverCard from '@/shared/UserHoverCard'

const MessageRow = ({ msg }) => {
  return <li className='bg-white dark:bg-gray-700 rounded-lg border border-gray-200 dark:border-gray-700'>
      <div className="p-1">
        <div className='flex justify-between'>
          <div className="flex items-start space-x-3 overflow-hidden">
            <div className="relative m-1">
              <img className="h-10 w-10 rounded-sm bg-gray-200 flex items-center justify-center ring-4 ring-cccorange" src={msg.lastUserImageUrl} alt={msg.lastSender} />

              <span className="absolute -bottom-0.5 -right-1 bg-cccorange rounded-tl px-0.5 py-px">
                <ChatAltIcon className="h-4 w-4 text-white" aria-hidden="true" />
              </span>
            </div>
            <div className="min-w-0 flex-1">
              <UserHoverCard username={msg.lastSenderUsername} displayName={msg.lastSender} />
              <div className="mt-1 text-sm text-gray-600 dark:text-gray-400 truncate">
                <p>{msg.lastSender}: {msg.lastBody}</p>
              </div>
            </div>
          </div>
          <div className="mt-0.5 text-sm text-gray-500 ml-1">
            {msg.timeAgo}
          </div>
        </div>
        <div className="mt-1 flex space-x-8">
          <div className="flex space-x-6">
            <a href={`/private_messages/${msg.id}`} className="flex space-x-1 text-gray-400 hover:text-gray-500 dark:text-gray-400 dark:hover:text-gray-300 items-center">
              <ChatAltIcon className="h-5 w-5" aria-hidden="true" />
              <span className="text-xs">Reply</span>
            </a>
          </div>
        </div>
      </div>
    </li>
}

const NoMessages = () => {
  return <span>
    <a href='/' className="relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-2 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccblue flex justify-start items-center" >
      <MailIcon className="h-6 w-6 text-gray-400" />
      <span className="text-sm font-medium text-gray-900 dark:text-gray-300"> You no private messages yet.</span>
    </a>
  </span>
}

const RecentMessages = () => {
  const { getRequest } = useQuery()
  const [messages, setMessages] = useState([])
  const [messagesCount, setMessagesCount] = useState(0)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    getRequest('/api/v3/dashboard/messages', {}, (err, jsonData) => {
      setLoading(false)
      if (err) { /* handled in hook */ return }
      setMessages(jsonData.messages)
      setMessagesCount(jsonData.messagesCount)
    })
  }, [])

  return <>
    <Card title={
      <span className='flex items-center'>
        <MailIcon className="h-6 w-6 mr-1" />
        Recent Messages ({messagesCount} unread)
      </span>
      } footer={<a href='/private_messages'>All Messages</a> } light>
      <ul className="space-y-3">
        { messages.map((msg) => (
          <MessageRow key={`dashmsg${msg.id}`} msg={msg} />
        ))}
      </ul>
      { loading && <Loading /> }
      { !loading && messages.length === 0 && <NoMessages /> }
    </Card>
  </>
}
export default RecentMessages

MessageRow.propTypes = {
  msg: PropTypes.object.isRequired
}
