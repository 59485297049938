import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { debounce } from 'throttle-debounce'
import { useForm } from 'react-hook-form'
import TipTapPrivatePost from '@/shared/TipTapPrivatePost'
import PrimaryButton from '@/shared/Buttons/Primary'

import useQuery from '@/hooks/useQuery'

const PrivatePostMessageForm = ({ addPostMessage }) => {
  const [customError, setCustomError] = useState(null)
  const { id } = useParams()
  const { putpostRequest } = useQuery()
  const tipTapIdx = useRef(1)
  const { register, unregister, handleSubmit, setValue, getValues, setError } = useForm({
   defaultValues: {
     body: null
    }
  })

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    register('body', { required: true })
    return () => {
      unregister('body')
    }
  }, [register])

  const onSubmit = debounce(300, (data) => {
    setLoading(true)

    const formData = {
      post_message: {
        body: data.body,
        post_id: id
      }
    }

    putpostRequest('/api/v3/post_messages', 'POST', formData, (err, jsonData) => {
      setLoading(false)
      if (err) { // 422 code
        if (typeof err === 'string') { // 422 custom error
          setCustomError(err)
        }
        if (typeof err !== 'string') { // 500 code on API
          Object.entries(err).forEach(([key, value]) => {
            setError(key, value)
          })
        }
        return
      }
      tipTapIdx.current += 1

      setCustomError(null)
      addPostMessage(jsonData.postMessage)
    })
  })

  const keyPress = (e) => {
    if ((e.metaKey || e.ctrlKey) && e.keyCode === 13) {
      handleSubmit(onSubmit)()
    }
  }

  const handleUpdateTipTap = (key, val) => {
    setValue(key, val, { shouldDirty: true, shouldValidate: true })
  }

  const body = getValues().body

  return <div>
    <form onKeyDown={keyPress} className='flex items-center space-x-1'>
      <div className='w-full'>
        <TipTapPrivatePost
          html={body}
          idx={tipTapIdx.current}
          updateHtml={(val) => handleUpdateTipTap('body', val)}
        />
      </div>
      <PrimaryButton onClick={handleSubmit(onSubmit)} loading={loading} text='Send' />
    </form>
    { customError && <div className='mt-2 text-sm text-red-600'>{customError}</div> }
  </div>
}

export default PrivatePostMessageForm

PrivatePostMessageForm.propTypes = {
  addPostMessage: PropTypes.func.isRequired
}
