import React from 'react'
import PropTypes from 'prop-types'

const PublicEducationRow = ({ education }) => {
  return <>
    <div className='bg-white dark:bg-gray-800 px-4 py-4 sm:px-6 rounded-lg shadow-lg '>
      <div className={'flex flex-col sm:flex-row sm:items-centerflex-wrap'}>
        <div className="min-w-0 flex-1 flex sm:items-center  flex-wrap sm:flex-nowrap">
          <div className="flex-shrink-0 hidden sm:block">
            <img className="h-16 w-16 rounded-full" src={education.imageUrl} />
          </div>
          <div className="min-w-0 flex-auto px-0 sm:px-4 md:gap-4">
            <div className='truncate'>
              <span className="uppercase text-xs dark:text-gray-300 text-gray-500 mr-5">{education.schoolName} - {education.year}</span>
              <p className='truncate text-cccblue'><a href={education.url} target="_blank" rel="noreferrer" className="font-medium text-cccblue truncate">{education.courseName}</a></p>
              { education.instructor && <div className="text-xs text-gray-500 dark:text-gray-300">Instructed by
                <span className="ml-1 text-sm font-medium text-gray-900 dark:text-gray-200"> {education.instructor} </span>
              </div> }
            </div>
          </div>
        </div>
      </div>
      <div className="text-gray-500 dark:text-gray-300 text-sm mt-3" dangerouslySetInnerHTML={{ __html: education.description }} />
    </div>
  </>
}

export default PublicEducationRow

PublicEducationRow.propTypes = {
  education: PropTypes.object.isRequired
}
