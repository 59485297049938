import React, { useEffect } from 'react'
import { Route, useLocation, Link } from 'react-router-dom'
import useQuery from '@/hooks/useQuery'
import { useGlobalState } from '@/state'

import Breadcrumbs from '@/Layout/Breadcrumbs'
import PageTitle from '@/Layout/PageTitle'

import SubscriptionForm from '@/pages/SubscriptionForm'
import ChangeCardForm from '@/pages/ChangeCardForm'
import Loading from '@/shared/Loading'

function breadCrumbs() {
  // const location = useLocation()
  // const activePath = location.pathname
  const crumbs = [
    { to: '/subscriptions', name: 'Subscription' }
  ]
  return crumbs
}

function pageTitle() {
  const location = useLocation()
  const activePath = location.pathname
  const titleInfo = {
     title: '',
    buttons: []
  }
  if (['/subscriptions', '/membership'].includes(activePath)) { titleInfo.title = 'Subscription' }
  if (activePath === '/buy_credits') { titleInfo.title = 'Checkout' }
  return titleInfo
}

export default function SubscriptionContainer(props) {
  const titleInfo = pageTitle()
  const crumbs = breadCrumbs()
  const [subscriptionContainer, setSubscriptionContainer] = useGlobalState('subscriptionContainer')
  const { getRequest } = useQuery()
  const { subPlans } = subscriptionContainer

  useEffect(() => {
    getRequest('/api/v3/subscription_plans', {}, (err, jsonData) => {
      if (err) { /* handled in hook */ return }
      setSubscriptionContainer({
        ...subscriptionContainer,
        subPlans: jsonData.subscriptionPlans,
        existingSubscription: jsonData.existingSubscription,
        version: jsonData.version
      })
    })
  }, [])

  if (subPlans.length === 0) { return <Loading /> }

  return <>
    <Breadcrumbs>
      { crumbs.map((crumb, index) => (
        <Link key={crumb.name} to={crumb.to} className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700 dark:text-gray-100">
          {crumb.name}
        </Link>
      ))}
    </Breadcrumbs>
    <PageTitle pagetitle={titleInfo.title}>
      {titleInfo.buttons}
    </PageTitle>
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <Route {...props} exact path={['/membership', '/subscriptions']}>
        <SubscriptionForm />
      </Route>
      <Route {...props} exact path='/change_card'>
        <ChangeCardForm />
      </Route>
    </div>
  </>
}
