import React, { useState, useEffect, useReducer, useRef } from 'react'
import { Switch, Route, Link, useParams } from 'react-router-dom'
import useMediaQuery from '@/hooks/useMediaQuery'
import PropTypes from 'prop-types'
import useQuery from '@/hooks/useQuery'
import { MailIcon } from '@heroicons/react/solid'
import { FilterIcon } from '@heroicons/react/outline'
import useDropdown from '@/hooks/useDropdown'
import Card from '@/shared/Card'
import Loading from '@/shared/Loading'

import PrivatePostShow from '@/pages/Posts/PrivatePostShow'
import PrivatePostPeopleInThisThread from '@/pages/Posts/PrivatePostPeopleInThisThread'
import MarkAllAsReadButton from '@/pages/Posts/MarkAllAsReadButton'

function useOnScreen(ref) {
  const [isIntersecting, setIntersecting] = useState(false)

  const observer = new IntersectionObserver(
    ([entry]) => setIntersecting(entry.isIntersecting)
  )

  useEffect(() => {
    observer.observe(ref.current)
    // Remove the observer as soon as the component is unmounted
    return () => { observer.disconnect() }
  }, [])

  return isIntersecting
}

const NoResults = () => {
  return (
    <span>
      <div className="relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-8 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccblue" >
        <MailIcon className="mx-auto h-12 w-12 text-gray-400" />
        <span className="mt-2 block text-sm font-medium text-gray-900 dark:text-gray-300"> No private messages here. Send a 'hello' to someone!</span>
        <Link key='editcredit' to={'/private_messages/new'} className="inline-flex justify-center px-4 py-2 shadow-sm text-sm font-medium rounded-md text-white dark:text-gray-200 dark:hover:bg-cccblue-alt bg-cccblue hover:bg-cccblue-alt hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 my-2">
        New Private Message
      </Link>
      </div>
    </span>
  )
}

const PostRow = ({ post }) => {
  const { id } = useParams()
  const isSelected = post.id.toString() === id
  const [isRead, setIsRead] = useState(post.isRead || isSelected)
  const selected = isSelected ? 'py-2 px-1 dark:bg-gray-700 bg-gray-100' : 'py-2 px-1 hover:bg-gray-100 dark:hover:bg-gray-700'
  return <li className={selected}>
    <Link to={`/private_messages/${post.id}`} onClick={() => setIsRead(true)} className="p-0 text-gray-700 dark:text-gray-200">
      <div className='flex justify-between'>
        <div className="flex flex-grow space-x-1 overflow-hidden mx-1">
          <div className="flex flex-col">
            <div className="flex -space-x-1 overflow-hidden py-1">
              { post.postUsers.map((pu) => (
                <img key={`puuser${pu.id}`} className="inline-block h-6 w-6 rounded-full ring-2 ring-white" src={pu.publicImageUrl} alt="" />
              ))}
            </div>
            <div className='truncate text-sm'>{post.lastMessage.userDisplayName}</div>
            <div className='truncate text-xs'>
              {post.lastMessage.lastMessagePreview}
            </div>
          </div>
        </div>
        <div className="text-sm text-gray-500">
          {post.timeAgo}
        </div>
        { !isRead && <span className="w-2 h-2 ml-2 flex-shrink-0 rounded-full bg-pink-600" aria-hidden="true"></span> }
      </div>
    </Link>
  </li>
}

const PrivatePostsList = () => {
  const [end, setEnd] = useState(false)
  const { getRequest } = useQuery()
  const [loading, setLoading] = useState(true)
  const [filter, setFilter] = useState('all')
  const dropdownRef = useRef(false)
  const { dropdownOpen, openDropdown, setDropdownOpen } = useDropdown(dropdownRef)

  const [state, setState] = useReducer(
    (state, newState) => (
      { ...state, ...newState }), {
        posts: [],
        startsAt: null
      }
    )
  const { posts, startsAt } = state

  const fetchInitial = () => {
    const data = {
      filter: filter
    }
    getRequest('/api/v3/posts/private', data, (err, jsonData) => {
      setLoading(false)
      if (err) { /* handled in hook */ return }

      setState({
        posts: jsonData.posts,
        startsAt: jsonData.startsAt
      })
    })
  }

  const loadMore = () => {
    const data = {
      filter: filter,
      ends_at: startsAt
    }
    setLoading(true)
    getRequest('/api/v3/posts/private', data, (err, jsonData) => {
      setLoading(false)
      if (err) { /* handled in hook */ return }
      if (jsonData.posts.length > 0) {
        setState({
          posts: [...posts, ...jsonData.posts],
          startsAt: jsonData.startsAt
        })
      } else {
        setEnd(true)
      }
    })
  }

  const ref = useRef()
  const isVisible = useOnScreen(ref)

  useEffect(() => {
    loadMore()
  }, [isVisible])

  useEffect(() => {
    setDropdownOpen(false)
    fetchInitial()
  }, [filter])

  const filterMap = {
    all: 'All Messages',
    unread: 'Only Unread'
  }

  return <>
    <div className='border-t-2 bg-cccblue bg-opacity-5 dark:bg-gray-800 shadow border-cccorange dark:border-cccpurple-alt dark:bg-gray-800'>
      <div className='flex justify-between items-center px-2'>
        <div className='font-medium leading-6 dark:bg-gray-800 text-cccorange dark:text-cccpurple mx-2 sm:mx-3 mt-1'> Messages </div>
        <div className='flex items-center'>
          <div className='relative flex items-center px-2'>
            <button onClick={openDropdown} className='mt-1 px-2 text-xs sm:text-sm text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-cccblue focus:ring-2 focus:ring-blue-700 focus:text-cccblue dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white flex space-x-1 items-center w-32'>
              <span className="sr-only" hidden>Open filters</span>
              <FilterIcon className="h-4 w-4 sm:h-5 sm:w-5" aria-hidden="true" />
              <span className='truncate'>{filterMap[filter]}</span>
            </button>
            { dropdownOpen && <div ref={dropdownRef} tabIndex="-1" className={`absolute left-0 z-30 ${dropdownOpen ? '' : 'hidden opacity-0 scale-0'} py-2 overflow-y-auto transition origin-top-right bg-white border border-gray-900 dark:border-gray-100 rounded-md shadow-md dark:bg-gray-800 top-6 max-h-80`}>
              <div onClick={() => setFilter('all')} className='text-gray-700 dark:text-gray-100 flex px-4 py-2 text-sm cursor-pointer hover:text-cccblue-alt dark:hover:text-cccblue-alt' >{filterMap.all}</div>
              <div onClick={() => setFilter('unread')} className='text-gray-700 dark:text-gray-100 flex px-4 py-2 text-sm cursor-pointer hover:text-cccblue-alt dark:hover:text-cccblue-alt' >{filterMap.unread}</div>
            </div> }
          </div>
          <MarkAllAsReadButton />
        </div>
      </div>
      <div className='p-2 sm:p-3'>
        { posts.length === 0 && !loading && <NoResults /> }
        <ul role="list" className='h-75vh overflow-y-auto'>
          {posts.map((post) => (
            <PostRow key={`postrow${post.id}`} post={post} />
          ))}
          { loading && <li><Loading /></li> }
          { !end && <li ref={ref}></li> }
        </ul>
      </div>
    </div>
  </>
}

const PrivatePostsIndex = (props) => {
  const isMobileSize = useMediaQuery('(max-width: 639px)')

  if (isMobileSize) {
    return <Switch>
      <Route {...props} exact path={['/private_messages']}>
        <PrivatePostsList />
      </Route>
      <Route {...props} exact path={['/private_messages/:id']}>
        <PrivatePostShow />
      </Route>
    </Switch>
  }

  return <div className='grid grid-cols-4'>
    <div className='sm:col-span-1 col-span-4'>
      <PrivatePostsList />
    </div>
    <div className='sm:col-span-2 col-span-4'>
      <Switch>
        <Route {...props} exact path={['/private_messages/:id']}>
          <PrivatePostShow />
        </Route>
        <Route {...props} exact path={['/private_messages']}>
          <Card light title='Select a Message'>
            <span>
              <div className="relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccblue" >
                <MailIcon className="mx-auto h-12 w-12 text-gray-400" />
                <span className="mt-2 block text-sm font-medium text-gray-900 dark:text-gray-300"> Select a Message from the list</span>
              </div>
            </span>
          </Card>
        </Route>
      </Switch>
    </div>
    <div className='sm:col-span-1 col-span-4'>
      <Switch>
        <Route {...props} exact path={['/private_messages/:id']}>
          <PrivatePostPeopleInThisThread />
        </Route>
        <Route {...props} exact path={['/private_messages']}>
          <Card light title='People in this Thread'>
            <span>
              <div className="relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccblue" >
                <MailIcon className="mx-auto h-12 w-12 text-gray-400" />
                <span className="mt-2 block text-sm font-medium text-gray-900 dark:text-gray-300"> Select a Message</span>
              </div>
            </span>
          </Card>
        </Route>
      </Switch>
    </div>
  </div>
}

export default PrivatePostsIndex

PostRow.propTypes = {
  post: PropTypes.object.isRequired
}
