import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'

import SearchIndex from '@/pages/ProjectSearch/SearchIndex'

const LoggedOutOpenProjectsSearchIndex = (props) => {
  return <>
    <BrowserRouter>
      <Switch>
        <Route {...props} exact path={['/open_projects', '/find_jobs', '/category/:category', '/search', '/']}>
          <SearchIndex {...props} />
        </Route>
      </Switch>
    </BrowserRouter>
  </>
}

export default LoggedOutOpenProjectsSearchIndex

LoggedOutOpenProjectsSearchIndex.propTypes = { }
