import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useGlobalState } from '@/state'
import { TrendingUpIcon, CalendarIcon, LocationMarkerIcon } from '@heroicons/react/solid'
import useQuery from '@/hooks/useQuery'

import Card from '@/shared/Card'
import UserHoverCard from '@/shared/UserHoverCard'

const ProfileViewerUser = ({ viewerUser }) => {
  const user = viewerUser

  const handleContentClick = (e) => {
    const targetLink = e.target.closest('a')
    if (!targetLink) { return }

    const to = e.target.getAttribute('data-to')
    const isMention = e.target.getAttribute('data-mention')

    if (isMention === 'mention') {
      e.preventDefault()
      history.push('/' + to)
    }
  }

  return <li>
    <div className="w-full mt-1 mx-auto rounded-lg bg-gray-100 shadow p-5 text-gray-800 dark:text-gray-300 dark:bg-gray-700">
      <div className='flex justify-between'>
        <div className="w-full flex mb-4 space-x-4">
          <div className="flex-shrink-0">
            <img className="h-12 w-12 rounded-sm" src={viewerUser.publicImageUrl} alt="" />
          </div>
          <div className="flex flex-col">
            <div className=''><UserHoverCard username={viewerUser.username} displayName={viewerUser.displayName} /></div>
            <span className="text-xs text-gray-500">@{user.username}</span>
          </div>
          <div className="w-12 text-right">
            <i className="mdi mdi-twitter text-blue-400 text-3xl"></i>
          </div>
        </div>
        <div className='text-sm text-gray-500'> {viewerUser.timeAgo} </div>
      </div>
      { user.headline && <div className="w-full mb-4">
        <p onClick={handleContentClick} className="text-sm" dangerouslySetInnerHTML={{ __html: user.headline }}></p>
      </div> }
      <div className="w-full space-x-5 flex justify-items-start items-center">
        {user.location && <span className="text-gray-800 dark:text-gray-100 flex flex-shrink">
          <LocationMarkerIcon className="flex-shrink-0 h-5 w-5 mr-1" aria-hidden="true" /> <span className='text-sm text-gray-500'> {user.location}</span>
        </span> }
        <span className="text-gray-800 dark:text-gray-100 flex flex-shrink">
          <CalendarIcon className="flex-shrink-0 h-5 w-5 mr-1" aria-hidden="true" /> <span className='text-sm text-gray-500'>Joined {user.createdAt}</span>
        </span>
        <a href="" className="text-gray-500 text-sm"><span className='text-lg text-gray-800 dark:text-gray-100'>{user.followingCount}</span> Following</a>
        <a href="" className="text-gray-500 text-sm"><span className='text-lg text-gray-800 dark:text-gray-100'>{user.followersCount}</span> Followers</a>
      </div>
    </div>
  </li>
}

const ProfileViewerUserBlank = () => {
  return <li>
    <div className="flex items-center space-x-4 opacity-20">
      <div className="flex-shrink-0">
        <img className="h-12 w-12 rounded-sm" src={'https://ddppjbdexhxzj.cloudfront.net/avatars/CCC_Cover_UNSURE.svg'} alt="" />
      </div>
      <div className="flex-1 flex-col items-center">
        <div className="max-w-sm w-40 rounded-md h-2 bg-gray-500"></div>
        <p className="text-sm text-gray-500 truncate flex justify-start items-center">@<div className="max-w-sm w-32 rounded-md h-2 bg-gray-500"></div>
        </p>
      </div>
      <div className='text-sm text-gray-500'> 2d </div>
    </div>
  </li>
}

const ProfileViewerAnon = ({ viewerUser }) => {
  return <li>
    <div className="flex items-center space-x-4">
      <div className="flex-shrink-0">
        <img className="h-12 w-12 rounded-sm" src={'https://ddppjbdexhxzj.cloudfront.net/avatars/CCC_Cover_UNSURE.svg'} alt="" />
      </div>
      <div className="flex-1 flex-col items-center">
        <p className="text-sm text-gray-500 truncate">Anonymous Viewer</p>
      </div>
      <div className='text-sm text-gray-500'> {viewerUser?.timeAgo || '2d' }</div>
    </div>
  </li>
}

const WhoViewedMyProfileDetailed = () => {
  const [currentUser] = useGlobalState('currentUser')
  const { getRequest } = useQuery()
  const [profileViewers, setProfileViewers] = useState([])

  // TODO need to paginate
  const loadViewers = () => {
    getRequest('/api/v3/dashboard/who_viewed_my_profile', {}, (err, jsonData) => {
      if (err) { /* hook */ }
      setProfileViewers(jsonData.profileViewers || [])
    })
  }

  useEffect(() => {
    if (currentUser.canViewProfileAnalytics) { loadViewers() }
  }, [])

  return <>
    <Card title={
      <span className='flex items-center'>
        <TrendingUpIcon className="h-6 w-6 mr-1" />
        Who Viewed My Profile
      </span>
      } light>
      <ul className="space-y-3">
        { profileViewers.map((pv) => (
          <>
            { pv.id === 'Anonymous' ? <ProfileViewerAnon viewerUser={pv} /> : <ProfileViewerUser key={`profileview${pv.id}`} viewerUser={pv} />
            }
          </>
        ))}
        { !currentUser.canViewProfileAnalytics && <>
          <ProfileViewerUserBlank />
          <ProfileViewerUserBlank />
          <ProfileViewerUserBlank />
          <ProfileViewerUserBlank />
          <ProfileViewerUserBlank />
          <ProfileViewerUserBlank />
          <ProfileViewerUserBlank />
          <ProfileViewerUserBlank />
          <ProfileViewerUserBlank />
        </> }
        { currentUser.canViewProfileAnalytics && profileViewers.length < 2 && <>
          <ProfileViewerAnon />
        </> }
      </ul>
    </Card>
  </>
}

export default WhoViewedMyProfileDetailed

ProfileViewerAnon.propTypes = {
  viewerUser: PropTypes.object.isRequired
}

ProfileViewerUser.propTypes = {
  viewerUser: PropTypes.object.isRequired
}
