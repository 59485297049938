import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { ThumbUpIcon, ChevronDoubleUpIcon } from '@heroicons/react/solid'

import useQuery from '@/hooks/useQuery'
import { useGlobalState } from '@/state'
import useModal from '@/hooks/useModalV2'
import Modal from '@/shared/Modal'
import PrimaryButton from '@/shared/Buttons/Primary'
import DefaultButton from '@/shared/Buttons/Default'
import UserHoverCard from '@/shared/UserHoverCard'

const AssignToRoleModal = ({ roleId, submissionId, userId, review }) => {
  const { openModal, closeModal, isOpen } = useModal()
  const [, setToast] = useGlobalState('toast')
  const { putpostRequest, getRequest } = useQuery()
  const [loading, setLoading] = useState(false)
  const [selectedRoleId, setSelectedRoleId] = useState(roleId)
  const [roles, setRoles] = useState([])
  const [roleUsers, setRoleUsers] = useState([])
  const [user, setUser] = useState(null)

  useEffect(() => { if (isOpen) { fetchData() } }, [isOpen])

  const changeRole = (e) => { setSelectedRoleId(e.target.value) }
  const assign = () => {
    const data = {
      user_id: userId,
      audition_id: submissionId,
      role_id: selectedRoleId
    }
    putpostRequest('/api/v3/role_users', 'POST', { role_user: data }, (err, jsonData) => {
      setLoading(false)
      if (err) { /* hook */ return }
      console.log(jsonData)

      setToast(<div className="ml-3 w-0 flex-1 pt-0.5">
        <p className="text-sm font-medium text-green-500">Role assigned!</p>
        <p className="mt-1 text-sm text-green-500">You can manage your assigned roles in the Manage Role tab</p>
      </div>)
      closeModal()
      review()
    })
  }

  const fetchData = () => {
    getRequest(`/api/v3/roles/${roleId}/role_users`, { submission_id: submissionId, user_id: userId }, (err, jsonData) => {
      if (err) { /* hooks */ return }

      setRoles(jsonData.roles)
      setRoleUsers(jsonData.roleUsers)
      setUser(jsonData.user)
    })
  }

  return <>
    <DefaultButton onClick={openModal} text={
      <div className='flex space-x-1 items-center text-green-500'>
        <ThumbUpIcon className='h-4 w-4' />
        <span className='flex items-center justify-center'><span className='whitespace-nowrap'>Assign to Role</span> <ChevronDoubleUpIcon className='h-4 w-4' /></span>
      </div>
    } />
    <Modal isOpen={isOpen} closeModal={closeModal} >
      <div className="flex flex-col text-center items-center">
        <h2 className="mb-8 text-xs font-semibold tracking-widest text-gray-800 dark:text-gray-200 uppercase title-font">Assign a role!</h2>
        <p className='flex justify-center items-center text-gray-900 dark:text-gray-100'>Does everything look right?</p>
      </div>
      { user && roles.length > 0 && <div className='my-3 grid grid-cols-2'>
        <div className='col-span-1'>
          <div className='flex flex-col text-gray-900 dark:text-gray-100 space-y-2'>
            <b>Talent:</b>
            <UserHoverCard username={user.username} displayName={user.displayName} />
          </div>
        </div>
        <div className='col-span-1'>
            <b>Role:</b>
          <select className="block w-full py-2 px-3 border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm" defaultValue={selectedRoleId} onChange={changeRole} >
            { roles.map((rl, idx) => (
              <option key={`rlu${user.id}rl${rl.id}`} value={rl.id}>{rl.name}</option>
            ))}
          </select>
        </div>
      </div> }
      <div className="rounded-md bg-blue-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <svg className="h-5 w-5 text-blue-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fillRule="evenodd" d="M19 10.5a8.5 8.5 0 11-17 0 8.5 8.5 0 0117 0zM8.25 9.75A.75.75 0 019 9h.253a1.75 1.75 0 011.709 2.13l-.46 2.066a.25.25 0 00.245.304H11a.75.75 0 010 1.5h-.253a1.75 1.75 0 01-1.709-2.13l.46-2.066a.25.25 0 00-.245-.304H9a.75.75 0 01-.75-.75zM10 7a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
            </svg>
          </div>
          <div className="ml-3 flex-1 md:flex md:justify-between">
            <p className="text-sm text-blue-700">This person will get an email and they will be invited into the Project DMs so you can speak directly with them.</p>
          </div>
        </div>
      </div>

      <div className="flex justify-end space-x-2 mt-4">
        <DefaultButton onClick={closeModal} text='Cancel' />
        <PrimaryButton onClick={assign} loading={loading} text='Do it' />
      </div>
    </Modal>
  </>
}

export default AssignToRoleModal

AssignToRoleModal.propTypes = {
  roleId: PropTypes.number.isRequired,
  submissionId: PropTypes.number.isRequired
}
