import React from 'react'
import { SearchCircleIcon } from '@heroicons/react/solid'

const NoResults = () => {
  return (
    <span>
      <button type="button" className="relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccblue" >
        <SearchCircleIcon className="mx-auto h-12 w-12 text-gray-400" />
        <span className="mt-2 block text-sm font-medium text-gray-900 dark:text-gray-300"> I looked everywhere and can't find it. By everywhere, I mean my database. My database *should* have everything but clearly whatever you're looking for isn't in there. Otherwise I'd list it here for you. Okay, I'm rambling at this point. I'll stop. </span>
      </button>
    </span>
  )
}

export default NoResults
