import React from 'react'
import PropTypes from 'prop-types'
import { ExclamationIcon } from '@heroicons/react/solid'
import { useGlobalState } from '@/state'

const LegacyNotice = ({ legacyPlan }) => {
  const [currentUser] = useGlobalState('currentUser')
  return <>
    <div className="rounded-md bg-yellow-50 p-4 my-10">
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-yellow-800">You have a subscription plan that does not exist anymore.</h3>
          <div className="mt-2 text-sm text-yellow-700">
             You can continue your subscription as is, but once it expires or if you choose a new plan below, you cannot go back.
            { currentUser.gold === 'gold' && <p className='mt-2'>Your plan is equivalent to the 'apprentice' plan in the new model</p> }
          </div>
        </div>
      </div>
    </div>
  </>
}
export default LegacyNotice

LegacyNotice.propTypes = {
  legacyPlan: PropTypes.object.isRequired
}
