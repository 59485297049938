import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { FilmIcon } from '@heroicons/react/solid'

import UserHoverCard from '@/shared/UserHoverCard'

const FeedCreditRow = ({ feed }) => {
  return <li className='bg-white dark:bg-gray-700 rounded-lg border border-gray-200 dark:border-gray-700'>
    <div className="p-1">
      <div className='flex justify-between'>
        <div className="relative flex items-start space-x-3 overflow-hidden">
          <div className="relative m-1">
            <img className="h-16 w-16 rounded-sm bg-gray-200 flex items-center justify-center ring-4 ring-cccorange" src={feed.publicImageUrl} alt="" />

            <span className="absolute -bottom-0.5 -right-1 bg-cccorange rounded-tl px-0.5 py-px">
              <FilmIcon className="h-4 w-4 text-white" aria-hidden="true" />
            </span>
          </div>
          <div className="min-w-0 flex-1">
            <div className='flex flex-col sm:flex-row'>
              <UserHoverCard username={feed.username} displayName={feed.displayName} />
              <p className="mt-0.5 text-sm text-gray-500 sm:ml-1">was credited in </p>
            </div>
            <Link to={`/credits/${feed.objectId}`} className="font-medium text-cccblue cursor-pointer truncate">
              {feed.name}
            </Link>
          </div>
        </div>
        <div className="mt-0.5 text-sm text-gray-500 ml-1">{feed.timeAgo} </div>
      </div>
    </div>
  </li>
}

export default FeedCreditRow

FeedCreditRow.propTypes = {
  feed: PropTypes.object.isRequired
}
