import React, { useState } from 'react'
import PropTypes from 'prop-types'
import useQuery from '@/hooks/useQuery'
import SVGIcon from '@/components/icon'

const SaveForLaterButton = ({ currentUserId, currentlyFavorited, projectId, roleId }) => {
  const [favorited, setFavorited] = useState(currentlyFavorited)
  const { putpostRequest } = useQuery()

  const toggleFavorite = () => {
    setFavorited(!favorited)
    if (projectId) {
      putpostRequest(`/api/v3/favorites/${projectId}/favorite_project`, 'POST', {}, (err, jsonData) => {
        if (err) { return console.log(err) }
        // noop
      })
    } else if (roleId) {
      putpostRequest(`/api/v3/favorites/${roleId}/favorite_role`, 'POST', {}, (err, jsonData) => {
        if (err) { return console.log(err) }
        // noop
      })
    }
  }

  if (favorited) {
   return <button onClick={toggleFavorite} className="w-full h-full gap-x-1 flex items-center justify-center px-4 py-3 border text-sm text-cccblue font-medium rounded-md shadow-sm bg-cccpurple bg-opacity-20">
      <SVGIcon name='icon-favorited' className='h-6 w-6' />
      <span>Saved!</span>
    </button>
  }

  return <button onClick={toggleFavorite} className="w-full h-full gap-x-1 flex items-center justify-center px-4 py-3 border text-sm text-cccblue font-medium rounded-md shadow-sm bg-white dark:bg-gray-900">
      <SVGIcon name='icon-favorites' className='h-6 w-6' />
      <span>Save for Later</span>
    </button>
}

SaveForLaterButton.propTypes = {
  currentUserId: PropTypes.number.isRequired,
  currentlyFavorited: PropTypes.bool.isRequired,
  projectId: PropTypes.number,
  roleId: PropTypes.number
}

export default SaveForLaterButton
