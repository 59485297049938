import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { ChatAltIcon, FilmIcon, BookmarkIcon } from '@heroicons/react/solid'
import useQuery from '@/hooks/useQuery'

import Loading from '@/shared/Loading'
import UserHoverCard from '@/shared/UserHoverCard'
import CommentForm from '@/shared/CommentForm'
import CommentRow from '@/shared/CommentRow'

const FeedProjectRow = ({ feed, favoriteIds }) => {
  const [comments, setComments] = useState([])
  const [favorited, setFavorited] = useState(favoriteIds.indexOf(feed.objectId) >= 0)
  const [loadingComments, setLoadingComments] = useState(false)
  const [showComments, setShowComments] = useState(false)
  const { putpostRequest, getRequest } = useQuery()

  const loadComments = () => {
    setShowComments(!showComments)
    setLoadingComments(true)
    getRequest(`/api/v3/projects/${feed.objectId}/comments`, {}, (err, jsonData) => {
      setLoadingComments(false)
      if (err) { /* hook */ }
      setComments(jsonData.comments)
    })
  }

  const addChildtoParent = (childComment) => {
    setComments([childComment, ...comments])
  }

  const toggleFavorite = () => {
    putpostRequest(`/api/v3/favorites/${feed.objectId}/favorite_project`, 'POST', {}, (err, jsonData) => {
      if (err) { /* hook */ }
      setFavorited(!favorited)
    })
  }

  return <li className='bg-white dark:bg-gray-700 rounded-lg border border-gray-200 dark:border-gray-700'>
    <div className="p-1">
      <div className='flex justify-between'>
        <div className="relative flex items-start space-x-3 overflow-hidden">
          <div className="relative m-1">
            <img className="h-16 w-16 rounded-sm bg-gray-200 flex items-center justify-center ring-4 ring-cccblue" src={feed.publicImageUrl} alt="" />

            <span className="absolute -bottom-0.5 -right-1 bg-cccblue rounded-tl px-0.5 py-px">
              <FilmIcon className="h-4 w-4 text-white" aria-hidden="true" />
            </span>
          </div>
          <div className="min-w-0 flex-1">
            <div className='flex flex-col sm:flex-row'>
              <UserHoverCard username={feed.username} displayName={feed.displayName} />
              <p className="mt-0.5 text-sm text-gray-500 sm:ml-1">published a project </p>
            </div>
            <a href={`/projects/${feed.slug}`} className="font-medium text-cccblue cursor-pointer truncate">
              {feed.name}
            </a>
            <div className="mt-1 text-sm text-gray-600 dark:text-gray-400 flex">
              <span className="uppercase text-xs dark:text-gray-300 text-gray-500 mr-5">
                Deadline: {feed.deadlin}
              </span>
              <span className="uppercase text-xs dark:text-gray-300 text-gray-500 mr-5">
                Roles: {feed.rolesAvailableCount}/{feed.rolesCount}
              </span>
            </div>
            <div className="mt-1 text-sm text-gray-600 dark:text-gray-400 truncate">
              {feed.description}
            </div>
          </div>
        </div>
        <div className="mt-0.5 text-sm text-gray-500 ml-1">{feed.timeAgo} </div>
      </div>
      <div className="mt-2 flex space-x-8">
        <div className="flex space-x-6">
          <button onClick={loadComments} aria-label='Load Comments' type="button" className="flex space-x-1 text-gray-400 hover:text-gray-500 dark:text-gray-400 dark:hover:text-gray-300 items-center">
            <ChatAltIcon className="h-5 w-5" aria-hidden="true" />
            <span className="text-xs hidden sm:inline">
              Add Comment
              {feed.commentCount > 0 && <>({feed.commentCount})</> }
            </span>
          </button>
        </div>
        <div className="flex space-x-6">
          <button onClick={toggleFavorite} type="button" aria-label='Save In Favorites' className={`flex space-x-1 ${favorited ? 'text-green-500' : 'text-gray-400 hover:text-gray-500 dark:text-gray-400'} dark:hover:text-gray-300 items-center`}>
            <BookmarkIcon className="h-5 w-5" aria-hidden="true" />
            { favorited && <span className="text-xs hidden sm:inline">Saved!</span> }
            { !favorited && <span className="text-xs hidden sm:inline">Save in Favorites</span> }
          </button>
        </div>
      </div>
      { showComments && <div className='overflow-hidden'>
        <CommentForm commentableId={feed.objectId} commentableType='Project' addChildtoParent={addChildtoParent} setHide={setShowComments} />
        {comments.length > 0 && <>
          <div className="space-y-3 mt-3">
            {comments.map((com, idx) => (
              <CommentRow key={`com${com.id}`} initialComment={com} />
            ))}
          </div>
        </> }
      </div> }
      {loadingComments && <Loading /> }
    </div>
  </li>
}

const FeedRowProjectUpdateRow = ({ feed }) => {
  const [comments, setComments] = useState([])
  const [loadingComments, setLoadingComments] = useState(false)
  const [showComments, setShowComments] = useState(false)
  const { getRequest } = useQuery()

  const loadComments = () => {
    setShowComments(!showComments)
    setLoadingComments(true)
    getRequest(`/api/v3/projects/${feed.projectId}/comments`, {}, (err, jsonData) => {
      setLoadingComments(false)
      if (err) { /* hook */ }
      setComments(jsonData.comments)
    })
  }

  const addChildtoParent = (childComment) => {
    setComments([childComment, ...comments])
  }

  return <li className='bg-white dark:bg-gray-700 rounded-lg border border-gray-200 dark:border-gray-700'>
      <div className="p-1">
        <div className='flex justify-between'>
          <div className="flex items-start space-x-3 overflow-hidden">
            <div className="relative m-1">
              <img className="h-10 w-10 rounded-sm bg-gray-200 flex items-center justify-center ring-4 ring-cccblue" src={feed.publicImageUrl} alt="" />

              <span className="absolute -bottom-0.5 -right-1 bg-cccblue rounded-tl px-0.5 py-px">
                <FilmIcon className="h-4 w-4 text-white" aria-hidden="true" />
              </span>
            </div>
            <div className="min-w-0 flex-1">
              <div className='flex flex-col sm:flex-row'>
                <UserHoverCard username={feed.username} displayName={feed.displayName} />
                <p className="mt-0.5 text-sm text-gray-500 sm:ml-1">updated their project</p>
              </div>
              <a href={`/projects/${feed.slug}`} className="sm:ml-1 font-medium text-cccblue cursor-pointer">
                {feed.name}
              </a>
              <div className="mt-1 text-sm text-gray-600 dark:text-gray-400 truncate">
                {feed.description}
              </div>
            </div>
          </div>
          <div className="mt-0.5 text-sm text-gray-500 ml-1">
            {feed.timeAgo}
          </div>
        </div>
        <div className="mt-2 flex space-x-8">
          <div className="flex space-x-6">
            <button onClick={loadComments} aria-label='Load Comments' type="button" className="flex space-x-1 text-gray-400 hover:text-gray-500 dark:text-gray-400 dark:hover:text-gray-300 items-center">
              <ChatAltIcon className="h-5 w-5" aria-hidden="true" />
              <span className="text-xs hidden sm:inline">
                Add Comment
                {feed.commentCount > 0 && <>({feed.commentCount})</> }
              </span>
            </button>
          </div>
        </div>
        { showComments && <div className='overflow-hidden'>
          <CommentForm commentableId={feed.projectId} commentableType='Project' addChildtoParent={addChildtoParent} setHide={setShowComments} />
          {comments.length > 0 && <>
            <div className="space-y-3 mt-3">
              {comments.map((com, idx) => (
                <CommentRow key={`com${com.id}`} initialComment={com} />
              ))}
            </div>
          </> }
        </div> }
        {loadingComments && <Loading /> }
      </div>
    </li>
}

export { FeedRowProjectUpdateRow, FeedProjectRow }

FeedProjectRow.propTypes = {
  feed: PropTypes.object.isRequired,
  favoriteIds: PropTypes.array.isRequired
}

FeedRowProjectUpdateRow.propTypes = {
  feed: PropTypes.object.isRequired
}
