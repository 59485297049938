import React, { useState } from 'react'
import { debounce } from 'throttle-debounce'
import { ExclamationCircleIcon } from '@heroicons/react/solid'

import { useForm } from 'react-hook-form'
import useQuery from '@/hooks/useQuery'
import { useGlobalState } from '@/state'
import PrimaryButton from '@/shared/Buttons/Primary'

import Card from '@/shared/Card'

const PasswordSettings = () => {
  const { putpostRequest } = useQuery()
  const [, setToast] = useGlobalState('toast')
  const [loading, setLoading] = useState(false)
  const [customError, setCustomError] = useState(null)
  const [funny, setFunny] = useState(false)
  const { formState, register, reset, handleSubmit, setError } = useForm({ defaultValues: { } })

  const { isDirty, errors } = formState

  const keyPress = (e) => {
    if (e.target.name === 'name' && e.keyCode === 13) { e.preventDefault() }
    if ((e.metaKey || e.ctrlKey) && e.keyCode === 13) {
      if (typeof (e.target.name) === 'undefined') {
        e.target.blur() // froala hack to make it update model so it can save properly
      }
      handleSubmit(onSubmit)()
    }
  }

  const onSubmit = debounce(300, (data) => {
    setLoading(true)
    setCustomError(null)

    putpostRequest('/api/v3/users/change_password', 'POST', data, (err, _) => {
      setLoading(false)
      if (err) { // 422 code
        if (typeof err !== 'string') { // 500 code on API
          Object.entries(err).forEach(([key, value]) => {
            setError(key, value)
          })
        }
        if (typeof err === 'string') { setCustomError(err) }
        return
      }

      reset()
      setToast(<div className="ml-3 w-0 flex-1 pt-0.5">
        <p className="text-sm font-medium dark:text-green-300 text-green-600">Password Changed</p>
        <p className="mt-1 text-sm dark:text-green-300 text-green-400">Security at its finest.</p>
      </div>)
    })
  })

  const sendResetInstructions = () => {
    putpostRequest('/api/v3/users/send_password_recovery', 'POST', {}, (err, _) => {
      setLoading(false)
      if (err) { /* 422 code */ return }
      setToast(<div className="ml-3 w-0 flex-1 pt-0.5">
        <p className="text-sm font-medium dark:text-green-300 text-green-600">Check Your Email</p>
        <p className="mt-1 text-sm dark:text-green-300 text-green-400">There is a special message waiting for you.....about recovering your password.</p>
      </div>)
    })
  }

  return <>
    <div className="md:col-span-1 mb-3">
      <div className="px-0 sm:px-4">
        <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-100">Password</h3>
        <p className="mt-1 text-sm text-gray-600 dark:text-gray-300">
          Even if you login with google or another service, you must still have a password set.
        </p>
        <div className="mt-1 text-sm text-gray-600 dark:text-gray-300">
          Your current password is:
          { !funny && <div className='cursor-pointer text-cccblue' onClick={() => setFunny(true)}>reveal password</div> }
          { funny && <div><i>I have no idea. It's encrypted.</i></div> }
        </div>
      </div>
    </div>
    <Card title='Password' footer={
      <div className='flex flex-rows justify-end items-end'>
        <div className='flex flex-col items-end w-min ml-2'>
          <div className='flex flex-col items-end w-min ml-2'>
            <PrimaryButton className='w-full' onClick={handleSubmit(onSubmit)} disabled={!isDirty} loading={loading} text={<div className='flex flex-col'>
              Save
              { isDirty && <div className="hidden sm:block w-min text-xs px-2 font-sans text-gray-50">⌘/ctrl+ENTER</div> }
            </div>} />
          </div>
        </div>
      </div>
      } >
        <form>
          <div className="grid sm:grid-cols-4 gap-6">
            <div className="col-span-4 sm:col-span-2">
              <label htmlFor="old_password" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">
                <span className='flex items-center'>Current Password <div className='ml-2 cursor-pointer text-xs text-cccblue' onClick={sendResetInstructions} aria-label='Forgot Password'>Forgot Password</div></span>
              </label>
              <div className="mt-1 flex flex-col rounded-md shadow-sm">
                <div className='relative'>
                  <input onKeyDown={keyPress} type="password" className={errors.old_password ? 'errors' : ''} {...register('old_password', { required: true }) } />
                  { errors.old_password && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                  </div> }
                </div>
              </div>
              { errors.old_password && <div className='mt-2 text-sm text-red-600'>This field is required</div> }
              { customError && <div className='mt-2 text-sm text-red-600'>{customError}</div> }
            </div>
            <div className="col-span-4" />
            <div className="col-span-4 sm:col-span-2">
              <label htmlFor="new_password" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">New Password </label>
              <div className="mt-1 flex flex-col rounded-md shadow-sm">
                <div className='relative'>
                  <input onKeyDown={keyPress} type="password" className={errors.new_password ? 'errors' : ''} {...register('new_password', { required: true }) } />
                  { errors.new_password && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                  </div> }
                </div>
              </div>
              { errors.new_password && <div className='mt-2 text-sm text-red-600'>This field is required</div> }
            </div>
            <div className="col-span-4 sm:col-span-2">
              <label htmlFor="new_password2" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">Literally just the New Password again </label>
              <div className="mt-1 flex flex-col rounded-md shadow-sm">
                <div className='relative'>
                  <input onKeyDown={keyPress} type="password" className={errors.new_password2 ? 'errors' : ''} {...register('new_password2', { required: true }) } />
                  { errors.new_password2 && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                  </div> }
                </div>
              </div>
              { errors.new_password2 && <div className='mt-2 text-sm text-red-600'>This field is required</div> }
            </div>
          </div>
        </form>
    </Card>
  </>
}
export default PasswordSettings
