import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import SVGIcon from '@/components/icon'
import useModal from '@/hooks/useModalV2'
import Loading from '@/shared/Loading'
import useQuery from '@/hooks/useQuery'
import Modal from '@/shared/Modal'

import RoleEdit from '@/pages/ManageProjects/ProjectEdit/RoleEdit'
import RoleRow from '@/pages/ManageProjects/ProjectEdit/RoleRow'

const talentKindHash = {
  'voice_actor': { image: 'voiceactor', text: 'Voice Actor', to_db: 'voice_actor' },
  'live_actor': { image: 'actor', text: 'Actor', to_db: 'live_actor' },
  'singer': { image: 'singer', text: 'Singer', to_db: 'singer' },
  'artist': { image: 'artist', text: 'Artist', to_db: 'artist' },
  'animator': { image: 'animator', text: 'Animator', to_db: 'animator' },
  'video_editor': { image: 'video-editor', text: 'Video Editor', to_db: 'video_editor' },
  'music_composer': { image: 'musiccomposer', text: 'Musician', to_db: 'music_composer' },
  'audio_engineer': { image: 'audio-engineer', text: 'Audio Engineer', to_db: 'audio_engineer' },
  'writer': { image: 'writer', text: 'Writer', to_db: 'writer' },
  'director': { image: 'director', text: 'Director', to_db: 'director' },
  'agent': { image: 'agent', text: 'Agent', to_db: 'agent' },
  'producer': { image: 'producer', text: 'Producer', to_db: 'producer' }
}

const RoleIndex = ({ initialRole, project }) => {
  const { openModal, closeModal, isOpen, willClose } = useModal()
  const { getRequest, putpostRequest } = useQuery()
  const [loading, setLoading] = useState(false)
  const [positionChanging, setPositionChanging] = useState(null)
  const [newRoleKind, setNewRoleKind] = useState(null)
  const [roles, setRoles] = useState([])

  useEffect(() => {
    if (!willClose) { return }

    fetchData()
  }, [willClose])

  const reorder = (newIdx) => {
    if (!positionChanging) { return }
    console.log(newIdx)

    putpostRequest(`/api/v3/manage/projects/${project.id}/reorder`, 'POST', { role_id: positionChanging, new_idx: newIdx }, (err, jsonData) => {
      if (err) { console.log(err); return }
      fetchData()
    })

    setPositionChanging(null)
  }

  const fetchData = () => {
    setLoading(true)
    setNewRoleKind(null)
    getRequest(`/api/v3/manage/projects/${project.id}/roles`, {}, (err, jsonData) => {
      if (err) { /* handled in hook */ return }
      setLoading(false)
      setRoles(jsonData.roles)
    })
  }

  const createNewRole = (roleKind) => { setNewRoleKind(roleKind) }

  useEffect(() => { if (newRoleKind !== null) { openModal() } }, [newRoleKind])

  useEffect(() => { fetchData() }, [])

  if (loading) {
    return (
      <section aria-labelledby="loading" className='p-5'>
        <button type="button" className="relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" >
          <svg xmlns="http://www.w3.org/2000/svg" className="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19.428 15.428a2 2 0 00-1.022-.547l-2.387-.477a6 6 0 00-3.86.517l-.318.158a6 6 0 01-3.86.517L6.05 15.21a2 2 0 00-1.806.547M8 4h8l-1 1v5.172a2 2 0 00.586 1.414l5 5c1.26 1.26.367 3.414-1.415 3.414H4.828c-1.782 0-2.674-2.154-1.414-3.414l5-5A2 2 0 009 10.172V5L8 4z" />
          </svg>
          <span className="mt-2 block text-sm font-medium text-gray-900 dark:text-gray-300">
            Roles
            <Loading />
          </span>
        </button>
      </section>
    )
  }

  return <>
    <Modal full={true} isOpen={isOpen} closeModal={closeModal} >
      <RoleEdit initialRole={{}} projectId={project.id} roleKind={newRoleKind} closeModal={closeModal} />
    </Modal>
    <div className='bg-white dark:bg-gray-900 rounded-md p-2 sm:mx-15'>
      <h4 className='text-lg text-center dark:text-gray-100'>What type of talent are you looking for?</h4>
      <ul className="grid grid-cols-2 gap-1 sm:gap-2 sm:grid-cols-3 lg:grid-cols-6">
        { Object.keys(talentKindHash).map((key, idx) => (
          <li key={`role${key}`} className="col-span-1 flex shadow-sm rounded-md">
            <button onClick={() => createNewRole(talentKindHash[key].text)} className='dark:text-gray-200 dark:bg-gray-700 bg-gray-100 hover:bg-gray-200 dark:hover:bg-gray-750 w-full flex space-x-1 items-center px-1 py-2 text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccpurple'>
              <SVGIcon name={talentKindHash[key].image} className='h-14 w-14' />
              <span className='text-xs'> {talentKindHash[key].text}</span>
            </button>
          </li>
        ))}
      </ul>
    </div>
    { roles.length > 0 && <>
      <ul className="mt-3 space-y-3 sm:mx-20">
        { roles.map((role, idx) => (
          <RoleRow
            key={`role${role.id}`}
            idx={idx}
            setPositionChanging={() => setPositionChanging(role.id)}
            reorder={() => reorder(idx)}
            positionChanging={positionChanging}
            initialRole={role}
            fetchData={fetchData}
          />
        ))}
      </ul>
    </> }
  </>
}

export default RoleIndex

RoleIndex.propTypes = {
  project: PropTypes.object.isRequired
}
