import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { EditorContent, useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import Placeholder from '@tiptap/extension-placeholder'
import Link from '@tiptap/extension-link'
import Underline from '@tiptap/extension-underline'

const TipTapPrivatePost = ({ html, updateHtml, idx }) => {
  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Placeholder.configure({ placeholder: 'Start typing here...' }),
      Link.configure({ openOnClick: false })
    ],
    onUpdate({ editor }) {
      updateHtml(editor.getHTML())
    },
    editorProps: {
      handleDOMEvents: {
        keydown: (view, event) => {
          if ((event.metaKey || event.ctrlKey) && event.key === 'Enter') {
            event.preventDefault()
            return false
          }
        }
      },
      attributes: {
        class: 'p-2 text-sm focus:outline-none dark:text-gray-100'
      }
    },
    content: html
  })

  useEffect(() => {
    if (idx > 1) { editor?.commands.clearContent(true) }
  }, [idx])

  return (
    <div className="bg-white dark:bg-gray-900 border border-gray-200 rounded-md">
      <EditorContent editor={editor} />
    </div>
  )
}

export default TipTapPrivatePost

TipTapPrivatePost.propTypes = {
  html: PropTypes.string,
  updateHtml: PropTypes.func.isRequired,
  idx: PropTypes.number
}
