import React, { useEffect, useRef } from 'react'
import useQuery from '@/hooks/useQuery'
import { useGlobalState } from '@/state'

import ProfileGeneralBasic from '@/pages/Users/ProfileGeneralBasic'
import ProfileSocialMedia from '@/pages/Users/ProfileSocialMedia'
import Loading from '@/shared/Loading'

const AboutYou = () => {
  const isComponentMounted = useRef(false)
  const { getRequest } = useQuery()

  const [, setUser] = useGlobalState('userContainerUser')
  const [currentUser] = useGlobalState('currentUser')

  useEffect(() => {
    getRequest(`/api/v3/users/${currentUser.id}/edit`, {}, (err, jsonData) => {
      if (err) { /* handled in hook */ return }
      setUser(jsonData.user)
    })
    isComponentMounted.current = true
  }, [])

  if (!isComponentMounted.current) {
    return <Loading />
  }

  return <>
    <div className='mt-10 flex flex-col justify-center items-center space-y-5 text-gray-900 dark:text-gray-100 bg-cccpurple bg-opacity-20 p-2 rounded-md'>
      <b>About You: Optional</b>
      <ProfileGeneralBasic />
      <ProfileSocialMedia />
    </div>
  </>
}

export default AboutYou
