import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'

import SearchIndex from '@/pages/TalentSearch/SearchIndex'

const LoggedOutTalentSearchIndex = (props) => {
  return <>
    <BrowserRouter>
      <Switch>
        <Route {...props} exact path={['/talent', '/talent/:talent_type']}>
          <SearchIndex {...props} />
        </Route>
      </Switch>
    </BrowserRouter>
  </>
}

export default LoggedOutTalentSearchIndex

LoggedOutTalentSearchIndex.propTypes = { }
