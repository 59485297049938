import React, { useEffect } from 'react'
import { EditorContent, useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import Placeholder from '@tiptap/extension-placeholder'
import Link from '@tiptap/extension-link'
import Underline from '@tiptap/extension-underline'
import { ViewListIcon } from '@heroicons/react/solid'

const MenuBar = ({ editor }) => {
  if (!editor) {
    return null
  }

  return (
    <div className='w-full flex flex-wrap border-b border-gray-200 text-sm text-gray-600 dark:text-gray-300'>
      <button tabIndex='-1' onClick={() => editor.chain().focus().toggleBold().run()} className={editor.isActive('bold') ? 'outline-none focus:outline-none border-r border-gray-200 w-8 h-8 text-cccblue' : 'outline-none focus:outline-none border-r border-gray-200 w-8 h-8 hover:text-cccblue'} > B </button>
      <button tabIndex='-1' onClick={() => editor.chain().focus().toggleItalic().run()} className={editor.isActive('italic') ? 'outline-none focus:outline-none border-r border-gray-200 w-8 h-8 text-cccblue' : 'outline-none focus:outline-none border-r border-gray-200 w-8 h-8 hover:text-cccblue'} >I </button>
      <button tabIndex='-1' onClick={() => editor.chain().focus().toggleUnderline().run()} className={editor.isActive('underline') ? 'outline-none focus:outline-none border-r border-gray-200 w-8 h-8 text-cccblue' : 'outline-none focus:outline-none border-r border-gray-200 w-8 h-8 hover:text-cccblue'} > U </button>
      <button tabIndex='-1' onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()} className={editor.isActive('heading', { level: 1 }) ? 'outline-none focus:outline-none border-r border-gray-200 w-8 h-8 text-cccblue' : 'outline-none focus:outline-none border-r border-gray-200 w-8 h-8 hover:text-cccblue'} > h1 </button>
      <button tabIndex='-1' onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()} className={editor.isActive('heading', { level: 2 }) ? 'outline-none focus:outline-none border-r border-gray-200 w-8 h-8 text-cccblue' : 'outline-none focus:outline-none border-r border-gray-200 w-8 h-8 hover:text-cccblue'} > h2 </button>
      <button tabIndex='-1' onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()} className={editor.isActive('heading', { level: 3 }) ? 'outline-none focus:outline-none border-r border-gray-200 w-8 h-8 text-cccblue' : 'outline-none focus:outline-none border-r border-gray-200 w-8 h-8 hover:text-cccblue'} > h3 </button>
      <button tabIndex='-1' onClick={() => editor.chain().focus().toggleBulletList().run()} className={editor.isActive('bulletList') ? 'outline-none focus:outline-none border-r border-gray-200 w-8 h-8 text-cccblue' : 'outline-none focus:outline-none border-r border-gray-200 w-8 h-8 hover:text-cccblue'} > <ViewListIcon className='h-5 w-5 mx-auto' /> </button>
      <button tabIndex='-1' onClick={() => editor.chain().focus().toggleCodeBlock().run()} className={editor.isActive('codeBlock') ? 'outline-none focus:outline-none border-r border-gray-200 w-8 h-8 text-cccblue' : 'outline-none focus:outline-none border-r border-gray-200 w-8 h-8 hover:text-cccblue'} > {'<>'} </button>
      <button tabIndex='-1' onClick={() => editor.chain().focus().setHorizontalRule().run()} className='outline-none focus:outline-none border-r border-gray-200 w-8 h-8 hover:text-cccblue'> -- </button>
      <button tabIndex='-1' onClick={() => editor.chain().focus().toggleStrike().run()} className={editor.isActive('strike') ? 'outline-none focus:outline-none border-r border-gray-200 w-8 h-8 text-cccblue' : 'outline-none focus:outline-none border-r border-gray-200 w-8 h-8 hover:text-cccblue'} > S̶ </button>
      <button tabIndex='-1' onClick={() => editor.chain().focus().unsetAllMarks().run()} className='outline-none focus:outline-none border-r border-gray-200 w-8 h-8 hover:text-cccblue'> T̷ </button>
    </div>
  )
}

const TipTapWysiwyg = ({ html, updateHtml, idx, placeholder }) => {
  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link.configure({ openOnClick: false }),
      Placeholder.configure({ placeholder: placeholder })
    ],
    onCreate({ editor }) {
      // editor.chain().focus()
    },
    onUpdate({ editor }) {
      updateHtml(editor.getHTML())
    },
    editorProps: {
      attributes: {
        class:
        'wysiwyg prose prose-sm m-1 sm:m-3 focus:outline-none dark:text-gray-100' //prose needed for styling to work
      }
    },
    content: html
  })

  useEffect(() => {
    if (idx > 1) { editor?.commands.clearContent(true) }
  }, [idx])

  return (
    <div className="bg-white dark:bg-gray-900 border border-gray-200 rounded-md">
      <MenuBar editor={editor} />
      <EditorContent editor={editor} />
    </div>
  )
}

export default TipTapWysiwyg
