import React, { useState, Fragment, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useDebouncedEffect } from '@/utils/useDebouncedEffect'
import PublicDemoRow from '@/pages/Users/PublicProfile/PublicDemoRow'

import useQuery from '@/hooks/useQuery'
import Loading from '@/shared/Loading'
import PlayPauseButton from '@/shared/Buttons/PlayPauseButton'
import Pagination from '@/shared/Pagination'
import DefaultButton from '@/shared/Buttons/Default'
import { useGlobalState } from '@/state'

import useModal from '@/hooks/useModalV2'
import Modal from '@/shared/Modal'
import { TippyTooltip } from '@/shared/ToolTip'

import NoSubmissions from '@/pages/Users/ProfileSubmissions/NoSubmissions'
import NoResults from '@/pages/Users/ProfileSubmissions/NoResults'
import NoShowcases from '@/pages/Users/ProfileSubmissions/NoShowcases'
import Permission from '@/pages/Submissions/Permission'

import { LinkIcon, XCircleIcon, FilmIcon } from '@heroicons/react/solid'

import Card from '@/shared/Card'

const ShowUpgradePanel = () => {
  return <div className="bg-white shadow sm:rounded-lg">
    <div className="px-4 py-5 sm:p-6">
      <h3 className="text-lg leading-6 font-medium text-gray-900">Cannot Add Showcase</h3>
      <div className="mt-2">
        <div className="text-sm text-gray-500">
          <p className='mb-2'>I'm so sorry to tell you this, but this is a paid feature.</p>
          <p>Paying for CCC really helps us source paid work for you, and pay our moderators. With a paid plan, you can showcase an

          <TippyTooltip content={<>Unlimited Power!</>}>
            <i className='mx-1 underline'>unlimited</i>
          </TippyTooltip>
          number of submissions.</p>
          <Link to='/membership' className="mt-2 inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-cccblue hover:bg-cccblue-alt sm:text-sm focus:outline-none" >
            View Plans <span aria-hidden="true">&rarr;</span>
          </Link>
        </div>
      </div>
    </div>
  </div>
}

const ShowcaseRow = ({ submission, refresh, showcased }) => {
  const { putpostRequest } = useQuery()
  const [currentUser] = useGlobalState('currentUser')
  const { openModal, closeModal, isOpen } = useModal()
  const canAdd = currentUser.canShowcase

  const addShowcase = () => {
    const data = { audition_id: submission.id }
    putpostRequest('/api/v3/showcases', 'POST', { showcase: data }, (err, jsonData) => {
      if (err) {
        console.log(err)
      }
      refresh()
    })
  }
  const deleteShowcase = () => {
    putpostRequest(`/api/v3/showcases/${submission.id}`, 'DELETE', {}, (err, jsonData) => {
      if (err) { /* hook */ }
      refresh()
    })
  }

  return <li className='bg-white dark:bg-gray-700 px-4 py-4 sm:px-6  rounded-lg shadow-sm'>
    <div className="sm:flex sm:justify-between sm:items-center">
      <div className="flex-shrink-0 text-gray-500 dark:text-gray-100 hidden sm:block">
        <img className='h-16 w-16 rounded-full' src={submission.roleImageUrl} alt={submission.roleName} />
      </div>
      <div className="flex-1 px-0 md:px-4 md:py-0 pb-2 w-full truncate">
        <div className='flex flex-col truncate'>
          <span className="uppercase text-xs dark:text-gray-300 text-gray-500 mr-5">{submission.createdAt}</span>
          <span className="text-sm font-medium text-gray-900 dark:text-gray-200">{submission.projectName}</span>
          <a href={`/projects/${submission.projectSlug}`} className="font-medium text-cccblue">{submission.roleName}</a>
          { submission.remoteLink && <div className='inline-flex justify-start w-full min-w-3 min-h-3'>
            <a href={submission.remoteLink} target='_blank' rel="noreferrer" className="inline-flex items-center py-2 shadow-sm text-sm font-medium text-gray-700 text-cccblue hover:text-cccblue-alt">
              <LinkIcon className="mr-1 h-5 w-5" aria-hidden="true" />
              {submission.remoteLink}
            </a>
          </div> }
        </div>
      </div>
      <div className="flex-shrink-0 text-gray-500 dark:text-gray-100 mr-5 mb-2 w-12 max-w-12">
        { submission.audioUrl && <PlayPauseButton
          mediaUrl={submission.audioUrl}
          objectId={submission.id}
          objectKind='Audition' />
        }
      </div>
      { !showcased && !canAdd && <DefaultButton onClick={openModal} text="Showcase" /> }
      { !showcased && canAdd && <DefaultButton onClick={addShowcase} text="Showcase" /> }
      { showcased && <DefaultButton onClick={deleteShowcase} text="Remove" /> }
    </div>
    <Modal isOpen={isOpen} closeModal={closeModal} >
      <div className='relative mb-1'>
        <span className="sr-only" hidden>Close Modal</span>
        <XCircleIcon onClick={closeModal} className='cursor-pointer w-7 h-7 absolute -top-5 -right-5 text-gray-500 dark:text-gray-400' />
      </div>
      <ShowUpgradePanel />
    </Modal>
  </li>
}

const Showcases = () => {
  const [submissions, setSubmissions] = useState([])
  const [showcases, setShowcases] = useState([])
  const { getRequest } = useQuery()
  const [loading, setLoading] = useState(true)
  const [contains, setContains] = useState('')

  useDebouncedEffect(() => { if (contains.length > 0) { fetchData() } }, 200, [contains])
  useEffect(() => { fetchShowcases() }, [])

  const fetchShowcases = () => {
    setLoading(true)
    setSubmissions([])
    setContains('')
    getRequest('/api/v3/showcases', {}, (err, jsonData) => {
      setLoading(false)
      if (err) { /* hooks */ return }
      setShowcases(jsonData.showcases)
    })
  }

  const fetchData = () => {
    setLoading(true)
    const data = {
      contains: contains,
      page: 1
    }
    getRequest('/api/v3/auditions/profile_auditions', data, (err, jsonData) => {
      setLoading(false)
      if (err) { /* hooks */ return }
      setSubmissions(jsonData.submissions)
    })
  }

  return <>
    <div>
      <div className="md:grid md:grid-cols-4 md:gap-6 space-x-2">
        <div className="md:col-span-1">
          <div className="px-4 sm:px-0">
            <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-100">Showcase</h3>
            <p className="mt-1 text-sm text-gray-600 dark:text-gray-300">
              Select top submissions you want to appear at the top of your submission list
            </p>
          </div>
        </div>
        <Card>
          <div className="grid grid-cols-3 gap-6">
            <div className='col-span-3'>
              <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-100">Current Showcase</h3>
              { showcases.length === 0 && <NoShowcases /> }
              <ul className="space-y-3 pb-5">
                { showcases.map((sub, idx) => (
                  <ShowcaseRow key={`sub${sub.id}`} submission={sub} refresh={fetchShowcases} showcased={true} />
                ))}
              </ul>
              <div className="sm:rounded-md">
                <div className='pb-5'>
                  <input name='contains' type="text" onChange={(e) => setContains(e.target.value)}
                    className="shadow-sm focus:ring-cccpurple dark:bg-gray-900 dark:text-white focus:border-cccpurple block w-full sm:text-sm border-gray-300 rounded-md mb-2"
                    placeholder="Search by project name or role name. ex: Whinecraft"
                  />
                  { loading && <Loading /> }
                </div>
                { submissions.length === 0 && !loading && contains.length > 0 && <NoResults /> }
              </div>
              <ul className="space-y-3">
                { submissions.map((sub, idx) => (
                  <ShowcaseRow key={`sub${sub.id}`} submission={sub} refresh={fetchShowcases} showcased={false} />
                ))}
              </ul>
            </div>
          </div>
        </Card>
      </div>
    </div>
  </>
}

const Submissions = () => {
  const [submissions, setSubmissions] = useState([])
  const [meta, setMeta] = useState({})
  const { getRequest } = useQuery()
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(1)
  const [contains, setContains] = useState('')
  const scrollRef = useRef(null)

  useDebouncedEffect(() => { fetchData() }, 200, [contains])
  useEffect(() => { fetchData() }, [page])

  const fetchData = () => {
    setLoading(true)
    const newPage = contains.length > 0
    const data = {
      contains: contains,
      page: newPage ? 1 : page
    }
    getRequest('/api/v3/auditions/profile_auditions', data, (err, jsonData) => {
      setLoading(false)
      if (err) { /* hooks */ return }
      setSubmissions(jsonData.submissions)
      setMeta(jsonData.meta)
    })
  }

  return (
    <div>
      <div className="md:grid md:grid-cols-4 md:gap-6 space-x-2">
        <div className="md:col-span-1">
          <div className="px-4 sm:px-0">
            <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-100">Submissions</h3>
            <p className="mt-1 text-sm text-gray-600 dark:text-gray-300">
              Choose which submissions you've created that you want to appear on your profile.
            </p>
            <p className="mt-3 text-sm text-gray-600 dark:text-gray-300">
              You can view your submission activity, like comments, listens, and awards on your <a href='/submissions'>my submissions</a> page.
            </p>
          </div>
        </div>
        <Card>
            <div className="grid grid-cols-3 gap-6">
              <div className='col-span-3'>
                <div className="sm:rounded-md">
                  <div className='pb-5' ref={scrollRef}>
                    <input name='contains' type="text" onChange={(e) => setContains(e.target.value)}
                      className="shadow-sm focus:ring-cccpurple dark:bg-gray-900 dark:text-white focus:border-cccpurple block w-full sm:text-sm border-gray-300 rounded-md mb-2"
                      placeholder="Search by project name or role name. ex: Lacha Gife"
                    />
                    { submissions.length >= 0 && <Pagination meta={meta} callback={setPage} scrollRef={scrollRef} /> }
                    { loading && <Loading /> }
                  </div>
                  { submissions.length === 0 && !loading && contains.length === 0 && <NoSubmissions /> }
                  { submissions.length === 0 && !loading && contains.length > 0 && <NoResults /> }
                  <ul className="space-y-3">
                    { submissions.map((sub, idx) => (
                      <SubmissionRow key={`sub${sub.id}`} submission={sub} />
                    ))}
                  </ul>
                  { submissions.length >= 0 && <Pagination meta={meta} callback={setPage} scrollRef={scrollRef} /> }
                </div>
              </div>
            </div>
        </Card>
      </div>
    </div>
  )
}

const SubmissionRow = ({ submission }) => {
  return <li className='bg-white dark:bg-gray-700 px-2 py-2 sm:px-3 rounded-lg shadow-sm'>
    <div className="flex items-start justify-between flex-col space-y-2">
      <div className='flex space-x-1 items-start justify-between w-full'>
        <div className="relative m-1">
          <img className="h-8 w-8 sm:h-16 sm:w-16 rounded-sm bg-gray-200 flex items-center justify-center ring-4 ring-cccblue" src={submission.roleImageUrl} alt={submission.roleName} />

          <span className="absolute -bottom-0.5 -right-1 bg-cccblue rounded-tl px-0.5 py-px">
            <FilmIcon className="h-4 w-4 text-white" aria-hidden="true" />
          </span>
        </div>
        <div className="flex-1 py-0 w-full flex flex-col truncate">
          <div className="uppercase text-xs dark:text-gray-300 text-gray-500 mr-5">
            <span>{submission.createdAt}</span>
          </div>
          <span className="text-sm font-medium text-gray-900 dark:text-gray-200 truncate">{submission.projectName}</span>
          <a href={`/projects/${submission.projectSlug}`} className="font-medium text-cccblue truncate">{submission.roleName}</a>
          { submission.remoteLink && <div className='inline-flex justify-start w-full min-w-3 min-h-3 truncate'>
            <a href={submission.remoteLink} target='_blank' rel="noreferrer" className="inline-flex items-center py-2 shadow-sm text-sm font-medium text-gray-700 text-cccblue hover:text-cccblue-alt">
              <LinkIcon className="mr-1 h-5 w-5" aria-hidden="true" />
              {submission.remoteLink}
            </a>
          </div> }
        </div>
        <div className="flex-shrink-0 text-gray-500 dark:text-gray-100 mr-5 mb-2 w-12 max-w-12">
          { submission.audioUrl && <PlayPauseButton
            mediaUrl={submission.audioUrl}
            objectId={submission.id}
            objectKind='Audition' />
          }
        </div>
      </div>
      <Permission submission={submission} />
      {submission.note && <div className="text-xs sm:text-sm text-gray-700 dark:text-gray-300" dangerouslySetInnerHTML={{ __html: submission.note }} /> }
      { submission.demo?.id && <PublicDemoRow demo={submission.demo} /> }
    </div>
  </li>
}

const ProfileSubmissions = () => {
  return (
    <>
      <Showcases />

      <div className="py-5">
        <div className="hidden sm:block" aria-hidden="true">
          <div className="border-t border-gray-200" />
        </div>
      </div>

      <Submissions />
    </>
  )
}
export default ProfileSubmissions

SubmissionRow.propTypes = {
  submission: PropTypes.object.isRequired
}

ShowcaseRow.propTypes = {
  submission: PropTypes.object.isRequired,
  refresh: PropTypes.func,
  showcased: PropTypes.bool
}
