import React from 'react'

import EmailSettings from '@/pages/Settings/EmailSettings'
import PasswordSettings from '@/pages/Settings/PasswordSettings'
import OmniAuthSettings from '@/pages/Settings/OmniAuthSettings'
import DeleteAccount from '@/pages/Settings/DeleteAccount'
import EmailPreferences from '@/pages/Settings/EmailPreferences'

const SettingsIndex = () => {
  return (
    <div className='mt-3'>
      <div className="md:grid md:grid-cols-3 md:gap-6">
        <EmailPreferences />
        <div className="col-span-4 mt-3" />
        <EmailSettings />
        <div className="col-span-4 mt-3" />
        <PasswordSettings />
        <div className="col-span-4 mt-3" />
        <OmniAuthSettings />
        <div className="col-span-4 mt-3" />
        <DeleteAccount />
      </div>
    </div>
  )
}

export default SettingsIndex
