import React from 'react'
import { useGlobalState } from '@/state'
import useQuery from '@/hooks/useQuery'
import DefaultButton from '@/shared/Buttons/Default'
import Modal from '@/shared/Modal'
import useModal from '@/hooks/useModalV2'

const DeleteRoleUserModal = ({ roleUser, deleteRoleUser, roleName }) => {
  const { openModal, closeModal, isOpen } = useModal()
  const [, setToast] = useGlobalState('toast')
  const { putpostRequest, getRequest } = useQuery()

  const destroy = () => {
    putpostRequest(`/api/v3/role_users/${roleUser.id}`, 'DELETE', {}, (err, jsonData) => {
      if (err) { /* hook */ return }

      setToast(<div className="ml-3 w-0 flex-1 pt-0.5">
        <p className="text-sm font-medium text-green-500">It's finished.</p>
        <p className="mt-1 text-sm text-green-500">It's done. We can go back to the shire, Frodo.</p>
      </div>)
      deleteRoleUser(roleUser.id)
      closeModal()
    })
  }

  return <>
    <DefaultButton onClick={openModal} text='Unassign' />
    <Modal isOpen={isOpen} closeModal={closeModal} >
      <div className="flex flex-col text-center items-center">
        <h2 className="mb-8 text-xs font-semibold tracking-widest text-gray-800 dark:text-gray-200 uppercase title-font">Unassign from a role</h2>
      </div>
        <p className='flex justify-center items-center text-gray-900 dark:text-gray-100'>You are about to remove {roleUser.name} from {roleName}. That look right?</p>
      <div className="rounded-md bg-blue-50 mt-4 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <svg className="h-5 w-5 text-blue-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fillRule="evenodd" d="M19 10.5a8.5 8.5 0 11-17 0 8.5 8.5 0 0117 0zM8.25 9.75A.75.75 0 019 9h.253a1.75 1.75 0 011.709 2.13l-.46 2.066a.25.25 0 00.245.304H11a.75.75 0 010 1.5h-.253a1.75 1.75 0 01-1.709-2.13l.46-2.066a.25.25 0 00-.245-.304H9a.75.75 0 01-.75-.75zM10 7a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
            </svg>
          </div>
          <div className="ml-3 flex-1 md:flex md:justify-between">
            <p className="text-sm text-blue-700">This person won't be notified automatically.</p>
          </div>
        </div>
      </div>
      <div className="flex justify-end space-x-2 mt-4">
        <DefaultButton onClick={closeModal} text='Cancel' />
        <button className='inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-white bg-red-600' onClick={destroy}>Unassign</button>
      </div>
    </Modal>
  </>
}

export default DeleteRoleUserModal
