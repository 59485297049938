import React, { useState, Fragment, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Listbox, Transition } from '@headlessui/react'
import { useDebouncedEffect } from '@/utils/useDebouncedEffect'

import classNames from '@/utils/classNamesLocal'
import Loading from '@/shared/Loading'
import useQuery from '@/hooks/useQuery'
import Pagination from '@/shared/Pagination'

import { CheckIcon, ChevronDownIcon, EyeIcon, FingerPrintIcon, EyeOffIcon } from '@heroicons/react/solid'

import Card from '@/shared/Card'
import NoResults from '@/pages/Users/ProfileProjects/NoResults'
import NoProjects from '@/pages/Users/ProfileProjects/NoProjects'

const Projects = () => {
  const [projects, setProjects] = useState([])
  const [meta, setMeta] = useState({})
  const { getRequest } = useQuery()
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(1)
  const [contains, setContains] = useState('')

  useDebouncedEffect(() => { fetchData() }, 200, [contains])
  useEffect(() => { fetchData() }, [page])

  const fetchData = () => {
    setLoading(true)
    const data = {
      contains: contains,
      page: contains.length > 0 ? 1 : page
    }
    getRequest('/api/v3/projects/profile_projects', data, (err, jsonData) => {
      setLoading(false)
      if (err) { /* hooks */ return }
      setProjects(jsonData.projects)
      setMeta(jsonData.meta)
    })
  }
  return (
    <div>
      <div className="md:grid md:grid-cols-4 md:gap-6 space-x-2">
        <div className="md:col-span-1">
          <div className="px-4 sm:px-0">
            <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-100">Projects</h3>
            <p className="mt-1 text-sm text-gray-600 dark:text-gray-300">
              Choose which projects you've created that you want to appear on your profile.
            </p>
            <p className="mt-3 text-sm text-gray-600 dark:text-gray-300">
              You can manage your projects on your <a href='/manage/projects'>manage projects</a> page.
            </p>
            <p className="mt-3 text-sm text-gray-600 dark:text-gray-300">IMPORTANT: The changes you make here only impact what appears on your <i>profile</i>. If you want to edit your project how it appears in <i>search</i>, please go to the <a href='/manage/projects'>manage projects</a> page.
            </p>
          </div>
        </div>
        <Card>
          <div className="grid grid-cols-3 gap-6">
            <div className='col-span-3'>
              <div className="sm:rounded-md">
                <div className='pb-5'>
                  <input name='contains' type="text" onChange={(e) => setContains(e.target.value)}
                    className="shadow-sm focus:ring-cccpurple dark:bg-gray-900 dark:text-white focus:border-cccpurple block w-full sm:text-sm border-gray-300 rounded-md mb-2"
                    placeholder="Search by project name"
                  />
                  { projects.length >= 0 && <Pagination meta={meta} callback={setPage} /> }
                  { loading && <Loading /> }
                </div>
                { projects.length === 0 && !loading && contains.length === 0 && <NoProjects /> }
                { projects.length === 0 && !loading && contains.length > 0 && <NoResults /> }
                <ul className="space-y-3">
                  { projects.map((sub, idx) => (
                    <ProjectRow key={`pro${sub.id}`} project={sub} />
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </div>
  )
}

const publishingOptions = [
  { title: 'public', description: 'This project will appear on your profile.' },
  { title: 'unlisted', description: 'This project will not appear on your profile.' }
]

const ProjectRow = ({ project }) => {
  const [selected, setSelected] = useState(project.permissions)
  const { putpostRequest } = useQuery()
  let pillColor = 'bg-yellow-500'
  if (project.status === 'open') {
    pillColor = 'bg-green-500'
  } else if (project.status === 'completed') {
    pillColor = 'bg-indigo-500'
  }

  const changePermission = (perm) => {
    setSelected(perm)
    const data = { permissions: perm }
    putpostRequest(`/api/v3/projects/${project.id}`, 'PATCH', { project: data }, (err, jsonData) => {
      if (err) { /* hook */ }
    })
  }

  return <li className='bg-white dark:bg-gray-700 px-4 py-4 sm:px-6  rounded-lg shadow-sm'>
    <div className="sm:flex sm:justify-between sm:items-center">
      <div className="flex-shrink-0 text-gray-500 dark:text-gray-100 hidden sm:block">
        <img className='h-16 w-16 rounded-full' src={project.imageUrl} alt={project.name} />
      </div>
      <div className="flex-1 px-0 md:px-4 md:py-0 pb-2 w-full truncate">
        <div className='flex flex-col truncate'>
          <span className="uppercase text-xs dark:text-gray-300 text-gray-500 mr-5">
            {project.createdAt} - {project.deadline}
          </span>
          <a href={`/projects/${project.slug}`} className="font-medium text-cccblue">{project.name}</a>
          <span className='flex justify-start'>
            <span className={`flex-shrink-0 inline-block px-2 py-0.5 text-white ${pillColor} text-xs font-medium rounded-full flex cursor-pointer capitalize`}>
              {project.status}
            </span>
          </span>
        </div>
      </div>
      {project.status !== 'draft' && <div>
        <Listbox value={selected} onChange={(value) => changePermission(value)}>
          {({ open }) => (
            <>
              <Listbox.Label className="sr-only" hidden>Change published status</Listbox.Label>
              <div className="relative">
                <div className="inline-flex shadow-sm rounded-md divide-x divide-white">
                  <div className="relative z-0 inline-flex shadow-sm rounded-md divide-x divide-white">
                    <div className="relative inline-flex items-center bg-cccblue py-2 pl-3 pr-4 border border-transparent rounded-l-md shadow-sm text-white w-48">
                      { selected === 'unlisted' && <FingerPrintIcon className="h-5 w-5" aria-hidden="true" /> }
                      { selected === 'private' && <EyeOffIcon className="h-5 w-5" aria-hidden="true" /> }
                      { selected === 'public' && <EyeIcon className="h-5 w-5" aria-hidden="true" /> }
                      <p className="ml-2.5 text-sm font-medium capitalize">{selected}</p>
                    </div>
                    <Listbox.Button className="relative inline-flex items-center bg-cccblue p-2 rounded-l-none rounded-r-md text-sm font-medium text-white hover:bg-cccblue focus:outline-none focus:z-10 focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-cccblue">
                      <span className="sr-only">Change published status</span>
                      <ChevronDownIcon className="h-5 w-5 text-white" aria-hidden="true" />
                    </Listbox.Button>
                  </div>
                </div>

                <Transition show={open} as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0" >
                  <Listbox.Options static className="origin-top-right absolute z-10 right-0 mt-2 w-72 rounded-md shadow-lg overflow-hidden bg-white dark:bg-gray-800 divide-y divide-gray-200 ring-1 ring-black ring-opacity-5 focus:outline-none" >
                    {publishingOptions.map((option) => (
                      <Listbox.Option key={option.title}
                        className={({ active }) =>
                            classNames(
                              active ? 'text-white bg-cccblue' : 'text-gray-900 dark:text-gray-100',
                              'cursor-default select-none relative p-4 text-sm'
                            )
                        }
                        value={option.title}
                      >
                        {({ selected, active }) => (
                          <div className="flex flex-col">
                            <div className="flex justify-between">
                              <p className={selected ? 'font-semibold capitalize' : 'font-normal capitalize'}>{option.title}</p>
                              {selected ? (
                                <span className={active ? 'text-white' : 'text-cccblue'}>
                                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                </span>
                              ) : null}
                            </div>
                            <p className={classNames(active ? 'text-white' : 'text-gray-500 dark:text-gray-200 dark:bg-gray-800', 'mt-2')}>
                              {option.description}
                            </p>
                          </div>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </>
          )}
        </Listbox>
      </div> }
    </div>
  </li>
}

const ProfileProjects = () => {
  return <Projects />
}
export default ProfileProjects

ProjectRow.propTypes = {
  project: PropTypes.object.isRequired
}
