import React, { useEffect, useRef } from 'react'
import { Switch, Route, useLocation, Link } from 'react-router-dom'
import useQuery from '@/hooks/useQuery'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useGlobalState } from '@/state'

import Breadcrumbs from '@/Layout/Breadcrumbs'
import PageTitle from '@/Layout/PageTitle'
import ProfileTabs from '@/pages/Users/ProfileTabs'
import { LinkIcon, DuplicateIcon } from '@heroicons/react/solid'

import DefaultButton from '@/shared/Buttons/Default'
import ProfileGeneral from '@/pages/Users/ProfileGeneral'
import ProfileAbout from '@/pages/Users/ProfileAbout'
import ProfileProjects from '@/pages/Users/ProfileProjects'
import ProfileSubmissions from '@/pages/Users/ProfileSubmissions'
import ProfileOptions from '@/pages/Users/ProfileOptions'
import Loading from '@/shared/Loading'

function breadCrumbs() {
  const location = useLocation()
  const activePath = location.pathname
  const crumbs = [
    { to: '/profile', name: 'Profile' }
  ]
  if (activePath === '/profile' || activePath === '/profile/general') {
    crumbs.push({ to: '/profile/general', name: 'General Profile Info' })
  }
  if (activePath === '/profile/about') { crumbs.push({ to: activePath, name: 'About My Work' }) }
  if (activePath === '/profile/submissions') { crumbs.push({ to: activePath, name: 'Submissions' }) }
  if (activePath === '/profile/projects') { crumbs.push({ to: activePath, name: 'Projects' }) }
  if (activePath === '/profile/options') { crumbs.push({ to: activePath, name: 'Options' }) }
  return crumbs
}

function pageTitle() {
  const location = useLocation()
  const activePath = location.pathname
  const [currentUser] = useGlobalState('currentUser')
  const [, setToast] = useGlobalState('toast')
  const showCopiedNotification = () => {
    setToast(<div className="ml-3 w-0 flex-1 pt-0.5">
      <p className="text-sm font-medium dark:text-green-300 text-green-600">Copied</p>
      <p className="mt-1 text-sm dark:text-green-300 text-green-400">You did it! It's copied. You can paste it anywhere you want now.</p>
    </div>)
  }

  const titleInfo = {
    title: '',
    buttons: [
      <CopyToClipboard key='copyprofile' text={`https://cstng.cc/${currentUser.username}`} onCopy={showCopiedNotification} >
        <span className='flex flex-start cursor-pointer'>
          <DefaultButton onClick={() => {}} text={<>
            <DuplicateIcon className="h-4 w-4 sm:h-5 sm:w-5 text-gray-400" aria-hidden="true" /> <span className='text-xs sm:text-sm'>Copy Public Profile Link </span>
          </>}
          />
        </span>
      </CopyToClipboard>,
      <Link key='profilelink' to={`/${currentUser.username}`} className="ml-3 inline-flex justify-center px-4 py-2 shadow-sm text-sm font-medium rounded-md text-white dark:text-gray-200 dark:hover:bg-cccblue-alt bg-cccblue hover:bg-cccblue-alt focus:outline-none focus:ring-2 focus:ring-offset-2 hover:text-white focus:ring-gray-900">
        <LinkIcon className="h-4 w-4 sm:h-5 sm:w-5 text-white" aria-hidden="true" />
        <span className='text-xs sm:text-sm'>View Public Profile</span>
      </Link>
    ]
  }
  if (activePath === '/profile' || activePath === '/profile/general') { titleInfo.title = 'My Profile' }
  if (activePath === '/profile/about') { titleInfo.title = 'About My Work' }
  if (activePath === '/profile/submissions') { titleInfo.title = 'Submissions' }
  if (activePath === '/profile/projects') { titleInfo.title = 'Projects' }
  if (activePath === '/profile/options') { titleInfo.title = 'Options' }
  return titleInfo
}

export default function UsersContainer(props) {
  const crumbs = breadCrumbs()
  const titleInfo = pageTitle()
  const isComponentMounted = useRef(false)
  const { getRequest } = useQuery()

  const [, setUser] = useGlobalState('userContainerUser')
  const [currentUser] = useGlobalState('currentUser')

  useEffect(() => {
    getRequest(`/api/v3/users/${currentUser.id}/edit`, {}, (err, jsonData) => {
      if (err) { /* handled in hook */ return }
      setUser(jsonData.user)
    })
    isComponentMounted.current = true
  }, [])

  if (!isComponentMounted.current) {
    return <Loading />
  }

  return <>
    <Breadcrumbs>
      { crumbs.map((crumb, index) => (
        <Link key={`${index}${crumb.name}`} to={crumb.to} className="text-xs sm:text-sm text-gray-500 hover:text-gray-700 dark:text-gray-100">
          {crumb.name}
        </Link>
      ))}
    </Breadcrumbs>
    <PageTitle pagetitle={titleInfo.title}>
      {titleInfo.buttons}
    </PageTitle>
    <ProfileTabs />
    <Switch>
      <Route {...props} exact path={['/profile', '/profile/general']}>
        <ProfileGeneral />
      </Route>
      <Route {...props} exact path='/profile/about'>
        <ProfileAbout />
      </Route>
      <Route {...props} exact path='/profile/submissions'>
        <ProfileSubmissions />
      </Route>
      <Route {...props} exact path='/profile/projects'>
        <ProfileProjects />
      </Route>
      <Route {...props} exact path='/profile/options'>
        <ProfileOptions />
      </Route>
    </Switch>
  </>
}
