import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { RadioGroup, Switch } from '@headlessui/react'
import classNames from '@/utils/classNamesLocal'

import { useForm } from 'react-hook-form'
import { debounce } from 'throttle-debounce'

import Card from '@/shared/Card'
import { randomToastSuccess } from '@/shared/Toast'

import TipTapTextarea from '@/shared/TipTapTextarea'
import FroalaWrapper from '@/shared/FroalaWrapper'
import PrimaryButton from '@/shared/Buttons/Primary'
import DefaultButton from '@/shared/Buttons/Default'
import { ExclamationCircleIcon, CheckCircleIcon } from '@heroicons/react/solid'
import useQuery from '@/hooks/useQuery'
import { useGlobalState } from '@/state'

import RoleTags from '@/pages/ManageProjects/ProjectEdit/RoleTags'

const RoleEdit = ({ initialRole, projectId, updateRole, roleKind, closeModal }) => {
  const tipTapIdx = useRef(1)
  const [, setToast] = useGlobalState('toast')
  const [role, setRole] = useState(initialRole || {})
  const [overridePayment, setOverridePayment] = useState(false)
  const [payment, setPayment] = useState(initialRole.payment)
  const { putpostRequest } = useQuery()
  const { formState, register, unregister, watch, handleSubmit, setValue, getValues, setError, setFocus } = useForm({
   defaultValues: {
      name: initialRole.name || roleKind,
      characteristics: initialRole.characteristics,
      kind: initialRole.kind || roleKind,
      payment: initialRole.payment,
      line1: initialRole.line1,
      line2: initialRole.line2,
      line3: initialRole.line3,
      payment_details: initialRole.paymentDetails,
      payment_range: initialRole.paymentRange,
      payment_interval: initialRole.paymentInterval,
      payment_currency: initialRole.paymentCurrency,
      payment_deferred_type: initialRole.paymentDeferredType,
      payment_amount_min: initialRole.paymentAmountMin,
      payment_amount_max: initialRole.paymentAmountMax,
      expected_commitment_amount: initialRole.expectedCommitmentAmount,
      expected_commitment_interval: initialRole.expectedCommitmentInterval
    }
  })

  const { errors } = formState
  const [loading, setLoading] = useState(false)
  const characteristics = getValues().characteristics

  const updateAllValues = (role) => {
    setPayment(role.payment)
    setValue('payment', role.payment, { shouldDirty: false, shouldValidate: false })
    setValue('payment_details', role.paymentDetails, { shouldDirty: false, shouldValidate: false })
    setValue('payment_range', role.paymentRange, { shouldDirty: false, shouldValidate: false })
    setValue('payment_interval', role.paymentInterval, { shouldDirty: false, shouldValidate: false })
    setValue('payment_currency', role.paymentCurrency, { shouldDirty: false, shouldValidate: false })
    setValue('payment_deferred_type', role.paymentDeferredType, { shouldDirty: false, shouldValidate: false })
    setValue('payment_amount_min', role.paymentAmountMin, { shouldDirty: false, shouldValidate: false })
    setValue('payment_amount_max', role.paymentAmountMax, { shouldDirty: false, shouldValidate: false })
    setValue('expected_commitment_amount', role.expectedCommitmentAmount, { shouldDirty: false, shouldValidate: false })
    setValue('expected_commitment_interval', role.expectedCommitmentInterval, { shouldDirty: false, shouldValidate: false })
  }

  useEffect(() => {
    setFocus('name')
  }, [setFocus])

  useEffect(() => {
    register('characteristics')
    register('payment')
    register('payment_details')
    return () => {
      unregister('characteristics')
      unregister('payment')
      unregister('payment_details')
    }
  }, [register])

  const handleUpdateFroala = (key, val) => {
    setValue(key, val, { shouldDirty: true, shouldValidate: true })
  }

  const keyPress = (e) => {
    if (e.target.name === 'name' && e.keyCode === 13) { e.preventDefault() }
    if ((e.metaKey || e.ctrlKey) && e.keyCode === 13) {
      if (typeof (e.target.name) === 'undefined') {
        e.target.blur() // froala hack to make it update model so it can save properly
      }
      handleSubmit(onSubmit)()
    }
  }

  const handlePaymentChange = (value) => {
    setPayment(value)
    setValue('payment', value, { shouldDirty: true, shouldValidate: true })
  }

  const handleUpdateSwitch = (key) => {
    setValue(key, !getValues()[key], { shouldDirty: true, shouldValidate: true })
  }

  const handleUpdateTipTap = (key, val) => {
    setValue(key, val, { shouldDirty: true, shouldValidate: true })
  }
  const onSubmit = debounce(300, (data) => {
    setLoading(true)

    let URL = '/api/v3/roles'
    let action = 'POST'
    if (role.id) {
      URL = `/api/v3/roles/${role.id}`
      action = 'PATCH'
    }

    putpostRequest(URL, action, { role: { ...data, ...{ project_id: projectId } } }, (err, jsonData) => {
      setLoading(false)
      if (err) { // 422 code
        if (typeof err !== 'string') { // 500 code on API
          Object.entries(err).forEach(([key, value]) => {
            setError(key, value)
          })
        }
        return
      }

      setRole({ ...jsonData.role })
      if (action === 'POST') {
        updateAllValues(jsonData.role)
        setToast(<div className="ml-3 w-0 flex-1 pt-0.5">
          <p className="text-sm font-medium dark:text-white text-gray-800">Role Created</p>
          <p className="mt-1 text-sm dark:text-gray-100 text-gray-500">You're on a roll! ...okay, I'll stop.</p>
        </div>)
        return
      }

      // updateRole doesn't exist when it's first being create. On every save thereafter, it exists
      if (typeof (updateRole) === 'function') { updateRole(jsonData.role) }

      setToast(randomToastSuccess())
    })
  })

  if (typeof (role.id) === 'undefined') {
    return <>
      <Card
        footer={
        <div className='flex flex-col items-end'>
          <div className='flex flex-col items-end w-min ml-2'>
            <div className="hidden sm:block mt-1 w-min text-xs border border-gray-200 dark:border-gray-800 rounded px-2 font-sans text-gray-500 dark:text-gray-400">⌘/ctrl+ENTER</div>
            <PrimaryButton className='w-full' onClick={handleSubmit(onSubmit)} loading={loading} text='Next' />
          </div>
        </div>
        } >
        <form className='grid grid-cols-4'>
          <div className="col-span-4 sm:col-span-2 sm:col-start-2">
            <label htmlFor="school_name" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">Name of Role </label>
            <div className="mt-1 flex flex-col rounded-md shadow-sm">
              <div className='relative'>
                <input onKeyDown={keyPress} type="text" className={errors.name ? 'errors' : ''} {...register('name', { required: true }) } placeholder="Car Salesman / Annoying Sibling / Narrator / Wolverine" />
                { errors.name && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                </div> }
              </div>
            </div>
            <p className="text-xs text-gray-500 dark:text-gray-400 mt-1">Name of the role. This will be public.</p>
            { errors.name && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
          </div>
        </form>
      </Card>
    </>
  }

  const paymentDetails = watch('payment_details')
  const paymentCurrency = watch('payment_currency')
  const paymentRange = watch('payment_range')
  const paymentAmountMax = watch('payment_amount_max')
  const paymentInterval = watch('payment_interval')
  const expectedCommitmentInterval = watch('expected_commitment_interval')
  const expectedCommitmentAmount = watch('expected_commitment_amount')
  const kind = watch('kind')

  let totalPay = null
  let hourlyRate = null
  let lowRate = false
  let currencySymbol = '$'
  if (paymentCurrency === 'GBP') { currencySymbol = '£' }
  if (paymentCurrency === 'EUR') { currencySymbol = '€' }

  if (parseInt(paymentAmountMax, 10) >= 0 && parseInt(expectedCommitmentAmount, 10) >= 0) {
    const multiplier = expectedCommitmentInterval === 'hours' ? 1 : 8
    totalPay = paymentAmountMax * expectedCommitmentAmount * multiplier
    if (paymentInterval === 'Flat Rate') {
      totalPay = paymentAmountMax
      hourlyRate = totalPay / multiplier / expectedCommitmentAmount
    } else if (paymentInterval === 'Hourly') {
      hourlyRate = paymentAmountMax
      totalPay = hourlyRate * expectedCommitmentAmount * multiplier
    } else if (paymentInterval === 'Daily') {
      hourlyRate = paymentAmountMax / 8
      totalPay = hourlyRate * expectedCommitmentAmount * multiplier
    } else if (paymentInterval === 'Weekly') {
      hourlyRate = paymentAmountMax / 40
      totalPay = hourlyRate * expectedCommitmentAmount * multiplier
    } else if (paymentInterval === 'Monthly') {
      hourlyRate = paymentAmountMax / 160
      totalPay = hourlyRate * expectedCommitmentAmount * multiplier
    }
  }
  if (hourlyRate && hourlyRate < 20) { lowRate = true }

  return <>
    <div className="md:grid md:grid-cols-4 md:gap-6 space-x-2">
      <div className="md:col-span-1">
        <div className="px-4 sm:px-0">
          <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-100">General Information</h3>
          <p className="mt-1 text-sm text-gray-600 dark:text-gray-300">
            Provide enough information to make talent want to apply for your role.
          </p>
        </div>
      </div>
      <Card title={`General Information for role: ${kind}`}
        footer={
        <div className='flex flex-rows justify-end items-end'>
          { closeModal && <DefaultButton onClick={closeModal} text='Done' /> }
          <div className='flex flex-col items-end w-min ml-2'>
            <div className="hidden sm:block w-min text-xs border border-gray-200 dark:border-gray-800 rounded px-2 font-sans text-gray-500 dark:text-gray-400">⌘/ctrl+ENTER</div>
            <PrimaryButton className='w-full' onClick={handleSubmit(onSubmit)} loading={loading} text='Save' />
          </div>
        </div>
        } >
        <form>
          <div className="grid sm:grid-cols-4 gap-6">
            <div className="col-span-4 sm:col-span-2">
              <label htmlFor="school_name" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">Name of Role </label>
              <div className="mt-1 flex flex-col rounded-md shadow-sm">
                <div className='relative'>
                  <input onKeyDown={keyPress} type="text" className={errors.name ? 'errors' : ''} {...register('name', { required: true }) } placeholder="Car Salesman / Annoying Sibling / Narrator / Wolverine" />
                  { errors.name && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                  </div> }
                </div>
              </div>
            <p className="text-xs text-gray-500 dark:text-gray-400 mt-1">Name of the role. This will be public.</p>
              { errors.name && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
            </div>

            <div className="col-span-4 sm:col-span-3">
              <div className='mt-4'>
                <label htmlFor="pricing" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                  Description / Characteristics of this role
                </label>
                <p className="text-xs text-gray-500  dark:text-gray-400 mb-1"> Please add demo lines, software needed, links. Is this role a lead? </p>
                <div className="mt-1 shadow-sm w-full min-w-full">
                  <FroalaWrapper
                    model={characteristics}
                    keyPress={keyPress}
                    updateModel={(val) => handleUpdateFroala('characteristics', val)}
                  />
                </div>
              </div>
            </div>

            { ['Voice Actor', 'Singer', 'Actor'].includes(kind) && <>
              <div className="col-span-4 sm:col-span-3">
                <div className='mt-4'>
                  <label htmlFor="pricing" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                    Custom audition lines
                  </label>
                  <p className="text-xs text-gray-500  dark:text-gray-400 mb-1"> If you want talent to audition with custom lines, please add them here. </p>
                  <div className="mt-1 flex flex-col space-y-2">
                    <input onKeyDown={keyPress} type="text" className={errors.line1 ? 'errors' : ''} {...register('line1') } placeholder="With great power comes great responsibility." />
                    <input onKeyDown={keyPress} type="text" className={errors.line2 ? 'errors' : ''} {...register('line2') } placeholder="Roads? Where we're going we don't need roads." />
                    <input onKeyDown={keyPress} type="text" className={errors.line3 ? 'errors' : ''} {...register('line3') } placeholder="So you're telling me there's a chance?" />
                  </div>
                </div>
              </div>
            </> }

            <div className="col-span-4 sm:col-span-3">
              { !overridePayment && <div className='flex justify-start items-center text-sm space-x-3 rounded-md'>
                { payment === 'unpaid' && <div>Unpaid Role</div> }
                { payment === 'deferred' && <div>Deferred Payment</div> }
                { payment === 'paid' && <div> <span><b>Payment Rate:</b> {currencySymbol}{hourlyRate} {paymentCurrency}  / hour</span> </div> }
                <button className='text-cccblue' onClick={() => setOverridePayment(true) }>Edit</button>
              </div> }
              { overridePayment && <>
                <div className='border-t border-cccblue m-4' />
                <div className='grid grid-cols-3'>
                  <div className='col-span-2'>
                    <label htmlFor="time_zone" className="block text-sm font-medium text-gray-700 dark:text-gray-200 flex">
                      <span>Payment Information</span>
                    </label>
                    <RadioGroup value={payment} onChange={handlePaymentChange}>
                      <RadioGroup.Label className="sr-only" hidden>Payment Information</RadioGroup.Label>
                      <div className="p-2 rounded-sm bg-white dark:bg-gray-700">
                        <label htmlFor="payment" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                          Is this a paid project?
                          <p className="text-xs text-gray-500 dark:text-gray-400 mt-1">Paying different rates per role? You can specify other rates later</p>
                        </label>
                        <div className="mt-1 flex flex-col rounded-md shadow-sm">
                          <RadioGroup.Option value='paid' className={({ checked }) => classNames(checked ? 'bg-cccblue z-10' : 'border-gray-200', 'relative border p-4  w-full cursor-pointer focus:outline-none')}>
                            {({ active, checked }) => <>
                              <div className="flex items-center text-sm col-span-1">
                                <span className={classNames(
                                  checked ? 'bg-cccorange ring-2 ring-white' : 'bg-white border-gray-300',
                                  active ? 'ring-2 ring-white' : '',
                                  'h-4 w-4 rounded-full border flex items-center justify-center'
                                )} aria-hidden="true" >
                                  <span className="rounded-full bg-white w-1.5 h-1.5" />
                                </span>
                                <RadioGroup.Label as="span" className={classNames(checked ? 'text-white' : 'text-gray-900 dark:text-gray-200', 'ml-3 font-medium')} >
                                  [Paid] Yes, I will pay talent for the work they do.
                                </RadioGroup.Label>
                              </div>
                            </>}
                          </RadioGroup.Option>
                          <RadioGroup.Option value='deferred' className={({ checked }) => classNames(checked ? 'bg-cccblue z-10' : 'border-gray-200', 'relative border p-4  w-full cursor-pointer focus:outline-none')}>
                            {({ active, checked }) => <>
                              <div className="flex items-center text-sm col-span-1">
                                <span className={classNames(
                                  checked ? 'bg-cccorange ring-2 ring-white' : 'bg-white border-gray-300',
                                  active ? 'ring-2 ring-white' : '',
                                  'h-4 w-4 rounded-full border flex items-center justify-center'
                                )} aria-hidden="true" >
                                  <span className="rounded-full bg-white w-1.5 h-1.5" />
                                </span>
                                <RadioGroup.Label as="span" className={classNames(checked ? 'text-white' : 'text-gray-900 dark:text-gray-200', 'ml-3 font-medium')} >
                                  [Deferred] It depends on the project outcome.
                                </RadioGroup.Label>
                              </div>
                            </>}
                          </RadioGroup.Option>
                          <RadioGroup.Option value='unpaid' className={({ checked }) => classNames(checked ? 'bg-cccblue z-10' : 'border-gray-200', 'relative border p-4  w-full cursor-pointer focus:outline-none')}>
                            {({ active, checked }) => <>
                              <div className="flex items-center text-sm col-span-1">
                                <span className={classNames(
                                  checked ? 'bg-cccorange ring-2 ring-white' : 'bg-white border-gray-300',
                                  active ? 'ring-2 ring-white' : '',
                                  'h-4 w-4 rounded-full border flex items-center justify-center'
                                )} aria-hidden="true" >
                                  <span className="rounded-full bg-white w-1.5 h-1.5" />
                                </span>
                                <RadioGroup.Label as="span" className={classNames(checked ? 'text-white' : 'text-gray-900 dark:text-gray-200', 'ml-3 font-medium')} >
                                  [Unpaid] This is an unpaid project.
                                </RadioGroup.Label>
                              </div>
                            </>}
                          </RadioGroup.Option>
                        </div>
                      </div>
                    </RadioGroup>
                  </div>
                  { payment === 'deferred' && <div className='mt-3 col-span-2 border border-gray-400 p-2 rounded-md'>
                    <label htmlFor="payment_deferred_type" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                      Will this be profit share or agreed amount?
                    </label>
                    <select
                      {...register('payment_deferred_type')}
                      className="block w-full py-2 px-3 border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm"
                    >
                      <option value='profit-share'>Profit Share</option>
                      <option value='agreed-amount'>Agreed amount based on project success</option>
                    </select>
                  </div> }

                  { payment === 'paid' && <div className='mt-3 col-span-3 border border-gray-400 p-2 rounded-md'>
                    <label htmlFor="payment_interval" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                      How much will they be paid?
                    </label>
                    <div className={`grid ${paymentRange ? 'grid-cols-4' : 'grid-cols-3'} gap-x-3`}>
                      <div className='col-span-1'>
                        <select {...register('payment_interval', { required: true })}
                          className="block w-full py-2 px-3 border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm" >
                          <option>Flat Rate</option>
                          <option>Hourly</option>
                          <option>Daily</option>
                          <option>Weekly</option>
                          <option>Monthly</option>
                        </select>
                      </div>
                      <div className='col-span-1'>
                        <select {...register('payment_currency', { required: true })}
                          className="block w-full py-2 px-3 border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm" >
                          <option value='USD'>US Dollar</option>
                          <option value='GBP'>Pound Sterling</option>
                          <option value='CAD'>Canadian Dollar</option>
                          <option value='EUR'>Euro</option>
                          <option value='AUD'>Australian Dollar</option>
                        </select>
                      </div>
                      {paymentRange && <div className='col-span-1'>
                        <div className="relative">
                          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                            <span className="text-gray-500 sm:text-sm">{currencySymbol}</span>
                          </div>
                          <input type="number" {...register('payment_amount_min', { required: true }) } className="block w-full rounded-md border-gray-300 pl-7 pr-12 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" placeholder="20" aria-describedby="price-currency" />
                          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                            <span className="text-gray-500 sm:text-sm" id="price-currency">{paymentCurrency}</span>
                          </div>
                        </div>
                        <span className='text-xs text-gray-500 dark:text-gray-400 mt-1'>Min</span>
                      </div> }
                      <div className='col-span-1'>
                        <div className="relative">
                          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                            <span className="text-gray-500 sm:text-sm">{currencySymbol}</span>
                          </div>
                          <input type="number" {...register('payment_amount_max', { required: true }) } className="block w-full rounded-md border-gray-300 pl-7 pr-12 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" placeholder="20" aria-describedby="price-currency" />
                          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                            <span className="text-gray-500 sm:text-sm" id="price-currency">{paymentCurrency}</span>
                          </div>
                        </div>
                        {paymentRange && <span className='text-xs text-gray-500 dark:text-gray-400 mt-1'>Max</span> }
                      </div>
                    </div>
                    <div className='mt-3 grid grid-cols-3'>
                      <Switch.Group as="div" className="col-span-1 flex items-center justify-between mb-2">
                        <Switch.Label as="span" className="flex-grow flex flex-col" >
                          <span className="text-sm text-gray-700 dark:text-gray-300">Payment is in a range</span>
                        </Switch.Label>
                        <Switch checked={paymentRange} onChange={() => handleUpdateSwitch('payment_range')}
                          className={classNames(paymentRange ? 'bg-cccpurple' : 'bg-gray-300 dark:bg-gray-500',
                            'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccpurple')} >
                          <span className="sr-only" hidden>Payment is a range</span>
                          <span aria-hidden="true" className={classNames(paymentRange ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200')}
                          />
                        </Switch>
                      </Switch.Group>
                    </div>
                    <div className='mt-4' />

                    <label htmlFor="expected_commitment" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                      How long do you expect the work to take?
                    </label>
                    <div className='grid grid-cols-3 gap-x-3'>
                      <div className='col-span-1'>
                        <input type="number" {...register('expected_commitment_amount', {
                          validate: { positive: v => parseInt(v,10) > 0 }
                        }) } className="block w-full rounded-md border-gray-300 pl-7 pr-12 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" placeholder="2" aria-describedby="price-currency" />
                        { errors.expected_commitment_amount && <div className='mt-2 text-sm text-red-600'>Must be a positive number (greater than zero).</div> }
                      </div>
                      <div className='col-span-1'>
                        <select {...register('expected_commitment_interval')}
                          className="block w-full py-2 px-3 border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm" >
                          <option>hours</option>
                          <option>days</option>
                        </select>
                      </div>
                    </div>
                    { totalPay !== null && <div className={`rounded-md ${lowRate ? 'bg-yellow-50' : 'bg-green-50'} p-2 sm:p-4 m-2`}>
                      <div className={`mt-1 text-xs sm:text-sm ${lowRate ? 'text-yellow-600' : 'text-green-500'} flex flex-col space-y-2`}>
                        <div className='flex justify-start items-center space-x-5'>
                          { !lowRate && <CheckCircleIcon className='h-5 w-5' /> }
                          { lowRate && <ExclamationCircleIcon className="h-5 w-5" /> }
                          <div className='flex flex-col space-y-2'>
                            <span><b>Payment Rate:</b> {currencySymbol}{hourlyRate} {paymentCurrency}  / hour</span>
                            <span><b>Total Pay:</b> {currencySymbol}{totalPay} {paymentCurrency} for an estimated {expectedCommitmentAmount} {expectedCommitmentInterval} of work</span>
                            { lowRate && <span> This is a low payment amount and may result in fewer high quality submissions </span> }
                          </div>
                        </div>
                      </div>
                    </div> }
                  </div> }
                  <div className='col-span-2 mt-2'>
                    <label htmlFor="payment_details" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                      Any other payment or contract details?
                    </label>
                    <TipTapTextarea
                      html={paymentDetails}
                      idx={tipTapIdx.current}
                      updateHtml={(val) => handleUpdateTipTap('payment_details', val)}
                      placeholder='Example: Meals and travel included. OR, Requires NDA'
                    />
                  </div>
                </div>
              </> }
            </div>

          </div>
        </form>
      </Card>
      <div className="py-5">
        <div className="hidden sm:block" aria-hidden="true">
          <div className="border-t border-gray-200" />
        </div>
      </div>
    </div>
    <RoleTags roleId={role.id} roleKind={role.kind} />
  </>
}

export default RoleEdit

RoleEdit.propTypes = {
  initialRole: PropTypes.object.isRequired
}
