import React from 'react'
import { Switch, Route, useLocation, Link } from 'react-router-dom'
import { useGlobalState } from '@/state'

import Breadcrumbs from '@/Layout/Breadcrumbs'
import PageTitle from '@/Layout/PageTitle'

import { NewCreditForm, CreditEdit } from '@/pages/Credits/CreditEdit'
import CreditIndex from '@/pages/Credits/CreditIndex'
import CreditManage from '@/pages/Credits/CreditManage'
import CreditShow from '@/pages/Credits/CreditShow'

function breadCrumbs() {
  const location = useLocation()
  const activePath = location.pathname
  const [credit] = useGlobalState('credit')
  const crumbs = [
    { to: '/credits', name: 'Production Credits' }
  ]
  if (activePath === '/credits/new') {
    crumbs.push({ to: '/credits/new', name: 'New Credit' })
  }
  if (activePath.includes('/edit')) {
    crumbs.push({ to: activePath, name: 'Edit Credit' })
  }
  if (activePath.includes('/credits/') && activePath !== '/credits/new') {
    crumbs.push({ to: activePath, name: credit?.name })
  }
  return crumbs
}

function pageTitle() {
  const location = useLocation()
  const activePath = location.pathname
  const [credit] = useGlobalState('credit')

  const titleInfo = {
    title: 'Production Credits',
    buttons: []
  }
  if (activePath === '/credits/new') { titleInfo.title = 'New Credit' }
  if (activePath.includes('/credits/') && activePath !== '/credits/new' && credit?.name) {
     titleInfo.title = credit?.name
  }
  return titleInfo
}

export default function CreditsContainer(props) {
  const crumbs = breadCrumbs()
  const titleInfo = pageTitle()

  return <>
    <Breadcrumbs>
      { crumbs.map((crumb, index) => (
        <Link key={`${index}${crumb.name}`} to={crumb.to} className="text-xs sm:text-sm text-gray-500 hover:text-gray-700 dark:text-gray-100">
          {crumb.name}
        </Link>
      ))}
    </Breadcrumbs>
    <PageTitle pagetitle={titleInfo.title}>
      {titleInfo.buttons}
    </PageTitle>
    <Switch>
      <Route {...props} exact path={['/credits/new']}>
        <NewCreditForm />
      </Route>
      <Route {...props} exact path={['/credits/:id/manage']}>
        <CreditManage />
      </Route>
      <Route {...props} exact path={['/credits/:id/edit']}>
        <CreditEdit />
      </Route>
      <Route {...props} exact path={['/credits/:id']}>
        <CreditShow />
      </Route>
      <Route {...props} exact path={['/credits']}>
        <CreditIndex />
      </Route>
    </Switch>
  </>
}
