import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'

import Loading from '@/shared/Loading'
import Card from '@/shared/Card'

import PrimaryButton from '@/shared/Buttons/Primary'
import UserCreditRow from '@/pages/Credits/UserCreditRow'
import CreditTabs from '@/pages/Credits/Tabs'
import useQuery from '@/hooks/useQuery'
import DefaultButton from '@/shared/Buttons/Default'
import { useGlobalState } from '@/state'

const TranserOwnership = ({ userCredits, creditId }) => {
  const [currentUser] = useGlobalState('currentUser')
  const { putpostRequest } = useQuery()
  const [userId, setUserId] = useState(currentUser.id)
  const [loading, setLoading] = useState(false)
  const [, setToast] = useGlobalState('toast')

  if ( currentUser.id !== creditId) { return null }

  const save = () => {
    setLoading(true)
    putpostRequest('/api/v3/credits', 'POST', { credit: { transfer_user_id: userId } }, (err, jsonData) => {
      setLoading(false)
      if (err) { // 422 code
        if (typeof err !== 'string') {
          Object.entries(err).forEach(([key, value]) => {
            console.log(key, value)
          })
        }
        return
      }

      setToast(<div className="ml-3 w-0 flex-1 pt-0.5">
        <p className="text-sm font-medium dark:text-white text-gray-800">Ownership Transferred</p>
        <p className="mt-1 text-sm dark:text-gray-100 text-gray-500">They are the captain now.</p>
      </div>)
    })
  }

  const handleChange = (e) => {
    if (e.target.name === 'credit_user_id') {
      setUserId(e.target.value)
    }
  }

  const eligibleUCs = userCredits.filter(uc => uc.status === 'confirmed')

  return <Card title="Transfer Ownership" footer={
    <div className='flex flex-rows justify-end items-end'>
      <div className='flex flex-col items-end w-min ml-2'>
        <div className="hidden sm:block w-min text-xs border border-gray-200 dark:border-gray-800 rounded px-2 font-sans text-gray-500 dark:text-gray-400">⌘/ctrl+ENTER</div>
        <PrimaryButton className='w-full' onClick={save} loading={loading} text='Save' />
      </div>
    </div>
    } >
    <span className='text-xs sm:text-sm dark:text-gray-300 text-gray-700'>If you would like to transfer ownership of this production to someone else, make sure they are a Credited Member, and then they will appear in this dropdown. Please note that the owner must confirm their Credited Member status before they appear in this dropdown.</span>
    <div className='col-span-1'>
      <div className="col-span-6 sm:col-span-3">
        <label htmlFor="country" className="block text-sm font-medium text-gray-700 dark:text-gray-200 flex justify-between">
          <span>Owner</span>
        </label>
        <select onChange={handleChange} name='credit_user_id' className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm" >
          <option value={currentUser.id}>{currentUser.username}</option>
          {eligibleUCs.map((userCredit, idx) => <option key={`elig${userCredit.id}`} value={userCredit.userId}>{userCredit.username}</option>)}
        </select>
      </div>
    </div>
  </Card>
}

const CreditManage = () => {
  const { id } = useParams()
  const { getRequest } = useQuery()
  const [credit, setCredit] = useGlobalState('credit')
  const [creatingNewUserCredit, setCreatingNewUserCredit] = useState(false)
  const [userCredits, setUserCredits] = useState([])

  useEffect(() => {
    if (!creatingNewUserCredit) {
      getRequest(`/api/v3/credits/${id}/manage`, {}, (err, jsonData) => {
        if (err) { /* handled in hook */ return }
        setCredit(jsonData.credit)
        setUserCredits(jsonData.userCredits)
      })
    }
  }, [id, creatingNewUserCredit])

  if (!credit.id) { return <Loading /> }

  return <div>
    <CreditTabs credit={credit} />
    <Card title="Credited Members" footer={<DefaultButton onClick={() => setCreatingNewUserCredit(true)} text='New Credited Member' />}>
      <div className="min-w-full divide-y divide-gray-200">
        <div className='hidden sm:block'>
          <div className="bg-gray-50 dark:bg-gray-700 text-gray-500 dark:text-gray-300 p-0 sm:p-2 flex justify-start items-center space-x-1 sm:space-x-3">
            <div className="w-64 text-xs font-medium uppercase tracking-wider"> Name </div>
            <div className="w-64 text-xs font-medium uppercase tracking-wider"> Credited For </div>
            <div className="relative px-6 py-3"> <span className="sr-only" hidden>Edit</span> </div>
          </div>
        </div>
        {userCredits.map((userCredit, idx) => (
          <UserCreditRow
            key={`ucform${userCredit.id}`}
            idx={idx}
            initialUserCredit={userCredit}
            creditId={credit.id}
            canEdit={credit.canEdit}
          />
        ))}
      </div>
      { creatingNewUserCredit && <UserCreditRow
        initialUserCredit={{}}
        creditId={credit.id}
        setCreatingNewUserCredit={setCreatingNewUserCredit}
      /> }
    </Card>

    <div className='mt-4' />
    <TranserOwnership userCredits={userCredits} creditId={credit.id} />
  </div>
}

export default CreditManage

TranserOwnership.propTypes = {
  userCredits: PropTypes.array
}
