import React from 'react'
import PropTypes from 'prop-types'
import { ExclamationIcon } from '@heroicons/react/solid'

const Downgrade = ({ existingSubscription }) => {
  return <>
    <div className="rounded-md bg-yellow-50 p-4 mt-10">
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-yellow-800">Your plan will change!</h3>
          <div className="mt-2 text-sm text-yellow-700">
            {existingSubscription.downgradeTo === 'cancel' && (
              <p>You have already paid for the <b>{existingSubscription.currentPlan}</b> plan for the agreed term, but your plan will <b>expire</b> at the next subscription renewal cycle and <b>you will not be charged again</b> after that time.</p>
            )}
            {existingSubscription.downgradeTo !== 'cancel' && (
              <p>You have already paid for the <b>{existingSubscription.currentPlan}</b> plan, but your plan will change to <b>{existingSubscription.downgradeTo}</b> at the next subscription renewal cycle.</p>
            )}
            <p className='mt-2'>If you make additional changes to your subscription, it will override your previous planned change</p>
          </div>
        </div>
      </div>
    </div>
  </>
}
export default Downgrade

Downgrade.propTypes = {
  existingSubscription: PropTypes.object.isRequired
}
