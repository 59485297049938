import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { AnnotationIcon } from '@heroicons/react/solid'
import { debounce } from 'throttle-debounce'
import { useForm } from 'react-hook-form'
import SVGIcon from '@/components/icon'

import useQuery from '@/hooks/useQuery'
import { useGlobalState } from '@/state'
import useModal from '@/hooks/useModalV2'
import Modal from '@/shared/Modal'
import Card from '@/shared/Card'
import PrimaryButton from '@/shared/Buttons/Primary'
import FroalaWrapper from '@/shared/FroalaWrapper'

const RecommendationModal = ({ user }) => {
  const { openModal, closeModal, isOpen } = useModal()
  const [, setToast] = useGlobalState('toast')
  const [customError, setCustomError] = useState(null)
  const { putpostRequest } = useQuery()
  const { formState, register, reset, unregister, handleSubmit, setValue, getValues, setError } = useForm({
   defaultValues: {
     how_known: 'Peer',
     body: null
    }
  })

  const { errors } = formState
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    register('body', { required: true })
    return () => {
      unregister('body')
    }
  }, [register])

  const onSubmit = debounce(300, (data) => {
    setLoading(true)

    const formData = {
      user_id: user.id,
      recommendation: {
        body: data.body,
        how_known: data.how_known
      }
    }

    putpostRequest('/api/v3/recommendations', 'POST', formData, (err, jsonData) => {
      setLoading(false)
      if (err) { // 422 code
        if (typeof err === 'string') { // 422 custom error
          setCustomError(err)
        }
        if (typeof err !== 'string') { // 500 code on API
          Object.entries(err).forEach(([key, value]) => {
            setError(key, value)
          })
        }
        return
      }
      setToast(<div className="ml-3 w-0 flex-1 pt-0.5">
        <p className="text-sm font-medium dark:text-white text-gray-800">Recommendation sent!</p>
        <p className="mt-1 text-sm dark:text-gray-100 text-gray-500">This person will be able to approve it, and then it will show up on theirs and your profile.</p>
      </div>)
      reset({ ...getValues(), body: ' ' }) // resetting this without this hack kills froala somehow
      closeModal()
    })
  })

  const keyPress = (e) => {
    if (e.target.name === 'name' && e.keyCode === 13) { e.preventDefault() }
    if ((e.metaKey || e.ctrlKey) && e.keyCode === 13) {
      if (typeof (e.target.name) === 'undefined') {
        e.target.blur() // froala hack to make it update model so it can save properly
      }
      handleSubmit(onSubmit)()
    }
  }

  const handleUpdateFroala = (key, val) => {
    setValue(key, val, { shouldDirty: true, shouldValidate: true })
  }

  const body = getValues().body

  return <>
    <button onClick={openModal}
      type="button" className="flex items-center space-x-1 justify-center p-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white dark:bg-gray-800 dark:text-gray-200 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccblue" >
      <SVGIcon name='icon-premium-only' className='h-7 w-7 sm:h-5 sm:w-5' />
      <span className='hidden sm:block'>Recommend</span>
    </button>
    <Modal isOpen={isOpen} closeModal={closeModal} >
      <Card title="Recommendation"
        footer={
          <div className='flex flex-rows justify-end items-end'>
            <div className='flex flex-col items-end w-min ml-2'>
              <div className="hidden sm:block w-min text-xs border border-gray-200 dark:border-gray-800 rounded px-2 font-sans text-gray-500 dark:text-gray-400">⌘/ctrl+ENTER</div>
              <PrimaryButton className='w-full' onClick={handleSubmit(onSubmit)} loading={loading} text='Save' />
            </div>
          </div>
        } >
        <form>
          <div className="grid sm:grid-cols-4 gap-6">
            <div className="col-span-3 sm:col-span-3">
              <label htmlFor="country" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                How do you know this person?
              </label>
              <div className="mt-1">
                <select {...register('how_known', { required: true })}
                  className="shadow-sm dark:bg-gray-900 dark:text-white focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                >
                  <option value="ManagedThem">I managed them on a project</option>
                  <option value="Peer">I was a peer on a project with them</option>
                  <option value="ManagedMe">They managed me on a project</option>
                  <option value="Friend">We're just friends</option>
                </select>
              </div>
            </div>

            <div className="col-span-4 sm:col-span-4">
              <div className='mt-4'>
                <label htmlFor="pricing" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                  Recommendation Text
                </label>
                <div className="mt-1 shadow-sm w-full min-w-full">
                  <FroalaWrapper
                    model={body}
                    heightMin={100}
                    placeholderText="I have known Buford since he was born. There hasn't been a kinder, more passionate, and dare I say more handsome Buford I've ever seen in the last 24ish hours."
                    keyPress={keyPress}
                    updateModel={(val) => handleUpdateFroala('body', val)}
                  />
                </div>
                { errors.body && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
              </div>
            </div>
          </div>
        </form>
        { customError && <div className='mt-2 text-sm text-red-600'>{customError}</div> }
      </Card>
    </Modal>
  </>
}

export default RecommendationModal

RecommendationModal.propTypes = {
  user: PropTypes.object.isRequired
}
