import React, { useEffect } from 'react'
import { Switch, Route, Link, useParams, useLocation } from 'react-router-dom'
import { useGlobalState } from '@/state'
import useQuery from '@/hooks/useQuery'

import Loading from '@/shared/Loading'
import { EyeIcon } from '@heroicons/react/solid'
import Breadcrumbs from '@/Layout/Breadcrumbs'
import PageTitle from '@/Layout/PageTitle'

import ManageProjectsIndex from '@/pages/ManageProjects/ManageProjectsIndex'
import ManageProjectStart from '@/pages/ManageProjects/ManageProjectStart'
import ManageProjectsDashboard from '@/pages/ManageProjects/ManageProjectsDashboard'
import ProjectEdit from '@/pages/ManageProjects/ProjectEdit'
import SubmissionsIndex from '@/pages/ManageProjects/SubmissionsIndex'
import ProjectMessagesIndex from '@/pages/ManageProjects/ProjectMessagesIndex'
import RolesIndex from '@/pages/ManageProjects/RolesIndex'
import ManageProjectsTabs from '@/pages/ManageProjects/Tabs'
import PublishButtonModal from '@/pages/ManageProjects/PublishButtonModal'

function breadCrumbs() {
   const location = useLocation()
   const activePath = location.pathname
  const [manageProjectContainer] = useGlobalState('manageProjectContainer')
  const { project } = manageProjectContainer
  const crumbs = [
    { to: '/manage/projects', name: 'Manage Projects' }
  ]
  if (project?.id && activePath.includes('/manage/projects/')) {
    crumbs.push({ to: `/manage/projects/${project.id}`, name: `${project.name}` })
  }
  return crumbs
}

function pageTitle() {
  const [manageProjectContainer] = useGlobalState('manageProjectContainer')
  const { project } = manageProjectContainer
  const location = useLocation()
  const activePath = location.pathname
  let title = 'Manage Projects'
  const text = project.status === 'draft' ? 'View Draft' : 'View Published Page'

  const buttons = []
  if (project?.id && activePath.includes('/manage/projects/')) {
    title = project.name
    if (!activePath.includes('/start')) {
      buttons.push(<PublishButtonModal key='publishbuttonmodal' />)
    }
    buttons.push(
      <a key='viewprojectbutton' target="_blank" href={`/projects/${project.slug}`} className="ml-2 whitespace-nowrap inline-flex justify-center px-4 py-2 shadow-sm text-sm font-medium rounded-md text-white dark:text-gray-200 dark:hover:bg-cccblue-alt bg-cccblue hover:bg-cccblue-alt hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900">
      <EyeIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
      {text}
    </a>
    )
  }
  return { title: title, buttons: buttons }
}

export default function ManageProjectsContainer(props) {
  const crumbs = breadCrumbs()
  const titleInfo = pageTitle()

  const { getRequest } = useQuery()
  const { id } = useParams()
  const [manageProjectContainer, setManageProjectContainer] = useGlobalState('manageProjectContainer')
  const { project } = manageProjectContainer

  useEffect(() => {
    if (!id) { return }

    getRequest(`/api/v3/manage/projects/${id}/container`, {}, (err, jsonData) => {
      if (err) { /* handled in hook */ return }

      setManageProjectContainer({
        ...manageProjectContainer,
        project: jsonData.project,
        tabs: jsonData.tabs,
        stats: jsonData.stats
      })
    })
  }, [id])

  if (id && id.toString() !== project?.id?.toString()) { return <Loading /> }

  return <>
    <Breadcrumbs>
      { crumbs.map((crumb, index) => (
        <Link key={`${index}${crumb.name}`} to={crumb.to} className="text-xs sm:text-sm text-gray-500 hover:text-gray-700 dark:text-gray-100">
          {crumb.name}
        </Link>
      ))}
    </Breadcrumbs>
    <PageTitle pagetitle={titleInfo.title}>
      {titleInfo.buttons}
    </PageTitle>
    <Switch>
      <Route {...props} exact path={['/manage/projects/:id/start']}>
        <ManageProjectStart />
      </Route>
      <Route {...props} exact path={['/manage/projects/:id/submissions']}>
        <ManageProjectsTabs />
        <div className='mt-4' />
        <SubmissionsIndex />
      </Route>
      <Route {...props} exact path={['/manage/projects/:id/roles']}>
        <ManageProjectsTabs />
        <div className='mt-4' />
        <RolesIndex />
      </Route>
      <Route {...props} exact path={['/manage/projects/:id/edit']}>
        <ManageProjectsTabs />
        <div className='mt-4' />
        <ProjectEdit />
      </Route>
      <Route {...props} exact path={['/manage/projects/:id/messages']}>
        <ManageProjectsTabs />
        <div className='mt-4' />
        <ProjectMessagesIndex />
      </Route>
      <Route {...props} exact path={['/manage/projects/:id']}>
        <ManageProjectsTabs />
        <div className='mt-4' />
        <ManageProjectsDashboard />
      </Route>
      <Route {...props} exact path={['/manage/projects']}>
        <ManageProjectsIndex />
      </Route>
    </Switch>
  </>
}
